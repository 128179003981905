import { Answer } from './scenarios/services/type';

declare global {
  interface WindowEventMap {
    deleteAnswer: CustomEvent<number>;
    updateAnswer: CustomEvent<{ id: number; stepRef: number }>;
  }
}

export type GraphModals = 'delete' | 'import' | 'idle';

export interface Scenario {
  id: number;
  reference: number;
  label: string;
  version: number;
  zoneId: number;
  zones?: Zone[];
  nextRef?: number;
  prevRef?: number;
  mediasId?: number[];
  tasks?: Task[];
  deleted?: boolean;
  isDraft: boolean;
  isLast: boolean;
  pendingReview: boolean;
  pendingApprove: boolean;
  validationDate: Date;
  validator: string;
  validatedPrevRef?: number;
  validatedNextRef: number[];
  tags: Tag[];
}

export interface ZoneLevel {
  id: number;
  label: string;
  rank: number;
}

export interface Zone {
  id: number;
  label: string;
  childs: Zone[];
  gears?: Gear[];
  scenarios?: Scenario[];
  parentId?: number;
  rank?: number;
  useQRCode?: boolean;
}

export interface Task {
  id: number;
  reference: number;
  label: string;
  scenarioId: number;
  scenarioRef: number;
  rank: number;
  steps?: Step[];
  deleted?: boolean;
}

export interface Step {
  id: number;
  reference: number;
  description: string;
  label: string;
  taskId: number;
  rank: number;
  gearsId?: number[];
  gearsOrder?: number[];
  mediasOrder?: number[];
  mediasId?: number[];
  answers?: Answer[];
  inputRequests?: InputRequest[];
  linkedCases?: Answer[];
  mandatoryValidation?: boolean;
  taskRank?: number;
  taskRef: number;
  deleted?: boolean;
  isDraft: boolean;
  zone?: Zone;
}

export interface User {
  id: number;
  authId?: string;
  zoneId?: number;
  zones: Zone[];
  alertsId?: number[];
  firstname?: string;
  surname?: string;
  roles: string;
  email: string;
  alertScenarioRefs?: number[];
  interventionScenarioRefs?: number[];
}

export interface Folder {
  id: number;
  label: string;
  zoneId?: number | null;
  parentId?: number | null;
  childs?: Folder[];
  medias?: Media[];
  deleted: boolean;
  updateDate?: string;
  isStandard: boolean;
}

export interface Media {
  id: number;
  url: string;
  filename: string;
  label: string;
  originId?: number;
  zoneId?: number;
  zoneLabel?: string;
  gearId?: number;
  gearLabel?: string;
  stepRef?: number;
  stepLabel?: string;
  scenarioRef?: number;
  step?: Step;
  scenarioLabel?: string;
  replicasId: number[];
  replicas?: Media[];
  updateDate?: string;
  isStandard?: boolean;
  folderId?: number;
  deleted: boolean;
}

export interface InputRequest {
  id: number;
  label: string;
  reference: number;
  stepId: number;
  stepRef: number;
  deleted: boolean;
  isDraft: boolean;
  isLast: boolean;
}

export interface InputResponse {
  id: number;
  inputRequestId: number;
  inputRequest?: InputRequest;
  interventionStepId: number;
  value: number;
}

export interface GearLevel {
  id: number;
  label: string;
  rank: number;
}

export interface Gear {
  id: number;
  label: string;
  zoneId: number;
  placed: boolean;
  rank?: number;
  positionId: number;
  deleted: boolean;
  childs: Gear[];
  position?: Position;
  parentId?: number;
  gmaoLink?: string;
  parent?: Gear;
  zone?: Zone;
  steps?: Step[];
}

export interface Tag {
  id: number;
  label: string;
  scenarios: Scenario[];
}

export interface Alert {
  id: number;
  label: string;
  state: 'todo' | 'doing' | 'done';
  mediasId: number[];
  zoneId?: number;
  zone?: Zone;
  userId: number;
  gear: Gear;
  interventionStep: object;
  createDate: string;
}

export interface Session {
  id: number;
  userId: number;
}
export interface Intervention {
  id: number;
  scenario: Scenario;
  session: Session;
  startDate: string;
  duration: number;
  interventionSteps: InterventionStep[];
  switchPin: number;
}

export interface InterventionStep {
  id: number;
  alerts: Alert[];
  step: Step;
  duration: number;
  createDate: string;
  comments: Comment[];
  inputResponses: InputResponse[];
  isOk?: boolean;
  commentId?: number;
  medias?: Media[];
  comment: string;
  children?: any[];
  interventionId: number;
}

export interface Comment {
  id: number;
  medias: Media[];
  text: string;
  ok: boolean;
  gearId?: number;
}

export interface CommentInputUpdate {
  id: number;
  text: string;
}

export interface Subscription {
  subscribed: boolean;
}

export interface Statistics {
  t2s: number;
  canceledAlerts: number;
  zoneAlerts: number;
  scan: number;
  gearAlerts: number;
  prevScenario: number;
  mandatorySteps: number;
  possibleMandatorySteps: number;
}

export interface ScenarioStatistics extends Scenario {
  id: number;
  label: string;
  prevId?: number | null;
  nextRef?: number;
  prevRef?: number;
  reference: number;
  zoneId: number;
  hasPreviousScenario?: boolean;
  hasMandatorySteps?: boolean;
}

export interface Position {
  id: number;
  placed: boolean;
  x: number;
  y: number;
  z: number;
  rx: number;
  ry: number;
  rz: number;
  sx: number;
  sy: number;
  sz: number;
}

export enum AuditAction {
  validate = 'VALIDATED',
  reviewe = 'ASK_FOR_REVIEW',
  approve = 'ASK_FOR_APPROVAL',
  declined = 'DECLINED',
}

export interface Audit {
  id: number;
  scenarioId: number;
  user: string;
  date: Date;
  action: AuditAction;
}
