import React from 'react';
import { Button, Input } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { InputRequest } from 'business/type';
import { Flex } from 'ui/flex';
import { InputRequestInputUpdate } from 'business/scenarios/services/type';
import classes from './index.module.scss';

interface StepEditionInputRequestProps {
  value: InputRequest[];
  selectedStep: number;
  canUpdate: boolean;
  onCreate: (label: string) => void;
  onChange: (input: InputRequest[]) => void;
  onUpdate: (inputRequestRefInput: InputRequestInputUpdate) => void;
  onDelete: (inputRequestRef: number) => void;
}

export default function StepEditionInputRequest({
  value,
  selectedStep,
  canUpdate,
  onCreate,
  onChange,
  onUpdate,
  onDelete,
}: StepEditionInputRequestProps) {
  const { t } = useTranslation('scenarios');

  async function handleAddInputRequest() {
    onCreate(t('detail.step-edition.placeholder.input-request'));
  }

  function handleChangeLabel(inputRequestRef: number, label: string) {
    const updatedInputRequests = value.map((inputRequest) =>
      inputRequest.id === inputRequestRef
        ? { ...inputRequest, label }
        : inputRequest,
    );
    onChange(updatedInputRequests);
  }

  return (
    <>
      {value.map((inputRequest) => (
        <Flex key={inputRequest.id} className={classes.inputRequest}>
          <Input
            id="label"
            name="label"
            defaultValue={inputRequest.label}
            disabled={!canUpdate || inputRequest.id === -1}
            onChange={(e) =>
              handleChangeLabel(inputRequest.reference, e.target.value)
            }
            onPressEnter={(e) => {
              onUpdate({
                reference: inputRequest.reference,
                stepRef: selectedStep,
                label: e.currentTarget.value,
              });
            }}
            onBlur={(e) => {
              onUpdate({
                reference: inputRequest.reference,
                stepRef: selectedStep,
                label: e.currentTarget.value,
              });
            }}
            placeholder={t('detail.step-edition.placeholder.input-request')}
          />
          {canUpdate ? (
            <DeleteOutlined
              className={classes.delete}
              onClick={() => onDelete(inputRequest.reference)}
            />
          ) : null}
        </Flex>
      ))}
      {value.length < 6 && canUpdate ? (
        <Button onClick={() => handleAddInputRequest()} type="link">
          <PlusOutlined />
          {t('detail.step-edition.step-type-input-request.add')}
        </Button>
      ) : null}
    </>
  );
}
