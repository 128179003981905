export enum Environment {
  local = 'local',
  ci = 'ci',
  testing = 'testing',
  staging = 'staging',
  production = 'production',
}

export const environmentVariables = {
  apiURL: assertExist(process.env.REACT_APP_API_URL),
  apiURLMedia: assertExist(process.env.REACT_APP_API_URL_MEDIA_UPLOAD),
  env: assertEnvironment(process.env.REACT_APP_ENV),
  azureActiveDirectory: {
    clientId: assertExist(process.env.REACT_APP_AZURE_AD_CLIENT_ID),
    authority: castURL(assertExist(process.env.REACT_APP_AZURE_AD_AUTHORITY)),
    login: {
      strategy: assertLoginStrategy(
        process.env.REACT_APP_AZURE_AD_LOGIN_STRATEGY,
      ),
      scopes: assertExist(process.env.REACT_APP_AZURE_AD_LOGIN_SCOPE),
    },
  },
};

/*
  Assertions
*/

function assertEnvironment(env?: string): Environment {
  if (!env || !(env in Environment)) {
    throw new Error(
      `Application environment not valid '${env}'. Can be one of ${Object.values(
        Environment,
      )}`,
    );
  }
  return env as Environment;
}

function assertLoginStrategy(strategy?: string): 'popup' | 'redirect' {
  const strategies = ['popup', 'redirect'];
  if (!strategy || !strategies.includes(strategy)) {
    throw new Error(
      `Login strategy '${strategy}' is not supported. Strategies accepted: ${strategies}`,
    );
  }
  return strategy as 'popup' | 'redirect';
}

function assertExist<T>(value?: T): Exclude<T, undefined | null> {
  if (value === null || typeof value === 'undefined') {
    throw new Error(`Environment variable does not exist.`);
  }
  return value as Exclude<T, undefined | null>;
}

function castURL(url: string): URL {
  return new URL(url);
}
