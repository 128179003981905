import { Zone } from 'business/type';
import { api } from 'technical/api';
import { ZoneInputCreate, ZoneInputUpdate } from './type';

export async function getZone(tenant: string, zone: number) {
  return api(tenant)
    .get<Zone>(`/v1/zones/${zone}`)
    .then((response) => response.data);
}

export async function deleteZone(tenant: string, zone: number) {
  return api(tenant).delete<void>(`/v1/zones/${zone}`);
}

export async function createZone(tenant: string, input: ZoneInputCreate) {
  const { data: zone } = await api(tenant).post<Pick<Zone, 'id'>>(
    '/v1/zones',
    input,
  );
  return zone;
}

export async function updateZone(tenant: string, input: ZoneInputUpdate) {
  await api(tenant).patch<void>(`/v1/zones/${input.id}`, {
    ...input,
  });
}

export async function uploadCsv(tenant: string, zoneId: number, file: any) {
  const fmData = new FormData();
  fmData.append('gears', file);
  await api(tenant).post<void>(`/v1/gears/imports/zones/${zoneId}`, fmData);
}

export async function getZoneTree(tenant: string, zone: number) {
  const tree = api(tenant)
    .get<Zone>(`/v1/zones/trees/${zone}`)
    .then((response) => response.data);

  return tree;
}

export async function getZoneFullTree(tenant: string, zoneId: number) {
  const { data: tree } = await api(tenant).get<Zone>(
    `/v1/zones/fulltrees/${zoneId}`,
  );
  return tree;
}
