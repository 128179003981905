import { Scenario } from 'business/type';
import { api } from 'technical/api';

export default async function getScenarioListFromZone(
  tenant: string,
  id: number,
) {
  const scenarioList = api(tenant)
    .get<Scenario[]>(`/v1/scenarios/zones/${id}?deleted=true`)
    .then((response) => response.data);

  const [scenarios] = await Promise.all([scenarioList]);

  return scenarios.sort((a, b) => b.reference - a.reference);
}
