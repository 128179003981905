import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Col, Row } from 'antd';
import { Draggable } from '@hello-pangea/dnd';
import { Media } from 'business/type';
import { MediaThumbnail } from 'business/medias/routes/library/thumbnail';
import { useUrlQueryParams } from 'technical/hooks/use-url-query-params';

interface DraggableMediasGaleryProps {
  medias: Media[];
  canUpdate?: boolean;
  type?: 'preview' | 'thumbnail' | 'detail';
}

export function DraggableMediasGalery({
  medias,
  type = 'preview',
  canUpdate,
}: DraggableMediasGaleryProps) {
  const location = useLocation();
  const query = useUrlQueryParams();

  return (
    <Row gutter={16}>
      {medias.map((media, index) => {
        const isExpertCapture = media.label.startsWith('expert_capture_video');
        if (isExpertCapture) {
          return null;
        }

        return (
          <Draggable
            key={media.id}
            draggableId={`medias-${media.id.toString()}`}
            index={index}
            isDragDisabled={!canUpdate}
          >
            {(provided, snapshot) => {
              const style = {
                ...provided.draggableProps.style,
                backgroundColor: snapshot.isDragging ? '#f5f5f5' : 'white',
              };
              const link = `${location.pathname}?media_id=${media.id}`;

              return (
                <Col
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...provided.draggableProps}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                  style={style}
                >
                  <Link to={link}>
                    <MediaThumbnail
                      fromModal={false}
                      media={media}
                      mediaQueryId={query.get('media_id')}
                      type={type}
                      canUpdate={canUpdate}
                    />
                  </Link>
                </Col>
              );
            }}
          </Draggable>
        );
      })}
    </Row>
  );
}
