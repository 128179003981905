import arrow_curve_sharp from "./arrow_curve_sharp_thumbnail.jpg";
import arrow_curve_smooth from "./arrow_curve_smooth_thumbnail.jpg";
import arrow_straight from "./arrow_straight_thumbnail.jpg";
import arrow_turn_back from "./arrow_turn_back_thumbnail.jpg";
import arrow_turn_long from "./arrow_turn_long_thumbnail.jpg";
import arrow_turn from "./arrow_turn_thumbnail.jpg";
import arrow_zigzag_long from "./arrow_zigzag_long_thumbnail.jpg";
import arrow_zigzag_short from "./arrow_zigzag_short_thumbnail.jpg";
import arrow_zigzag_smooth from "./arrow_zigzag_smooth_thumbnail.jpg";
import arrow_clockwise from "./arrow_clockwise.jpg";
import arrow_counter_clockwise from "./arrow_counter_clockwise.jpg";
import arrow_straight_blue from "./arrow_straight_blue.jpg";
import arrow_straight_green from "./arrow_straight_green.jpg";
import arrow_straight_orange from "./arrow_straight_orange.jpg";
import arrow_straight_red from "./arrow_straight_red.jpg";
import arrow_straight_yellow from "./arrow_straight_yellow.jpg";
import arrow_turn_1_blue from "./arrow_turn_1_blue.jpg";
import arrow_turn_1_green from "./arrow_turn_1_green.jpg";
import arrow_turn_1_orange from "./arrow_turn_1_orange.jpg";
import arrow_turn_1_red from "./arrow_turn_1_red.jpg";
import arrow_turn_1_yellow from "./arrow_turn_1_yellow.jpg";

const StandardPreview: { [media_name: string]: string } = {
  arrow_curve_sharp,
  arrow_curve_smooth,
  arrow_straight,
  arrow_turn_back,
  arrow_turn_long,
  arrow_turn,
  arrow_zigzag_long,
  arrow_zigzag_short,
  arrow_zigzag_smooth,
  arrow_clockwise,
  arrow_counter_clockwise,
  arrow_straight_blue,
  arrow_straight_green,
  arrow_straight_orange,
  arrow_straight_red,
  arrow_straight_yellow,
  arrow_turn_1_blue,
  arrow_turn_1_green,
  arrow_turn_1_orange,
  arrow_turn_1_red,
  arrow_turn_1_yellow,
};

export { StandardPreview };
