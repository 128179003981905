import React from "react";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { Statistics } from "business/type";
import GenericTable from "ui/table";

interface MonitoringListProps {
  monitoringData: Statistics;
}
interface StatisticsDataSource {
  key: string;
  label: string;
  value: number;
}

function FormatStatisticsTab(stats: Statistics) {
  const { t } = useTranslation("monitoring");
  const statsArray: StatisticsDataSource[] = Object.entries(stats).map(
    ([key, value]) => ({
      key,
      label: t(`dataLabels.${key}`),
      value,
    })
  );

  return statsArray.filter((stat) => stat.key !== "id");
}

export default function MonitoringList({
  monitoringData,
}: MonitoringListProps) {
  const { t } = useTranslation("monitoring");

  const columns: ColumnsType<any> = [
    {
      title: t("dashboard.data"),
      dataIndex: "label",
      key: "label",
    },
    {
      title: t("dashboard.value"),
      dataIndex: "value",
      key: "value",
    },
  ];

  return (
    <GenericTable
      dataSource={FormatStatisticsTab(monitoringData)}
      columns={columns}
      pageSize={20}
    />
  );
}
