import React from 'react';
import { Navigate, Route, Routes } from 'react-router';
import LoginPage from './users/routes/login';
import UsersPage from './users/routes/users';
import ScenariosPage from './scenarios/routes/scenarios';
import UserDetailPage from './users/routes/user-detail';
import TopZonePage from './zones/routes/top-zone';
import MediasPage from './medias/routes/library';
import AlertsPage from './alerts/routes/alerts';
import AlertDetailPage from './alerts/routes/alert-detail';
import InterventionsPage from './interventions/routes/interventions';
import InterventionDetailPage from './interventions/routes/intervention-detail';
import MonitoringDashboardPage from './monitoring/routes/dashboard';
import ScenarioWithGraph from './scenarios/routes/scenario-graph';
import DigitalTwinPage from './digital-twin/routes/digital-tree';
import RedirectPage from './users/routes/redirect';

export function UnauthenticatedRoutes() {
  return (
    <Routes>
      <Route path="/redirect" element={<RedirectPage />} />
      <Route path="/" element={<LoginPage />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export function AuthenticatedRoutes() {
  return (
    <Routes>
      <Route path="/users" element={<UsersPage />} />
      <Route path="/scenarios" element={<ScenariosPage />}/>
      <Route path="/scenarios/:reference" element={<ScenarioWithGraph />} />
      <Route path="/medias/" element={<MediasPage />} />
      <Route path="/medias/personal" element={<MediasPage />} />
      <Route path="/medias/standard" element={<MediasPage />} />
      <Route path="/medias/personal/:mediaFolderId" element={<MediasPage />} />
      <Route path="/medias/standard/:mediaFolderId" element={<MediasPage />} />
      <Route path="/alerts/" element={<AlertsPage />} />
      <Route path="/alerts/:alertId" element={<AlertDetailPage />} />
      <Route path="/interventions/" element={<InterventionsPage />} />
      <Route
        path="/interventions/:interventionId"
        element={<InterventionDetailPage />}
      />
      <Route path="/zones-top" element={<TopZonePage />} />
      <Route path="/zones" element={<DigitalTwinPage />} />
      <Route path="/users/:id" element={<UserDetailPage />} />
      <Route path="/monitoring" element={<MonitoringDashboardPage />} />
      <Route path="*" element={<Navigate to="/scenarios" replace />} />
    </Routes>
  );
}
