import React from 'react';
import { Alert, Tabs, TabsProps, UploadProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useHandleMedias } from 'business/scenarios/hooks/medias';
import { Media } from 'business/type';
import PersonalMedias from './personalMedias';
import StandardMedias from './standardMedias';

interface MediasProps {
  isModal: boolean;
  mediasRessource?: Media[];
  selectionIsLoading?: boolean;
  disableFolderActions?: boolean;
  onSelect?: (input: Media) => void;
  onUnselect?: (input: Media) => void;
  onUpload: UploadProps['customRequest'];
  onDeleteMedia?: (media: Media, currentFolderId?: number) => void;
}

type MediaParams = {
  mediaFolderId: string;
};

export default function Medias({
  isModal,
  mediasRessource,
  selectionIsLoading,
  disableFolderActions = false,
  onSelect,
  onUnselect,
  onUpload,
  onDeleteMedia,
}: MediasProps) {
  const { t } = useTranslation('medias');
  const navigate = useNavigate();
  const { mediaFolderId } = useParams<keyof MediaParams>() as MediaParams;
  const location = useLocation();
  const urlFolderId = parseInt(mediaFolderId, 10);
  const { queries } = useHandleMedias({ personalFolderId: urlFolderId });

  const standardPathnameRegex = /medias\/standard\/\d*/g;
  const isStandardPathname = standardPathnameRegex.test(location.pathname);

  const items: TabsProps['items'] = [
    {
      key: 'personal',
      label: t('list.tab.personal'),
      children: (
        <PersonalMedias
          isModal={isModal}
          mediasRessource={mediasRessource}
          selectionIsLoading={selectionIsLoading}
          disableFolderActions={disableFolderActions}
          onSelect={onSelect}
          onUnselect={onUnselect}
          onUpload={onUpload}
          onDeleteMedia={onDeleteMedia}
        />
      ),
    },
    {
      key: 'standard',
      label: t('list.tab.standard'),
      children: (
        <StandardMedias
          isModal={isModal}
          mediasRessource={mediasRessource}
          selectionIsLoading={selectionIsLoading}
          disableFolderActions={disableFolderActions}
          onSelect={onSelect}
          onUnselect={onUnselect}
        />
      ),
    },
  ];

  return (
    <>
      {queries.mediasTopZone.isError ? (
        <Alert type="error" message={t('list.error')} />
      ) : null}
      <Tabs
        defaultActiveKey={isStandardPathname ? 'standard' : 'personal'}
        items={items}
        onChange={(key) => {
          if (isModal) {
            const link = `${location.pathname}?modal=add_media&media_type=${key}`;
            navigate(link);
          } else {
            const link = `/medias/${key}/`;
            navigate(link);
          }
        }}
      />
    </>
  );
}
