import React from 'react';
import { Languages } from 'translations/types';
import {
  Menu as AntMenu,
  Select,
  Button,
  Tooltip,
  Typography,
  MenuProps,
} from 'antd';
import { HomeOutlined, LogoutOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Flex } from 'ui/flex';
import { useUserSession } from 'technical/hooks/use-user-session';
// @ts-ignore
import ReactCountryFlag from 'react-country-flag';
import classes from './menus.module.scss';

export function UserMenu({ collapsed }: { collapsed: boolean }) {
  const { t, i18n } = useTranslation('common');
  const {
    name,
    tenant,
    availableTenants,
    switchTenant,
    logout,
    zone,
    availableZones,
    changeZone,
  } = useUserSession();

  const menuItems: MenuProps['items'] = [
    {
      key: 'selection-zone',
      label: (
        <Select
          data-testid="user-select-menu"
          showSearch
          optionFilterProp="label"
          optionLabelProp="label"
          filterOption={(input, option) =>
            option?.label
              ?.toString()
              .toLowerCase()
              .indexOf(input.toLowerCase())! >= 0
          }
          className={classes.select}
          onChange={(value) => {
            changeZone(value);
          }}
          value={zone}
          disabled={availableZones.length < 2}
        >
          {availableZones
            .sort((optionA, optionB) =>
              optionA.label
                .toLowerCase()
                .localeCompare(optionB.label.toLowerCase()),
            )
            .map((option) => (
              <Select.Option
                value={option.id}
                key={option.id}
                label={option.label}
              >
                {option.label}
              </Select.Option>
            ))}
        </Select>
      ),
      icon: <HomeOutlined />,
    },
    availableTenants.length > 1
      ? {
          key: 'tenant',
          label: (
            <Flex items="center" gap={8} width="100%">
              <Flex.Child shrink={0}>{t('user.tenant')}</Flex.Child>
              <Flex.Child grow={1}>
                <Select
                  showSearch
                  className={classes.tenantSelect}
                  onChange={switchTenant}
                  value={tenant}
                  key={tenant}
                >
                  {availableTenants.map((availableTenant) => (
                    <Select.Option
                      value={availableTenant}
                      key={availableTenant}
                    >
                      {availableTenant}
                    </Select.Option>
                  ))}
                </Select>
              </Flex.Child>
            </Flex>
          ),
        }
      : null,
    {
      key: 'lang',
      label: (
        <Select
          showSearch
          /* className={classes.languageSelect} */
          onChange={(value) => i18n.changeLanguage(value)}
          value={i18n.language}
        >
          {Object.keys(Languages).map((lang) => (
            <Select.Option value={lang} key={lang}>
              <ReactCountryFlag countryCode={lang === 'en' ? 'us' : lang} svg />{' '}
              {lang}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      key: 'logout',
      label: (
        <Flex items="center" gap={6}>
          <Flex.Child shrink={0}>
            <Tooltip title={t('user.logout')} placement="bottom">
              <Button onClick={logout} icon={<LogoutOutlined />} />
            </Tooltip>
          </Flex.Child>
          <Flex.Child>
            <Typography className={classes.username}>{name}</Typography>
          </Flex.Child>
        </Flex>
      ),
    },
  ];

  return !collapsed ? (
    <Flex direction="column" gap={12} className={classes.userMenu}>
      <AntMenu mode="inline" theme="dark" selectedKeys={[]} items={menuItems} />
    </Flex>
  ) : null;
}
