import React from 'react';
import { Modal, UploadProps } from 'antd';
import { useTranslation } from 'react-i18next';
import Medias from 'business/medias/routes/library/medias';
import { Media } from 'business/type';
import classes from './modals.module.scss';

interface MediasAddModalProps {
  mediasRessource: Media[];
  selectionIsLoading?: boolean;
  onClose: () => void;
  onSelect?: (input: Media) => void;
  onUnselect?: (input: Media) => void;
  onUpload: UploadProps['customRequest'];
  onDeleteMedia: (media: Media) => void;
}

export function MediasAddModal({
  mediasRessource,
  selectionIsLoading,
  onClose,
  onSelect,
  onUnselect,
  onUpload,
  onDeleteMedia,
}: MediasAddModalProps) {
  const { t } = useTranslation('medias');

  const handleDelete = (origin: Media) => {
    const mediaChild = mediasRessource.find((el) => el.originId === origin.id);
    if (mediaChild) onDeleteMedia(mediaChild);
    if (document.activeElement) (document.activeElement as HTMLElement).blur();
  };

  const handleSelect = (media: Media) => {
    if (onSelect) onSelect(media);
  };

  const handleUnselect = (media: Media) => {
    if (onUnselect) onUnselect(media);
  };

  return (
    <Modal
      open
      centered
      // 70% arbitrary value to have the modal not taking all the screen but still be wide enough
      width="70%"
      title={t('modal.add.title')}
      onCancel={onClose}
      onOk={onClose}
      okText={t('modal.add.submit')}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <div className={classes.modalMediaContent}>
        <Medias
          isModal
          disableFolderActions
          mediasRessource={mediasRessource}
          selectionIsLoading={selectionIsLoading}
          onUpload={onUpload}
          onDeleteMedia={handleDelete}
          onSelect={onSelect ? (media) => handleSelect(media) : undefined}
          onUnselect={onUnselect ? (media) => handleUnselect(media) : undefined}
        />
      </div>
    </Modal>
  );
}
