import React, { useState } from 'react';
import { Checkbox, Form, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

interface GearMediaValidationModalProps {
  onClose: () => void;
  onSubmit: (keepGears: boolean, keepMedias: boolean) => void;
}

export function GearMediaValidationModal({
  onClose,
  onSubmit,
}: GearMediaValidationModalProps) {
  const { t } = useTranslation('scenarios');

  const [keepGears, setKeepGears] = useState(false);
  const [keepMedias, setKeepMedias] = useState(false);

  function onSubmitForm() {
    onSubmit(keepGears, keepMedias);
    onClose();
  }

  return (
    <Modal
      open
      title={t('duplication.modal.title')}
      okText={t('duplication.modal.submit')}
      onOk={onSubmitForm}
      cancelText={t('duplication.modal.cancel')}
      onCancel={onClose}
    >
      <Form name="scenario">
        <Form.Item
          label={t('duplication.modal.inputs.gear')}
          labelAlign="left"
          name="gear"
        >
          <Checkbox onChange={() => setKeepGears(!keepGears)} />
        </Form.Item>

        <Form.Item
          label={t('duplication.modal.inputs.medias')}
          labelAlign="left"
          name="medias"
        >
          <Checkbox onChange={() => setKeepMedias(!keepMedias)} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
