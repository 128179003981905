import { GearLevel, Gear } from "business/type";
import { api } from "technical/api";
import { DoubleGearInputCreate, GearInputCreate } from "./type";

export async function getGearLevels(tenant: string) {
  const { data: levels } = await api(tenant).get<GearLevel[]>("/v1/gearlevels");
  return levels;
}

export async function getZoneGears(tenant: string, zoneId: number) {
  const { data: gears } = await api(tenant).get<Gear[]>(
    `/v1/gears/zones/${zoneId}?deleted=true`
  );
  return gears;
}

export async function createGear(tenant: string, input: GearInputCreate) {
  const { data: gear } = await api(tenant).post<Pick<Gear, "id">>(
    "/v1/gears",
    input
  );
  return gear;
}

export async function createBothGear(
  tenant: string,
  input: DoubleGearInputCreate
) {
  const { data: parent } = await api(tenant).post<Pick<Gear, "id">>(
    "/v1/gears",
    { label: input.parentLabel, zoneId: input.zoneId }
  );

  const { data: gear } = await api(tenant).post<Pick<Gear, "id">>("/v1/gears", {
    label: input.label,
    zoneId: input.zoneId,
    parentId: parent.id,
  });
  return gear;
}
