// eslint-disable-next-line max-classes-per-file
import { CSSProperties } from 'react';
import { Edge, EdgeMarker, MarkerType } from 'reactflow';

export class CustomEdge {
  id: string;

  source: string;

  target: string;

  type: string;

  hidden: boolean;

  markerEnd: EdgeMarker;

  style: CSSProperties;

  animated: boolean;

  selectedStepId: number | undefined;

  constructor({
    id,
    source,
    target,
    selectedStepId,
    hidden = false,
  }: Pick<Edge, 'source' | 'target' | 'hidden' | 'animated'> & {
    selectedStepId: number | undefined;
    id: string;
  }) {
    this.id = id;
    this.source = source;
    this.target = target;
    this.hidden = hidden;
    this.type = 'smoothstep';
    this.animated = selectedStepId?.toString() === source;
    this.markerEnd = {
      type: MarkerType.Arrow,
      width: 20,
      height: 20,
      color: selectedStepId?.toString() === source ? '#2F54EB' : '#adb5bd',
    };
    this.style = {
      strokeWidth: selectedStepId?.toString() === source ? 1.5 : 0.5,
      stroke: selectedStepId?.toString() === source ? '#2F54EB' : '#adb5bd',
    };
  }
}
