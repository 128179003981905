import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Descriptions, Modal, Spin, Tag } from 'antd';
import { Media } from 'business/type';
import { Flex } from 'ui/flex';
import { MediaThumbnailPreview } from 'ui/media/thumbnail';
import classes from './modals.module.scss';

interface MediaDetailModalProps {
  media: Media;
  mediasChild?: Media[];
  isModalOpen: boolean;
  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export function MediaDetailModal({
  media,
  mediasChild,
  isModalOpen,
  onCancel,
}: MediaDetailModalProps) {
  const { t } = useTranslation('medias');
  const mediasScenario: Media[] = [];
  const mediasStep: Media[] = [];
  const mediasZone: Media[] = [];
  const mediasGear: Media[] = [];

  if (mediasChild && mediasChild.length > 0) {
    mediasChild.forEach((item) => {
      if (item.gearId) mediasGear.push(item);
      if (item.zoneId) mediasZone.push(item);
      if (
        item.stepRef &&
        !mediasStep.find((mediaTmp) => mediaTmp.stepRef === item.stepRef)
      )
        mediasStep.push(item);
      if (item.scenarioRef) mediasScenario.push(item);
    });
  }

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
      }}
    >
      <Modal
        centered
        // 50% arbitrary value to have the modal not taking all the screen but still be wide enough
        width="50%"
        title={t('modal.detail.title')}
        open={isModalOpen}
        onCancel={onCancel}
        footer={[
          <Button key="back" onClick={onCancel}>
            {t('modal.detail.cancel')}
          </Button>,
        ]}
      >
        <div className={classes.modalMediaContent}>
          <Flex gap={64} direction="column">
            <Flex.Child grow={1} shrink={0}>
              <MediaThumbnailPreview media={media} type="detail" />
            </Flex.Child>
            <Flex.Child shrink={1}>
              <Descriptions title={t('modal.detail.info.title')}>
                <Descriptions.Item label={t('modal.detail.info.label')}>
                  {media.label}
                </Descriptions.Item>
              </Descriptions>
              {media.replicasId && media.replicasId.length > 0 ? (
                <>
                  <Descriptions title={t('modal.detail.link.title')} bordered>
                    {mediasZone.length > 0 ? (
                      <Descriptions.Item label={t('modal.detail.link.zone')}>
                        {mediasZone.map((child) => (
                          <Link
                            key={child.zoneId}
                            to={`/zones/${child.zoneId}`}
                          >
                            <Tag>{child.zoneLabel}</Tag>
                          </Link>
                        ))}
                      </Descriptions.Item>
                    ) : null}
                    {mediasGear.length > 0 ? (
                      <Descriptions.Item label={t('modal.detail.link.gear')}>
                        <p>
                          {mediasGear.map((child) => (
                            <Link
                              key={child.gearId}
                              to={`/gears/${child.gearId}`}
                            >
                              <Tag>{child.gearLabel}</Tag>
                            </Link>
                          ))}
                        </p>
                      </Descriptions.Item>
                    ) : null}
                    {mediasScenario.length > 0 ? (
                      <Descriptions.Item
                        label={t('modal.detail.link.scenario')}
                      >
                        {mediasScenario.map((child) => (
                          <Link
                            key={child.scenarioRef}
                            to={`/scenarios/${child.scenarioRef}`}
                          >
                            <Tag>{child.scenarioLabel}</Tag>
                          </Link>
                        ))}
                      </Descriptions.Item>
                    ) : null}
                    {mediasStep.length > 0 ? (
                      <Descriptions.Item label={t('modal.detail.link.step')}>
                        {mediasStep.map((child) => (
                          <Tag key={child.stepRef}>{child.stepLabel}</Tag>
                        ))}
                      </Descriptions.Item>
                    ) : null}
                  </Descriptions>
                  {mediasChild ? null : <Spin size="large" />}
                </>
              ) : null}
            </Flex.Child>
          </Flex>
        </div>
      </Modal>
    </div>
  );
}
