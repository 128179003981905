import React from "react";
import { Intervention, InterventionStep } from "business/type";
import { Card, Timeline } from "antd";
import {
  CheckCircleOutlined,
  DownCircleOutlined,
  PlayCircleOutlined,
  UpCircleOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import Paragraph from "antd/lib/typography/Paragraph";

interface InterventionTimelineProps {
  intervention: Intervention;
}

export default function InterventionTimeline({
  intervention,
}: InterventionTimelineProps) {
  const { t } = useTranslation("interventions");
  let content;

  const orderSteps = intervention.scenario.tasks
    ?.sort((a, b) => a.rank - b.rank)
    .flatMap((task) =>
      task.steps?.sort((a, b) => a.rank - b.rank).map((step) => step.id)
    );

  const orderInterventionsStep = intervention.interventionSteps.sort(
    (a, b) =>
      new Date(a.createDate).getTime() - new Date(b.createDate).getTime()
  );

  if (orderSteps) {
    const getIcon = (interventionStep: InterventionStep, index: number) => {
      if (index === 0) {
        return { dot: <PlayCircleOutlined />, color: "green" };
      }
      if (
        intervention.interventionSteps &&
        index === intervention.interventionSteps.length - 1
      ) {
        return { dot: <CheckCircleOutlined />, color: "green" };
      }
      if (
        orderSteps.indexOf(interventionStep.step.id) >
        orderSteps.indexOf(orderInterventionsStep[index - 1].step.id)
      ) {
        return { dot: <DownCircleOutlined />, color: "green" };
      }
      return { dot: <UpCircleOutlined />, color: "red" };
    };

    content = (
      <Timeline mode="left">
        {orderInterventionsStep.map((interventionStep, index) => (
          <Timeline.Item
            dot={getIcon(interventionStep, index).dot}
            color={getIcon(interventionStep, index).color}
            key={interventionStep.id}
            label={new Date(interventionStep?.duration ?? 0)
              .toISOString()
              .slice(11, -5)}
          >
            <Paragraph ellipsis={{ tooltip: true }}>
              {interventionStep.step.label}
            </Paragraph>
          </Timeline.Item>
        ))}
      </Timeline>
    );
  } else {
    content = t("timeline.nodisplay");
  }

  return <Card>{content}</Card>;
}
