import { Step } from 'business/type';
import { api } from 'technical/api';
import { Answer } from './type';

export async function getStepInformations(tenant: string, stepId: number) {
  const { data: step } = await api(tenant).get<Step>(`/v1/steps/id/${stepId}`);
  return step;
}

export async function getStepAnswers(tenant: string, stepId: number) {
  const { data: answers } = await api(tenant).get<Answer[]>(
    `/v1/answers/steps/id/${stepId}`,
  );
  return answers;
}
