/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Gear } from 'business/type';
import {
  Badge,
  Col,
  Dropdown,
  Input,
  MenuProps,
  Row,
  Space,
  Typography,
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  PictureOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { useUserSession } from 'technical/hooks/use-user-session';
import { hasEditingRights } from 'technical/auth/authorization';
import { useTranslation } from 'react-i18next';
import { useHandleGears } from 'business/digital-twin/hooks/gears';
import GearCreationModal from 'business/gears/routes/modal/gear-creation';
import GearDeletionModal from 'business/gears/routes/modal/gear-deletion';
import { GearMediaModal } from 'business/medias/routes/modals/gear-media';

interface GearBlockProps {
  gear: Gear;
  searchValue: string;
  addExpand: (id: string) => void;
}

export function GearBlock({ gear, searchValue, addExpand }: GearBlockProps) {
  const { role } = useUserSession();
  const [deletionModalVisible, setDeletionModalVisible] = useState(false);
  const [gearModalVisible, setGearModalVisible] = useState(false);
  const [mediaModalVisible, setMediaModalVisible] = useState(false);

  const [renaming, setRenaming] = useState(false);
  const { t } = useTranslation('digitaltwin');

  const { Text } = Typography;
  const [title, setTitle] = useState(<Text>{gear.label}</Text>);

  const { createGear, updateGear, deleteGear } = useHandleGears();

  useEffect(() => {
    // Perform any actions you want when the propValue changes
    if (searchValue.length > 0) {
      const index = gear.label.toLowerCase().indexOf(searchValue);
      const beforeStr = gear.label.substring(0, index);
      const str = gear.label.substring(index, index + searchValue.length);
      const afterStr = gear.label.slice(index + searchValue.length);

      if (index > -1) {
        setTitle(
          <Text>
            {beforeStr}
            <Text mark>{str}</Text>
            {afterStr}
          </Text>,
        );
      } else {
        setTitle(<Text>{gear.label}</Text>);
      }
    } else {
      setTitle(<Text>{gear.label}</Text>);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, gear]);

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    switch (e.key) {
      case 'rename':
        setRenaming(true);
        break;
      case 'media':
        setMediaModalVisible(true);
        break;
      case 'add_gear':
        setGearModalVisible(true);
        break;
      case 'delete':
        setDeletionModalVisible(true);
        break;
      default:
    }
  };

  const items: MenuProps['items'] = [
    {
      label: t('menu.rename'),
      icon: <EditOutlined />,
      key: 'rename',
    },
    {
      label: t('menu.medias'),
      icon: <PictureOutlined />,
      key: 'media',
    },
    {
      type: 'divider',
    },
    {
      label: t('menu.add-gear'),
      icon: <SettingOutlined />,
      key: 'add_gear',
    },
    {
      type: 'divider',
    },
    {
      label: t('menu.delete'),
      icon: <DeleteOutlined />,
      key: 'delete',
      danger: true,
    },
  ];

  function rename(label: string) {
    updateGear({
      id: gear.id,
      label,
    });
    setRenaming(false);
  }

  function onCreateGear(label: string) {
    createGear({
      label,
      zoneId: gear.zoneId,
      parentId: gear.id,
    });
    addExpand(`gear-${gear.id}`);
    setGearModalVisible(false);
  }

  function onDelete() {
    deleteGear(gear.id);
    setDeletionModalVisible(false);
  }

  return (
    <>
      <Space
        direction="vertical"
        style={{ width: '100%' /* , backgroundColor: '#DCDAD1' */ }}
      >
        <Dropdown
          menu={{ items, onClick: handleMenuClick }}
          trigger={['contextMenu']}
          disabled={!hasEditingRights(role)}
        >
          <Row>
            <Col flex="auto">
              <Space>
                <SettingOutlined />
                {renaming ? (
                  <Input
                    defaultValue={gear.label}
                    autoFocus
                    onPressEnter={(e) => rename(e.currentTarget.value)}
                    onBlur={(e) => rename(e.currentTarget.value)}
                  />
                ) : (
                  title
                )}
              </Space>
            </Col>
            <Col flex="initial">
              <Space>
                {gear.position?.placed ? (
                  <Badge color="green" text={t('gear.anchored')} />
                ) : (
                  <Badge color="red" text={t('gear.not-anchored')} />
                )}
              </Space>
            </Col>
          </Row>
        </Dropdown>
      </Space>

      {gearModalVisible ? (
        <GearCreationModal
          onClose={() => setGearModalVisible(false)}
          onSubmit={(label) => onCreateGear(label)}
        />
      ) : null}

      <GearMediaModal
        gear={gear}
        isModalOpen={mediaModalVisible}
        onCancel={() => setMediaModalVisible(false)}
        /* onSubmit={(label) => onCreateGear(label)} */
      />

      {deletionModalVisible ? (
        <GearDeletionModal
          label={gear.label}
          onClose={() => setDeletionModalVisible(false)}
          onSubmit={() => onDelete()}
        />
      ) : null}
    </>
  );
}
