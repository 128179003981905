/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Card, Input, Form, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { Scenario } from 'business/type';
import { ScenarioUpdate } from 'business/scenarios/services/scenario-detail';
import { Flex } from 'ui/flex';
import classes from './scenario-header.module.scss';
import { ScenarioTags } from '../tags/scenario-tags';
import AuditTable from './audit-table';

interface ScenarioSiderProps {
  scenario: Scenario;
  onModify: (input: ScenarioUpdate) => void;
  onModifyAsync: (input: ScenarioUpdate) => void;
  isSaving: boolean;
  canUpdate: boolean;
  isSubscribedDraft: boolean;
}

export function ScenarioSider({
  scenario,
  isSaving,
  canUpdate,
  onModify,
  onModifyAsync,
  isSubscribedDraft,
}: ScenarioSiderProps) {
  const { t } = useTranslation('scenarios');
  const [label, setLabel] = useState<string>();

  // Reset state
  useEffect(() => {
    setLabel(undefined);
  }, [scenario.reference]);

  return (
    <Card bordered className={classes.edition}>
      <Tabs defaultActiveKey="infosTab">
        <Tabs.TabPane tab={t('detail.step-edition.tabs.infos')} key="infosTab">
          <Flex direction="column" gap={12}>
            <Form layout="horizontal">
              <Form.Item label={t('detail.header.name')}>
                <Input
                  className={classes.input}
                  value={label ?? scenario.label}
                  disabled={!canUpdate}
                  onPressEnter={(e) =>
                    onModify({
                      reference: scenario.reference,
                      label: e.currentTarget.value,
                    })
                  }
                  onBlur={(e) =>
                    onModify({
                      reference: scenario.reference,
                      label: e.currentTarget.value,
                    })
                  }
                  onChange={(e) => setLabel(e.target.value)}
                />
              </Form.Item>
            </Form>
            <Flex.Child grow={1.1}>
              <ScenarioTags scenarioId={scenario.id} canUpdate={canUpdate} />
            </Flex.Child>
            <Flex.Child>
              <AuditTable scenarioRef={scenario.reference} loading={isSaving} />
            </Flex.Child>
          </Flex>
        </Tabs.TabPane>
      </Tabs>
    </Card>
  );
}
