import {
  getScenarioTasksAndSteps,
  getScenarioTasksAndStepsId,
} from 'business/scenarios/services/scenario-detail';
import { useQuery } from 'react-query';
import { useUserSession } from 'technical/hooks/use-user-session';

export function useHandleTasks(reference: number, versionId?: number) {
  const { tenant } = useUserSession();
  const tasksAndStepQuery = useQuery(
    ['scenarioTasks', tenant, { reference }],
    () => getScenarioTasksAndSteps(tenant, reference),
  );

  const tasksAndStepQueryId = useQuery(
    ['scenarioTasks', tenant, { id: versionId }],
    () => getScenarioTasksAndStepsId(tenant, versionId!),
    { enabled: !!versionId },
  );

  /* eslint-disable no-nested-ternary */
  const tasksAndStep = tasksAndStepQueryId.isSuccess
    ? tasksAndStepQueryId.data
    : tasksAndStepQuery.isSuccess
    ? tasksAndStepQuery.data
    : [];

  return {
    data: tasksAndStep,
    isLoading: tasksAndStepQuery.isLoading || tasksAndStepQueryId.isLoading,
    isSuccess: tasksAndStepQuery.isSuccess || tasksAndStepQueryId.isSuccess,
  };
}
