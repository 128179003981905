import { useEffect } from 'react';
import { Edge, useReactFlow, type Node } from 'reactflow';
import { Scenario, type Task as TaskType } from 'business/type';
import { useUserSession } from 'technical/hooks/use-user-session';
import { type Step } from '../../../../../type';
import {
  TASK_MIN_WIDTH,
  TASK_MIN_HEIGHT,
  POSITION_INCREMENT,
} from '../utils/constants';
import createElkLayout from '../utils/createElkLayout';
import { CustomEdge } from '../utils/factory/custom-edge';
import { CustomStep } from '../utils/factory/custom-step';
import { CustomTask } from '../utils/factory/custom-task';
import { createTag } from './use-node-click';
import useNodeDrag from './use-node-drag';

export type GraphViewInitializer = { nodes: Node[]; edges: Edge[] };

export type ScenarioViewProps = {
  scenarioReference: Scenario['reference'];
  tenant: string;
  zone: number;
  tasksAndStep: TaskType[];
  availableSteps: any;
  loading: boolean;
  canUpdate: boolean;
  selectedStep?: number;
  scenarioZone: number;
  selectedStepId?: number;
  nodeConnecting?: string;
  setActiveTab: (tab: string) => void;
  onSelectStepRef: (stepRef: number) => void;
  onSelectStep: (
    taskIndex: number,
    stepRef: number | undefined,
    stepId: number | undefined,
  ) => void;
};

export const checkEdgesDoesNotExist = (
  edgesList: Edge[],
  comparator: (edg: Edge) => boolean,
) => edgesList.filter((edg) => comparator(edg)).length === 0;

export default function useScenarioView({
  scenarioReference,
  tasksAndStep,
  availableSteps,
  selectedStep,
  loading,
  canUpdate,
  selectedStepId,
  nodeConnecting,
  onSelectStep,
  onSelectStepRef,
  scenarioZone,
  setActiveTab,
}: ScenarioViewProps) {
  const { tenant, zone } = useUserSession();
  const { setNodes, setEdges } = useReactFlow();
  const { onNodeDragStart, onNodeDrag, onNodeDragStop } = useNodeDrag({
    tenant,
    zone,
    reference: scenarioReference,
  });

  const checkStepAlreadyExist = (id: number) => {
    const stepsRefs: number[] = availableSteps.map((step: Step) => step.id);
    return stepsRefs.includes(id);
  };

  useEffect(() => {
    const initGraph = (): GraphViewInitializer => {
      if (!tasksAndStep)
        return {
          nodes: [],
          edges: [],
        };

      return tasksAndStep.reduce(
        (acc: GraphViewInitializer, task, taskIndex, taskArray) => {
          const { id: taskId, steps, rank: taskRank, ...taskData } = task;

          const taskPosition = {
            x: 0,
            y: 0,
          };

          acc.nodes.push(
            new CustomTask({
              id: 'task-' + taskId.toString(),
              data: {
                startPosition: taskPosition,
                height: 100,
                parent: taskArray?.[taskIndex - 1]?.id.toString() || '',
                next: taskArray?.[taskIndex + 1]?.id.toString() || '',
                tag: createTag([taskRank, 0]),
                rank: taskRank,
                tenant,
                zone,
                scenarioZone,
                scenarioReference,
                steps,
                canUpdate,
                loading,
                selectedStep,
                selectedStepId,
                onSelectStep,
                ...taskData,
              },
              style: {
                width: TASK_MIN_WIDTH,
                height: TASK_MIN_HEIGHT,
              },
              position: taskPosition,
            }) satisfies Node,
          );

          // This is a little trick to force elkjs to maintain a hierarchical view with the first task on top and the rest under it
          if (taskIndex > 0) {
            if (steps) {
              for (const stepTarget of steps) {
                if (stepTarget && stepTarget.linkedCases) {
                  for (const prevAnswer of stepTarget.linkedCases) {
                    const prevTask = tasksAndStep.find(
                      (taskTmp) =>
                        taskTmp.steps?.find(
                          (step) => step.id === prevAnswer.stepId,
                        ),
                    );
                    if (prevTask)
                      if (
                        !acc.edges.find(
                          (edge) =>
                            edge.id ===
                            `${prevTask?.id.toString()}->${taskId.toString()}`,
                        )
                      )
                        acc.edges.push(
                          new CustomEdge({
                            id: `${prevTask.id.toString()}->${taskId.toString()}`,
                            hidden: true,
                            source: 'task-' + prevTask.id.toString(),
                            target: 'task-' + taskId.toString(),
                            selectedStepId,
                          }),
                        );
                  }
                }
              }
            }
          }

          if (steps && steps?.length > 0) {
            steps?.forEach(
              (step: Step, stepIndex: number, stepArray: Step[]) => {
                const {
                  id: stepId,
                  rank: stepRank,
                  answers,
                  ...stepData
                } = step;
                const stepPosition = {
                  x: POSITION_INCREMENT / 2,
                  y: POSITION_INCREMENT / 2 + POSITION_INCREMENT * stepIndex,
                };

                /* if (
                  stepArray?.[stepIndex + 1]?.id &&
                  answers?.length === 0 &&
                  checkEdgesDoesNotExist(
                    acc.edges,
                    (edg) =>
                      edg.id ===
                      `${stepId.toString()}->${stepArray?.[
                        stepIndex + 1
                      ]?.id.toString()}`,
                  )
                ) {
                  if (
                    checkEdgesDoesNotExist(
                      acc.edges,
                      (edg) =>
                        stepArray?.[stepIndex + 1]?.id.toString() ===
                        edg.target,
                    )
                  ) {
                    acc.edges.push(
                      new CustomEdge({
                        source: stepId.toString(),
                        target: stepArray?.[stepIndex + 1]?.id.toString(),
                      }) satisfies Edge,
                    );
                  }
                } */

                if (answers !== undefined && answers?.length > 0) {
                  answers.forEach((answer) => {
                    const { stepId: linkedStepId, nextStepId } = answer;

                    if (nextStepId && linkedStepId) {
                      acc.edges.push(
                        new CustomEdge({
                          id: 'edge-' + answer.id.toString(),
                          source: linkedStepId.toString(),
                          target: nextStepId.toString(),
                          selectedStepId,
                        }) satisfies Edge,
                      );
                    }
                  });
                }

                /* const shouldHaveNextEdge =
                  stepIndex === stepArray.length - 1 &&
                  taskArray?.[taskIndex + 1] &&
                  taskArray?.[taskIndex + 1]?.steps &&
                  taskArray?.[taskIndex + 1]?.steps?.[0] &&
                  Array.isArray(taskArray?.[taskIndex + 1]?.steps);

                if (shouldHaveNextEdge) {
                  acc.edges.push(
                    new CustomEdge({
                      source: stepId.toString(),
                      target:
                        taskArray[taskIndex + 1].steps?.[0].id.toString()!,
                    }) satisfies Edge,
                  );
                } */

                acc.nodes.push(
                  new CustomStep({
                    id: stepId.toString(),
                    position: stepPosition,
                    parentNode: 'task-' + taskId.toString(),
                    nodeType: 'step',
                    selected: stepData.reference === selectedStep,
                    data: {
                      startPosition: stepPosition,
                      parent: stepArray?.[stepIndex - 1]?.id.toString() || '',
                      next: [],
                      tag: createTag([taskRank, stepRank]),
                      rank: stepRank,
                      linkedTask: 'task-' + taskId.toString(),
                      answers: answers || [],
                      globalDatas: tasksAndStep,
                      availableSteps,
                      onSelectStep,
                      selectedStep,
                      setActiveTab,
                      selectedStepId,
                      canUpdate,
                      loading,
                      nodeConnecting,
                      ...stepData,
                    },
                  }) satisfies Node,
                );
              },
            );
          }

          return acc;
        },
        {
          nodes: [],
          edges: [],
        },
      );
    };

    const { nodes, edges } = initGraph();

    createElkLayout({
      nodes,
      edges,
      direction: 'DOWN',
      useInitialNodes: true,
      setNodes,
      setEdges,
    });
    // ! Adding setNodes in useEffect deps might trigger an infinite re-render loops while dragging a node
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    onSelectStep,
    scenarioReference,
    selectedStep,
    tasksAndStep,
    tenant,
    zone,
    onSelectStepRef,
    scenarioZone,
    canUpdate,
    setActiveTab,
    loading,
    availableSteps,
    checkStepAlreadyExist,
    selectedStepId,
  ]);

  useEffect(() => {});

  return { onNodeDragStart, onNodeDrag, onNodeDragStop };
}
