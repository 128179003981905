import React, { useState } from 'react';
import { Alert, Button } from 'antd';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useUserSession } from 'technical/hooks/use-user-session';
import { PageLayout } from 'ui/layout';
import { getTopZone } from 'business/users/services/user-creation';
import TopZoneList from './top-zone-list';
import ZoneCreationModal from '../zone/zone-creation';

export default function TopZonePage() {
  const { t } = useTranslation('zones');
  const { tenant } = useUserSession();
  const [isModalVisible, setModalIsVisible] = useState(false);

  const toggleModal = () => setModalIsVisible((current) => !current);

  const topZoneListQuery = useQuery(['top-level-zones', tenant], () =>
    getTopZone(tenant),
  );

  return (
    <PageLayout
      title={t('list.title')}
      leftButton={
        <Button type="primary" onClick={toggleModal}>
          {t('list.creation')}
        </Button>
      }
    >
      {topZoneListQuery.isError ? (
        <Alert type="error" message={t('list.error')} />
      ) : null}
      <TopZoneList
        loading={topZoneListQuery.isLoading}
        topZones={topZoneListQuery.data}
      />
      {isModalVisible && <ZoneCreationModal onClose={toggleModal} />}
    </PageLayout>
  );
}
