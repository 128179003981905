export async function downloadFile(data: string, filename: string) {
  const blob = new Blob([data], { type: 'application/pdf' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = `${filename}.pdf`;
  link.click();
}

export async function downloadCsv(data: string, filename: string) {
  const blob = new Blob([data], { type: 'text/csv' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = `${filename}.csv`;
  link.click();
}

export async function downloadZip(data: string, filename: string) {
  const blob = new Blob([data], { type: 'application/zip' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = `${filename}.zip`;
  link.click();
}
