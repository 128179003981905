import { notification } from 'antd';
import { createZone } from 'business/scenarios/services/scenario-creation';
import { ZoneInputCreate, ZoneInputUpdate } from 'business/zones/services/type';
import {
  deleteZone,
  getZoneFullTree,
  getZoneTree,
  updateZone,
} from 'business/digital-twin/services/zone';
import { useMutation, useQuery } from 'react-query';
import { useUserSession } from 'technical/hooks/use-user-session';
import { queryClient } from 'technical/react-query';

export function useHandleZones() {
  const { tenant, zone } = useUserSession();
  const rootKey = ['tree', tenant, zone];

  const treeQuery = useQuery(rootKey, () => getZoneTree(tenant, zone));

  const treeScenarioQuery = useQuery(
    ['stepTaskTree', tenant, { zoneId: zone }],
    () => getZoneFullTree(tenant, zone),
  );

  const invalidateQueries = () => {
    queryClient.invalidateQueries(rootKey);
  };

  const createZoneMutation = useMutation(
    async (input: ZoneInputCreate) => createZone(tenant, input),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries();
      },
      onError: (err: Error) => notification.error({ message: err.message }),
    },
  );

  const updateZoneMutation = useMutation(
    async (zoneUpdate: ZoneInputUpdate) => updateZone(tenant, zoneUpdate),
    {
      onSuccess: async () => invalidateQueries(),
      onError: (err: Error) => notification.error({ message: err.message }),
    },
  );

  const deleteZoneMutation = useMutation(
    async (zoneId: number) => deleteZone(tenant, zoneId),
    {
      onSuccess: async () => invalidateQueries(),
      onError: (err: Error) => notification.error({ message: err.message }),
    },
  );

  return {
    queries: {
      tree: treeQuery,
      treeScenario: treeScenarioQuery,
    },
    isLoading:
      treeQuery.isLoading ||
      createZoneMutation.isLoading ||
      deleteZoneMutation.isLoading ||
      updateZoneMutation.isLoading,
    createZone: createZoneMutation.mutate,
    updateZone: updateZoneMutation.mutate,
    deleteZone: deleteZoneMutation.mutate,
  };
}
