import * as yup from 'yup';
import React, { useEffect, useRef, useState } from 'react';
import { Modal, Form, Input, Alert, InputRef } from 'antd';
import { useTranslation } from 'react-i18next';
import { useUserSession } from 'technical/hooks/use-user-session';
import { useQueryClient, useMutation } from 'react-query';
import { ZoneInputCreate } from 'business/zones/services/type';
import { createZone } from 'business/zones/services/zone';
import classes from './zone-creation.module.scss';

interface ZoneCreationModalProps {
  parentId?: number;
  folderCreation?: boolean;
  addExpand?: (id: string) => void;
  onClose: () => void;
}

export default function ZoneCreationModal({
  parentId,
  folderCreation,
  addExpand,
  onClose,
}: ZoneCreationModalProps) {
  const { t } = useTranslation('zones');
  const { zone, tenant } = useUserSession();
  const [label, setLabel] = useState('');
  const [isError, setIsError] = useState(false);
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const queryClient = useQueryClient();

  const createZoneMutation = useMutation(
    async (input: ZoneInputCreate) => createZone(tenant, input),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(['tree', tenant, zone]);
        onClose();
      },
    },
  );

  const onSubmitForm = () => {
    const schema = yup.object({
      label: yup.string().required(),
    });
    schema
      .validate({ label })
      .then((res) => {
        createZoneMutation.mutate({
          label: res.label,
          useQRCode:
            typeof folderCreation === 'boolean' ? !folderCreation : false,
          parentId,
        });
        if (addExpand) addExpand(`zone-${parentId}`);
      })
      .catch(() => setIsError(true));
  };

  return (
    <Modal
      open
      title={t('creation.modal.title')}
      okText={t('creation.modal.submit')}
      onOk={onSubmitForm}
      okButtonProps={{
        loading: createZoneMutation.isLoading,
      }}
      cancelText={t('creation.modal.cancel')}
      onCancel={onClose}
    >
      {isError && (
        <Alert
          type="error"
          message={t('creation.modal.error')}
          className={classes.modalError}
        />
      )}
      <Form name="label">
        <Form.Item
          className={classes.modalItem}
          label={t('creation.modal.inputs.label')}
          labelAlign="left"
          name="label"
          rules={[{ required: true }]}
        >
          <Input
            ref={inputRef}
            onChange={(e) => setLabel(e.target.value)}
            onPressEnter={onSubmitForm}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
