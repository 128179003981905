import { Tag } from 'business/type';
import { api } from 'technical/api';
import { TagInputCreate, TagInputUpdate } from './type';

export async function getTags(tenant: string, type: 'gear' | 'scenario') {
  return api(tenant)
    .get<Tag[]>(
      `/v1/tags?${
        type === 'scenario' ? 'onlyScenarios=true' : 'onlyGears=true'
      }`,
    )
    .then((response) => response.data);
}

export async function createTag(tenant: string, tag: TagInputCreate) {
  return api(tenant)
    .post<Pick<Tag, 'id'>>('/v1/tags', {
      label: tag.label,
      ...(tag.type === 'gear' && { gearsId: [tag.typeId] }),
      ...(tag.type === 'scenario' && { scenariosId: [tag.typeId] }),
    })
    .then((response) => response.data);
}

export async function updateTag(tenant: string, tag: TagInputUpdate) {
  return api(tenant)
    .patch<Tag>(`/v1/tags/${tag.id}`, {
      ...(tag.type === 'gear' && { gearsId: tag.typeIds }),
      ...(tag.type === 'scenario' && { scenariosId: tag.typeIds }),
    })
    .then((response) => response.data);
}

export async function deleteTagFromGear(
  tenant: string,
  gearId: number,
  tagId: number,
) {
  return api(tenant)
    .delete<void>(`/v1/tags/gears/${tagId}/${gearId}`)
    .then((response) => response.data);
}

export async function deleteTagFromScenario(
  tenant: string,
  scenarioId: number,
  tagId: number,
) {
  return api(tenant)
    .delete<void>(`/v1/tags/scenarios/${tagId}/${scenarioId}`)
    .then((response) => response.data);
}
