import React, { useEffect, useState } from 'react';
import { Scenario } from 'business/type';
import { Col, Row, Space, Typography } from 'antd';
import { ProfileOutlined } from '@ant-design/icons';

interface ScenarioBlockProps {
  scenario: Scenario;
  searchValue: string;
}

export function ScenarioBlock({ scenario, searchValue }: ScenarioBlockProps) {
  const { Text } = Typography;
  const [title, setTitle] = useState(<Text>{scenario.label}</Text>);

  useEffect(() => {
    if (searchValue.length > 0) {
      // Perform any actions you want when the propValue changes
      const index = scenario.label.toLowerCase().indexOf(searchValue);
      const beforeStr = scenario.label.substring(0, index);
      const str = scenario.label.substring(index, index + searchValue.length);
      const afterStr = scenario.label.slice(index + searchValue.length);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      index > -1
        ? setTitle(
            <Text>
              {beforeStr}
              <Text mark>{str}</Text>
              {afterStr}
            </Text>,
          )
        : setTitle(<Text>{scenario.label}</Text>);
    } else {
      setTitle(<Text>{scenario.label}</Text>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, scenario]);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Row>
        <Col flex="auto">
          <Space>
            <ProfileOutlined />
            {title}
          </Space>
        </Col>
      </Row>
    </Space>
  );
}
