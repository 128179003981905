import { useState } from "react";
import useEventListener from "@use-it/event-listener";

export function useLocalStorage<T>(
  key: string,
  defaultValue: T,
  validate: (value: unknown) => asserts value is T
) {
  const [state, setState] = useState<T>(() => {
    try {
      const persisted = window.localStorage.getItem(key);
      const value = persisted ? JSON.parse(persisted) : defaultValue;
      validate(value);
      return value;
    } catch (error) {
      return defaultValue;
    }
  });

  useEventListener("storage", (event: StorageEvent) => {
    // this ensure that state is updated between tabs
    if (event.key === key) {
      try {
        if (event.newValue !== event.oldValue) {
          const received = event.newValue ? JSON.parse(event.newValue) : null;
          if (received) {
            validate(received);
            setState(received);
          }
        }
      } catch (error) {
        const old = event.oldValue ? JSON.parse(event.oldValue) : defaultValue;
        setState(old);
        window.localStorage.setItem(key, JSON.stringify(old));
      }
    }
  });

  function update(value: T | ((state: T) => T)) {
    try {
      const store = value instanceof Function ? value(state) : value;
      setState(store);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(store));
    } catch (error) {
      // reset the state to the last value
      setState(state);
      window.localStorage.setItem(key, JSON.stringify(state));
    }
  }

  return [state, update] as const;
}

