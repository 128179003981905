import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import { Folder } from 'business/type';

interface MediaBreadcrumbProps {
  isModal: boolean;
  parents: Folder[];
  mediaType: string;
}

export default function MediaBreadcrumb({
  isModal,
  parents,
  mediaType,
}: MediaBreadcrumbProps) {
  const location = useLocation();

  let homeLink;
  if (isModal) {
    homeLink = `${location.pathname}?modal=add_media&media_type=${mediaType}`;
  } else {
    homeLink = `/medias/${mediaType}`;
  }

  const items = [
    {
      title: (
        <Link to={homeLink}>
          <HomeOutlined />
        </Link>
      ),
    },
  ].concat(
    parents.map((folder) => {
      let itemLink;
      if (isModal) {
        itemLink = `${location.pathname}?modal=add_media&media_type=${mediaType}&folder_id=${folder.id}`;
      } else {
        itemLink = `/medias/${mediaType}/${folder.id}`;
      }

      return {
        title: <Link to={itemLink}>{folder.label}</Link>,
      };
    }),
  );

  return <Breadcrumb items={items} />;
}
