import React from "react";
import { useTranslation } from "react-i18next";
import { SpectralLogo } from "ui/images";
import classes from "./index.module.scss";

export default function RedirectPage() {
  const { t } = useTranslation("users");

  return (
    <div className={classes.container}>
      <img src={SpectralLogo} alt="spectral logo" className={classes.logo} />
      <div className={classes.text}>{t("redirect-message")}</div>
    </div>
  );
}
