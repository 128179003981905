/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Button, Space } from 'antd';
import { CloseOutlined, PlusOutlined, QrcodeOutlined } from '@ant-design/icons';
import { Flex } from 'ui/flex';
import { useTranslation } from 'react-i18next';
import { useHandleSteps } from 'business/scenarios/hooks/step';
import { Step } from 'business/type';
import { ZoneAddModal } from './zoneAddModal';

interface StepZoneProps {
  scenarioReference: number;
  scenarioZone: number;
  step: Step;
  canUpdate: boolean;
}

export function StepZone({
  scenarioReference,
  scenarioZone,
  step,
  canUpdate,
}: StepZoneProps) {
  const { t } = useTranslation('scenarios');
  const [zoneAddModalIsVisible, setZoneAddModalIsVisible] = useState(false);

  const { updateStep, removeZone } = useHandleSteps({
    scenarioReference,
    stepId: step.id,
    zone: scenarioZone,
  });

  const handleDeletion = () => {
    removeZone();
  };

  const updateableContent = (
    <>
      {step.zone ? (
        <Space size="middle">
          <QrcodeOutlined />
          {step.zone.label}

          {!(step.gearsId && step.gearsId.length > 0) ? (
            <Button
              size="small"
              type="default"
              danger
              icon={<CloseOutlined />}
              onClick={() => handleDeletion()}
            />
          ) : null}
        </Space>
      ) : (
        <Button onClick={() => setZoneAddModalIsVisible(true)} type="link">
          <PlusOutlined />
          {t('detail.step-edition.actions.add-zone')}
        </Button>
      )}
    </>
  );

  const handleUpdate = (id: number) => {
    updateStep({ stepRef: step.reference, zoneId: id });
    setZoneAddModalIsVisible(false);
  };

  const unupdatbleContent = (
    <>
      {step.zone ? (
        <Space size="middle">
          <QrcodeOutlined />
          {step.zone.label}
        </Space>
      ) : null}
    </>
  );

  return (
    <Flex grow={1} direction="column" gap={16} justify="space-between">
      <Flex.Child>
        {canUpdate ? updateableContent : unupdatbleContent}
      </Flex.Child>
      <ZoneAddModal
        open={zoneAddModalIsVisible}
        onClose={() => setZoneAddModalIsVisible(false)}
        onSubmit={handleUpdate}
      />
    </Flex>
  );
}
