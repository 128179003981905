import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, InputRef, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import classes from './gear-creation.module.scss';

interface GearCreationModalProps {
  onClose: () => void;
  onSubmit: (label: string) => void;
}

export default function GearCreationModal({
  onClose,
  onSubmit,
}: GearCreationModalProps) {
  const { t } = useTranslation('gears');
  const [label, setLabel] = useState<string>('');
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <Modal
      title={t('creation.modal.title')}
      cancelText={t('creation.modal.cancel')}
      okText={t('creation.modal.submit')}
      open
      onOk={() => onSubmit(label)}
      onCancel={onClose}
    >
      <Form name="label">
        <Form.Item
          className={classes.modalItem}
          label={t('creation.modal.inputs.label')}
          labelAlign="left"
          name="label"
          rules={[{ required: true }]}
        >
          <Input
            ref={inputRef}
            onChange={(e) => setLabel(e.target.value)}
            onPressEnter={() => onSubmit(label)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
