import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, DropResult } from '@hello-pangea/dnd';
import { useHandleSteps } from 'business/scenarios/hooks/step';
import { Media } from 'business/type';
import { reorder } from 'technical/drag-and-drop/reorder';
import { Flex } from 'ui/flex';
import { DraggableMediasGalery } from 'ui/media';

interface StepMediaListProps {
  scenarioReference: number;
  stepReference: number;
  selectedStepId: number;
  scenarioZoneId: number;
  canUpdate: boolean;
  isMediaLoading: boolean;
  list: Media[];
  setModalIsVisible: (bool: boolean) => void;
}

export default function StepMediaList({
  scenarioReference,
  stepReference,
  selectedStepId,
  scenarioZoneId,
  canUpdate,
  isMediaLoading,
  list,
  setModalIsVisible,
}: StepMediaListProps) {
  const { t } = useTranslation('scenarios');
  const [medias, setMedias] = useState<Media[]>([]);
  const { updateStepWithoutInvalidation } = useHandleSteps({
    scenarioReference,
    stepId: selectedStepId,
    zone: scenarioZoneId,
  });

  useEffect(() => {
    setMedias(list);
  }, [list]);

  const toMediaIdList = (listMedias: Media[]) =>
    listMedias.map((media) => ({ value: media.id }));

  return (
    <Flex grow={1} direction="column" gap={16} justify="space-between">
      <DragDropContext
        onDragEnd={async (result: DropResult) => {
          const { source, destination } = result;
          if (!destination) {
            return;
          }

          const items = reorder(medias, source.index, destination.index);
          setMedias(items);

          const mediaIds = toMediaIdList(items);
          await updateStepWithoutInvalidation({
            mediasOrder: mediaIds.map((media) => media.value),
            stepRef: stepReference,
          });
        }}
      >
        <Droppable droppableId="medias" direction="horizontal">
          {(provided) => (
            <Flex.Child>
              <Flex items="center" gap={16}>
                <Flex.Child>
                  <div
                    ref={provided.innerRef}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...provided.droppableProps}
                  >
                    <DraggableMediasGalery
                      medias={medias}
                      canUpdate={canUpdate}
                    />
                  </div>
                </Flex.Child>
                <Flex.Child>
                  {isMediaLoading ? (
                    <LoadingOutlined style={{ fontSize: 24 }} spin />
                  ) : null}
                </Flex.Child>
              </Flex>
            </Flex.Child>
          )}
        </Droppable>
      </DragDropContext>
      <Flex.Child>
        {canUpdate ? (
          <Button onClick={() => setModalIsVisible(true)} type="link">
            <PlusOutlined />
            {t('detail.header.media')}
          </Button>
        ) : null}
      </Flex.Child>
    </Flex>
  );
}
