import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { Gear } from 'business/type';
import TagTitle from 'ui/tag/tag-title';
import classes from './gear-parent-tooltip.module.scss';

interface GearParentTooltipProps {
  item: Gear;
  parentGearLabel: string | undefined;
  children: ReactNode;
}

export default function GearParentTooltip({
  item,
  parentGearLabel,
  children,
}: GearParentTooltipProps) {
  const { t } = useTranslation(['scenarios', 'common']);
  const tooltipInnerStyle = {
    color: 'rgba(0, 0, 0, 0.85)',
    width: '255px',
    height: '54px',
    padding: '16px',
  };

  if (!parentGearLabel) {
    return <>{children}</>;
  }

  return (
    <Tooltip
      title={
        item.parentId ? (
          <div className={classes.tooltipTitle}>
            {t('detail.step-edition.step-type-action.parent')}{' '}
            <TagTitle label={parentGearLabel} />
          </div>
        ) : null
      }
      color="white"
      overlayInnerStyle={tooltipInnerStyle}
      placement="bottomRight"
    >
      {children}
    </Tooltip>
  );
}
