enum Languages {
  fr = 'fr',
  en = 'en',
  es = 'es',
  cn = 'cn',
  it = 'it',
  pl = 'pl',
  th = 'th',
  nl = 'nl',
  hu = 'hu',
}

export { Languages };
