import { Subscription } from 'business/type';
import { api } from 'technical/api';

export async function getReportSubscription(tenant: string) {
  const { data: subscription } = await api(tenant).get<Subscription>(
    `/v1/subscriptions/reportInterventions`,
  );
  return subscription;
}

export async function getVersionsSubscription(tenant: string) {
  const { data: subscription } = await api(tenant).get<Subscription>(
    `/v1/subscriptions/versions`,
  );
  return subscription;
}

export async function updateSubscription(
  tenant: string,
  input: { subscribed: boolean; feature: string },
) {
  return api(tenant).patch<Subscription>(`/v1/subscriptions/${input.feature}`, {
    subscribed: input.subscribed,
  });
}

export async function getGmaoSubscription(tenant: string) {
  const { data: subscription } = await api(tenant).get<Subscription>(
    `/v1/subscriptions/gmao`,
  );
  return subscription;
}

export async function getScanSubscription(tenant: string) {
  const { data: subscription } = await api(tenant).get<Subscription>(
    `/v1/subscriptions/scans`,
  );
  return subscription;
}

export async function getExpertCaptureSubscription(tenant: string) {
  const { data: subscription } = await api(tenant).get<Subscription>(
    `/v1/subscriptions/expertCapture`,
  );
  return subscription;
}
