import React, { useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useUserSession } from 'technical/hooks/use-user-session';
import { Button, DatePicker } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { downloadCsv } from 'technical/utils/download';
import { exportMonitoring } from 'business/monitoring/services/statistics';
import { Flex } from 'ui/flex';

export default function MonitoringExport() {
  const { t } = useTranslation('monitoring');

  const { tenant } = useUserSession();
  const [startDate, setStartDate] = useState(
    dayjs().subtract(7, 'days').toISOString().slice(0, 10),
  );
  const [endDate, setEndDate] = useState<string>(
    dayjs().toISOString().slice(0, 10),
  );
  const [loadingExport, setLoadingExport] = useState(false);

  const handleChange = (dates: any, dateStrings: [string, string]) => {
    setStartDate(dateStrings[0]);
    setEndDate(dateStrings[1]);
  };

  // issue with update antd to correct when updated
  // eslint-disable-next-line prefer-destructuring
  const RangePicker: any = DatePicker.RangePicker;

  async function handleExportIntervention() {
    setLoadingExport(true);
    const blob = await exportMonitoring(tenant, startDate, endDate);
    downloadCsv(blob, `export_monitoring_${tenant}`);
    setLoadingExport(false);
  }

  return (
    <Flex direction="column">
      <Flex.Child>
        <RangePicker
          onChange={handleChange}
          defaultValue={[dayjs(startDate), dayjs(endDate)]}
        />
        <Button
          icon={<DownloadOutlined />}
          onClick={() => handleExportIntervention()}
          loading={loadingExport}
        >
          {t('buttons.export')}
        </Button>
      </Flex.Child>
    </Flex>
  );
}
