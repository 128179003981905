import React, { useState } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Input, Space, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { User, Zone } from 'business/type';
import GenericTable from 'ui/table';
import { AuthorizedAccessOnly } from 'technical/auth/authorization';
import { ADMINS, SUPER_ADMINS } from 'technical/auth/roles';
import {
  deleteInvitedUser,
  deleteUser,
} from 'business/users/services/users-list';
import { useUserSession } from 'technical/hooks/use-user-session';
import { useQueryClient } from 'react-query';
import { UserRole, userRoleLabel } from 'business/users/services/type';
import UserDeletionModal from './user-deletion';

interface UserListProps {
  users?: User[];
  loading: boolean;
}

export default function UsersList({ users, loading }: UserListProps) {
  const { t } = useTranslation('users');
  const { tenant, authId } = useUserSession();
  const navigate = useNavigate();
  const [isModalVisible, setModalIsVisible] = useState(false);
  const [userSelected, setUserSelected] = useState<User>();
  const [dataSource, setDataSource] = useState<User[] | undefined>(users);
  const [searchName, setSearchName] = useState<string>();
  const [searchFirstname, setSearchFirstname] = useState<string>();

  const toggleModal = () => setModalIsVisible((current) => !current);

  const queryClient = useQueryClient();

  const onConfirm = (record: User) => {
    setUserSelected(record);
    toggleModal();
  };

  const handleEditClick = (user: User) => navigate(`/users/${user.authId}`);

  const handleDelete = () => {
    if (userSelected?.roles) {
      deleteUser(tenant, userSelected).then(() => {
        queryClient.invalidateQueries('users');
      });
    } else if (userSelected) {
      deleteInvitedUser(tenant, userSelected).then(() => {
        queryClient.invalidateQueries('users');
      });
    }
    toggleModal();
  };

  const allZones = users
    ?.map((user) => user.zones)
    .flat()
    .filter(
      (zone, index, self) => self.findIndex((z) => z.id === zone.id) === index,
    );

  const FilterByNameInput = (
    <Input
      placeholder={t('list.name')}
      value={searchName}
      onChange={(e) => {
        const currValue = e.target.value;
        setSearchName(currValue);
        const filteredData = users?.filter((entry) =>
          entry.surname
            ? entry.surname.toLowerCase().includes(currValue.toLowerCase())
            : entry.email.toLowerCase().includes(currValue.toLowerCase()),
        );
        setDataSource(filteredData);
      }}
    />
  );

  const FilterByFirstNameInput = (
    <Input
      placeholder={t('list.firstname')}
      value={searchFirstname}
      onChange={(e) => {
        const currValue = e.target.value;
        setSearchFirstname(currValue);
        const filteredData = users?.filter((entry) =>
          entry.firstname
            ? entry.firstname.toLowerCase().includes(currValue.toLowerCase())
            : entry.email.toLowerCase().includes(currValue.toLowerCase()),
        );
        setDataSource(filteredData);
      }}
    />
  );

  const columns: ColumnsType<User> = [
    {
      title: FilterByFirstNameInput,
      key: 'firstname',
      render: (record: User) => record.firstname ?? record.email,
    },
    {
      title: FilterByNameInput,
      dataIndex: 'surname',
      key: 'surname',
    },
    {
      title: t('list.zone'),
      dataIndex: 'zones',
      key: 'zone',
      onFilter: (value, record) =>
        !!record.zones?.find((zone) => zone.id === value),
      filters: allZones?.map((zone) => ({
        text: zone.label,
        value: zone.id,
      })),
      render: (zones: Zone[]) => (
        <>
          {zones.map((zone) => (
            <Tag color="blue" key={zone.id}>
              {zone.label}
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: t('list.role'),
      key: 'roles',
      render: (record: User) =>
        userRoleLabel[record.roles as UserRole] ?? t('list.pending'),
      onFilter: (value, record) => record.roles === value,
      filters: Object.values(UserRole).map((value) => ({
        text: userRoleLabel[value],
        value,
      })),
    },
    {
      title: t('list.action'),
      key: 'actions',
      render: (record: User) => {
        const allowEditors =
          record.roles === UserRole.SuperAdmin ? SUPER_ADMINS : ADMINS;
        return (
          <AuthorizedAccessOnly authorized={allowEditors}>
            <Space size="small">
              <Button
                type="primary"
                icon={<EditOutlined />}
                disabled={!record.roles}
                onClick={() => handleEditClick(record)}
              />
              <Button
                type="primary"
                icon={<DeleteOutlined />}
                danger
                disabled={record.authId === authId}
                onClick={() => onConfirm(record)}
              />
            </Space>
          </AuthorizedAccessOnly>
        );
      },
    },
  ];

  return (
    <>
      <GenericTable
        loading={loading}
        dataSource={dataSource ?? users}
        columns={columns}
        pageSize={20}
      />
      <UserDeletionModal
        isVisible={isModalVisible}
        onClose={toggleModal}
        onSubmit={handleDelete}
      />
    </>
  );
}
