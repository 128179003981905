import { User } from 'business/type';
import { Role } from 'technical/auth/roles';
import { api } from 'technical/api';

export async function getUserList(tenant: string) {
  const usersList = api(tenant)
    .get<User[]>(`/v1/users/`)
    .then((response) => response.data);

  const invitationsList = api(tenant)
    .get<User[]>(`/v1/users/invited`)
    .then((response) => response.data);

  const [users, invitations] = await Promise.all([usersList, invitationsList]);

  return invitations.concat(users);
  
}

export async function deleteUser(tenant: string, user: User) {
  return api(tenant).delete(`/v1/users/${user.id}`);
}

export async function deleteInvitedUser(tenant: string, user: User) {
  return api(tenant).delete(`/v1/users/invited/${user.email}`);
}
