import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from 'ui/layout';
import DigitalTree from './digital-tree';

export default function DigitalTwinPage() {
  const { t } = useTranslation('zones');

  return (
    <PageLayout title={t('list.title')}>
      <DigitalTree />
    </PageLayout>
  );
}
