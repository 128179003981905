import React from 'react';
import { ExpandOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Panel, useReactFlow, useViewport } from 'reactflow';
import { Flex } from 'ui/flex';

import classes from './view-controls.module.scss';

export default function ViewControls() {
  const { zoomIn, zoomOut, fitView } = useReactFlow();
  const { zoom } = useViewport();

  return (
    <Panel position="bottom-right">
      <Flex>
        <Button
          icon={<ExpandOutlined />}
          size="middle"
          onClick={() => fitView()}
          className={classes.builderControlBtn}
        />
        <div className={classes.builderControlsBtnGroup}>
          <Button
            icon={<MinusOutlined />}
            size="middle"
            disabled={zoom === 0.1}
            onClick={() => zoomOut({ duration: 250 })}
            className={`${classes.builderControlBtn} ${classes.builderControlBtnLeft}`}
          />
          <div
            className={`${classes.builderControlBtn} ${classes.builderControlZoomIndicator}`}
          >
            {Math.round(zoom * 100)} %
          </div>
          <Button
            icon={<PlusOutlined />}
            size="middle"
            disabled={zoom === 2}
            onClick={() => zoomIn({ duration: 250 })}
            className={`${classes.builderControlBtn} ${classes.builderControlBtnRight}`}
          />
        </div>
      </Flex>
    </Panel>
  );
}
