import React from 'react';
import { Tabs } from 'antd';
import {
  getScenarioStatistics,
  getStatistics,
} from 'business/monitoring/services/statistics';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useUserSession } from 'technical/hooks/use-user-session';
import { PageLayout } from 'ui/layout';
import InterventionStatsList from './intervention-stats-list';
import MonitoringList from './monitoring-list';
import MonitoringScenario from './monitoring-scenario';
import ToggleBar from './toggle-bar';
import MonitoringExport from './monitoring-export';

export default function MonitoringDashboardPage() {
  const { t } = useTranslation('monitoring');

  const { zone, tenant } = useUserSession();

  // a integrer dans la tab MonitoringList au besoin dans le futur
  const statisticsQuery = useQuery(['statistics', tenant], () =>
    getStatistics(tenant),
  );

  const scenarioListQuery = useQuery(
    ['scenarioStatistics', tenant, { zoneId: zone }],
    () => getScenarioStatistics(tenant),
  );
  return (
    <PageLayout title={t('dashboard.title')}>
      <Tabs defaultActiveKey="generalTab">
        <Tabs.TabPane tab={t('dashboard.generalTab')} key="generalTab">
          {statisticsQuery.isSuccess ? (
            <MonitoringList monitoringData={statisticsQuery.data!} />
          ) : null}
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('dashboard.toggleTab')} key="toggleTab">
          <ToggleBar />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('dashboard.pinTab')} key="pinTab">
          <InterventionStatsList />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('dashboard.scenarioTab')} key="scenarioTab">
          <MonitoringScenario
            loading={scenarioListQuery.status === 'loading'}
            scenarios={scenarioListQuery.data}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('dashboard.exportTab')} key="exportTab">
          <MonitoringExport />
        </Tabs.TabPane>
      </Tabs>
    </PageLayout>
  );
}
