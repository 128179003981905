export enum Role {
  Invitation = 'ROLE_INVITATION',
  Operator = 'ROLE_OPERATOR',
  Author = 'ROLE_AUTHOR',
  Admin = 'ROLE_ADMIN',
  Reviewer = 'ROLE_REVIEWER',
  Approver = 'ROLE_APPROVER',
  SuperAdmin = 'ROLE_SUPER_ADMIN',
}

export function validateRole(role: string): Role {
  if (Object.values(Role).includes(role as Role)) {
    return role as Role;
  }
  throw new Error(
    `Role ${role} is not valid. Role can be any ${Object.values(Role)}`,
  );
}

export const EDITORS = [Role.Author, Role.Admin, Role.SuperAdmin];

export const ADMINS = [Role.Admin, Role.SuperAdmin];

export const SUPER_ADMINS = [Role.SuperAdmin];

export const REVIWERS = [Role.Reviewer];

export const APPROVERS = [Role.Approver];
