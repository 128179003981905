import * as yup from 'yup';
import React, { useState } from 'react';
import {
  Modal,
  Form,
  Input,
  Alert,
  notification,
  Checkbox,
  Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { ScenarioInputCopy } from 'business/scenarios/services/type';
import { useUserSession } from 'technical/hooks/use-user-session';
import { useNavigate } from 'react-router';
import { useQuery, useQueryClient, useMutation } from 'react-query';
// @ts-ignore
import ReactCountryFlag from 'react-country-flag';
import { getMailSubscription } from 'business/users/services/user-detail';
import { copyScenario } from 'business/scenarios/services/scenario-creation';
import { getTopLevelZones } from 'business/zones/services/top-level-zones';
import { hasAdminRights } from 'technical/auth/authorization';
import { Languages } from 'translations/types';
import { Flex } from 'ui/flex';
import classes from './scenario-duplication.module.scss';

interface ScenariosDuplicationModalProps {
  onClose: () => void;
  originId: number;
  originLabel: string;
}

export default function ScenariosDuplicationModal({
  originId,
  originLabel,
  onClose,
}: ScenariosDuplicationModalProps) {
  const { t } = useTranslation('scenarios');
  const { zone, tenant, role } = useUserSession();
  const [scenario, setScenario] = useState(
    originLabel + t('duplication.modal.inputs.copy-placeholder'),
  );
  const [siteId, setSiteId] = useState<number>(zone);
  const [keepGears, setKeepGears] = useState(false);
  const [keepMedias, setKeepMedias] = useState(false);
  const [keepAlerts, setKeepAlerts] = useState(false);
  const [keepInterventions, setKeepInterventions] = useState(false);
  const [isError, setIsError] = useState(false);
  const [tradVisible, setTradVisible] = useState(false);
  const [lang, setLang] = useState<string>();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const createScenarioMutation = useMutation(
    async (input: ScenarioInputCopy) => copyScenario(tenant, input),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('scenarios');
        queryClient.invalidateQueries('user');
        queryClient.invalidateQueries([
          'stepTaskTree',
          tenant,
          { zoneId: zone },
        ]);
        queryClient.invalidateQueries('gears');
        queryClient.invalidateQueries('childs');
        const { reference } = res.scenario.data;
        if (zone === siteId) navigate(`/scenarios/${reference}`);
        else {
          navigate(`/scenarios`);
        }
        onClose();
      },
      onError: (err: Error) => {
        notification.error({
          message: err.message,
          description: t('duplication.modal.errors.request'),
        });
      },
    },
  );

  const mailSubscriptionQuery = useQuery(
    ['mailInterventions', tenant],
    () => getMailSubscription(tenant),
    {
      staleTime: 24 * 60 * 60 * 1000,
    },
  );

  const siteListQuery = useQuery(['all-top-level-zones', tenant], () =>
    getTopLevelZones(tenant, true),
  );

  const onSubmitForm = () => {
    const schema = yup.object({
      scenario: yup.string().required(),
      gears: yup.boolean(),
      medias: yup.boolean(),
      alerts: yup.boolean(),
      interventions: yup.boolean(),
      lang: yup.string(),
    });
    schema
      .validate({
        scenario,
        gears: keepGears,
        medias: keepMedias,
        alerts: keepAlerts,
        interventions: keepInterventions,
        lang,
      })
      .then((res) => {
        createScenarioMutation.mutate({
          id: originId,
          label: res.scenario,
          siteId: siteId,
          gears: res.gears ?? false,
          medias: res.medias ?? false,
          alertUsers: res.alerts ?? false,
          interventionUsers: res.interventions ?? false,
          lang,
        });
      })
      .catch(() => setIsError(true));
  };

  return (
    <Modal
      open
      title={t('duplication.modal.title')}
      okText={t('duplication.modal.submit')}
      onOk={onSubmitForm}
      okButtonProps={{
        loading: createScenarioMutation.isLoading,
      }}
      cancelText={t('duplication.modal.cancel')}
      onCancel={onClose}
    >
      {isError && (
        <Alert
          type="error"
          message={t('duplication.modal.error')}
          className={classes.modalError}
        />
      )}
      <Form name="scenario">
        <Form.Item
          className={classes.modalItem}
          label={t('duplication.modal.inputs.scenario')}
          labelAlign="left"
          name="scenario"
          rules={[{ required: true }]}
        >
          <Input
            onChange={(e) => setScenario(e.target.value)}
            defaultValue={scenario}
          />
        </Form.Item>
        {hasAdminRights(role) ? (
          <Form.Item
            className={classes.modalItem}
            label={t('duplication.modal.inputs.site')}
            labelAlign="left"
            name="site"
          >
            <Select
              options={
                siteListQuery?.data?.map((site) => ({
                  label: site.label,
                  value: site.id,
                })) ?? []
              }
              defaultValue={zone}
              loading={siteListQuery.isLoading}
              value={siteId}
              onChange={setSiteId}
            />
          </Form.Item>
        ) : null}
        <Form.Item
          label={t('duplication.modal.inputs.lang')}
          labelAlign="left"
          name="lang"
        >
          <Flex gap={16} justify="space-between">
            <Checkbox onChange={() => setTradVisible(!tradVisible)} />
            {tradVisible ? (
              <Select onChange={setLang}>
                {Object.keys(Languages).map((langOption) => (
                  <Select.Option
                    value={langOption === 'cn' ? 'zh' : langOption}
                    key={langOption}
                  >
                    <ReactCountryFlag
                      countryCode={langOption === 'en' ? 'us' : langOption}
                      svg
                    />{' '}
                    {langOption}
                  </Select.Option>
                ))}
              </Select>
            ) : null}
          </Flex>
        </Form.Item>
        {siteId === zone ? (
          <Form.Item
            label={t('duplication.modal.inputs.gear')}
            labelAlign="left"
            name="gear"
          >
            <Checkbox onChange={() => setKeepGears(!keepGears)} />
          </Form.Item>
        ) : null}
        <Form.Item
          label={t('duplication.modal.inputs.medias')}
          labelAlign="left"
          name="medias"
        >
          <Checkbox onChange={() => setKeepMedias(!keepMedias)} />
        </Form.Item>
        {siteId === zone ? (
          <>
            <Form.Item
              label={t('duplication.modal.inputs.alerts')}
              labelAlign="left"
              name="alerts"
            >
              <Checkbox onChange={() => setKeepAlerts(!keepAlerts)} />
            </Form.Item>
            {mailSubscriptionQuery.data?.subscribed ? (
              <Form.Item
                label={t('duplication.modal.inputs.interventions')}
                labelAlign="left"
                name="interventions"
              >
                <Checkbox
                  onChange={() => setKeepInterventions(!keepInterventions)}
                />
              </Form.Item>
            ) : null}
          </>
        ) : null}
      </Form>
    </Modal>
  );
}
