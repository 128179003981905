import {
  createTask,
  deleteTask,
  updateTask,
  createStep,
  updateStep,
} from 'business/scenarios/services/scenario-detail';
import {
  TaskInputCreate,
  TaskInputUpdate,
  StepInputCreate,
  StepInputUpdateRank,
} from 'business/scenarios/services/type';
import { useMutation, useQueryClient } from 'react-query';
import { reorderTasks, reorderSteps } from 'technical/drag-and-drop/reorder';

import { Task as TaskType, Step as StepType } from 'business/type';
import {
  importSteps,
  importTasks,
} from 'business/scenarios/services/task-step-import';
import { deleteStep } from 'business/scenarios/services/step-edition';

export default function useTasksAndStepsLifeCycle(
  tenant: string,
  zone: number,
  reference: number,
  onSelectStep?: (ref: number | undefined, id: number | undefined) => void,
) {
  const queryClient = useQueryClient();
  const queryKey = ['scenarioTasks', tenant, { reference }];

  const invalidateQueries = () => {
    queryClient.invalidateQueries('scenarioTasks');
    queryClient.invalidateQueries(['stepTaskTree', tenant, { zoneId: zone }]);
  };

  const createTaskMutation = useMutation(
    async (input: TaskInputCreate) => createTask(tenant, input),
    {
      onSuccess: async () => {
        await queryClient.cancelQueries(queryKey);
      },
      onSettled: invalidateQueries,
    },
  );

  const deleteTaskMutation = useMutation(
    async (ref: number) => deleteTask(tenant, ref),
    {
      onMutate: async (ref: number) => {
        await queryClient.cancelQueries(queryKey);
        const previousData = queryClient.getQueryData<TaskType[]>(queryKey);
        const filteredTasks = previousData
          ? previousData.filter((task) => task.reference !== ref)
          : [];

        queryClient.setQueryData<TaskType[]>(queryKey, () => filteredTasks);
        return { previousData };
      },
      onSettled: invalidateQueries,
    },
  );

  const updateTaskMutation = useMutation(
    async (input: TaskInputUpdate) => updateTask(tenant, input),
    {
      onMutate: async (input: TaskInputUpdate) => {
        await queryClient.cancelQueries(queryKey);
        const previousData =
          queryClient.getQueryData<TaskType[]>(queryKey) || [];
        const tasks = reorderTasks(previousData, {
          start: input.startIndex ? input.startIndex + 1 : 1,
          end: input.endIndex ? input.endIndex + 1 : 1,
        });
        queryClient.setQueryData<TaskType[]>(queryKey, () => tasks);
        return { previousData };
      },
      onSettled: invalidateQueries,
    },
  );

  const createStepMutation = useMutation(
    async (input: StepInputCreate) => createStep(tenant, input),
    {
      onSuccess: async () => {
        await queryClient.cancelQueries(queryKey);
      },
      onSettled: (data, error, variables) => {
        if (variables.fromId) {
          queryClient.invalidateQueries([
            'step',
            tenant,
            { stepId: variables.fromId },
          ]);
          queryClient.invalidateQueries(['answers', tenant, variables.fromId]);
        }
        invalidateQueries();
      },
    },
  );

  const updateStepMutation = useMutation(
    async (input: StepInputUpdateRank) => updateStep(tenant, input),
    {
      onMutate: async (input: StepInputUpdateRank) => {
        await queryClient.cancelQueries(queryKey);
        const previousData =
          queryClient.getQueryData<TaskType[]>(queryKey) || [];
        const tasks = reorderSteps(previousData, {
          start: {
            task: input.startTaskIndex,
            index: input.startIndex + 1,
          },
          end: {
            task: input.endTaskIndex,
            index: input.endIndex + 1,
          },
        });
        queryClient.setQueryData<TaskType[]>(queryKey, () => tasks);
        return { previousData };
      },
      onSettled: invalidateQueries,
    },
  );

  const importStepsMutation = useMutation(
    async (input: { refsList: number[]; taskRef: number }) =>
      importSteps(tenant, input.refsList, input.taskRef),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries([
          'stepTaskTree',
          tenant,
          { zoneId: zone },
        ]);
        queryClient.invalidateQueries([
          'scenarioTasks',
          tenant,
          { reference: +reference },
        ]);
      },
    },
  );

  const importTasksMutation = useMutation(
    async (input: {
      refsList: number[];
      scenarioRef: number;
      keepGears: boolean;
      keepMedias: boolean;
    }) =>
      importTasks(
        tenant,
        input.refsList,
        input.scenarioRef,
        input.keepGears,
        input.keepMedias,
      ),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries([
          'stepTaskTree',
          tenant,
          { zoneId: zone },
        ]);
        queryClient.invalidateQueries([
          'scenarioTasks',
          tenant,
          { reference: +reference },
        ]);
        queryClient.invalidateQueries(['all gears', tenant, { zoneId: zone }]);
        queryClient.invalidateQueries(['childs', tenant, { zoneId: zone }]);
        queryClient.invalidateQueries(['gears', tenant, { zoneId: zone }]);
      },
    },
  );

  type StepDeletionArgs = {
    reference: number;
    index: number;
    activeStep: number | undefined;
    steps: StepType[];
  };

  const deleteStepMutation = useMutation(
    async (data: StepDeletionArgs) => deleteStep(tenant, data.reference),
    {
      onSuccess: (_, d) => {
        if (d.reference === d.activeStep) {
          if (d.steps.length > 1 && onSelectStep) {
            onSelectStep(
              d.index === 0
                ? d.steps[1].reference
                : d.steps[d.index - 1].reference,
              d.index === 0 ? d.steps[1].id : d.steps[d.index - 1].id,
            );
          } else if (onSelectStep) {
            onSelectStep(undefined, undefined);
          }
        }
        queryClient.invalidateQueries([
          'stepTaskTree',
          tenant,
          { zoneId: zone },
        ]);
        queryClient.invalidateQueries(['scenarioTasks', tenant, { reference }]);
      },
    },
  );

  return {
    createTaskMutation,
    deleteTaskMutation,
    updateTaskMutation,
    importTasksMutation,
    createStepMutation,
    updateStepMutation,
    importStepsMutation,
    deleteStepMutation,
    loading:
      createTaskMutation.isLoading ||
      deleteTaskMutation.isLoading ||
      updateTaskMutation.isLoading ||
      importTasksMutation.isLoading ||
      createStepMutation.isLoading ||
      updateStepMutation.isLoading ||
      importStepsMutation.isLoading ||
      deleteStepMutation.isLoading,
  };
}
