import clsx from 'clsx';
import React from 'react';
import { Flex } from 'ui/flex';

import classes from './spectral-loader.module.scss';

interface AnimatedSpectralLogoProps {
  animated: boolean;
}
function AnimatedSpectralLogo({ animated }: AnimatedSpectralLogoProps) {
  return (
    <svg
      className={clsx(classes.logo, {
        [classes.animated]: animated,
      })}
      viewBox="0 0 841.89 595.28"
    >
      <polygon
        stroke="currentColor"
        fill="transparent"
        className={classes.segment}
        points="410.81 22.81 410.8 108.4 248.07 185.14 161.33 140.34 410.81 22.81"
      />
      <polygon
        stroke="currentColor"
        fill="transparent"
        className={classes.segment}
        points="161.33 140.34 410.81 263.22 321.86 305.2 161.33 226.04 161.33 140.34"
      />
      <polygon
        stroke="currentColor"
        fill="transparent"
        className={classes.segment}
        points="161.33 380.96 161.33 466.81 410.81 349.07 410.81 263.22 161.33 380.96"
      />
      <polygon
        stroke="currentColor"
        fill="transparent"
        className={classes.segment}
        points="422.16 22.81 422.16 108.4 583.15 184.32 671.63 140.25 422.16 22.81"
      />
      <polygon
        stroke="currentColor"
        fill="transparent"
        className={classes.segment}
        points="422.16 343.46 422.16 257.78 671.5 140.19 671.63 226.04 422.16 343.46"
      />
      <polygon
        stroke="currentColor"
        fill="transparent"
        className={classes.segment}
        points="499.2 460.99 499.2 307.2 422.16 343.46 422.16 460.99 499.2 460.99"
      />
      <text
        className={classes.spectral}
        fill="currentColor"
        transform="translate(190.23 623.87)"
      >
        SPECTRAL
      </text>
    </svg>
  );
}

interface SpectralLoaderProps {
  children: React.ReactNode;
  status: 'ready' | 'loading' | 'error';
}
export function SpectralLoader({ children, status }: SpectralLoaderProps) {
  const animated = status !== 'error';
  const hide = status === 'ready';

  return (
    <Flex
      justify="center"
      /* removed because size was to heigh 
      height="100%" */
      testid="spectral-loader"
      className={clsx(classes.container, { [classes.hide]: hide })}
    >
      <Flex direction="column" items="center" gap={16}>
        <AnimatedSpectralLogo animated={animated} />
        {children}
      </Flex>
    </Flex>
  );
}
