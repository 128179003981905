/* eslint-disable no-else-return */
import React, { useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { Intervention } from 'business/type';
import GenericTable from 'ui/table';
import { getUserList } from 'business/users/services/users-list';
import { useQuery } from 'react-query';
import { useUserSession } from 'technical/hooks/use-user-session';
import { Link } from 'react-router-dom';
import {
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
} from '@ant-design/icons';
import { getReportSubscription } from 'business/monitoring/services/subscriptions';

interface InterventionListProps {
  interventions?: Intervention[];
  loading: boolean;
  onSelect: (keys: React.Key[]) => void;
}

export default function InterventionList({
  interventions,
  loading,
  onSelect,
}: InterventionListProps) {
  const { t } = useTranslation('interventions');
  const { tenant } = useUserSession();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const userListQuery = useQuery(['users', tenant], () => getUserList(tenant));
  const reportSubscriptionQuery = useQuery(
    ['reportInterventions', tenant],
    () => getReportSubscription(tenant),
  );

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    onSelect(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns: ColumnsType<Intervention> = [
    {
      title: t('list.state'),
      render: (record: Intervention) => {
        for (const interventionStep of record.interventionSteps) {
          for (const comment of interventionStep.comments) {
            if (comment.ok === false) {
              return <ExclamationCircleTwoTone twoToneColor="red" />;
            }
          }
        }
        return <CheckCircleTwoTone twoToneColor="#52c41a" />;
      },
      width: 100,
    },
    {
      title: t('list.scenario'),
      dataIndex: ['scenarioLabel'],
      render: (text: string, record: Intervention) => (
        <Link to={`/interventions/${record.id}`}>{text}</Link>
      ),
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: t('list.user'),
      render: (record: Intervention) => {
        const interventionUser = userListQuery.data?.find(
          (user) => user.id === record.session.userId,
        );
        const display = interventionUser ? (
          <p>
            {interventionUser.firstname} {interventionUser.surname}
          </p>
        ) : null;
        return display;
      },
    },
    {
      title: t('list.date'),
      render: (record: Intervention) =>
        new Date(record.startDate ?? '').toLocaleString(),
      defaultSortOrder: 'descend',
      sorter: (a, b) =>
        new Date(a.startDate ?? '').getTime() -
        new Date(b.startDate ?? '').getTime(),
    },
    {
      title: t('list.alerts'),
      render: (record: Intervention) =>
        record.interventionSteps.reduce(
          (tot, interventionStep) => tot + interventionStep.alerts.length,
          0,
        ),
    },
  ];

  return (
    <GenericTable
      loading={loading}
      rowSelection={rowSelection}
      dataSource={interventions}
      columns={columns.filter((col) => {
        if (
          reportSubscriptionQuery.isSuccess &&
          !reportSubscriptionQuery.data.subscribed
        ) {
          return col.title !== t('list.state');
        }
        return col.title;
      })}
      pageSize={20}
    />
  );
}
