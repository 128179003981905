import React from 'react';
import clsx from 'clsx';
import classes from './index.module.scss';

interface SeparatorProps {
  apparence?: 'light' | 'dark';
}

export function Separator({ apparence = 'light' }: SeparatorProps) {
  return (
    <div
      className={clsx(classes.separator, {
        [classes.light]: apparence === 'light',
        [classes.dark]: apparence === 'dark',
      })}
    />
  );
}
