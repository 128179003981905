import * as yup from 'yup';
import React, { useState } from 'react';
import { Modal, Form, Input, Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import { ScenarioInputCreate } from 'business/scenarios/services/type';
import { useUserSession } from 'technical/hooks/use-user-session';
import { useNavigate } from 'react-router';
import { useQueryClient, useMutation } from 'react-query';
import { createScenario } from 'business/scenarios/services/scenario-creation';
import classes from './scenario-creation.module.scss';

interface ScenariosCreationModalProps {
  onClose: () => void;
}

export default function ScenariosCreationModal({
  onClose,
}: ScenariosCreationModalProps) {
  const { t } = useTranslation('scenarios');
  const { zone, tenant } = useUserSession();
  const [scenario, setScenario] = useState('');
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const createScenarioMutation = useMutation(
    async (input: ScenarioInputCreate) => createScenario(tenant, input),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('scenarios');
        queryClient.invalidateQueries([
          'stepTaskTree',
          tenant,
          { zoneId: zone },
        ]);
        const { reference } = res.scenario.data;
        navigate(`/scenarios/${reference}`);
      },
    },
  );

  const onSubmitForm = () => {
    const schema = yup.object({
      scenario: yup.string().required(),
    });
    schema
      .validate({ scenario })
      .then((res) => {
        createScenarioMutation.mutate({
          label: res.scenario,
          zoneId: zone,
        });
      })
      .catch(() => setIsError(true));
  };

  return (
    <Modal
      open
      title={t('creation.modal.title')}
      okText={t('creation.modal.submit')}
      data-testid="modal-scenario-creation"
      onOk={onSubmitForm}
      okButtonProps={{
        loading: createScenarioMutation.isLoading,
      }}
      cancelText={t('creation.modal.cancel')}
      onCancel={onClose}
    >
      {isError && (
        <Alert
          type="error"
          message={t('creation.modal.error')}
          className={classes.modalError}
        />
      )}
      <Form name="scenario">
        <Form.Item
          className={classes.modalItem}
          label={t('creation.modal.inputs.scenario')}
          labelAlign="left"
          name="scenario"
          rules={[{ required: true }]}
        >
          <Input
            onChange={(e) => setScenario(e.target.value)}
            data-testid="scenario-title"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
