import { Folder, Media } from 'business/type';
import { MixedMedia } from './types';

interface FormattedFolder {
  value: number;
  title: string;
  children?: FormattedFolder[];
}

export const isMedia = (record: MixedMedia): record is Media => 'url' in record;

export function formatTreeData(
  data: Folder[],
  rootName: string,
): FormattedFolder[] {
  const result: FormattedFolder[] = [];

  function traverse(node: Folder, obj: FormattedFolder[]) {
    obj.push({
      value: node.id,
      title: node.label,
    });

    if (node.childs && node.childs.length > 0) {
      obj[obj.length - 1].children = [];
      for (const child of node.childs) {
        traverse(child, obj[obj.length - 1].children!);
      }
    }
  }

  for (const node of data) {
    traverse(node, result);
  }

  return [{ value: -1, title: rootName }, ...result];
}
