import React, { Key, useEffect, useState } from 'react';
import { useHandleZones } from 'business/digital-twin/hooks/zones';
import { Scenario, Task, Zone } from 'business/type';
import { Space, Spin, Tree, Input } from 'antd';
import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import { DataNode } from 'antd/lib/tree';
import { ZoneBlock } from 'business/digital-twin/routes/digital-tree/zone-block';
import { ScenarioBlock } from 'business/digital-twin/routes/digital-tree/scenario-block';
import { TaskBlock } from 'business/digital-twin/routes/digital-tree/task-block';

interface ImportTasksTreeProps {
  scenarioExpanded: number;
  onUpdateArray?: (array: number[]) => void;
}

export default function ImportTasksTree({
  scenarioExpanded,
  onUpdateArray,
}: ImportTasksTreeProps) {
  const { queries } = useHandleZones();
  const [expandedKeys, setExpandedKeys] = useState<Key[]>();
  const [checkedKeys, setCheckedKeys] = useState<Key[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);
  const [treeData, setTreeData] = useState<any>([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (queries.treeScenario.isSuccess) {
      setTreeData([buildZoneTree(queries.treeScenario.data, 0)]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedKeys, searchValue, queries.treeScenario.data]);

  useEffect(() => {
    setExpandedKeys([...(expandedKeys || []), `scenario-${scenarioExpanded}`]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { Search } = Input;

  function buildZoneTree(zone: Zone, depth: number): any {
    let children = {};

    if (zone.childs.length > 0) {
      children = {
        children: zone.childs.map((child) => buildZoneTree(child, depth + 1)),
      };
    } else if (zone.scenarios) {
      children = {
        children: buildScenarioTree(zone.scenarios),
      };
    }

    return {
      title: (
        <ZoneBlock
          zone={zone}
          searchValue={searchValue}
          addExpand={(id) => {
            setExpandedKeys([...(expandedKeys || []), id]);
          }}
          depth={depth}
        />
      ),
      subtitle: zone.label.toLowerCase(),
      key: `zone-${zone.id}`,
      checkable: false,
      ...children,
    };
  }

  function buildScenarioTree(scenarios: Scenario[]): any {
    return scenarios
      .filter((scenario) => !scenario.deleted && scenario.isDraft)
      .map((scenario) => ({
        title: <ScenarioBlock scenario={scenario} searchValue={searchValue} />,
        subtitle: scenario.label.toLowerCase(),
        key: `scenario-${scenario.reference}`,
        children: buildTaskTree(scenario.tasks),
        checkable: false,
      }));
  }

  function buildTaskTree(tasks: Task[] | undefined): any {
    return tasks
      ? tasks
          .filter((task) => !task.deleted)
          .sort((taskA, taskB) => taskA.rank - taskB.rank)
          .map((task) => ({
            title: <TaskBlock task={task} searchValue={searchValue} />,
            subtitle: task.label.toLowerCase(),
            key: `task-${task.reference}`,
            checkable: true,
          }))
      : [];
  }

  const onExpand = (expandedKeysValue: React.Key[]) => {
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const onCheck = (checkedKeysValue: any) => {
    // eslint-disable-next-line no-param-reassign
    const taskRef = checkedKeysValue.checked.map((key: string) =>
      parseInt(key.split('-')[1], 10),
    );
    if (onUpdateArray) onUpdateArray(taskRef);

    setCheckedKeys(checkedKeysValue.checked);
  };

  const flattenTree = (tree: any[]): any[] =>
    tree
      .map((node) => {
        if (node.children) {
          return [node, ...flattenTree(node.children)];
        }
        return [node];
      })
      .flat();

  const defaultData = queries.treeScenario.isSuccess
    ? flattenTree([buildZoneTree(queries.treeScenario.data, 0)])
    : [];

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value.length > 0) {
      const newExpandedKeys = defaultData
        .map((item: { subtitle: string | string[]; key: React.Key }) => {
          if (item.subtitle.indexOf(value.toLowerCase()) > -1) {
            return getParentKey(item.key, defaultData);
          }
          return null;
        })
        .filter(
          (item: any, i: any, self: string | any[]) =>
            item && self.indexOf(item) === i,
        );
      setExpandedKeys(newExpandedKeys as React.Key[]);
      setSearchValue(value.toLowerCase());
      setAutoExpandParent(true);
    } else {
      setSearchValue('');
      setExpandedKeys([]);
      setAutoExpandParent(false);
    }
  };

  const getParentKey = (key: React.Key, tree: DataNode[]): React.Key => {
    let parentKey: React.Key;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node.children) {
        if (node.children.some((item) => item.key === key)) {
          parentKey = node.key;
        } else if (getParentKey(key, node.children)) {
          parentKey = getParentKey(key, node.children);
        }
      }
    }
    return parentKey!;
  };

  return (
    <Space direction="vertical" style={{ display: 'flex' }}>
      <Search placeholder="input search text" allowClear onChange={onChange} />
      {queries.treeScenario.isSuccess && treeData.length > 0 ? (
        <Tree
          showLine
          blockNode
          autoExpandParent={autoExpandParent}
          checkedKeys={checkedKeys}
          expandedKeys={expandedKeys}
          onExpand={onExpand}
          switcherIcon={<DownOutlined />}
          checkable
          onCheck={onCheck}
          checkStrictly
          treeData={treeData}
        />
      ) : (
        <Spin
          size="large"
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        />
      )}
    </Space>
  );
}
