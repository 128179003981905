import React from 'react';
import { Badge, Tag, Tooltip } from 'antd';

const style = { padding: '0px 7px' };
const LIMIT_TAG_TEXT = 17;

export default function TagTitle({ label }: { label: string | undefined }) {
  if (!label) {
    return <Tag color="blue" style={style} />;
  }

  const isLongTag = label.length > LIMIT_TAG_TEXT;
  const tagElem = (
    <Tag color="blue" style={style}>
      <Badge
        color="blue"
        text={isLongTag ? `${label.slice(0, LIMIT_TAG_TEXT)}...` : label}
      />
    </Tag>
  );

  return isLongTag ? (
    <Tooltip title={label} key={label}>
      {tagElem}
    </Tooltip>
  ) : (
    tagElem
  );
}
