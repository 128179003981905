import { useMutation } from 'react-query';
import { useUserSession } from 'technical/hooks/use-user-session';
import { notification } from 'antd';
import {
  createGear,
  updateGear,
  deleteGear,
} from 'business/gears/services/gear';
import { GearInputUpdate } from 'business/gears/services/type';
import { GearInputCreate } from 'business/scenarios/services/type';
import { queryClient } from 'technical/react-query';

export function useHandleGears() {
  const { tenant, zone } = useUserSession();
  const rootKey = ['tree', tenant, zone];

  const invalidateQueries = () => {
    queryClient.invalidateQueries(rootKey);
  };

  const createGearMutation = useMutation(
    async (input: GearInputCreate) => createGear(tenant, input),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries();
      },
      onError: (err: Error) => notification.error({ message: err.message }),
    },
  );

  const updateGearMutation = useMutation(
    async (gearUpdate: GearInputUpdate) => updateGear(tenant, gearUpdate),
    {
      onSuccess: async (data, variables) => {
        invalidateQueries();
        if (variables.mediasId) {
          queryClient.invalidateQueries([
            'medias',
            tenant,
            { gearId: variables.id },
          ]);
        }
      },
      onError: (err: Error) => notification.error({ message: err.message }),
    },
  );

  const deleteGearMutation = useMutation(
    async (gearId: number) => deleteGear(tenant, gearId),
    {
      onSuccess: async () => invalidateQueries(),
      onError: (err: Error) => notification.error({ message: err.message }),
    },
  );

  return {
    isLoading:
      createGearMutation.isLoading ||
      deleteGearMutation.isLoading ||
      updateGearMutation.isLoading,
    createGear: createGearMutation.mutate,
    deleteGear: deleteGearMutation.mutate,
    updateGear: updateGearMutation.mutate,
    updateGearAsync: updateGearMutation.mutateAsync,
  };
}
