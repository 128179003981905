import { Zone } from 'business/type';
import { useQuery } from 'react-query';
import { api } from 'technical/api';

export async function getTopLevelZones(
  tenant: string,
  all: boolean = false,
): Promise<Array<Pick<Zone, 'id' | 'label'>>> {
  const { data: zones } = await api(tenant).get<Array<Zone>>('/v1/zones/roots');

  return zones.map(({ label, id }) => ({ id, label }));
}

export function useTopLevelZones(
  tenant: string,
  // options?: UseQueryOptions<TopLevelZones, AxiosError, TData>
) {
  return useQuery(['top-level-zones', tenant], () => getTopLevelZones(tenant), {
    // ...options,
    enabled: !!tenant,
  });
}
