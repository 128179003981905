import React, { useEffect, useState } from 'react';
import { Task } from 'business/type';
import { Col, Row, Space, Typography } from 'antd';

interface TaskBlockProps {
  task: Task;
  searchValue: string;
}

export function TaskBlock({ task, searchValue }: TaskBlockProps) {
  const { Text } = Typography;
  const [title, setTitle] = useState(<Text>{task.label}</Text>);

  useEffect(() => {
    if (searchValue.length > 0) {
      // Perform any actions you want when the propValue changes
      const index = task.label.toLowerCase().indexOf(searchValue);
      const beforeStr = task.label.substring(0, index);
      const str = task.label.substring(index, index + searchValue.length);
      const afterStr = task.label.slice(index + searchValue.length);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      index > -1
        ? setTitle(
            <Text>
              {beforeStr}
              <Text mark>{str}</Text>
              {afterStr}
            </Text>,
          )
        : setTitle(<Text>{task.label}</Text>);
    } else {
      setTitle(<Text>{task.label}</Text>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, task]);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Row>
        <Col flex="auto">
          <Space>{title}</Space>
        </Col>
      </Row>
    </Space>
  );
}
