import React, { useState } from "react";
import { Alert, Button } from "antd";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { getUserList } from "business/users/services/users-list";
import { PageLayout } from "ui/layout";
import { useUserSession } from "technical/hooks/use-user-session";
import { AuthorizedAccessOnly } from "technical/auth/authorization";
import { ADMINS } from "technical/auth/roles";
import UsersList from "./user-list";
import UsersCreationModal from "./user-creation";

export default function UsersPage() {
  const { t } = useTranslation("users");
  const { tenant } = useUserSession();
  const userListQuery = useQuery(["users", tenant], () => getUserList(tenant));
  const [isModalVisible, setModalIsVisible] = useState(false);

  const toggleModal = () => setModalIsVisible((current) => !current);

  return (
    <PageLayout
      title={t("list.title")}
      leftButton={
        <AuthorizedAccessOnly authorized={ADMINS}>
          <Button type="primary" onClick={toggleModal}>
            {t("list.invite")}
          </Button>
        </AuthorizedAccessOnly>
      }
    >
      {userListQuery.status === "error" ? (
        <Alert type="error" message={t("list.error")} />
      ) : null}
      <UsersList
        loading={userListQuery.status === "loading"}
        users={userListQuery.data}
      />
      {isModalVisible && <UsersCreationModal onClose={toggleModal} />}
    </PageLayout>
  );
}
