/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { Button, Card, Modal, Space, Tag } from 'antd';
import {
  MenuOutlined,
  DeleteTwoTone,
  WarningTwoTone,
} from '@ant-design/icons';
import { AuthorizedAccessOnly } from 'technical/auth/authorization';
import { Role } from 'technical/auth/roles';
import { Step as StepType } from 'business/type';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import classes from './step.module.scss';

interface StepProps {
  reference: number;
  step: StepType;
  canUpdate: boolean;
  active?: boolean;
  position: string;
  children: React.ReactNode;
  isCondition: boolean;
  isDeletionLoading: boolean;
  onClick: () => void;
  onDelete?: () => void;
}

export function Step({
  reference,
  step,
  canUpdate,
  active,
  position,
  children,
  isCondition,
  isDeletionLoading,
  onClick,
  onDelete,
}: StepProps) {
  const roles = [Role.Admin, Role.Author, Role.SuperAdmin];
  const { t } = useTranslation('scenarios');
  const [isVisible, setIsVisible] = useState(false);

  const handleClick = () => {
    if (reference !== -1) {
      onClick();
    }
  };

  const toggleModal = (e: { stopPropagation: () => void }) => {
    setIsVisible((current) => !current);
    e.stopPropagation();
  };

  const handleDelete = (e: { stopPropagation: () => void }) => {
    onDelete!();
    e.stopPropagation();
  };

  const hasWarning = step.answers?.find((answer) => !answer.nextStepId);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div onClick={handleClick}>
      <Card
        size="small"
        className={clsx(classes.step, {
          [classes.active]: active,
          [classes.disabled]: reference === -1,
          [classes.warning]: hasWarning,
        })}
        extra={
          onDelete ? (
            <AuthorizedAccessOnly authorized={roles} fallback={null}>
              <Space>
                <Button
                  type="link"
                  disabled={isCondition}
                  onClick={toggleModal}
                  loading={isDeletionLoading}
                  icon={
                    <DeleteTwoTone
                      twoToneColor={isCondition ? 'grey' : 'red'}
                    />
                  }
                />
                <Modal
                  open={isVisible}
                  title={t('detail.delete-step.title')}
                  okText={t('detail.delete-step.submit')}
                  okType="danger"
                  onOk={handleDelete}
                  onCancel={toggleModal}
                  cancelText={t('detail.delete-step.cancel')}
                >
                  <p>{t('detail.delete-step.message')}</p>
                </Modal>
              </Space>
            </AuthorizedAccessOnly>
          ) : null
        }
        title={
          <>
            {canUpdate && <MenuOutlined className={classes.icon} />}
            <Tag color={hasWarning ? 'warning' : 'blue'}>{position}</Tag>
            {hasWarning ? (
              <WarningTwoTone twoToneColor="red" style={{ marginLeft: 8 }} />
            ) : null}
          </>
        }
      >
        {children}
      </Card>
    </div>
  );
}
