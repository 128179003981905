import React from 'react';
import { Gear, Intervention, InterventionStep } from 'business/type';
import { useTranslation } from 'react-i18next';
import GenericTable from 'ui/table';
import { ColumnsType } from 'antd/lib/table';
import {
  CheckSquareTwoTone,
  CloseSquareTwoTone,
  MinusSquareTwoTone,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Separator } from 'ui/separator';
import { CommentMedia, CommentText } from './comment';
import classes from './intervention-detail-table.module.scss';

interface InterventionDetailTableProps {
  intervention?: Intervention;
  gears?: Gear[];
}

export default function InterventionDetailTable({
  intervention,
  gears,
}: InterventionDetailTableProps) {
  const { t } = useTranslation('interventions');

  const columns: ColumnsType<InterventionStep> = [
    {
      title: t('detail.list.step'),
      dataIndex: ['step', 'label'],
      align: 'center',
    },
    {
      title: t('detail.list.okko'),
      render: (record: InterventionStep) => {
        if (typeof record.isOk === 'boolean') {
          return record.isOk ? (
            <CheckSquareTwoTone twoToneColor="#52c41a" />
          ) : (
            <CloseSquareTwoTone twoToneColor="red" />
          );
        }
        return <MinusSquareTwoTone twoToneColor="silver" />;
      },
      width: 100,
      align: 'center',
    },
    {
      title: t('detail.list.input-request.title'),
      align: 'center',
      render: (record: InterventionStep) => (
        <>
          {record.inputResponses.map((inputResponse, index) => (
            <div key={inputResponse.id} className={classes.inputResponse}>
              <div className={classes.block}>
                <span>{inputResponse.inputRequest?.label} : </span>
                <span className={classes.information}>
                  {inputResponse.value}
                </span>
              </div>
              {record.inputResponses.length - 1 !== index ? (
                <Separator />
              ) : null}
            </div>
          ))}
        </>
      ),
    },
    {
      title: t('detail.list.comment.title'),
      align: 'center',
      children: [
        {
          title: t('detail.list.comment.text'),
          align: 'center',
          render: (record: InterventionStep) => (
            <CommentText interventionStep={record} />
          ),
        },
        {
          title: t('detail.list.comment.medias'),
          align: 'center',
          render: (record: InterventionStep) => (
            <CommentMedia interventionStep={record} />
          ),
        },
      ],
    },
    {
      title: t('detail.list.alert'),
      align: 'center',
      render: (record: InterventionStep) =>
        record.alerts?.map((alert) => (
          <>
            <Link to={`/alerts/${alert.id}`}>{alert.label}</Link>
            <br />
          </>
        )),
    },
  ];

  const dataToUse = intervention?.interventionSteps.map((interventionStep) => {
    if (interventionStep.comments.length === 1) {
      interventionStep.isOk = interventionStep.comments[0].ok;
      interventionStep.comment = interventionStep.comments[0].text;
      interventionStep.commentId = interventionStep.comments[0].id;
      /*  interventionStep.medias = interventionStep.comments[0].medias; */
    } else if (interventionStep.comments.length > 1) {
      const isOk = interventionStep.comments.reduce(
        (allOk, comment) => comment.ok && allOk,
        true,
      );
      const concatText = interventionStep.comments?.reduce(
        (allText, comment) => {
          if (comment.text !== '' && comment.text !== null) {
            interventionStep.commentId = comment.id;
            return allText + comment.text;
          }
          if (comment.medias.length > 0) {
            interventionStep.commentId = comment.id;
          }
          return allText;
        },
        '',
      );
      interventionStep.isOk = isOk;
      interventionStep.comment = concatText;
      /*  interventionStep.medias = interventionStep.comments
        .map((comment) => comment.medias)
        .flat(); */
      if (!interventionStep.commentId)
        interventionStep.commentId = interventionStep.comments[0].id;
      interventionStep.children = interventionStep.comments.map((comment) => ({
        isOk: comment.ok,
        step: {
          label: gears?.find((gear) => gear.id === comment.gearId)?.label,
        },
        comment: undefined,
        inputResponses: [],
      }));
    }
    return interventionStep;
  });

  return (
    <GenericTable
      bordered
      dataSource={dataToUse}
      columns={columns}
      pageSize={20}
    />
  );
}
