import React, { useState } from 'react';
import { Button, Card, Modal } from 'antd';
import { DeleteTwoTone } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { AuthorizedAccessOnly } from 'technical/auth/authorization';
import { Role } from 'technical/auth/roles';
import classes from './task.module.scss';

interface TaskProps {
  title: React.ReactNode;
  onRemove?: () => void;
  children: React.ReactNode;
  hasTarget: boolean;
}

export function Task({ title, onRemove, children, hasTarget }: TaskProps) {
  const { t } = useTranslation('scenarios');
  const [isVisible, setIsVisible] = useState(false);
  const roles = [Role.Admin, Role.SuperAdmin, Role.Author];

  const toggleModal = () => setIsVisible((current) => !current);

  return (
    <Card
      bordered
      title={title}
      className={classes.task}
      extra={
        onRemove ? (
          <AuthorizedAccessOnly authorized={roles}>
            <Button
              type="link"
              onClick={toggleModal}
              icon={<DeleteTwoTone twoToneColor={hasTarget ? 'grey' : 'red'} />}
              disabled={hasTarget}
            />
            <Modal
              open={isVisible}
              title={t('detail.delete-task.title')}
              okText={t('detail.delete-task.submit')}
              okType="danger"
              onOk={() => {
                onRemove();
                toggleModal();
              }}
              onCancel={toggleModal}
              cancelText={t('detail.delete-task.cancel')}
            >
              <p>{t('detail.delete-task.message')}</p>
            </Modal>
          </AuthorizedAccessOnly>
        ) : null
      }
    >
      {children}
    </Card>
  );
}
