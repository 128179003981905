import React, { useState } from 'react';
import { Alert, Button, DatePicker } from 'antd';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useUserSession } from 'technical/hooks/use-user-session';
import {
  exportInterventionsCSVByIds,
  getInterventionListFromZone,
} from 'business/interventions/services/intervention-list';
import { PageLayout } from 'ui/layout';
import dayjs from 'dayjs';
import InterventionList from './intervention-list';
import { downloadZip } from 'technical/utils/download';
import { DownloadOutlined } from '@ant-design/icons';

export default function InterventionsPage() {
  const { t } = useTranslation('interventions');
  const { zone, tenant } = useUserSession();
  const [startDate, setStartDate] = useState(
    dayjs().subtract(7, 'days').toISOString().slice(0, 10),
  );
  const [endDate, setEndDate] = useState<string>(
    dayjs().toISOString().slice(0, 10),
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const interventionListQuery = useQuery(
    ['interventions', tenant, { zoneId: zone, startDate, endDate }],
    () => getInterventionListFromZone(tenant, zone, startDate, endDate),
  );

  const handleChange = (dates: any, dateStrings: [string, string]) => {
    setStartDate(dateStrings[0]);
    setEndDate(dateStrings[1]);
  };

  const onSelect = (keys: React.Key[]) => {
    setSelectedRowKeys(keys);
  };

  const onExport = async () => {
    const blob = await exportInterventionsCSVByIds(
      tenant,
      selectedRowKeys as string[],
    );
    downloadZip(blob, 'export_interventions');
  };

  // issue with update antd to correct when updated
  // eslint-disable-next-line prefer-destructuring
  const RangePicker: any = DatePicker.RangePicker;

  return (
    <PageLayout
      title={t('list.title')}
      leftButton={
        <RangePicker
          onChange={handleChange}
          defaultValue={[dayjs(startDate), dayjs(endDate)]}
        />
      }
      rightButton={
        <Button icon={<DownloadOutlined />} onClick={onExport}>
          {t('detail.buttons.export')}
        </Button>
      }
    >
      {interventionListQuery.status === 'error' ? (
        <Alert type="error" message={t('list.error')} />
      ) : null}
      <InterventionList
        loading={interventionListQuery.status === 'loading'}
        interventions={interventionListQuery.data}
        onSelect={onSelect}
      />
    </PageLayout>
  );
}
