import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

interface ScenariosDeletionModalProps {
  isVisible: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export default function ScenarioDeletionModal({
  isVisible,
  onClose,
  onSubmit,
}: ScenariosDeletionModalProps) {
  const { t } = useTranslation('scenarios');

  return (
    <Modal
      title={t('deletion.modal.title')}
      cancelText={t('deletion.modal.cancel')}
      okText={t('deletion.modal.submit')}
      open={isVisible}
      okType="danger"
      onOk={onSubmit}
      onCancel={onClose}
      data-testid="modal-scenario-deletion"
    >
      <p>{t('deletion.modal.message')}</p>
    </Modal>
  );
}
