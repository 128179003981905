import { notification } from 'antd';
import { Scenario } from 'business/type';
import { useQuery, useMutation } from 'react-query';
import { useUserSession } from 'technical/hooks/use-user-session';
import { queryClient } from 'technical/react-query';
import deleteScenario from '../services/scenario-deletion';
import {
  declineScenario,
  getScenarioDetail,
  getScenarioDetailId,
  modifyScenario,
  ScenarioUpdate,
  validateScenario,
} from '../services/scenario-detail';

export function useHandleScenario(
  reference: number,
  versionId: number,
  zoneId: number,
  validationCallback?: (scenarioId?: number) => void,
) {
  const { tenant } = useUserSession();
  const scenarioQuery = useQuery(['scenario', tenant, { reference }], () =>
    getScenarioDetail(tenant, reference),
  );

  const scenarioIdQuery = useQuery(
    ['scenario', tenant, { id: versionId }],
    () => getScenarioDetailId(tenant, versionId!),
    { enabled: !!versionId },
  );

  const scenarioData: Scenario | undefined = scenarioIdQuery.isSuccess
    ? scenarioIdQuery.data
    : scenarioQuery.data;

  const updateScenarioMutation = useMutation(
    async (update: ScenarioUpdate) => modifyScenario(tenant, update),
    {
      onMutate: (update: ScenarioUpdate) => {
        if (update.reference && update.mediasId) {
          queryClient.setQueryData(
            ['medias', tenant, { reference: update.reference }],
            update.mediasId,
          );

          if (versionId) {
            queryClient.setQueryData(
              ['medias', tenant, { scenario: versionId }],
              update.mediasId,
            );
          }
        }
      },
      onSuccess: (_, update) => {
        queryClient.invalidateQueries([
          'scenario',
          tenant,
          { reference: update.reference },
        ]);
        queryClient.invalidateQueries([
          'scenario',
          tenant,
          { reference: update.id },
        ]);
        queryClient.invalidateQueries(['scenarios', tenant]);
        queryClient.invalidateQueries([
          'medias',
          tenant,
          { scenario: update.id },
        ]);
        queryClient.invalidateQueries([
          'scenario',
          tenant,
          {
            reference: update.prevRef
              ? update.prevRef
              : scenarioQuery.data?.prevRef,
          },
        ]);
        queryClient.invalidateQueries(['stepTaskTree', tenant, { zoneId }]);
      },
      onError: (err: Error) => notification.error({ message: err.message }),
    },
  );

  const deleteScenarioMutation = useMutation(
    async () => deleteScenario(tenant, +reference),
    {
      onMutate: () => {
        const previousData =
          queryClient.getQueryData<Scenario[]>([
            'scenarios',
            tenant,
            { zoneId },
          ]) || [];
        queryClient.setQueryData(['scenarios', tenant, { zoneId }], () =>
          previousData.filter((scenario) => scenario.reference !== +reference),
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries('scenarios');
        queryClient.invalidateQueries([
          'scenario',
          tenant,
          { reference: scenarioQuery.data?.prevRef },
        ]);
        queryClient.invalidateQueries([
          'scenario',
          tenant,
          { reference: scenarioQuery.data?.nextRef },
        ]);
        queryClient.invalidateQueries(['stepTaskTree', tenant, { zoneId }]);
      },
    },
  );

  const validateScenarioMutation = useMutation(
    async () => validateScenario(tenant, +reference),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'versions',
          tenant,
          { reference: +reference },
        ]);
        queryClient.invalidateQueries(['scenario', tenant]);
        queryClient.invalidateQueries(['scenarioTasks', tenant]);
        queryClient.invalidateQueries(['scenarios', tenant, { zoneId }]);
        queryClient.invalidateQueries(['tags', tenant, 'scenario']);

        if (validationCallback) {
          validationCallback(scenarioQuery.data?.id);
        }
      },
    },
  );

  const declineScenarioMutation = useMutation(
    async () => declineScenario(tenant, +reference),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['scenario', tenant]);
        queryClient.invalidateQueries(['scenarios', tenant, { zoneId }]);
      },
    },
  );

  return {
    data: scenarioData,
    queries: {
      scenarioQuery,
      scenarioIdQuery,
    },
    loading: {
      scenario: scenarioQuery.isLoading,
      scenarioId: scenarioIdQuery.isLoading,
      updateScenario: updateScenarioMutation.isLoading,
      deleteScenario: deleteScenarioMutation.isLoading,
      validateScenario: validateScenarioMutation.isLoading,
      declineScenario: declineScenarioMutation.isLoading,
    },
    updateScenario: updateScenarioMutation.mutate,
    updateScenarioAsync: updateScenarioMutation.mutateAsync,
    deleteScenario: deleteScenarioMutation.mutate,
    validateScenario: validateScenarioMutation.mutate,
    declineScenario: declineScenarioMutation.mutate,
  };
}
