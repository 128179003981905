import React from "react";
import { Button } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

interface AddElementProps {
  children: React.ReactNode;
  disabled?: boolean;
  isLoading?: boolean;
  onClick: () => void;
}

export function AddElement({
  children,
  onClick,
  disabled = false,
  isLoading = false,
}: AddElementProps) {
  return (
    <Button onClick={onClick} block disabled={disabled || isLoading}>
      {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
      {children}
    </Button>
  );
}
