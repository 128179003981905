import React from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { useHandleSteps } from 'business/scenarios/hooks/step';
import { RichTextEditor } from 'ui/rich-text-editor';
import { Flex } from 'ui/flex';
import StepGearList from './gear/step-gear-list';
import StepEditionName from './step-edition-name';
import StepMediaList from './media/step-media-list';
import classes from './step-edition-infos.module.scss';
import { StepZone } from './zone/step-zone';

interface StepEditionInfosProps {
  canUpdate: boolean;
  form: any;
  scenarioReference: number;
  scenarioZoneId: number;
  selectedStepId: number;
  selectedStep: number;
  isMediaLoading: boolean;
  setModalIsVisible: (bool: boolean) => void;
}

const { Panel } = Collapse;

export default function StepEditionInfos({
  canUpdate,
  form,
  scenarioReference,
  scenarioZoneId,
  selectedStepId,
  selectedStep,
  isMediaLoading,
  setModalIsVisible,
}: StepEditionInfosProps) {
  const { t } = useTranslation(['scenarios', 'common']);

  // Steps management
  const {
    data: stepData,
    updateStep,
    loading: stepLoading,
  } = useHandleSteps({
    scenarioReference,
    stepId: selectedStepId,
    zone: scenarioZoneId,
  });

  return (
    <Flex gap={16} direction="column" className={classes.collapsePanel}>
      <Flex gap={16} direction="column">
        <StepEditionName
          canUpdate={canUpdate}
          form={form}
          scenarioReference={scenarioReference}
          scenarioZoneId={scenarioZoneId}
          selectedStepId={selectedStepId}
          selectedStep={selectedStep}
        />
        <RichTextEditor
          key={selectedStep}
          defaultValue={stepData?.description}
          onChange={(value) => form.setFieldValue('description', value)}
          editable={canUpdate}
          loading={stepLoading.global}
          customSave={(value) => {
            updateStep({
              stepRef: selectedStep,
              description: value,
            });
          }}
        />
      </Flex>
      <Collapse
        bordered={false}
        defaultActiveKey={['1', '3']}
        ghost
        expandIcon={(panelProps) =>
          panelProps.isActive ? <MinusOutlined /> : <PlusOutlined />
        }
      >
        {/* </Panel> */}
        <Panel header={t('detail.step-edition.fields.gearlist')} key="1">
          <StepGearList
            scenarioReference={scenarioReference}
            stepReference={selectedStep}
            scenarioZoneId={scenarioZoneId}
            stepZoneId={stepData?.zone?.id}
            selectedStepId={selectedStepId}
            list={form.values.gearlist}
            isMandatory={form.values.isMandatory ?? true}
            canUpdate={canUpdate}
            onChecked={(checked) => {
              updateStep({
                stepRef: selectedStep,
                mandatoryValidation: checked,
              });
            }}
          />
        </Panel>
        <Panel header={t('detail.step-edition.fields.zone')} key="2">
          {stepData ? (
            <StepZone
              scenarioReference={scenarioReference}
              scenarioZone={scenarioZoneId}
              step={stepData}
              canUpdate={canUpdate}
            />
          ) : null}
        </Panel>
        <Panel header={t('detail.step-edition.fields.media')} key="3">
          <StepMediaList
            scenarioReference={scenarioReference}
            stepReference={selectedStep}
            selectedStepId={selectedStepId}
            scenarioZoneId={scenarioZoneId}
            canUpdate={canUpdate}
            isMediaLoading={isMediaLoading}
            list={form.values.medias}
            setModalIsVisible={setModalIsVisible}
          />
        </Panel>
      </Collapse>
    </Flex>
  );
}
