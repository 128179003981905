import React from 'react';
import { useParams } from 'react-router';
import {
  Alert,
  Button,
  Card,
  Dropdown,
  Menu,
  MenuProps,
  notification,
} from 'antd';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useUserSession } from 'technical/hooks/use-user-session';
import { PageLayout } from 'ui/layout';
import { getMediaFromAlert } from 'business/medias/services/medias';
import { getAlert, updateAlert } from 'business/alerts/services/alert-detail';
import {
  CheckCircleTwoTone,
  ClockCircleOutlined,
  ClockCircleTwoTone,
  DownOutlined,
  ExclamationCircleTwoTone,
  LoadingOutlined,
} from '@ant-design/icons';
import { getUserList } from 'business/users/services/users-list';
import { Flex } from 'ui/flex';
import { Link } from 'react-router-dom';
import { MediasGalery } from 'ui/media';
import { MediaThumbnail } from 'business/medias/routes/library/thumbnail';
import { queryClient } from 'technical/react-query';
import { AlertInputUpdate } from 'business/alerts/services/type';

type AlertDetailParams = {
  alertId: string;
};

export default function AlertDetailPage() {
  const { alertId } = useParams<keyof AlertDetailParams>() as AlertDetailParams;
  const { t } = useTranslation('alerts');
  const { tenant, zone } = useUserSession();

  const alertMediasQuery = useQuery(
    ['medias', tenant, { alertId: +alertId }],
    () => getMediaFromAlert(tenant, +alertId),
  );

  const alertQuery = useQuery(['alert', tenant, +alertId], () =>
    getAlert(tenant, +alertId),
  );

  const userListQuery = useQuery(['users', tenant], () => getUserList(tenant));

  const updateAlertMutation = useMutation(
    async (data: { alert: AlertInputUpdate }) =>
      updateAlert(tenant, data.alert),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(['alert', tenant, +alertId]);
        queryClient.invalidateQueries(['alerts', tenant, { zoneId: zone }]);
      },
      onError: (err: Error) => notification.error({ message: err.message }),
    },
  );

  const handleMenuClick = (e: any) => {
    let state: 'todo' | 'doing' | 'done';
    switch (e.key) {
      case '2':
        state = 'doing';
        break;
      case '3':
        state = 'done';
        break;
      default:
        state = 'todo';
    }
    updateAlertMutation.mutate({ alert: { id: +alertId, state } });
  };

  const menuItems: MenuProps['items'] = [
    {
      label: t('state.todo'),
      key: '1',
      icon: <ExclamationCircleTwoTone twoToneColor="red" />,
    },
    {
      label: t('state.doing'),
      key: '2',
      icon: <ClockCircleTwoTone twoToneColor="gold" />,
    },
    {
      label: t('state.done'),
      key: '3',
      icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
    },
  ];

  const stateMenu = <Menu onClick={handleMenuClick} items={menuItems} />;

  const alertUser = userListQuery.data?.find(
    (user) => user.id === alertQuery.data?.userId,
  );

  const menuPlaceHolder = (state: 'doing' | 'todo' | 'done' | undefined) => {
    switch (state) {
      case 'doing':
        return (
          <>
            <ClockCircleTwoTone twoToneColor="gold" /> {t('state.doing')}
          </>
        );
      case 'done':
        return (
          <>
            <CheckCircleTwoTone twoToneColor="#27AE60" /> {t('state.done')}
          </>
        );
      default:
        return (
          <>
            <ExclamationCircleTwoTone twoToneColor="red" /> {t('state.todo')}
          </>
        );
    }
  };

  return (
    <PageLayout title={t('detail.title')}>
      {alertQuery.status === 'error' ? (
        <Alert type="error" message={t('detail.error')} />
      ) : null}
      <Flex direction="column" gap={32}>
        <Card
          extra={
            <Dropdown overlay={stateMenu}>
              <Button>
                {menuPlaceHolder(alertQuery.data?.state)}
                {updateAlertMutation.isLoading ? (
                  <LoadingOutlined />
                ) : (
                  <DownOutlined />
                )}
              </Button>
            </Dropdown>
          }
          loading={alertQuery.isLoading}
          title={alertQuery.data?.label}
        >
          <ClockCircleOutlined /> {t('detail.create.when')}{' '}
          {new Date(alertQuery.data?.createDate ?? '').toLocaleString()}{' '}
          {t('detail.create.who')} {alertUser?.firstname} {alertUser?.surname}
        </Card>
        <Flex direction="row" justify="space-between" gap={16}>
          <Flex.Child grow={1}>
            <Card title={t('detail.ressources')}>
              {alertQuery.data?.zone ? (
                <>
                  {`${t('detail.zone')}: `}
                  <Link to={`/zones/${alertQuery.data.zone.id}`}>
                    {alertQuery.data.zone.label}
                  </Link>
                </>
              ) : null}
              {alertQuery.data?.gear ? (
                <>
                  {`${t('detail.gear')}: `}
                  <Link to={`/gears/${alertQuery.data.gear.id}`}>
                    {alertQuery.data.gear.label}
                  </Link>
                </>
              ) : null}
            </Card>
          </Flex.Child>
          <Flex.Child grow={1}>
            {alertMediasQuery.data && alertMediasQuery.data.length > 0 ? (
              <Card title={t('detail.medias')}>
                {alertMediasQuery.data.length > 1 ? (
                  <MediasGalery medias={alertMediasQuery.data} />
                ) : (
                  <MediaThumbnail
                    fromModal={false}
                    media={alertMediasQuery.data[0]}
                  />
                )}
              </Card>
            ) : null}
          </Flex.Child>
        </Flex>
      </Flex>
    </PageLayout>
  );
}
