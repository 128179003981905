import React, { useState } from "react";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { Intervention } from "business/type";
import GenericTable from "ui/table";
import { getUserList } from "business/users/services/users-list";
import { useQuery } from "react-query";
import { useUserSession } from "technical/hooks/use-user-session";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import { getInterventionListFromZone } from "business/interventions/services/intervention-list";

export default function InterventionStatsList() {
  const { t } = useTranslation("monitoring");
  const { tenant, zone } = useUserSession();
  const [startDate, setStartDate] = useState(
    dayjs().subtract(7, "days").toISOString().slice(0, 10)
  );
  const [endDate, setEndDate] = useState<string>(
    dayjs().toISOString().slice(0, 10),
  );

  const handleChange = (dates: any, dateStrings: [string, string]) => {
    setStartDate(dateStrings[0]);
    setEndDate(dateStrings[1]);
  };

  const userListQuery = useQuery(["users", tenant], () => getUserList(tenant));

  const interventionListQuery = useQuery(
    ["interventions", tenant, { zoneId: zone, startDate, endDate }],
    () => getInterventionListFromZone(tenant, zone, startDate, endDate)
  );

  const columns: ColumnsType<Intervention> = [
    {
      title: t("intervention.scenario"),
      dataIndex: ["scenarioLabel"],
      render: (text: string, record: Intervention) => (
        <Link to={`/interventions/${record.id}`}>{text}</Link>
      ),
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: t("intervention.date"),
      render: (record: Intervention) =>
        new Date(record.startDate ?? "").toLocaleString(),
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        new Date(a.startDate ?? "").getTime() -
        new Date(b.startDate ?? "").getTime(),
    },
    {
      title: t("intervention.user"),
      render: (record: Intervention) => {
        const interventionUser = userListQuery.data?.find(
          (user) => user.id === record.session.userId
        );
        const display = interventionUser ? (
          <p>
            {interventionUser.firstname} {interventionUser.surname}
          </p>
        ) : null;
        return display;
      },
    },
    {
      title: t("intervention.switchPin"),
      render: (record: Intervention) => record.switchPin,
      sorter: (a, b) => a.id - b.id,
    },
  ];

  // issue with update antd to correct when updated
  // eslint-disable-next-line prefer-destructuring
  const RangePicker: any = DatePicker.RangePicker;

  return (
    <>
      <RangePicker
        onChange={handleChange}
        defaultValue={[dayjs(startDate), dayjs(endDate)]}
      />
      <GenericTable
        loading={interventionListQuery.isLoading}
        dataSource={interventionListQuery.data}
        columns={columns}
        pageSize={20}
      />
    </>
  );
}
