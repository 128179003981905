import * as yup from 'yup';
import React, { useState } from 'react';
import { Alert, Form, Input, Modal, notification, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useUserSession } from 'technical/hooks/use-user-session';
import { UserInputCreate } from 'business/users/services/type';
import { createUser, getTopZone } from 'business/users/services/user-creation';
import { getVersionsSubscription } from 'business/monitoring/services/subscriptions';
import classes from './user-creation.module.scss';

interface UsersCreationModalProps {
  onClose: () => void;
}

export default function UsersCreationModal({
  onClose,
}: UsersCreationModalProps) {
  const { t } = useTranslation('users');
  const { tenant } = useUserSession();
  const [email, setEmail] = useState('');
  const [topZoneId, setTopZoneId] = useState<number>();
  const [role, setRole] = useState('');
  const [isError, setIsError] = useState(false);

  const queryClient = useQueryClient();

  const createUserMutation = useMutation(
    async (input: UserInputCreate) => createUser(tenant, input),
    {
      onSuccess: (res, input) => {
        queryClient.invalidateQueries('users');
        notification.success({
          message: t('creation.modal.success.message'),
          description: t('creation.modal.success.description', {
            email: input.email,
          }),
        });
        onClose();
      },
    },
  );

  const dvSubscriptionQuery = useQuery(
    ['versions', tenant],
    () => getVersionsSubscription(tenant),
    {
      staleTime: 24 * 60 * 60 * 1000,
    },
  );

  const topZonesListQuery = useQuery(['topZones', tenant], () =>
    getTopZone(tenant),
  );

  const onSubmitForm = () => {
    const schema = yup.object({
      email: yup.string().email().required(),
      topZone: yup.number().required(),
      role: yup.string().required(),
    });
    schema
      .validate({ email, topZone: topZoneId, role })
      .then((res) => {
        createUserMutation.mutate({
          email: res.email,
          zoneId: res.topZone,
          roles: res.role,
        });
      })
      .catch(() => setIsError(true));
  };

  return (
    <Modal
      open
      title={t('creation.modal.title')}
      okText={t('creation.modal.submit')}
      onOk={onSubmitForm}
      cancelText={t('creation.modal.cancel')}
      onCancel={onClose}
      confirmLoading={createUserMutation.isLoading}
    >
      {isError && (
        <Alert
          type="error"
          message={t('creation.modal.error')}
          className={classes.modalError}
        />
      )}
      <Form name="email">
        <Form.Item
          className={classes.modalItem}
          label={t('creation.modal.inputs.email')}
          labelAlign="left"
          name="email"
          rules={[{ required: true }]}
        >
          <Input onChange={(e) => setEmail(e.target.value)} />
        </Form.Item>
        <Form.Item
          className={classes.modalItem}
          label={t('creation.modal.inputs.topZone')}
          labelAlign="left"
          name="topZone"
          rules={[{ required: true }]}
        >
          <Select
            loading={topZonesListQuery.isLoading}
            value={topZoneId}
            onChange={setTopZoneId}
          >
            {(topZonesListQuery?.data ?? []).map((topZone) => (
              <Select.Option value={topZone.id}>{topZone.label}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          className={classes.modalItem}
          label={t('creation.modal.inputs.role')}
          labelAlign="left"
          name="role"
          rules={[{ required: true }]}
        >
          <Select value={role} onChange={setRole}>
            <Select.Option value="ROLE_SUPER_ADMIN">
              {t('role.superAdmin')}
            </Select.Option>
            <Select.Option value="ROLE_ADMIN">{t('role.admin')}</Select.Option>
            <Select.Option value="ROLE_AUTHOR">
              {t('role.author')}
            </Select.Option>
            {dvSubscriptionQuery.data?.subscribed ? (
              <>
                <Select.Option value="ROLE_APPROVER">
                  {t('role.approver')}
                </Select.Option>
                <Select.Option value="ROLE_REVIEWER">
                  {t('role.reviewer')}
                </Select.Option>
              </>
            ) : null}
            <Select.Option value="ROLE_OPERATOR">
              {t('role.operator')}
            </Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}
