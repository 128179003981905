import React, { Key } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  EllipsisOutlined,
  MinusOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Input, Popover } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { TFunction } from 'i18next';
import { Folder, Media } from 'business/type';
import { Flex } from 'ui/flex';
import { MixedMedia } from 'business/medias/services/types';
import { MediaThumbnailPreview } from 'ui/media';
import { isMedia } from 'business/medias/services/folders';
import { MediaThumbnail } from './thumbnail';
import MediaActions from './media-actions';
import MediaLabelInput from './media-label-input';

type LocationType = ReturnType<typeof useLocation>;

export const mediaTableColumns = (
  t: TFunction<'medias', undefined>,
  location: LocationType,
  isModal: boolean,
  folderId: number,
  mediaQueryId: string | null,
  toggleMediaDeletionModal: () => void,
  setSelectedMedia: React.Dispatch<React.SetStateAction<Media | undefined>>,
  setSelectedFolder: React.Dispatch<React.SetStateAction<Folder | undefined>>,
  setReplaceModalIsVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setSearch: React.Dispatch<React.SetStateAction<string | undefined>>,
  search: string | undefined,
  onSearch: (search: string) => void,
  folderTree: Folder[] | undefined,
  standard: boolean,
  selectionIsLoading: boolean,
  disableFolderActions: boolean,
  mediasRessource?: Media[],
  onSelect?: (input: Media) => void,
  onUnselect?: (input: Media) => void,
  onCopyMedia?: (media: Media, destinationFolderId: number) => void,
  onCopyFolder?: (folder: Folder, destinationFolderId: number) => void,
  onMoveFolder?: (folderId: number, newFolderId: number) => void,
  onMoveMediaFolder?: (mediaId: number, newFolderId: number) => void,
): ColumnsType<MixedMedia> => {
  const selectType = (record: MixedMedia): string => {
    const extension = isMedia(record)
      ? record.filename.split('.').pop()
      : undefined;

    switch (true) {
      case isMedia(record) &&
        (extension === 'png' || extension === 'jpeg' || extension === 'jpg'):
        return t('list.format.picture');
      case isMedia(record) && (extension === 'mp4' || extension === 'avi'):
        return t('list.format.video');
      case isMedia(record) && extension === 'wav':
        return t('list.format.audio');
      case isMedia(record) &&
        (extension === 'stl' || extension === 'obj' || extension === 'fbx'):
        return t('list.format.3d');
      case isMedia(record) && extension === 'pdf':
        return t('list.format.pdf');
      default:
        return t('list.format.folder');
    }
  };

  const isSelection = !!onSelect && !!onUnselect && !!mediasRessource;
  const mediaType = standard ? 'standard' : 'personal';

  const MediaCellLink = ({ record }: { record: MixedMedia }) => {
    const { id } = record;
    if (isMedia(record)) {
      // Media link management
      if (isModal) {
        const link = `${
          location.pathname
        }?modal=add_media&media_type=${mediaType}&folder_id=${
          folderId || 0
        }&media_id=${id}`;
        return (
          <Link to={link}>
            <MediaThumbnail
              fromModal
              media={record}
              mediaQueryId={mediaQueryId}
              type="preview"
              canUpdate={false}
            />
          </Link>
        );
      }

      const link = `?media_id=${id}`;
      return (
        <Link to={link}>
          <MediaThumbnail
            fromModal={false}
            media={record}
            mediaQueryId={mediaQueryId}
            type="preview"
            canUpdate={false}
          />
        </Link>
      );
    }

    // Folder link management
    if (isModal) {
      const link = `?modal=add_media&media_type=${mediaType}&folder_id=${
        id || 0
      }`;
      return (
        <Link to={link}>
          <MediaThumbnailPreview type="folder" />
        </Link>
      );
    }

    const link = `/medias/${mediaType}/${id || 0}`;
    return (
      <Link to={link}>
        <MediaThumbnailPreview type="folder" />
      </Link>
    );
  };

  const onConfirmDeletion = (record: MixedMedia) => {
    if (isMedia(record)) {
      setSelectedMedia(record);
      toggleMediaDeletionModal();
      return;
    }

    setSelectedFolder(record);
    toggleMediaDeletionModal();
  };

  const handleSearch = (value: string) => {
    setSearch(value);
    onSearch(value);
  };

  const FilterByNameInput = (
    <Input
      placeholder={t('list.name')}
      value={search}
      onChange={(e) => handleSearch(e.target.value)}
    />
  );

  return [
    {
      title: t('list.media'),
      key: 'thumbnail',
      render: (record: MixedMedia) => <MediaCellLink record={record} />,
    },
    {
      title: FilterByNameInput,
      key: 'label',
      ellipsis: {
        showTitle: true,
      },
      render: (record: MixedMedia) => <MediaLabelInput ressource={record} />,
    },
    {
      title: t('list.format.title'),
      key: 'format',
      render: (record: MixedMedia) => selectType(record),
      filters: [
        { text: t('list.format.picture'), value: t('list.format.picture') },
        { text: t('list.format.video'), value: t('list.format.video') },
        { text: t('list.format.audio'), value: t('list.format.audio') },
        { text: t('list.format.3d'), value: t('list.format.3d') },
        { text: t('list.format.pdf'), value: t('list.format.pdf') },
        { text: t('list.format.folder'), value: t('list.format.folder') },
      ],
      /*       filterMode: 'tree',
       */ onFilter: (value: Boolean | Key, record: MixedMedia) =>
        value === selectType(record),
    },
    {
      title: t('list.element'),
      key: 'childs',
      render: (record: MixedMedia) =>
        isMedia(record)
          ? record.replicas?.filter(
              (replica) => !replica.step || replica.step.isDraft,
            ).length
          : (record.medias ?? []).length + (record.childs ?? []).length,
    },
    {
      title: t('list.date'),
      key: 'updateDate',
      render: (record: MixedMedia) =>
        record.updateDate
          ? new Date(record.updateDate ?? '').toLocaleString()
          : null,
      sorter: (a: MixedMedia, b: MixedMedia) =>
        new Date(a.updateDate ?? '').getTime() -
        new Date(b.updateDate ?? '').getTime(),
    },
    {
      title: t('list.action'),
      key: 'action',
      render: (record: MixedMedia) => {
        if (isSelection) {
          if (!isMedia(record)) return null;

          const mediaTmp = record as Media;
          const addedMedias = mediasRessource.filter(
            (m) => m.originId === mediaTmp.id,
          );

          return (
            <Flex gap={16} items="center">
              <Button
                block
                icon={<MinusOutlined />}
                disabled={addedMedias.length <= 0}
                loading={selectionIsLoading}
                onClick={() => onUnselect(mediaTmp)}
              />
              <span>{addedMedias.length}</span>
              <Button
                block
                icon={<PlusOutlined />}
                loading={selectionIsLoading}
                onClick={() => onSelect(mediaTmp)}
              />
            </Flex>
          );
        }

        if (standard) return null;

        return (
          <Popover
            title={t('list.action')}
            content={
              <MediaActions
                record={record}
                folderTree={folderTree}
                mediasRessource={mediasRessource}
                standard={standard}
                selectionIsLoading={selectionIsLoading}
                disableFolderActions={disableFolderActions}
                onConfirmDeletion={onConfirmDeletion}
                onSelect={onSelect}
                onUnselect={onUnselect}
                onCopyMedia={onCopyMedia}
                onCopyFolder={onCopyFolder}
                onMoveFolder={onMoveFolder}
                onMoveMediaFolder={onMoveMediaFolder}
                onDisplayReplaceModal={() => {
                  if (isMedia(record)) setSelectedMedia(record);
                  setReplaceModalIsVisible(true);
                }}
              />
            }
          >
            <EllipsisOutlined style={{ cursor: 'pointer' }} />
          </Popover>
        );
      },
    },
  ];
};
