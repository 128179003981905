import React, { useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import ImportTasksTree from './import-tasks-tree';
import { GearMediaValidationModal } from './gear-medias-validation';

interface ImportTasksModalProps {
  scenarioRef: number;
  onClose: () => void;
  onImport: (ref: number[], keepGears: boolean, keepMedias: boolean) => void;
}

export function ImportTasksModal({
  scenarioRef,
  onClose,
  onImport,
}: ImportTasksModalProps) {
  const { t } = useTranslation('scenarios');
  const [importRefs, setImportRefs] = useState<number[]>();
  const [modalOpen, setModalOpen] = useState(false);

  function onOk() {
    setModalOpen(true);
  }

  function onsubmit(keepGears: boolean, keepMedias: boolean) {
    if (importRefs) {
      onImport(importRefs, keepGears, keepMedias);
    }
    setModalOpen(false);
    onClose();
  }

  const handleUpdate = (array: number[]) => {
    setImportRefs(array);
  };

  return (
    <Modal
      open
      centered
      // 70% arbitrary value to have the modal not taking all the screen but still be wide enough
      width="70%"
      title={t('import.task')}
      onOk={() => onOk()}
      okText={t('import.submit')}
      onCancel={onClose}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <ImportTasksTree
        onUpdateArray={handleUpdate}
        scenarioExpanded={scenarioRef}
      />
      {modalOpen ? (
        <GearMediaValidationModal
          onClose={() => setModalOpen(false)}
          onSubmit={onsubmit}
        />
      ) : null}
    </Modal>
  );
}
