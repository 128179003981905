import React from "react";

function Icon() {
  return (
    <svg viewBox="0 0 72 72">
      <path
        fill="#F25022"
        fillRule="evenodd"
        d="M0 34.219h34.219V0H0v34.219z"
        clipRule="evenodd"
        transform="translate(.005 .006)"
      />
      <path
        fill="#7FBA00"
        fillRule="evenodd"
        d="M0 34.219h34.219V0H0v34.219z"
        clipRule="evenodd"
        transform="translate(37.779)"
      />
      <path
        fill="#00A4EF"
        fillRule="evenodd"
        d="M0 34.219h34.219V0H0v34.219z"
        clipRule="evenodd"
        transform="translate(0 37.779)"
      />
      <path
        fill="#FFB900"
        fillRule="evenodd"
        d="M0 34.219h34.219V0H0v34.219z"
        clipRule="evenodd"
        transform="translate(37.779 37.779)"
      />
    </svg>
  );
}

export default Icon;
