/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from '@hello-pangea/dnd';
import { Badge, Button, List } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useHandleGears } from 'business/scenarios/hooks/gears';
import { useHandleSteps } from 'business/scenarios/hooks/step';
import { Gear } from 'business/type';
import { queryClient } from 'technical/react-query';
import { reorder } from 'technical/drag-and-drop/reorder';
import GearParentTooltip from './gear-parent-tooltip';
import classes from './step-gears.module.scss';

interface StepGearsProps {
  scenarioReference: number;
  stepReference: number;
  scenarioZoneId: number;
  selectedStepId: number;
  list: Gear[];
  canUpdate: boolean;
}

const LIMIT_TEXT = 34;

export function StepGears({
  scenarioReference,
  stepReference,
  scenarioZoneId,
  selectedStepId,
  list,
  canUpdate,
}: StepGearsProps) {
  const [gears, setGears] = useState<Gear[]>([]);
  const { updateStepWithoutInvalidation } = useHandleSteps({
    scenarioReference,
    stepId: selectedStepId,
    zone: scenarioZoneId,
  });
  const { keys, deleteGearFromStep } = useHandleGears(
    scenarioZoneId,
    selectedStepId,
    scenarioReference,
  );

  const handleDeletion = (gearId: number) => {
    deleteGearFromStep(gearId);
    queryClient.invalidateQueries(keys.gearsFromZone);
    queryClient.invalidateQueries(keys.allGears);
  };

  useEffect(() => {
    setGears(list);
  }, [list]);

  return gears.length > 0 ? (
    <DragDropContext
      onDragEnd={async (result: DropResult) => {
        const { source, destination } = result;
        if (!destination) {
          return;
        }

        const items = reorder(gears, source.index, destination.index);
        setGears(items);

        await updateStepWithoutInvalidation({
          gearsId: items.map((gear) => gear.id),
          gearsOrder: items.map((gear) => gear.id),
          stepRef: stepReference,
        });
      }}
    >
      <Droppable droppableId="gears" direction="vertical">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{ marginBottom: snapshot.isDraggingOver ? '40px' : '' }}
          >
            <List
              grid={{
                gutter: 4,
                column: 1,
              }}
              dataSource={gears}
              renderItem={(item, index) => {
                const gearLabel = item.label;
                const displayableGearLabel =
                  gearLabel && gearLabel.length > LIMIT_TEXT
                    ? `${gearLabel.slice(0, LIMIT_TEXT)}...`
                    : gearLabel;
                const parentGearLabel = item.parent ? item.parent.label : '';
                const displayableParentGearLabel =
                  parentGearLabel && parentGearLabel.length > LIMIT_TEXT
                    ? `${parentGearLabel.slice(0, LIMIT_TEXT)}...`
                    : parentGearLabel;

                return (
                  <List.Item style={{ marginBottom: '0px' }}>
                    <Draggable
                      key={item.id}
                      draggableId={`gears-${item.id.toString()}`}
                      index={index}
                      isDragDisabled={!canUpdate}
                    >
                      {(draggableProvided, draggableSnapshot) => {
                        const style = {
                          ...draggableProvided.draggableProps.style,
                          backgroundColor: draggableSnapshot.isDragging
                            ? '#f5f5f5'
                            : '',
                          borderRadius: draggableSnapshot.isDragging
                            ? '8px'
                            : '',
                        };
                        return (
                          <div
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                            className={classes.gearCard}
                            style={style}
                          >
                            <GearParentTooltip
                              item={item}
                              parentGearLabel={displayableParentGearLabel}
                            >
                              <div className={classes.gearCardElement}>
                                <div className={classes.gearCardLabelContainer}>
                                  <Badge
                                    color={
                                      item.position?.placed ? 'green' : 'red'
                                    }
                                    text={displayableGearLabel}
                                  />
                                </div>
                                {canUpdate ? (
                                  <Button
                                    size="small"
                                    type="link"
                                    className={classes.gearCardDeleteButton}
                                    icon={<CloseOutlined />}
                                    onClick={() => handleDeletion(item.id)}
                                  />
                                ) : null}
                              </div>
                            </GearParentTooltip>
                          </div>
                        );
                      }}
                    </Draggable>
                  </List.Item>
                );
              }}
            />
          </div>
        )}
      </Droppable>
    </DragDropContext>
  ) : null;
}
