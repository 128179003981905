import { Step, Task, Zone } from 'business/type';
import { api } from 'technical/api';

export async function getTaskStepTree(tenant: string, zoneId: number) {
  const { data: tree } = await api(tenant).get<Zone>(
    `/v1/zones/fulltrees/${zoneId}`,
  );
  return tree;
}

export async function importSteps(
  tenant: string,
  refSteps: number[],
  refTask: number,
) {
  await api(tenant).post<Pick<Step, 'id'>>(`/v1/steps/tasks/${refTask}`, {
    stepRefs: refSteps,
  });
}

export async function importTasks(
  tenant: string,
  refTasks: number[],
  refScenario: number,
  gears: boolean,
  medias: boolean,
) {
  await api(tenant).post<Pick<Task, 'id'>>(
    `/v1/tasks/scenarios/${refScenario}`,
    {
      taskRefs: refTasks,
      gears: gears,
      medias: medias,
    },
  );
}
