import React from "react";
import { useUserSession } from "technical/hooks/use-user-session";
import { Role, EDITORS, ADMINS, SUPER_ADMINS, REVIWERS, APPROVERS } from "./roles";

export function createAuthorizationPolicy(authorized: Array<Role>) {
  return function policy(role: Role): boolean {
    return authorized.includes(role);
  };
}

interface AuthorizedAccessOnlyProps {
  children: React.ReactNode;
  fallback?: React.ReactNode;
  authorized: Array<Role>;
}

export function AuthorizedAccessOnly({
  authorized,
  children,
  fallback,
}: AuthorizedAccessOnlyProps) {
  const { role } = useUserSession();

  const isAuthorized = createAuthorizationPolicy(authorized);

  if (isAuthorized(role)) {
    return <>{children}</>;
  }

  return fallback ? <>{fallback}</> : null;
}

/*
  Global authorization policies
*/
// this is an example, feel free to rename it, change it or remove it
export const hasEditingRights = createAuthorizationPolicy(EDITORS);

export const hasAdminRights = createAuthorizationPolicy(ADMINS);

export const hasSuperAdminRights = createAuthorizationPolicy(SUPER_ADMINS);

export const hasReviewRights = createAuthorizationPolicy(REVIWERS);

export const hasApproveRights = createAuthorizationPolicy(APPROVERS);