import { getAuditsByScenarioRef } from 'business/scenarios/services/scenario-detail';
import { useQuery } from 'react-query';
import { useUserSession } from 'technical/hooks/use-user-session';

export function useHandleAudits(reference: number) {
  const { tenant } = useUserSession();
  const auditsByScenarioRef = useQuery(
    ['scenarioAudit', tenant, { scenario: reference }],
    () => getAuditsByScenarioRef(tenant, reference),
  );

  /* eslint-disable no-nested-ternary */
  const audits = auditsByScenarioRef.isSuccess ? auditsByScenarioRef.data : [];

  return {
    data: audits,
    isLoading: auditsByScenarioRef.isLoading,
    isSuccess: auditsByScenarioRef.isSuccess,
  };
}
