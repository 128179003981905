import React, { useEffect, useState } from 'react';
import { Flex } from 'ui/flex';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import DigitalTree from 'business/digital-twin/routes/digital-tree/digital-tree';

interface ZoneAddModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (id: number) => void;
}

export function ZoneAddModal({ open, onClose, onSubmit }: ZoneAddModalProps) {
  const { t } = useTranslation('zones');
  const [zoneId, setZoneId] = useState<number>();

  const handleClose = () => {
    onClose();
  };

  const handleUpdate = () => {
    if (zoneId) onSubmit(zoneId);
  };

  return (
    <Modal
      open={open}
      centered
      // 70% arbitrary value to have the modal not taking all the screen but still be wide enough
      width="70%"
      title={t('modal.add.title')}
      onOk={handleUpdate}
      okText={t('modal.add.submit')}
      onCancel={handleClose}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <Flex direction="column" gap={32}>
        <DigitalTree selectable onUpdateSelected={setZoneId} />
      </Flex>
    </Modal>
  );
}
