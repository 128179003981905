import { Task } from 'business/type';

export function reorder(
  list: any[],
  sourceIndex: number,
  destinationIndex: number,
) {
  if (list.length === 0) {
    return [];
  }

  const [itemToMove] = list.splice(sourceIndex, 1);
  list.splice(destinationIndex, 0, itemToMove);
  return list;
}

export function reorderTasks(
  list: Task[],
  update: {
    start: number;
    end: number;
  },
): Task[] {
  const { start, end } = update;
  const direction = start > end ? 'up' : 'down';
  switch (direction) {
    case 'up': {
      return list
        .map((task) => {
          if (task.rank === start) {
            return { ...task, rank: end };
          }
          if (task.rank >= end && task.rank < start) {
            return { ...task, rank: task.rank + 1 };
          }

          return task;
        })
        .sort((a, b) => a.rank - b.rank);
    }
    case 'down': {
      return list
        .map((task) => {
          if (task.rank === start) {
            return { ...task, rank: end };
          }
          if (task.rank > start && task.rank <= end) {
            return { ...task, rank: task.rank - 1 };
          }

          return task;
        })
        .sort((a, b) => a.rank - b.rank);
    }
    default: {
      return list;
    }
  }
}

export function reorderSteps(
  list: Task[],
  update: {
    start: {
      task: number;
      index: number;
    };
    end: {
      task: number;
      index: number;
    };
  },
): Task[] {
  const { start, end } = update;
  const isSameTask = start.task === end.task;

  if (isSameTask) {
    const direction = start.index > end.index ? 'up' : 'down';
    const currentTask = list[start.task];

    switch (direction) {
      case 'up': {
        const steps = currentTask.steps
          ?.map((step) => {
            if (step.rank === start.index) {
              return { ...step, rank: end.index };
            }
            if (step.rank >= end.index && step.rank < start.index) {
              return { ...step, rank: step.rank + 1 };
            }

            return step;
          })
          .sort((a, b) => a.rank - b.rank);

        const copy = Array.from(list);
        copy[start.task] = {
          ...currentTask,
          steps,
        };
        return copy;
      }
      case 'down': {
        const steps = currentTask.steps
          ?.map((step) => {
            if (step.rank === start.index) {
              return { ...step, rank: end.index };
            }
            if (step.rank <= end.index && step.rank > start.index) {
              return { ...step, rank: step.rank - 1 };
            }

            return step;
          })
          .sort((a, b) => a.rank - b.rank);

        const copy = Array.from(list);
        copy[start.task] = {
          ...currentTask,
          steps,
        };
        return copy;
      }
      default: {
        return list;
      }
    }
  } else {
    const startTask = list[start.task];
    const endTask = list[end.task];

    if (!startTask.steps) {
      return list;
    }

    let stepsStartTask = Array.from(startTask.steps);
    let currentStep = stepsStartTask.find((step) => step.rank === start.index);

    if (!currentStep) {
      return list;
    }

    stepsStartTask.splice(stepsStartTask.indexOf(currentStep), 1);
    stepsStartTask = stepsStartTask.map((step) => ({
      ...step,
      rank: step.rank >= start.index ? step.rank - 1 : step.rank,
    }));

    currentStep = { ...currentStep, rank: end.index };
    const stepsEndTask = (endTask.steps ?? []).map((step) => ({
      ...step,
      rank: step.rank >= end.index ? step.rank + 1 : step.rank,
    }));
    stepsEndTask.push(currentStep);

    const copy = Array.from(list);
    copy[start.task] = {
      ...startTask,
      steps: stepsStartTask.sort((a, b) => a.rank - b.rank),
    };

    copy[end.task] = {
      ...endTask,
      steps: stepsEndTask.sort((a, b) => a.rank - b.rank),
    };

    return copy;
  }
}
