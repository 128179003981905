import React from "react";
import { ColumnsType } from "antd/lib/table";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Alert } from "business/type";
import GenericTable from "ui/table";
import {
  CheckCircleTwoTone,
  ClockCircleTwoTone,
  ExclamationCircleTwoTone,
} from "@ant-design/icons";
import { getUserList } from "business/users/services/users-list";
import { useQuery } from "react-query";
import { useUserSession } from "technical/hooks/use-user-session";

interface AlertListProps {
  alerts?: Alert[];
  loading: boolean;
}

export default function AlertList({ alerts, loading }: AlertListProps) {
  const { t } = useTranslation("alerts");
  const { tenant } = useUserSession();

  const userListQuery = useQuery(["users", tenant], () => getUserList(tenant));

  const columns: ColumnsType<Alert> = [
    {
      title: t("list.state"),
      render: (record: Alert) => {
        if (record.state === "doing") {
          return <ClockCircleTwoTone twoToneColor="gold" />;
        }
        if (record.state === "done") {
          return <CheckCircleTwoTone twoToneColor="#52c41a" />;
        }
        return <ExclamationCircleTwoTone twoToneColor="red" />;
      },
      width: 100,
    },
    {
      title: t("list.name"),
      dataIndex: "label",
      render: (text: string, record: Alert) => (
        <Link to={`/alerts/${record.id}`}>{text}</Link>
      ),
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: t("list.user"),
      render: (record: Alert) => {
        const alertUser = userListQuery.data?.find(
          (user) => user.id === record.userId
        );
        const display = alertUser ? (
          <p>
            {alertUser.firstname} {alertUser.surname}
          </p>
        ) : null;
        return display;
      },
    },
    {
      title: t("list.date"),
      render: (record: Alert) =>
        new Date(record.createDate ?? "").toLocaleString(),
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        new Date(a.createDate ?? "").getTime() -
        new Date(b.createDate ?? "").getTime(),
    },
    {
      title: t("list.zone"),
      dataIndex: ["zone", "label"],
    },
    {
      title: t("list.gear"),
      dataIndex: ["gear", "label"],
    },
    {
      title: t("list.media"),
      render: (record: Alert) => record.mediasId.length,
    },
  ];

  return (
    <GenericTable
      loading={loading}
      dataSource={alerts}
      columns={columns}
      pageSize={20}
    />
  );
}
