import { Form, Switch } from 'antd';
import {
  getExpertCaptureSubscription,
  getGmaoSubscription,
  getReportSubscription,
  getScanSubscription,
  getVersionsSubscription,
  updateSubscription,
} from 'business/monitoring/services/subscriptions';
import { getMailSubscription } from 'business/users/services/user-detail';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useUserSession } from 'technical/hooks/use-user-session';
import { queryClient } from 'technical/react-query';

export default function ToggleBar() {
  const { t } = useTranslation('monitoring');
  const { tenant } = useUserSession();

  const updateSubscriptionMutation = useMutation(
    async (input: { subscribed: boolean; feature: string }) =>
      updateSubscription(tenant, input),
    {
      onSuccess: (res, input) => {
        queryClient.invalidateQueries([input.feature, tenant]);
      },
    },
  );

  const mailSubscriptionQuery = useQuery(
    ['mailInterventions', tenant],
    () => getMailSubscription(tenant),
    {
      staleTime: 24 * 60 * 60 * 1000,
    },
  );

  const reportSubscriptionQuery = useQuery(
    ['reportInterventions', tenant],
    () => getReportSubscription(tenant),
    {
      staleTime: 24 * 60 * 60 * 1000,
    },
  );

  const versionsSubscriptionQuery = useQuery(
    ['versions', tenant],
    () => getVersionsSubscription(tenant),
    {
      staleTime: 24 * 60 * 60 * 1000,
    },
  );

  const gmaoSubscriptionQuery = useQuery(
    ['gmao', tenant],
    () => getGmaoSubscription(tenant),
    {
      staleTime: 24 * 60 * 60 * 1000,
    },
  );

  const scanSubscriptionQuery = useQuery(
    ['scans', tenant],
    () => getScanSubscription(tenant),
    {
      staleTime: 24 * 60 * 60 * 1000,
    },
  );

  const expertCaptureSubscriptionQuery = useQuery(
    ['expertCapture', tenant],
    () => getExpertCaptureSubscription(tenant),
    {
      staleTime: 24 * 60 * 60 * 1000,
    },
  );

  const handleMailChange = (checked: boolean) => {
    updateSubscriptionMutation.mutate({
      subscribed: checked,
      feature: 'mailInterventions',
    });
  };

  const handleReportChange = (checked: boolean) => {
    updateSubscriptionMutation.mutate({
      subscribed: checked,
      feature: 'reportInterventions',
    });
  };

  const handleVersionsChange = (checked: boolean) => {
    updateSubscriptionMutation.mutate({
      subscribed: checked,
      feature: 'versions',
    });
  };

  const handleGmaoChange = (checked: boolean) => {
    updateSubscriptionMutation.mutate({
      subscribed: checked,
      feature: 'gmao',
    });
  };

  const handleScanChange = (checked: boolean) => {
    updateSubscriptionMutation.mutate({
      subscribed: checked,
      feature: 'scans',
    });
  };

  const handleExpertCaptureChange = (checked: boolean) => {
    updateSubscriptionMutation.mutate({
      subscribed: checked,
      feature: 'expertCapture',
    });
  };

  return (
    <Form layout="horizontal">
      <Form.Item name="mail-interventions" label={t('switch.mail')}>
        <Switch
          checked={mailSubscriptionQuery.data?.subscribed}
          onChange={handleMailChange}
        />
      </Form.Item>
      <Form.Item name="rapport-interventions" label={t('switch.rapport')}>
        <Switch
          checked={reportSubscriptionQuery.data?.subscribed}
          onChange={handleReportChange}
        />
      </Form.Item>
      <Form.Item name="versions" label={t('switch.versions')}>
        <Switch
          checked={versionsSubscriptionQuery.data?.subscribed}
          onChange={handleVersionsChange}
        />
      </Form.Item>
      <Form.Item name="gmao" label={t('switch.gmao')}>
        <Switch
          checked={gmaoSubscriptionQuery.data?.subscribed}
          onChange={handleGmaoChange}
        />
      </Form.Item>
      <Form.Item name="scan" label={t('switch.scan')}>
        <Switch
          checked={scanSubscriptionQuery.data?.subscribed}
          onChange={handleScanChange}
        />
      </Form.Item>
      <Form.Item name="expertCapture" label={t('switch.expertCapture')}>
        <Switch
          checked={expertCaptureSubscriptionQuery.data?.subscribed}
          onChange={handleExpertCaptureChange}
        />
      </Form.Item>
    </Form>
  );
}
