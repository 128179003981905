import { Intervention } from 'business/type';
import i18next from 'i18next';
import { api } from 'technical/api';

export async function getInterventionListFromZone(
  tenant: string,
  zoneId: number,
  startDate: string,
  endDate?: string,
) {
  const interventionsList = api(tenant)
    .get<Intervention[]>(`/v1/interventions/zones/${zoneId}`, {
      params: {
        fromDate: startDate,
        toDate: endDate,
        full: true,
      },
    })
    .then((response) => response.data);

  return interventionsList;
}

export async function exportInterventionsCSVByIds(
  tenant: string,
  ids: string[],
) {
  const lang = i18next.language;

  const file = await api(tenant).get(`/v1/interventions/exports/many`, {
    params: { ids: ids.toString(), lang: lang },
    responseType: 'blob',
  });
  return file.data;
}
