import { Subscription, User } from 'business/type';
import { api } from 'technical/api';
import { UserInputUpdate } from './type';

export async function getUser(tenant: string, id: string) {
  const { data: user } = await api(tenant).get<User>(`/v1/users/${id}`);
  return user;
}

export async function modifyUser(tenant: string, input: UserInputUpdate) {
  const { data: user } = await api(tenant).patch(
    `/v1/users/${input.id}`,
    input,
  );

  await Promise.all(
    input.zonesIdRemoved.map((zoneId) =>
      api(tenant).delete(`/v1/zones/users/${zoneId}/${input.id}`),
    ),
  );

  return user;
}

export async function getMailSubscription(tenant: string) {
  const { data: subscription } = await api(tenant).get<Subscription>(
    `/v1/subscriptions/mailInterventions`,
  );
  return subscription;
}
