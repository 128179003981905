// eslint-disable-next-line max-classes-per-file
import { CSSProperties } from 'react';
import { Node, XYPosition } from 'reactflow';

export type CustomTaskType = 'Task';

export type CustomTaskObject = { id: string; type: CustomTaskType };

export type CustomTaskData = {
  label: string;
  tag: string;
  parent: string;
  next: string;
  children: string[];
  type: CustomTaskType;
  startPosition: XYPosition;
  [key: string]: any; // necessary, because we don't know what we want to pass in data object right now, maybe should be updated in the future
};

export class CustomTask {
  id: string;

  style?: CSSProperties;

  position: XYPosition;

  data: CustomTaskData;

  type: string;

  hidden: boolean;

  constructor({
    id,
    position,
    data,
    style,
    hidden = false,
  }: Pick<
    Node<Partial<CustomTaskData>>,
    'id' | 'style' | 'position' | 'data' | 'hidden'
  >) {
    this.id = id;
    this.position = position;
    this.data = {
      label: 'Task',
      tag: '1.0',
      parent: '',
      next: '',
      children: [],
      type: 'Task',
      startPosition: { x: 0, y: 0 },
      ...data,
    };
    this.type = 'task';
    this.hidden = hidden;

    if (style) {
      this.style = style;
    }
  }
}
