import React, { useEffect, useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Input, Select, SelectProps, Tag, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Scenario, Tag as CustomTag, Zone } from 'business/type';
import { useUserSession } from 'technical/hooks/use-user-session';
import GenericTable from 'ui/table';
import deleteScenario from 'business/scenarios/services/scenario-deletion';
import { hasEditingRights } from 'technical/auth/authorization';
import getZoneTree from 'business/zones/services/zone-tree';
import ScenarioDeletionModal from './scenario-deletion';
import { useHandleTags } from 'business/tags/hooks/tags';

interface ScenariosListProps {
  scenarios?: Scenario[];
  loading: boolean;
  onSelect: (keys: React.Key[]) => void;
}

export default function ScenariosList({
  scenarios,
  loading,
  onSelect,
}: ScenariosListProps) {
  const { t } = useTranslation('scenarios');
  const { tenant, role, zone } = useUserSession();
  const [isModalVisible, setModalIsVisible] = useState(false);
  const [scenarioRef, setScenarioRef] = useState<number>();
  const [search, setSearch] = useState<string>();
  const [searchTags, setSearchTags] = useState<string[]>([]);
  const [dataSource, setDataSource] = useState<Scenario[]>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const { Text } = Typography;

  const toggleModal = () => setModalIsVisible((current) => !current);

  const onConfirm = (record: Scenario) => {
    setScenarioRef(record.reference);
    toggleModal();
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    onSelect(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const queryClient = useQueryClient();
  const deleteScenarioMutation = useMutation(
    async () => (scenarioRef ? deleteScenario(tenant, scenarioRef) : undefined),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('scenarios');
        queryClient.invalidateQueries('user');
        queryClient.invalidateQueries([
          'stepTaskTree',
          tenant,
          { zoneId: zone },
        ]);
        toggleModal();
      },
    },
  );

  const { data: tags } = useHandleTags({
    tenant,
    queryKey: ['tags', tenant, 'scenario'],
    type: 'scenario',
  });
  useEffect(() => {
    setSearch(undefined);
    setDataSource(undefined);
  }, [zone]);

  const treeQuery = useQuery(['tree', tenant, { zoneId: zone }], () =>
    getZoneTree(tenant, zone),
  );

  const FilterByNameInput = (
    <Input
      placeholder={t('list.name')}
      value={search}
      onChange={(e) => {
        const currValue = e.target.value;
        setSearch(currValue);
        const filteredData = scenarios?.filter(
          (entry) =>
            entry.label.toLowerCase().includes(currValue.toLowerCase()) &&
            searchTags.every((label) =>
              entry.tags.some((tag) => tag.label === label),
            ),
        );
        setDataSource(filteredData);
      }}
      onClick={(e) => e.stopPropagation()}
    />
  );

  const optionsTags: SelectProps['options'] = tags.map((tag) => {
    return { value: tag.label, label: tag.label };
  });

  const onFilterTags = (value: string[]) => {
    const filteredData = scenarios?.filter(
      (entry) =>
        value.every((label) => entry.tags.some((tag) => tag.label === label)) &&
        (search
          ? entry.label.toLowerCase().includes(search.toLowerCase())
          : true),
    );
    setSearchTags(value);
    setDataSource(filteredData);
  };

  const FilterByTagsSelect = (
    <Select
      allowClear
      placeholder={t('list.tags')}
      mode="multiple"
      options={optionsTags}
      onChange={onFilterTags}
      style={{ width: '100%' }}
    />
  );

  const zoneTreeFilter = (subZone: Zone) => {
    let children = {};
    if (subZone.childs) {
      children = {
        children: subZone.childs.map((child) => zoneTreeFilter(child)),
      };
    }
    return { text: subZone.label, value: subZone.id, ...children };
  };

  const zoneFilter = treeQuery.data?.childs?.map((child) =>
    zoneTreeFilter(child),
  );

  const stateFilter = [
    {
      text: t('list.states.deleted'),
      value: 'deleted',
    },
    {
      text: t('list.states.review'),
      value: 'review',
    },
    {
      text: t('list.states.approve'),
      value: 'approve',
    },
    {
      text: t('list.states.draft'),
      value: 'draft',
    },
  ];

  function stateFiltering(value: boolean | React.Key, record: Scenario) {
    switch (value) {
      case 'deleted':
        return !!record.deleted;
      case 'review':
        return record.pendingReview;
      case 'approve':
        return record.pendingApprove;
      case 'draft':
        return (
          !(record.pendingApprove || record.pendingReview) && !record.deleted
        );
      default:
        return false;
    }
  }

  const columns: ColumnsType<Scenario> = [
    {
      title: <>{FilterByNameInput}</>,
      dataIndex: 'label',
      sorter: (a, b) => a.label.localeCompare(b.label),
      render: (text: string, record: Scenario) => (
        <Link to={`/scenarios/${record.reference}`}>{text}</Link>
      ),
      ellipsis: true,
    },
    {
      title: t('list.zone'),
      dataIndex: ['zones'],
      render: (zones: Zone[]) => (
        <Text
          ellipsis={{
            tooltip: (
              <>
                {zones.map((childZone) => (
                  <Tag color="geekblue" key={childZone.label}>
                    {childZone.label}
                  </Tag>
                ))}
              </>
            ),
          }}
        >
          {zones.map((childZone) => (
            <Tag color="geekblue" key={childZone.label}>
              {childZone.label}
            </Tag>
          ))}
        </Text>
      ),
      ellipsis: true,
      filters: zoneFilter,
      filterMode: 'tree',
      /*       filterSearch: true,
       */ onFilter: (value, record) => record.zoneId === value,
    },
    {
      title: <>{FilterByTagsSelect}</>,
      dataIndex: ['tags'],
      render: (scenarioTags: CustomTag[]) => (
        <Text
          ellipsis={{
            tooltip: (
              <>
                {scenarioTags.map((tag) => (
                  <Tag color="cyan" key={tag.label}>
                    {tag.label}
                  </Tag>
                ))}
              </>
            ),
          }}
        >
          {scenarioTags.map((tag) => (
            <Tag color="cyan" key={tag.label}>
              {tag.label}
            </Tag>
          ))}
        </Text>
      ),
      ellipsis: true,
    },
    {
      title: t('list.state'),
      key: 'state',
      render: (record: Scenario) => {
        if (record.deleted) return t('list.states.deleted');
        if (record.pendingReview) return t('list.states.review');
        if (record.pendingApprove) return t('list.states.approve');
        return t('list.states.draft');
      },
      filters: stateFilter,
      defaultFilteredValue: ['draft'],
      onFilter: stateFiltering,
    },
  ];

  // feel free to change this, this is a usage example
  if (hasEditingRights(role)) {
    columns.push({
      title: t('list.action'),
      key: 'delete',
      width: 100,
      render: (record: Scenario) =>
        !record.deleted ? (
          <Button
            type="primary"
            onClick={() => onConfirm(record)}
            icon={<DeleteOutlined />}
            danger
          />
        ) : null,
    });
  }

  return (
    <>
      <GenericTable
        loading={loading}
        rowSelection={rowSelection}
        rowKey="reference"
        dataSource={dataSource ?? scenarios}
        columns={columns}
        pageSize={20}
      />
      <ScenarioDeletionModal
        isVisible={isModalVisible}
        onClose={toggleModal}
        onSubmit={deleteScenarioMutation.mutate}
      />
    </>
  );
}
