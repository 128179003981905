import {
  Answer,
  AnswerInputUpdate,
  StepAnswer,
} from 'business/scenarios/services/type';
import { notification } from 'antd';
import { updateAnswer } from 'business/scenarios/services/scenario-detail';
import {
  createAnswer,
  deleteAnswer,
} from 'business/scenarios/services/step-edition';
import { useQuery, useMutation, QueryKey } from 'react-query';
import { queryClient } from 'technical/react-query';
import { getStepAnswers } from 'business/scenarios/services/step-informations';
import { useUserSession } from 'technical/hooks/use-user-session';

export function useHandleAnswers(
  selectedStep: number,
  selectedStepId: number,
  invalidationKey: QueryKey,
) {
  const { tenant } = useUserSession();
  const rootKey = ['answers', tenant, selectedStepId];

  const answersQuery = useQuery(
    rootKey,
    () => getStepAnswers(tenant, selectedStepId),
    { enabled: !!selectedStep },
  );

  const invalidateQueries = () => {
    queryClient.invalidateQueries(rootKey);
    queryClient.invalidateQueries(invalidationKey);
    queryClient.invalidateQueries(['step', tenant, { stepId: selectedStepId }]);
  };

  const invalidatePartialQueries = (stepId: number) => {
    queryClient.invalidateQueries(invalidationKey);
    queryClient.invalidateQueries(['answers', tenant, stepId]);
    queryClient.invalidateQueries(['step', tenant, { stepId }]);
  };

  const createAnswerMutation = useMutation(
    async () => createAnswer(tenant, selectedStep),
    {
      // Optimistic update
      onMutate: async () => {
        queryClient.setQueryData(rootKey, (old: Answer[] = []) => [
          ...old,
          {
            id: -1,
            reference: -1,
            label: '',
            stepId: selectedStepId,
            stepRef: selectedStep,
            isDraft: true,
            isLast: true,
            deleted: false,
          },
        ]);
      },
      onSuccess: async () => invalidateQueries(),
      onError: (err: Error) => notification.error({ message: err.message }),
    },
  );

  const createFullAnswerMutation = useMutation(
    async (data: { nextStepRef: number; stepRef: number; stepId: number }) =>
      createAnswer(tenant, data.stepRef, undefined, data.nextStepRef),
    {
      // Optimistic update
      onMutate: async (data: {
        nextStepRef: number;
        stepRef: number;
        stepId: number;
      }) => {
        queryClient.setQueryData(rootKey, (old: Answer[] = []) => [
          ...old,
          {
            id: -1,
            reference: -1,
            label: 'next',
            stepId: selectedStepId,
            stepRef: data.stepRef,
            nextStepRef: data.nextStepRef,
            isDraft: true,
            isLast: false,
            deleted: false,
          },
        ]);
      },
      onSuccess: async (data, variables) => {
        invalidatePartialQueries(variables.stepId);
      },
      onError: (err: Error) => notification.error({ message: err.message }),
    },
  );

  const updateAnswerMutation = useMutation(
    async (answer: AnswerInputUpdate) => updateAnswer(tenant, answer),
    {
      onSuccess: async () => invalidateQueries(),
      onError: (err: Error) => notification.error({ message: err.message }),
    },
  );

  const deleteAnswerMutation = useMutation(
    async (answerRef: number) => deleteAnswer(tenant, answerRef),
    {
      // Optimistic update
      onMutate: async (answerRef: number) => {
        const filtered = (answersQuery.data || []).filter(
          (answer) => answer.reference !== answerRef,
        );
        queryClient.setQueryData(rootKey, () => filtered);
      },
      onSuccess: async () => invalidateQueries(),
      onError: (err: Error) => notification.error({ message: err.message }),
    },
  );

  return {
    data: (answersQuery.data || [])
      .sort((a, b) => {
        if (a.reference === -1) return 1;
        if (b.reference === -1) return -1;
        return a.reference - b.reference;
      })
      .map<StepAnswer>((answer) => ({
        reference: answer.reference,
        label: answer.label,
        nextStepRef: answer.nextStepRef,
      })),
    isLoading:
      answersQuery.isLoading ||
      createAnswerMutation.isLoading ||
      updateAnswerMutation.isLoading ||
      deleteAnswerMutation.isLoading,
    createAnswer: createAnswerMutation.mutate,
    updateAnswer: updateAnswerMutation.mutate,
    deleteAnswer: deleteAnswerMutation.mutate,
    createFullAnswer: createFullAnswerMutation.mutate,
  };
}
