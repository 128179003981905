import { useCallback } from 'react';
import { NodeProps, useReactFlow } from 'reactflow';

export const parseTag = (tag: string) => tag.split('.');
export const createTag = (tags: string[] | number[]) => tags.join('.');

export default function useNodeClick(id: NodeProps['id']) {
  const { setNodes, getNode } = useReactFlow();

  const setDraggable = useCallback(
    (isDraggable: boolean) => {
      const currentNode = getNode(id);

      if (!currentNode) return;

      setNodes((nodes) =>
        nodes.map((node) => {
          if (node.id === id) {
            const parentNode = getNode(node?.parentNode || '');
            return {
              ...node,
              draggable: isDraggable,
              data: {
                ...node.data,
                parentNode: node.parentNode,
                startPosition: {
                  x: node.position.x,
                  y: node.position.y,
                },
              },
              parentNode: undefined,
              position: {
                x: node.position.x + (parentNode?.positionAbsolute?.x ?? 0),
                y: node.position.y + (parentNode?.positionAbsolute?.y ?? 0),
              },
            };
          }

          return node;
        }),
      );
    },
    [getNode, id, setNodes],
  );

  return { setDraggable };
}
