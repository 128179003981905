import { notification } from 'antd';
import {
  deleteFolder,
  deleteMedia,
  getFoldersTopZone,
  getMediasTopZone,
  getFolderContent,
  getFoldersTree,
  createFolder,
  updateMedia,
  getFoldersStandard,
  copyMedia,
  copyFolder,
  updateFolder,
  getMediasChild,
} from 'business/medias/services/medias';
import {
  CreateFolderInput,
  UpdateFolderInput,
  UpdateMediaInput,
} from 'business/medias/services/types';
import { uploadMedia } from 'business/medias/services/upload-media';
import { Media, Zone, Scenario, Gear, Step } from 'business/type';
import { useMutation, useQuery } from 'react-query';
import { useUserSession } from 'technical/hooks/use-user-session';
import { queryClient } from 'technical/react-query';
import { getMediaFromStep } from '../services/scenario-detail';

export function useHandleMedias({
  selectedMediaId,
  selectedZoneId,
  selectedStepId,
  scenarioId,
  scenarioReference,
  gearId,
  personalFolderId,
}: {
  selectedMediaId?: number;
  selectedZoneId?: number;
  selectedStepId?: number;
  scenarioId?: number;
  scenarioReference?: number;
  gearId?: number;
  personalFolderId?: number;
}) {
  const { tenant, zone } = useUserSession();
  const personalMediasKey = ['medias', tenant, zone];
  const personalFoldersKey = ['folders', tenant, zone];
  const personalFolderContentKey = [
    'folder_content',
    personalFolderId,
    tenant,
    zone,
  ];
  const personalFoldersTreeKey = ['folders_tree', tenant];

  const standardFoldersKey = ['folders_standard', tenant];

  const stepMediasQuery = useQuery(
    ['medias', tenant, { step: selectedStepId }],
    () =>
      selectedStepId ? getMediaFromStep(tenant, selectedStepId) : undefined,
  );

  // Personal medias
  const mediasTopZoneQuery = useQuery(
    personalMediasKey,
    () => getMediasTopZone(tenant, zone),
    { staleTime: 10 * 60 * 1000 },
  );

  // Personal folders
  const foldersTopZoneQuery = useQuery(
    personalFoldersKey,
    () => getFoldersTopZone(tenant, zone),
    { staleTime: 10 * 60 * 1000 },
  );

  // Personal childrens (subfolders and medias)
  const foldersChildsQuery = useQuery(
    personalFolderContentKey,
    () =>
      personalFolderId ? getFolderContent(tenant, personalFolderId) : undefined,
    { staleTime: 10 * 60 * 1000, enabled: !!personalFolderId },
  );

  // Personal folders tree
  const foldersTreeQuery = useQuery(
    personalFoldersTreeKey,
    () => getFoldersTree(zone, tenant),
    { staleTime: 10 * 60 * 1000 },
  );

  // Standard medias
  const foldersStandardQuery = useQuery(
    standardFoldersKey,
    () => getFoldersStandard(tenant),
    { staleTime: 60 * 60 * 1000 },
  );

  const mediasChildQuery = useQuery(
    ['childs', tenant, { mediaId: selectedMediaId }],
    () =>
      selectedMediaId ? getMediasChild(tenant, selectedMediaId) : undefined,
    { enabled: !!selectedMediaId },
  );

  const uploadPersonalMediaMutation = useMutation(
    async (options: any) => {
      const currentZone = { id: zone } as Zone;
      return uploadMedia({
        tenant,
        file: options.file,
        label: options.file.name,
        ressource: currentZone,
        onProgress: options.onProgress,
        isStep: false,
        folderId: options.folderId,
      });
    },
    {
      onSuccess: (res: any, options) => {
        options.onSuccess(res);
        const previousData =
          queryClient.getQueryData<Media[]>(personalMediasKey) || [];
        queryClient.setQueryData(personalMediasKey, [
          ...previousData,
          {
            id: res.data.id,
            label: options.file.name,
            filename: options.file.name,
            url: res.data.url,
            replicasId: [],
            originId: res.data.originId,
          },
        ]);

        queryClient.invalidateQueries(personalMediasKey);
        queryClient.invalidateQueries(personalFoldersKey);
        if (options.folderId) {
          queryClient.invalidateQueries([
            'folder_content',
            options.folderId,
            tenant,
            zone,
          ]);
          return;
        }
        queryClient.invalidateQueries(personalFolderContentKey);
      },
      onError: (err: Error, options) => {
        options.onError(err);
        notification.error({ message: err.message });
      },
    },
  );

  const copyPersonalMediaMutation = useMutation(
    async ({
      mediaId,
      destinationFolderId,
    }: {
      mediaId: number;
      destinationFolderId?: number;
    }) => copyMedia(tenant, mediaId, destinationFolderId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(personalMediasKey);
        queryClient.invalidateQueries(personalFoldersKey);
        queryClient.invalidateQueries(personalFolderContentKey);
      },
    },
  );

  const searchPersonalMediaMutation = useMutation(
    async (search: string) => getMediasTopZone(tenant, zone, search),
    {
      onSuccess: (result) => {
        queryClient.setQueryData(personalMediasKey, result);
      },
    },
  );

  const updatePersonalMediaMutation = useMutation(
    async (input: UpdateMediaInput) => updateMedia(tenant, input),
    {
      onSuccess: (_, input) => {
        queryClient.invalidateQueries(personalMediasKey);
        queryClient.invalidateQueries(personalFoldersKey);
        queryClient.invalidateQueries(personalFolderContentKey);

        if (input.folderId) {
          queryClient.invalidateQueries([
            'folder_content',
            input.folderId,
            tenant,
            zone,
          ]);
        }
      },
    },
  );

  const deletePersonalMediaMutation = useMutation(
    async ({ mediaId }: { mediaId: number; folderId?: number }) =>
      deleteMedia(tenant, mediaId),
    {
      onSuccess: (_, input) => {
        queryClient.invalidateQueries(personalMediasKey);
        queryClient.invalidateQueries(personalFoldersKey);
        if (input.folderId) {
          queryClient.invalidateQueries([
            'folder_content',
            input.folderId,
            tenant,
            zone,
          ]);
        }
      },
    },
  );

  const deletePersonalMediaMutationAndRefreshAll = useMutation(
    async (media: Media) => deleteMedia(tenant, media.id),
    {
      onSuccess: (_, media) => {
        queryClient.invalidateQueries([
          'medias',
          tenant,
          { scenario: media.scenarioRef },
        ]);
        queryClient.invalidateQueries([
          'medias',
          tenant,
          { step: media.stepRef },
        ]);
        queryClient.invalidateQueries([
          'medias',
          tenant,
          { zoneId: media.zoneId },
        ]);
        queryClient.invalidateQueries([
          'medias',
          tenant,
          { gearId: media.gearId },
        ]);
        queryClient.invalidateQueries([
          'childs',
          tenant,
          { mediaId: media.originId },
        ]);
      },
      onError: (err: Error) => notification.error({ message: err.message }),
    },
  );

  const copyPersonalFolderMutation = useMutation(
    async ({
      folderId,
      destinationFolderId,
    }: {
      folderId: number;
      destinationFolderId?: number;
    }) => copyFolder(tenant, folderId, destinationFolderId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(personalMediasKey);
        queryClient.invalidateQueries(personalFoldersKey);
        queryClient.invalidateQueries(personalFolderContentKey);
      },
    },
  );

  const uploadScenarioMediaMutation = useMutation(
    async (options: any) => {
      if (!scenarioReference) {
        return undefined;
      }

      const scenario = { reference: scenarioReference } as Scenario;
      return uploadMedia({
        tenant,
        file: options.file,
        label: options.file.name,
        ressource: scenario,
        onProgress: options.onProgress,
        isStep: false,
        folderId: options.folderId,
      });
    },
    {
      onSuccess: (res, options) => {
        options.onSuccess(res);
        queryClient.invalidateQueries([
          'scenario',
          tenant,
          { scenarioReference },
        ]);
        queryClient.invalidateQueries(['medias', tenant, { scenarioId }]);
        queryClient.invalidateQueries(personalMediasKey);
        queryClient.invalidateQueries(personalFoldersKey);
        if (options.folderId) {
          queryClient.invalidateQueries([
            'folder_content',
            options.folderId,
            tenant,
            zone,
          ]);
          return;
        }
        queryClient.invalidateQueries(personalFolderContentKey);
      },
      onError: (err: Error, options) => {
        options.onError(err);
        notification.error({ message: err.message });
      },
    },
  );

  const deleteScenarioMediaMutation = useMutation(
    async (media: Media) => deleteMedia(tenant, media.id),
    {
      onSuccess: (_, media) => {
        queryClient.invalidateQueries(['medias', tenant, { scenarioId }]);
        queryClient.invalidateQueries([
          'childs',
          tenant,
          { mediaId: media.originId },
        ]);
      },
      onError: (err: Error) => notification.error({ message: err.message }),
    },
  );

  const uploadGearMediaMutation = useMutation(
    async (options: any) => {
      const gear = { id: gearId, zoneId: gearId } as Gear;
      return uploadMedia({
        tenant,
        file: options.file,
        label: options.file.name,
        ressource: gear,
        onProgress: options.onProgress,
        isStep: false,
        folderId: options.folderId,
      });
    },
    {
      onSuccess: (res, options) => {
        options.onSuccess(res);
        const previousData =
          queryClient.getQueryData<Media[]>(personalMediasKey) || [];
        queryClient.setQueryData(personalMediasKey, [
          ...previousData,
          {
            id: res.data.id,
            label: options.file.name,
            filename: options.file.name,
            url: res.data.url,
            replicasId: [],
            originId: res.data.originId,
          },
        ]);

        queryClient.invalidateQueries(['medias', tenant, { gearId }]);
        queryClient.invalidateQueries(personalMediasKey);
        queryClient.invalidateQueries(personalFoldersKey);
        if (options.folderId) {
          queryClient.invalidateQueries([
            'folder_content',
            options.folderId,
            tenant,
            zone,
          ]);
          return;
        }
        queryClient.invalidateQueries(personalFolderContentKey);
      },
      onError: (err: Error, options) => {
        options.onError(err);
        notification.error({ message: err.message });
      },
    },
  );

  const deleteGearMediaMutation = useMutation(
    async (mediaInput: Media) => deleteMedia(tenant, mediaInput.id),
    {
      onSuccess: (_, mediaInput) => {
        queryClient.invalidateQueries(['medias', tenant, { gearId }]);
        queryClient.invalidateQueries([
          'childs',
          tenant,
          { mediaId: mediaInput.originId },
        ]);
      },
      onError: (err: Error) => notification.error({ message: err.message }),
    },
  );

  const uploadStepMediaMutation = useMutation(
    async (options: any) => {
      const step = { id: selectedStepId } as Step;

      return uploadMedia({
        tenant,
        file: options.file,
        label: options.file.name,
        ressource: step,
        onProgress: options.onProgress,
        isStep: true,
        folderId: options.folderId,
      });
    },
    {
      onSuccess: (res: any, options) => {
        options.onSuccess(res);
        const previousData =
          queryClient.getQueryData<Media[]>(personalMediasKey) || [];
        queryClient.setQueryData(personalMediasKey, [
          ...previousData,
          {
            id: res.data.id,
            label: options.file.name,
            filename: options.file.name,
            url: res.data.url,
            replicasId: [],
            originId: res.data.originId,
          },
        ]);

        queryClient.invalidateQueries([
          'medias',
          tenant,
          { step: selectedStepId },
        ]);
        queryClient.invalidateQueries(personalMediasKey);
        queryClient.invalidateQueries(personalFoldersKey);
        if (options.folderId) {
          queryClient.invalidateQueries([
            'folder_content',
            options.folderId,
            tenant,
            zone,
          ]);
          return;
        }
        queryClient.invalidateQueries(personalFolderContentKey);
      },
      onError: (err: Error, options) => {
        options.onError(err);
        notification.error({ message: err.message });
      },
    },
  );

  const deleteStepMediaMutation = useMutation(
    async (mediaInput: Media) => deleteMedia(tenant, mediaInput.id),
    {
      onSuccess: (_, mediaInput) => {
        queryClient.invalidateQueries([
          'medias',
          tenant,
          { step: selectedStepId },
        ]);
        queryClient.invalidateQueries([
          'childs',
          tenant,
          { mediaId: mediaInput.originId },
        ]);
      },
      onError: (err: Error) => notification.error({ message: err.message }),
    },
  );

  const uploadZoneMediaMutation = useMutation(
    async (options: any) => {
      const selectedZone = { id: selectedZoneId } as Zone;
      return uploadMedia({
        tenant,
        file: options.file,
        label: options.file.name,
        ressource: selectedZone,
        onProgress: options.onProgress,
        isStep: false,
        folderId: options.folderId,
      });
    },
    {
      onSuccess: (res, options) => {
        options.onSuccess(res);
        queryClient.invalidateQueries(personalMediasKey);
        queryClient.invalidateQueries(personalFoldersKey);
        queryClient.invalidateQueries([
          'medias',
          tenant,
          { zoneId: selectedZoneId },
        ]);
        if (options.folderId) {
          queryClient.invalidateQueries([
            'folder_content',
            options.folderId,
            tenant,
            zone,
          ]);
          return;
        }
        queryClient.invalidateQueries(personalFolderContentKey);
      },
      onError: (err: Error, options) => {
        options.onError(err);
        notification.error({ message: err.message });
      },
    },
  );

  const deleteZoneMediaMutation = useMutation(
    async (mediaInput: Media) => deleteMedia(tenant, mediaInput.id),
    {
      onSuccess: (_, mediaInput) => {
        queryClient.invalidateQueries([
          'medias',
          tenant,
          { zoneId: selectedZoneId },
        ]);
        queryClient.invalidateQueries([
          'childs',
          tenant,
          { mediaId: mediaInput.originId },
        ]);
      },
      onError: (err: Error) => notification.error({ message: err.message }),
    },
  );

  const createFolderMutation = useMutation(
    async (input: CreateFolderInput) => createFolder(tenant, input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(personalMediasKey);
        queryClient.invalidateQueries(personalFoldersKey);
        queryClient.invalidateQueries(personalFolderContentKey);
        queryClient.invalidateQueries(personalFoldersTreeKey);
      },
    },
  );

  const updatePersonalFolderMutation = useMutation(
    async (input: UpdateFolderInput) => updateFolder(tenant, input),
    {
      onSuccess: (_, input) => {
        queryClient.invalidateQueries(personalMediasKey);
        queryClient.invalidateQueries(personalFoldersKey);
        queryClient.invalidateQueries(personalFoldersTreeKey);
        queryClient.invalidateQueries(personalFolderContentKey);

        queryClient.invalidateQueries([
          'folder_content',
          input.id,
          tenant,
          zone,
        ]);

        if (input.parentId) {
          queryClient.invalidateQueries([
            'folder_content',
            input.parentId,
            tenant,
            zone,
          ]);
        }
      },
    },
  );

  const deleteFolderMutation = useMutation(
    async (id: number) => deleteFolder(tenant, id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(personalMediasKey);
        queryClient.invalidateQueries(personalFoldersKey);
        queryClient.invalidateQueries(personalFolderContentKey);
        queryClient.invalidateQueries(personalFoldersTreeKey);
      },
    },
  );

  const uploadMediaMutation = useMutation(
    async (options: any) =>
      uploadMedia({
        tenant,
        file: options.file,
        label: options.file.name,
        stepsRef: options.stepsRef,
        scenariosRef: options.scenariosRef,
        folderId: options.folderId,
        onProgress: options.onProgress,
      }),
    {
      onSuccess: (res, options) => {
        queryClient.invalidateQueries(personalMediasKey);
        queryClient.invalidateQueries(personalFoldersKey);
        queryClient.invalidateQueries(personalFolderContentKey);
        queryClient.invalidateQueries(['medias', tenant]);
      },
      onError: (err: Error, options) => {
        options.onError(err);
        notification.error({ message: err.message });
      },
    },
  );

  const deleteMediaMutation = useMutation(
    async (mediaId: number) => deleteMedia(tenant, mediaId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(personalMediasKey);
        queryClient.invalidateQueries(personalFoldersKey);
        queryClient.invalidateQueries(personalFolderContentKey);
        queryClient.invalidateQueries(['medias', tenant]);
      },
      onError: (err: Error) => notification.error({ message: err.message }),
    },
  );

  return {
    keys: {
      medias: personalMediasKey,
    },
    queries: {
      // Step medias
      stepMedias: stepMediasQuery,

      // Personal medias & folders
      mediasTopZone: mediasTopZoneQuery,
      foldersTopZone: foldersTopZoneQuery,
      foldersChilds: foldersChildsQuery,
      foldersTree: foldersTreeQuery,

      // Standard medias
      foldersStandard: foldersStandardQuery,

      // Common
      mediasChild: mediasChildQuery,
    },
    loading: {
      deleteAndRefreshAllIsLoading:
        deletePersonalMediaMutationAndRefreshAll.isLoading,
      personalMedias:
        foldersChildsQuery.isLoading ||
        foldersTopZoneQuery.isLoading ||
        mediasTopZoneQuery.isLoading ||
        updatePersonalMediaMutation.isLoading ||
        uploadPersonalMediaMutation.isLoading ||
        deletePersonalMediaMutation.isLoading ||
        createFolderMutation.isLoading ||
        deleteFolderMutation.isLoading ||
        updatePersonalFolderMutation.isLoading ||
        searchPersonalMediaMutation.isLoading,
      standardMedias: foldersStandardQuery.isLoading,
    },
    // Personal medias
    uploadPersonalMedia: uploadPersonalMediaMutation.mutate,
    updatePersonalMedia: updatePersonalMediaMutation.mutate,
    copyPersonalMedia: copyPersonalMediaMutation.mutate,
    deletePersonalMedia: deletePersonalMediaMutation.mutate,
    deletePersonalMediaAndRefreshAll:
      deletePersonalMediaMutationAndRefreshAll.mutate,
    deletePersonalMediaAndRefreshAllAsync:
      deletePersonalMediaMutationAndRefreshAll.mutateAsync,
    searchPersonalMedia: searchPersonalMediaMutation.mutate,
    createPersonalFolder: createFolderMutation.mutate,
    updatePersonalFolder: updatePersonalFolderMutation.mutate,
    deletePersonalFolder: deleteFolderMutation.mutate,
    copyPersonalFolder: copyPersonalFolderMutation.mutate,

    // Scenario medias
    uploadScenarioMedia: uploadScenarioMediaMutation.mutate,
    deleteScenarioMedia: deleteScenarioMediaMutation.mutate,

    // Gears medias
    uploadGearMedia: uploadGearMediaMutation.mutate,
    deleteGearMedia: deleteGearMediaMutation.mutate,
    deleteGearMediaAsync: deleteGearMediaMutation.mutateAsync,

    // Steps medias
    uploadStepMedia: uploadStepMediaMutation.mutate,
    deleteStepMedia: deleteStepMediaMutation.mutate,
    deleteStepMediaAsync: deleteStepMediaMutation.mutateAsync,

    // Zones medias
    uploadZoneMedia: uploadZoneMediaMutation.mutate,
    deleteZoneMedia: deleteZoneMediaMutation.mutate,
    deleteZoneMediaAsync: deleteZoneMediaMutation.mutateAsync,

    // Common
    uploadMediaAsync: uploadMediaMutation.mutateAsync,
    deleteMediaAsync: deleteMediaMutation.mutateAsync,
  };
}
