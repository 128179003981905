import React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Field } from 'ui/forms';
import { Flex } from 'ui/flex';
import { useUserSession } from 'technical/hooks/use-user-session';
import { useHandleAnswers } from 'business/scenarios/hooks/answers';
import { Step } from 'business/type';
import StepConditions from './condition/step-conditions';
import { JumpStepHelperTooltip } from '../helper';

interface StepEditionLogicProps {
  canUpdate: boolean;
  form: any;
  scenarioReference: number;
  scenarioZoneId: number;
  selectedStepId: number;
  selectedStep: number;
  availableSteps: Step[] | undefined;
  setSelectedStep: (reference: number) => void;
}

export default function StepEditionLogic({
  canUpdate,
  form,
  scenarioReference,
  scenarioZoneId,
  selectedStepId,
  selectedStep,
  availableSteps,
  setSelectedStep,
}: StepEditionLogicProps) {
  const { t } = useTranslation(['scenarios', 'common']);
  const { tenant } = useUserSession();

  const invalidationKey = [
    'scenarioTasks',
    tenant,
    { reference: scenarioReference },
  ];

  // Answers management
  const { createAnswer, updateAnswer, deleteAnswer } = useHandleAnswers(
    selectedStep,
    selectedStepId,
    invalidationKey,
  );

  return (
    <Flex gap={16} direction="column">
      <Flex gap={5}>
        <Flex.Child>
          <JumpStepHelperTooltip>
            <QuestionCircleOutlined />
          </JumpStepHelperTooltip>
        </Flex.Child>
        {availableSteps ? (
          <Field
            name="conditions"
            label={t('detail.step-edition.fields.conditions')}
          >
            <StepConditions
              value={form.values.answers}
              selectedStep={selectedStep}
              onChange={(value) => {
                form.setFieldValue('answers', value);
              }}
              canUpdate={canUpdate}
              availableSteps={availableSteps}
              setSelectedStep={setSelectedStep}
              onUpdate={updateAnswer}
              onDelete={deleteAnswer}
              onCreate={createAnswer}
            />
          </Field>
        ) : null}
      </Flex>
    </Flex>
  );
}
