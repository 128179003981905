import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { Media } from 'business/type';
import { getMediasChild } from 'business/medias/services/medias';
import { useHandleMedias } from 'business/scenarios/hooks/medias';
import { useUserSession } from 'technical/hooks/use-user-session';
import { MediaThumbnailPreview } from 'ui/media/thumbnail';
import { MediaDetailModal } from 'ui/modals/media-detail';
import classes from './thumbnail.module.scss';

interface MediaThumbnailProps {
  fromModal: boolean;
  media: Media;
  mediaQueryId?: string | null;
  type?: 'preview' | 'thumbnail' | 'detail';
  canUpdate?: boolean;
}

export function MediaThumbnail({
  fromModal,
  media,
  mediaQueryId,
  type,
  canUpdate = true,
}: MediaThumbnailProps) {
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const { tenant } = useUserSession();
  const navigate = useNavigate();
  const location = useLocation();
  const { deletePersonalMediaAndRefreshAll, loading } = useHandleMedias({});
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (mediaQueryId && media.id === parseInt(mediaQueryId, 10)) {
      setModalIsVisible(true);
    }
  }, [mediaQueryId, media]);

  const mediasChildQuery = useQuery(
    ['childs', tenant, { mediaId: media.id }],
    () => getMediasChild(tenant, media.id),
    {
      enabled:
        media.replicasId && media.replicasId.length > 0 && modalIsVisible,
    },
  );

  const handleCancel = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    if (fromModal) {
      setModalIsVisible(false);
      searchParams.delete('media_id');
      setSearchParams(searchParams);
    } else {
      setModalIsVisible(false);
      navigate(location.pathname);
    }
  };

  return (
    <>
      {type === 'preview' && canUpdate ? (
        <Button
          icon={<CloseOutlined />}
          size="small"
          shape="circle"
          className={classes.closeButton}
          onClick={(e) => {
            e.preventDefault();
            deletePersonalMediaAndRefreshAll(media);
          }}
          loading={loading.deleteAndRefreshAllIsLoading}
        />
      ) : null}
      <MediaThumbnailPreview media={media} type={type ?? 'thumbnail'} />
      <MediaDetailModal
        media={media}
        isModalOpen={modalIsVisible}
        mediasChild={mediasChildQuery.data}
        onCancel={handleCancel}
      />
    </>
  );
}
