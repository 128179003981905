import React from 'react';
import { Alert, Input, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { Field } from 'ui/forms';
import { Flex } from 'ui/flex';
import { useHandleSteps } from 'business/scenarios/hooks/step';

interface StepEditionNameProps {
  canUpdate: boolean;
  form: any;
  scenarioReference: number;
  scenarioZoneId: number;
  selectedStepId: number;
  selectedStep: number;
}

export default function StepEditionName({
  canUpdate,
  form,
  scenarioReference,
  scenarioZoneId,
  selectedStepId,
  selectedStep,
}: StepEditionNameProps) {
  const { t } = useTranslation(['scenarios', 'common']);

  // Steps management
  const {
    data: stepData,
    updateStep,
    updateStepAsync,
    loading: stepLoading,
  } = useHandleSteps({
    scenarioReference,
    stepId: selectedStepId,
    zone: scenarioZoneId,
  });

  if (stepData) {
    return (
      <>
        {!form.values.name ? (
          <div>
            <Alert
              type="warning"
              message={t('detail.step-edition.fields.step-name-warning')}
            />
          </div>
        ) : null}
        <Flex gap={64} justify="space-between" style={{ flexWrap: 'wrap' }}>
          {canUpdate ? (
            <Input
              id="name"
              data-testid="step-name"
              name="name"
              value={form.values.name}
              status={!form.values.name ? 'warning' : undefined}
              style={{ width: '100%' }}
              disabled={stepLoading.updateStep}
              placeholder={t('detail.step-edition.fields.name')}
              onChange={(value) =>
                form.setFieldValue('name', value.target.value)
              }
              onPressEnter={(e) =>
                updateStepAsync({
                  stepRef: selectedStep,
                  label: e.currentTarget.value,
                })
              }
              onBlur={(e) =>
                updateStep({
                  stepRef: selectedStep,
                  label: e.currentTarget.value,
                })
              }
            />
          ) : (
            <span>{stepData.label}</span>
          )}
        </Flex>
      </>
    );
  }

  return (
    <Flex gap={64} justify="space-between">
      <Field name="name" label={t('detail.step-edition.fields.name')}>
        <Skeleton.Input active />
      </Field>
    </Flex>
  );
}
