import { Zone } from "business/type";
import { api } from "technical/api";

export default async function getZoneTree(tenant: string, zone: number) {
  const tree = api(tenant)
    .get<Zone>(`/v1/zones/trees/${zone}`)
    .then((response) => response.data);

  return tree;
}
