import React from 'react';
import { Col, Layout, Row, Typography } from 'antd';

interface PageLayoutProps {
  children: React.ReactNode;
  leftButton?: React.ReactNode;
  rightButton?: React.ReactNode;
  breadcrumb?: React.ReactNode;
  title: string;
}

export function PageLayout({
  children,
  leftButton,
  rightButton,
  breadcrumb,
  title,
}: PageLayoutProps) {
  const { Header, Content } = Layout;
  const { Title } = Typography;

  return (
    // Add Flex and flax child for table to work with new height size
    <Layout>
      <Header style={{ padding: 0, background: 'none' }}>
        <Title level={4}>{title}</Title>
      </Header>
      <Content style={{ paddingTop: 24 }}>
        <Row>
          <Col flex="auto">{leftButton}</Col>
          <Col flex="initial">{rightButton}</Col>
        </Row>
        {breadcrumb}
        {children}
      </Content>
    </Layout>
  );
}
