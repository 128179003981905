import i18next from 'i18next';

export enum UserRole {
  SuperAdmin = 'ROLE_SUPER_ADMIN',
  Admin = 'ROLE_ADMIN',
  Author = 'ROLE_AUTHOR',
  Reviewer = 'ROLE_REVIEWER',
  Approver = 'ROLE_APPROVER',
  Operator = 'ROLE_OPERATOR',
}

export const userRoleLabel = {
  [UserRole.SuperAdmin]: i18next.t('users:role.superAdmin'),
  [UserRole.Admin]: i18next.t('users:role.admin'),
  [UserRole.Author]: i18next.t('users:role.author'),
  [UserRole.Reviewer]: i18next.t('users:role.reviewer'),
  [UserRole.Approver]: i18next.t('users:role.approver'),
  [UserRole.Operator]: i18next.t('users:role.operator'),
};

export type UserInputCreate = {
  email: string;
  zoneId: number;
  roles: string;
};

export type UserInputUpdate = {
  id?: number;
  zonesId: number[];
  roles: string;
  zonesIdRemoved: number[];
  alertScenarioRefs?: number[];
  interventionScenarioRefs?: number[];
};
