import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Audit, AuditAction } from 'business/type';
import { useUserSession } from 'technical/hooks/use-user-session';
import GenericTable from 'ui/table';
import { useHandleAudits } from 'business/scenarios/hooks/audit';
import { getUserList } from 'business/users/services/users-list';

interface AuditTableProps {
  scenarioRef: number;
  loading: boolean;
}

export default function AuditTable({ scenarioRef, loading }: AuditTableProps) {
  const { t } = useTranslation('scenarios');
  const { tenant } = useUserSession();

  const { data: audits } = useHandleAudits(scenarioRef);

  const userListQuery = useQuery(['users', tenant], () => getUserList(tenant));

  const stateFilter = [
    {
      text: t('detail.audit.action.approve'),
      value: 'approve',
    },
    {
      text: t('detail.audit.action.declined'),
      value: 'declined',
    },
    {
      text: t('detail.audit.action.review'),
      value: 'review',
    },
    {
      text: t('detail.audit.action.validate'),
      value: 'validate',
    },
  ];

  function stateFiltering(value: boolean | React.Key, record: Audit) {
    switch (value) {
      case 'approve':
        return record.action === AuditAction.approve;
      case 'declined':
        return record.action === AuditAction.declined;
      case 'review':
        return record.action === AuditAction.reviewe;
      case 'validate':
        return record.action === AuditAction.validate;
      default:
        return false;
    }
  }

  const columns: ColumnsType<Audit> = [
    {
      title: t('detail.audit.user'),
      key: 'user',
      render: (record: Audit) => {
        const fullUser = userListQuery.data?.find(
          (user) => user.authId === record.user,
        );
        if (fullUser)
          return (fullUser.firstname ?? '') + ' ' + fullUser.surname;
        return '**********';
      },
      ellipsis: true,
    },
    {
      title: t('detail.audit.actions'),
      key: 'action',
      render: (record: Audit) => {
        switch (record.action) {
          case AuditAction.approve:
            return t('detail.audit.action.approve');
          case AuditAction.declined:
            return t('detail.audit.action.declined');
          case AuditAction.reviewe:
            return t('detail.audit.action.review');
          case AuditAction.validate:
            return t('detail.audit.action.validate');
          default:
            return '';
        }
      },
      filters: stateFilter,
      onFilter: stateFiltering,
    },
    {
      title: t('detail.audit.date'),
      key: 'date',
      render: (record: Audit) => new Date(record.date ?? '').toLocaleString(),
      ellipsis: true,
    },
  ];

  return (
    <>
      {audits.length > 0 ? (
        <GenericTable
          loading={loading}
          dataSource={audits}
          columns={columns}
          pageSize={20}
        />
      ) : null}
    </>
  );
}
