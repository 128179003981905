import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Col, Row } from 'antd';
import { MediaThumbnail } from 'business/medias/routes/library/thumbnail';
import { Media } from 'business/type';
import { useUrlQueryParams } from 'technical/hooks/use-url-query-params';

interface MediasGaleryProps {
  medias: Media[];
  canUpdate?: boolean;
  type?: 'preview' | 'thumbnail' | 'detail';
}

export function MediasGalery({
  medias,
  type = 'preview',
  canUpdate,
}: MediasGaleryProps) {
  const location = useLocation();
  const query = useUrlQueryParams();

  return (
    <Row gutter={4}>
      {medias.map((media) => {
        const isExpertCapture = media.label.startsWith('expert_capture_video');
        if (isExpertCapture) {
          return null;
        }

        const link = `${location.pathname}?media_id=${media.id}`;

        return (
          <Col key={media.id}>
            <Link to={link}>
              <MediaThumbnail
                fromModal={false}
                media={media}
                mediaQueryId={query.get('media_id')}
                type={type}
                canUpdate={canUpdate}
              />
            </Link>
          </Col>
        );
      })}
    </Row>
  );
}
