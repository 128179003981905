import React, { useEffect, useState } from 'react';
import { Zone } from 'business/type';
import { Col, Dropdown, Input, MenuProps, Row, Space, Typography } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  FolderOpenOutlined,
  QrcodeOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import {
  hasEditingRights,
  hasSuperAdminRights,
} from 'technical/auth/authorization';
import { useUserSession } from 'technical/hooks/use-user-session';
import ZoneCreationModal from 'business/zones/routes/zone/zone-creation';
import GearCreationModal from 'business/gears/routes/modal/gear-creation';
import { useTranslation } from 'react-i18next';
import ZoneDeletionModal from 'business/zones/routes/zone/zone-deletion';
import { useHandleGears } from 'business/digital-twin/hooks/gears';
import { useHandleZones } from '../../hooks/zones';

interface ZoneBlockProps {
  zone: Zone;
  searchValue: string;
  depth: number;
  addExpand: (id: string) => void;
}

export function ZoneBlock({
  zone,
  searchValue,
  depth,
  addExpand,
}: ZoneBlockProps) {
  const { role } = useUserSession();
  const { t } = useTranslation('digitaltwin');
  const [zoneModalVisible, setZoneModalVisible] = useState(false);
  const [deletionModalVisible, setDeletionModalVisible] = useState(false);
  const [gearModalVisible, setGearModalVisible] = useState(false);
  const [folderCreation, setFolderCreation] = useState(false);
  const [renaming, setRenaming] = useState(false);

  const { Text } = Typography;
  const [title, setTitle] = useState(<Text>{zone.label}</Text>);

  const { deleteZone, updateZone } = useHandleZones();
  const { createGear } = useHandleGears();

  useEffect(() => {
    if (searchValue.length > 0) {
      // Perform any actions you want when the propValue changes
      const index = zone.label.toLowerCase().indexOf(searchValue);
      const beforeStr = zone.label.substring(0, index);
      const str = zone.label.substring(index, index + searchValue.length);
      const afterStr = zone.label.slice(index + searchValue.length);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      index > -1
        ? setTitle(
            <Text>
              {beforeStr}
              <Text mark>{str}</Text>
              {afterStr}
            </Text>,
          )
        : setTitle(<Text>{zone.label}</Text>);
    } else {
      setTitle(<Text>{zone.label}</Text>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, zone]);

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    switch (e.key) {
      case 'rename':
        setRenaming(true);
        break;
      case 'add_folder':
        setFolderCreation(true);
        setZoneModalVisible(true);
        break;
      case 'add_zone':
        setFolderCreation(false);
        setZoneModalVisible(true);
        break;
      case 'add_gear':
        setGearModalVisible(true);
        break;
      case 'delete':
        setDeletionModalVisible(true);
        break;
      default:
    }
  };

  function buildDropDown() {
    const items: MenuProps['items'] = [];

    if ((!zone.parentId && hasSuperAdminRights(role)) || zone.parentId) {
      items.push(
        {
          label: t('menu.rename'),
          icon: <EditOutlined />,
          key: 'rename',
        },
        {
          type: 'divider',
        },
      );
    }

    if (
      (!zone.gears || (zone.gears && zone.gears.length === 0)) &&
      !zone.useQRCode
    ) {
      if (depth < 9)
        items.push({
          label: t('menu.add-folder'),
          icon: <FolderOpenOutlined />,
          key: 'add_folder',
        });
      items.push({
        label: t('menu.add-zone'),
        icon: <QrcodeOutlined />,
        key: 'add_zone',
      });
    }
    if (
      !zone.childs ||
      (zone.childs && zone.childs.length === 0 && zone.useQRCode) ||
      (zone.gears && zone.gears.length > 0)
    ) {
      items.push({
        label: t('menu.add-gear'),
        icon: <SettingOutlined />,
        key: 'add_gear',
      });
    }

    if ((!zone.parentId && hasSuperAdminRights(role)) || zone.parentId) {
      items.push({
        type: 'divider',
      });

      items.push({
        label: t('menu.delete'),
        icon: <DeleteOutlined />,
        key: 'delete',
        danger: true,
      });
    }

    return items;
  }

  function rename(label: string) {
    updateZone({
      id: zone.id,
      label,
    });
    setRenaming(false);
  }

  function onCreateGear(label: string) {
    createGear({
      label,
      zoneId: zone.id,
    });
    addExpand(`zone-${zone.id}`);
    setGearModalVisible(false);
  }

  function onDelete() {
    deleteZone(zone.id);
    setDeletionModalVisible(false);
  }

  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Dropdown
          menu={{ items: buildDropDown(), onClick: handleMenuClick }}
          trigger={['contextMenu']}
          disabled={!hasEditingRights(role)}
        >
          <Row>
            <Col flex="auto">
              {(zone.childs.length === 0 && zone.useQRCode) ||
              (zone.gears && zone.gears.length > 0) ? (
                <Space>
                  <QrcodeOutlined />
                  {renaming ? (
                    <Input
                      defaultValue={zone.label}
                      autoFocus
                      onPressEnter={(e) => rename(e.currentTarget.value)}
                      onBlur={(e) => rename(e.currentTarget.value)}
                    />
                  ) : (
                    title
                  )}
                </Space>
              ) : (
                <Space>
                  <FolderOpenOutlined />
                  {renaming ? (
                    <Input
                      defaultValue={zone.label}
                      autoFocus
                      onPressEnter={(e) => rename(e.currentTarget.value)}
                      onBlur={(e) => rename(e.currentTarget.value)}
                    />
                  ) : (
                    title
                  )}
                </Space>
              )}
            </Col>
          </Row>
        </Dropdown>
      </Space>

      {zoneModalVisible ? (
        <ZoneCreationModal
          parentId={zone.id}
          folderCreation={folderCreation}
          addExpand={addExpand}
          onClose={() => setZoneModalVisible(false)}
        />
      ) : null}

      {gearModalVisible ? (
        <GearCreationModal
          onClose={() => setGearModalVisible(false)}
          onSubmit={(label) => onCreateGear(label)}
        />
      ) : null}

      <ZoneDeletionModal
        isVisible={deletionModalVisible}
        label={zone.label}
        onClose={() => setDeletionModalVisible(false)}
        onSubmit={() => onDelete()}
      />
    </>
  );
}
