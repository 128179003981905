import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getParentFolders } from 'technical/utils/tree';
import { useUrlQueryParams } from 'technical/hooks/use-url-query-params';
import { useHandleMedias } from 'business/scenarios/hooks/medias';
import { Folder, Media } from 'business/type';
import MediasList from './media-list';

interface StandardMediasProps {
  isModal: boolean;
  mediasRessource?: Media[];
  selectionIsLoading?: boolean;
  disableFolderActions?: boolean;
  onSelect?: (input: Media) => void;
  onUnselect?: (input: Media) => void;
}

type MediaParams = {
  mediaFolderId: string;
};

export default function StandardMedias({
  isModal,
  mediasRessource,
  selectionIsLoading,
  disableFolderActions = true,
  onSelect,
  onUnselect,
}: StandardMediasProps) {
  const [parents, setParents] = useState<Folder[]>([]);
  const [standardSearchResult, setStandardSearchResult] = useState<Media[]>();
  const query = useUrlQueryParams();
  const { mediaFolderId } = useParams<MediaParams>() as MediaParams;

  let urlFolderId = 0;
  if (isModal) {
    const paramFolderId = query.get('folder_id');
    urlFolderId = parseInt(paramFolderId || '0', 10);
  } else {
    urlFolderId = parseInt(mediaFolderId, 10);
  }

  const { queries, loading } = useHandleMedias({
    personalFolderId: urlFolderId,
  });

  useEffect(() => {
    if (urlFolderId) {
      const parentsFolder = queries.foldersTree.data
        ?.map((folder) => {
          const parentsTab: Folder[] = [];
          getParentFolders(folder, urlFolderId, parentsTab);
          return parentsTab;
        })
        .filter((array) => array.length > 0)
        .flat()
        .reverse();

      if (parentsFolder) {
        setParents(parentsFolder);
      }
    }
  }, [queries.foldersTree.data, urlFolderId]);

  const handleSearch = (type: 'standard', value: string) => {
    if (value.length <= 0) {
      setStandardSearchResult(undefined);
      return;
    }

    let medias: Media[] = [];
    (queries.foldersStandard.data || []).forEach((folder) => {
      const result = (folder.medias || []).filter(
        (media) => media.label.includes(value) && !media.deleted,
      );
      medias = [...medias, ...result];
    });
    setStandardSearchResult(medias);
  };

  const getStandardMixed = () => {
    const folders = queries.foldersStandard.data || [];
    if (urlFolderId) {
      const standardMixedData =
        folders.find((folder) => folder.id === urlFolderId)?.medias || [];
      return standardMixedData.filter((m) => !m.deleted);
    }

    return folders.filter((m) => !m.deleted);
  };

  return (
    <MediasList
      isModal={isModal}
      parents={parents}
      urlFolderId={urlFolderId}
      mediaQueryId={query.get('media_id')}
      standard
      listIsLoading={loading.standardMedias}
      selectionIsLoading={selectionIsLoading}
      disableFolderActions={disableFolderActions}
      mixed={getStandardMixed()}
      mediasRessource={mediasRessource}
      searchResult={standardSearchResult}
      onSearch={(value) => handleSearch('standard', value)}
      onSelect={onSelect}
      onUnselect={onUnselect}
    />
  );
}
