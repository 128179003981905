// eslint-disable-next-line max-classes-per-file
import { Node, XYPosition } from 'reactflow';

export type CustomStepType = 'Step';

export type CustomStepObject = { id: string; type: CustomStepType };

export type CustomStepData = {
  label: string;
  tag: string;
  depth: number;
  parent: string;
  next: string[];
  branch: string[];
  type: CustomStepType;
  isRoot: boolean;
  isCondition: boolean;
  startPosition: XYPosition;
  hasMedias: boolean;
  hasGears: boolean;
  hasMandatoryValues: boolean;
  hasWarning: boolean;
  isComplete: boolean;
  [key: string]: any; // necessary, because we don't know what we want to pass in data object right now, maybe should be updated in the future
};

export class CustomStep {
  id: string;

  position: XYPosition;

  data: CustomStepData;

  type: string;

  nodeType?: 'step' | 'condition';

  selected: boolean;

  parentNode: string;

  hidden: boolean;

  constructor({
    id,
    position,
    parentNode,
    selected,
    data,
    nodeType = 'step',
    hidden = false,
  }: Pick<
    Node<Partial<CustomStepData>>,
    'id' | 'position' | 'data' | 'parentNode' | 'selected' | 'hidden'
  > & { nodeType: 'step' | 'condition' }) {
    this.id = id;
    this.position = position;
    this.data = {
      label: 'Step',
      tag: '1.0',
      parent: '',
      next: [],
      branch: [],
      type: 'Step',
      isCondition: false,
      depth: 0,
      isRoot: false,
      startPosition: position,
      hasMedias: false,
      hasGears: false,
      hasMandatoryValues: false,
      hasWarning: false,
      isComplete: false,
      ...data,
    };
    this.type = nodeType;
    this.parentNode = parentNode || '';
    this.selected = selected || false;
    this.hidden = hidden;
  }
}
