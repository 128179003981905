import React, { CSSProperties } from 'react';

interface FlexChildProps {
  children: React.ReactNode;
  className?: string;
  style?: CSSProperties;

  height?: CSSProperties['height'];
  width?: CSSProperties['width'];

  shrink?: CSSProperties['flexShrink'];
  grow?: CSSProperties['flexGrow'];
  basis?: CSSProperties['flexBasis'];
}

function FlexChild({
  shrink,
  grow,
  basis,
  height,
  width,
  className,
  style,
  children,
}: FlexChildProps) {
  return (
    <div
      className={className}
      style={{
        height,
        width,
        flexShrink: shrink,
        flexGrow: grow,
        flexBasis: basis,
        ...style,
      }}
    >
      {children}
    </div>
  );
}

interface FlexProps extends FlexChildProps {
  direction?: CSSProperties['flexDirection'];
  justify?: CSSProperties['justifyContent'];
  items?: CSSProperties['alignItems'];
  gap?: CSSProperties['gap'];
  testid?: string | null;
}

export function Flex({
  direction = 'row',
  justify,
  items,
  gap,
  shrink,
  grow,
  basis,
  height,
  width,
  className,
  style,
  children,
  testid = null,
}: FlexProps) {
  return (
    <div
      className={className}
      style={{
        height,
        width,
        display: 'flex',
        flexDirection: direction,
        justifyContent: justify,
        alignItems: items,
        flexShrink: shrink,
        flexGrow: grow,
        flexBasis: basis,
        gap,
        ...style,
      }}
      data-testid={testid}
    >
      {children}
    </div>
  );
}

Flex.Child = FlexChild;
