import React, { useEffect, useState } from 'react';
import {
  Configuration,
  AccountInfo,
  PublicClientApplication,
  IPublicClientApplication,
  NavigationClient,
  NavigationOptions,
} from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import config from 'config';
import { NavigateFunction, useNavigate } from 'react-router-dom';

export const REQUESTS = {
  login: {
    scopes: [
      'openid',
      config.environmentVariables.azureActiveDirectory.login.scopes,
    ],
    prompt: 'select_account',
  },
};

const configuration: Configuration = {
  auth: {
    clientId: config.environmentVariables.azureActiveDirectory.clientId,
    authority:
      config.environmentVariables.azureActiveDirectory.authority.toString(),
    knownAuthorities: [
      config.environmentVariables.azureActiveDirectory.authority.host,
    ],
    redirectUri: '/',
    postLogoutRedirectUri: '/',
  },
  cache: {
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/caching.md
    // we want session persistance between tabs, so session storage is not a good option.
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    allowRedirectInIframe: true,
  },
};

export const azureAuthClient = new PublicClientApplication(configuration);

export type AuthClient = IPublicClientApplication;

// eslint-disable-next-line consistent-return
export async function renewSession(client: AuthClient) {
  try {
    const activeAccount = client.getActiveAccount();
    const accounts = client.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
      /*
       * User is not signed in. Throw error or wait for user to login.
       * Do not attempt to log a user in outside of the context of MsalProvider
       */
    }
    const request = {
      ...REQUESTS.login,
      account: activeAccount || accounts[0],
    };

    const authResult = client
      .acquireTokenSilent(request)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        client.loginRedirect();
      });

    if (!authResult) {
      throw new Error('error authentification');
    }

    return await authResult;
  } catch {
    client.loginRedirect();
  }
}

// eslint-disable-next-line consistent-return
export async function getAccessToken(client: AuthClient) {
  const activeAccount = client.getActiveAccount();
  const accounts = client.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    client.loginRedirect();
  }
  const request = {
    ...REQUESTS.login,
    account: activeAccount || accounts[0],
  };

  const authRes = client
    .acquireTokenSilent(request)
    .then((res) => {
      return { type: res.tokenType, token: res.accessToken };
    })
    .catch((err) => {
      console.error(err);
    });

  return authRes;
}

interface Claims {
  name: string;
  sub: string;
  extension_tenant: string;
  extension_user_role: string;
}

export type Account = AccountInfo & { idTokenClaims: Claims };

export function getCurrentAccount(authClient: AuthClient): Account {
  const account =
    authClient.getActiveAccount() ?? authClient.getAllAccounts()[0];
  if (!account) {
    throw new Error('');
  }
  if (!account.idTokenClaims) {
    throw new Error('error');
  }
  return account as Account;
}

/*
  React components
*/
export function AzureAuthProvider({
  children,
  client,
}: {
  children: React.ReactNode;
  client: PublicClientApplication;
}) {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  client.setNavigationClient(navigationClient);

  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => {
    setFirstRender(false);
  }, []);

  if (firstRender) {
    return null;
  }

  return <MsalProvider instance={client}>{children}</MsalProvider>;
}

export class CustomNavigationClient extends NavigationClient {
  constructor(protected navigate: NavigateFunction) {
    super();
    this.navigate = navigate;
  }

  /**
   * Navigates to other pages within the same web application
   * You can use the useNavigate hook provided by react-router-dom to take advantage of client-side routing
   * @param url
   * @param options
   */
  async navigateInternal(url: string, options: NavigationOptions) {
    const relativePath = url.replace(window.location.origin, '');
    if (options.noHistory) {
      this.navigate(relativePath, { replace: true });
    } else {
      this.navigate(relativePath);
    }

    return false;
  }
}
