import { Alert } from "business/type";
import { api } from "technical/api";
import { AlertInputUpdate } from "./type";

export async function getAlert(tenant: string, alert: number) {
  return api(tenant)
    .get<Alert>(`/v1/alerts/${alert}`)
    .then((response) => response.data);
}

export async function updateAlert(tenant: string, input: AlertInputUpdate) {
  return api(tenant).patch<Alert>(`/v1/alerts/${input.id}`, {
    state: input.state,
  });
}
