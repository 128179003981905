import React, { useState } from 'react';
import { Input, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

interface CreateFolderModalProps {
  isVisible: boolean;
  onClose: () => void;
  onSubmit: (value: string) => void;
}

export default function CreateFolderModal({
  isVisible,
  onClose,
  onSubmit,
}: CreateFolderModalProps) {
  const { t } = useTranslation('medias');
  const [value, setValue] = useState('');

  return (
    <Modal
      title={t('create-folder.modal.title')}
      cancelText={t('create-folder.modal.cancel')}
      okText={t('create-folder.modal.submit')}
      open={isVisible}
      okType="primary"
      onOk={() => {
        onSubmit(value);
        setValue('');
      }}
      onCancel={() => {
        setValue('');
        onClose();
      }}
    >
      <p>{t('create-folder.modal.message')}</p>
      <Input
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={t('list.name')}
      />
    </Modal>
  );
}
