/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  ResponderProvided,
} from '@hello-pangea/dnd';
import { LoadingOutlined, MenuOutlined } from '@ant-design/icons';
import { Scenario, Task as TaskType } from 'business/type';
import { useTranslation } from 'react-i18next';
import { useUserSession } from 'technical/hooks/use-user-session';

import { importSteps } from 'business/scenarios/services/task-step-import';
import { Input } from 'antd';
import { Flex } from 'ui/flex';
import { Task } from './task';
import classes from './task-list.module.scss';
import { StepsList } from '../steps/steps-list';
import { AddElement } from '../utils/add-element';
import useTasksAndStepsLifeCycle from '../hooks/use-tasks-and-steps-life-cycle';

interface TasksListProps {
  scenario: Scenario;
  tasksAndStep: TaskType[];
  loading: boolean;
  canUpdate: boolean;
  selectedStep?: number;
  onSelectStep: (
    taskIndex: number,
    stepRef: number | undefined,
    stepId: number | undefined,
  ) => void;
}

export default function TasksList({
  scenario,
  tasksAndStep,
  selectedStep,
  loading,
  canUpdate,
  onSelectStep,
}: TasksListProps) {
  const { t } = useTranslation('scenarios');
  const { tenant, zone } = useUserSession();
  const { reference } = scenario;
  const {
    createTaskMutation,
    deleteTaskMutation,
    updateTaskMutation,
    createStepMutation,
    updateStepMutation,
  } = useTasksAndStepsLifeCycle(tenant, zone, reference);

  const queryClient = useQueryClient();

  const importStepsMutation = useMutation(
    async (input: { refsList: number[]; taskRef: number }) =>
      importSteps(tenant, input.refsList, input.taskRef),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries([
          'stepTaskTree',
          tenant,
          { zoneId: zone },
        ]);
        queryClient.invalidateQueries([
          'scenarioTasks',
          tenant,
          { reference: +reference },
        ]);
      },
    },
  );

  const handleCreateTask = () => {
    createTaskMutation.mutate({
      label: t('creation.modal.new-task.label'),
      scenarioRef: scenario.reference,
      rank:
        tasksAndStep.length > 0
          ? tasksAndStep[tasksAndStep.length - 1].rank + 1
          : 1,
    });
  };

  const handleCreateStep = (task: TaskType) => {
    if (task.steps) {
      createStepMutation.mutate(
        {
          label: t('creation.modal.new-step.label'),
          rank: task.steps.length + 1,
          taskRef: task.reference,
          newCondition: false,
        },
        {
          onSettled: (data) => {
            onSelectStep(task.reference, data?.reference, data?.id);
          },
        },
      );
    }
  };

  const handleDeleteTasks = (taskReference: number) => {
    const taskToDelete = tasksAndStep.find(
      (task) => task.reference === taskReference,
    );
    if (taskToDelete?.steps?.find((step) => step.reference === selectedStep)) {
      onSelectStep(taskReference, undefined, undefined);
    }
    deleteTaskMutation.mutate(taskReference);
  };

  const handleImportStep = (task: TaskType, refsList: number[]) => {
    if (task.steps) {
      importStepsMutation.mutate({ refsList, taskRef: task.reference });
    }
  };

  function onDragEnd({ type, destination, source }: DropResult) {
    if (destination && source) {
      const rank = destination.index + 1;

      switch (type) {
        case 'task': {
          const task = tasksAndStep[source.index];
          updateTaskMutation.mutate({
            reference: task.reference,
            rank,
            startIndex: source.index,
            endIndex: destination.index,
          });
          break;
        }
        case 'step': {
          const startTaskIndex = +source.droppableId.split('-')[0];
          const endTaskIndex = +destination.droppableId.split('-')[0];
          const task = tasksAndStep[endTaskIndex];
          const step = tasksAndStep[startTaskIndex].steps?.[source.index];

          if (step) {
            updateStepMutation.mutate({
              taskRef: task.reference,
              stepRef: step.reference,
              rank,
              startIndex: source.index,
              startTaskIndex,
              endIndex: destination.index,
              endTaskIndex,
            });
          }
          break;
        }
        default:
          break;
      }
    }
  }

  const cannotDragAndDrop = !canUpdate || loading;

  return (
    <div style={{ maxHeight: '90vh', overflow: 'auto' }}>
      <DragDropContext
        onDragEnd={(result: DropResult, provided: ResponderProvided) =>
          onDragEnd(result)
        }
      >
        <Droppable droppableId="tasks" type="task">
          {(provided) => (
            <>
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {tasksAndStep.map((task, taskIndex) => (
                  <Draggable
                    key={task.id}
                    draggableId={`task-${task.id}`}
                    index={taskIndex}
                    isDragDisabled={cannotDragAndDrop}
                  >
                    {(info) => (
                      <div
                        ref={info.innerRef}
                        {...info.draggableProps}
                        {...info.dragHandleProps}
                      >
                        <Task
                          title={
                            <div>
                              {canUpdate &&
                                (updateTaskMutation.isLoading &&
                                updateTaskMutation.variables?.reference ===
                                  task.reference ? (
                                  <LoadingOutlined
                                    className={classes.taskIcon}
                                  />
                                ) : (
                                  <MenuOutlined className={classes.taskIcon} />
                                ))}
                              <Input
                                className={classes.taskInput}
                                defaultValue={task.label}
                                bordered={false}
                                disabled={!canUpdate}
                                onPressEnter={(e) =>
                                  updateTaskMutation.mutate({
                                    ...task,
                                    label: e.currentTarget.value,
                                  })
                                }
                                onBlur={(e) =>
                                  updateTaskMutation.mutate({
                                    ...task,
                                    label: e.currentTarget.value,
                                  })
                                }
                              />
                            </div>
                          }
                          onRemove={
                            canUpdate
                              ? () => handleDeleteTasks(task.reference)
                              : undefined
                          }
                          hasTarget={
                            !!task.steps?.find((step) =>
                              step.linkedCases
                                ? step.linkedCases.length > 0
                                : false,
                            )
                          }
                        >
                          <StepsList
                            isLoading={cannotDragAndDrop}
                            taskIndex={taskIndex}
                            activeStep={selectedStep}
                            scenarioRef={scenario.reference}
                            steps={task.steps ? task.steps : []}
                            onCreateStep={() => handleCreateStep(task)}
                            onSelectStep={(stepRef, stepId) =>
                              onSelectStep(taskIndex, stepRef, stepId)
                            }
                            disableCreation={
                              task.reference === -1 ||
                              createStepMutation.isLoading ||
                              importStepsMutation.isLoading
                            }
                            onImportStep={(stepsRef) =>
                              handleImportStep(task, stepsRef)
                            }
                            canUpdate={canUpdate}
                          />
                        </Task>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
              {canUpdate ? (
                <Flex gap={16} direction="column">
                  <AddElement
                    onClick={handleCreateTask}
                    isLoading={createTaskMutation.isLoading}
                    disabled={createTaskMutation.isLoading}
                  >
                    {t('detail.add-task')}
                  </AddElement>
                </Flex>
              ) : null}
            </>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}
