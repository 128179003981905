import {
  Button,
  Card,
  Descriptions,
  Form,
  notification,
  Select,
  Skeleton,
  Space,
} from 'antd';
import { getVersionsSubscription } from 'business/monitoring/services/subscriptions';
import getScenarioListFromZone from 'business/scenarios/services/scenario-list';
import { UserInputUpdate } from 'business/users/services/type';
import { getTopZone } from 'business/users/services/user-creation';
import {
  getMailSubscription,
  getUser,
  modifyUser,
} from 'business/users/services/user-detail';
import { deleteUser } from 'business/users/services/users-list';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { useUserSession } from 'technical/hooks/use-user-session';
import { Flex } from 'ui/flex';
import { PageLayout } from 'ui/layout';
import UserDeletionModal from '../users/user-deletion';
import classes from './user-detail.module.scss';

type UserParams = {
  id: string;
};

export default function UserDetailPage() {
  const { id } = useParams<UserParams>() as UserParams;
  const { zone, tenant, role, authId } = useUserSession();
  const { t } = useTranslation('users');
  const navigate = useNavigate();
  const [newRole, setRole] = useState('');
  const [zonesId, setZonesId] = useState<number[]>();
  const [zonesIdRemoved, setZonesIdRemoved] = useState<number[]>();
  const [isModalVisible, setModalIsVisible] = useState(false);
  const [alertScenarioRefs, setAlertScenarioRefs] = useState<number[]>();
  const [interventionScenarioRefs, setInterventionScenarioRefs] =
    useState<number[]>();

  const toggleModal = () => setModalIsVisible((current) => !current);

  const userQuery = useQuery(['user', tenant, id], () => getUser(tenant, id));

  const mailSubscriptionQuery = useQuery(
    ['mailInterventions', tenant],
    () => getMailSubscription(tenant),
    {
      staleTime: 24 * 60 * 60 * 1000,
    },
  );

  const dvSubscriptionQuery = useQuery(
    ['versions', tenant],
    () => getVersionsSubscription(tenant),
    {
      staleTime: 24 * 60 * 60 * 1000,
    },
  );

  const topZonesListQuery = useQuery(['topZones', tenant], () =>
    getTopZone(tenant),
  );

  const scenarioListQuery = useQuery(
    ['scenarios', tenant, { zoneId: zone }],
    () => getScenarioListFromZone(tenant, zone),
  );

  const onConfirm = () => {
    toggleModal();
  };

  const handleSelect = (zoneId: number) => {
    setZonesId([...(zonesId ?? []), zoneId]);
    setZonesIdRemoved(
      zonesIdRemoved?.filter((IdToRemove) => IdToRemove !== zoneId),
    );
  };

  const handleDeselect = (zoneId: number) => {
    setZonesIdRemoved([...(zonesIdRemoved ?? []), zoneId]);
    setZonesId(zonesId?.filter((IdToAdd) => IdToAdd !== zoneId));
  };

  const handleAlertChange = (scenarioRef: number[]) => {
    setAlertScenarioRefs(scenarioRef);
  };

  const handleInterventionChange = (scenarioRef: number[]) => {
    setInterventionScenarioRefs(scenarioRef);
  };

  const deleteUserMutation = useMutation(
    async () =>
      userQuery.data ? deleteUser(tenant, userQuery.data) : undefined,
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(['users', tenant]);
        navigate(`/users`);
      },
      onError: (err: Error) => notification.error({ message: err.message }),
    },
  );

  const queryClient = useQueryClient();
  const modifyUserMutation = useMutation(
    async (user: UserInputUpdate) => modifyUser(tenant, user),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(['user', tenant, id]);
        queryClient.invalidateQueries(['users', tenant]);
        navigate(`/users`);
      },
      onError: (err: Error) => notification.error({ message: err.message }),
    },
  );

  const handleSave = () => {
    modifyUserMutation.mutate({
      id: userQuery.data?.id,
      roles: newRole,
      zonesId: zonesId ?? [],
      zonesIdRemoved: zonesIdRemoved ?? [],
      alertScenarioRefs,
      interventionScenarioRefs,
    });
  };

  const zonesNumber =
    (userQuery.data?.zones.length ?? 0) +
    (zonesId?.length ?? 0) -
    (zonesIdRemoved?.length ?? 0);

  return (
    <PageLayout title={t('detail.title')}>
      <Card
        extra={
          <Space size="small">
            <Button
              type="primary"
              onClick={handleSave}
              loading={modifyUserMutation.isLoading}
              disabled={zonesNumber === 0}
            >
              {t('detail.save')}
            </Button>
            <Button
              type="primary"
              danger
              onClick={onConfirm}
              loading={deleteUserMutation.isLoading}
            >
              {t('detail.delete')}
            </Button>
          </Space>
        }
      >
        <Flex
          gap={32}
          items="initial"
          justify="space-between"
          direction="column"
        >
          <Skeleton loading={userQuery.isLoading}>
            <Descriptions title={t('detail.info')} bordered>
              <Descriptions.Item label={t('detail.name')}>
                {userQuery.data?.surname}
              </Descriptions.Item>
              <Descriptions.Item label={t('detail.firstname')}>
                {userQuery.data?.firstname}
              </Descriptions.Item>
              <Descriptions.Item label={t('detail.email')}>
                {userQuery.data?.email}
              </Descriptions.Item>
            </Descriptions>
          </Skeleton>
          <Form>
            <Form.Item label={t('detail.zones')}>
              {userQuery.data ? (
                <Select
                  mode="tags"
                  defaultValue={userQuery.data.zones.map(
                    (userZone) => userZone.id,
                  )}
                  loading={topZonesListQuery.isLoading || userQuery.isLoading}
                  onSelect={handleSelect}
                  onDeselect={handleDeselect}
                >
                  {topZonesListQuery.data
                    ?.map((topZone) => (
                      <Select.Option key={topZone.id} value={topZone.id}>
                        {topZone.label}
                      </Select.Option>
                    ))
                    .concat(
                      userQuery.data.zones
                        .filter(
                          (userZone) =>
                            !topZonesListQuery.data.find(
                              (topZone) => topZone.id === userZone.id,
                            ),
                        )
                        .map((topZone) => (
                          <Select.Option key={topZone.id} value={topZone.id}>
                            {topZone.label}
                          </Select.Option>
                        )),
                    )}
                </Select>
              ) : (
                <Skeleton.Input active className={classes.largeSelect} />
              )}
            </Form.Item>
          </Form>
          <Form>
            <Form.Item label={t('detail.role')}>
              {userQuery.data ? (
                <Select
                  className={classes.input}
                  onSelect={setRole}
                  defaultValue={userQuery.data.roles}
                  disabled={id === authId}
                >
                  {role === 'ROLE_SUPER_ADMIN' ? (
                    <Select.Option value="ROLE_SUPER_ADMIN">
                      {t('role.superAdmin')}
                    </Select.Option>
                  ) : null}
                  <Select.Option value="ROLE_ADMIN">
                    {t('role.admin')}
                  </Select.Option>
                  <Select.Option value="ROLE_AUTHOR">
                    {t('role.author')}
                  </Select.Option>
                  {dvSubscriptionQuery.data?.subscribed ? (
                    <>
                      <Select.Option value="ROLE_APPROVER">
                        {t('role.approver')}
                      </Select.Option>
                      <Select.Option value="ROLE_REVIEWER">
                        {t('role.reviewer')}
                      </Select.Option>
                    </>
                  ) : null}
                  <Select.Option value="ROLE_OPERATOR">
                    {t('role.operator')}
                  </Select.Option>
                </Select>
              ) : (
                <Skeleton.Input active className={classes.input} />
              )}
            </Form.Item>
          </Form>
          <Form>
            <Form.Item label={t('detail.alertsMail')}>
              {userQuery.data ? (
                <Select
                  mode="tags"
                  value={alertScenarioRefs ?? userQuery?.data.alertScenarioRefs}
                  loading={scenarioListQuery.isLoading || userQuery.isLoading}
                  onChange={handleAlertChange}
                >
                  {scenarioListQuery.data?.map((scenario) => (
                    <Select.Option key={scenario.id} value={scenario.reference}>
                      {scenario.label}
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                <Skeleton.Input active className={classes.largeSelect} />
              )}
            </Form.Item>
          </Form>
          {mailSubscriptionQuery.data?.subscribed ? (
            <Form>
              <Form.Item label={t('detail.interventionsMail')}>
                {userQuery.data ? (
                  <Select
                    mode="tags"
                    loading={scenarioListQuery.isLoading || userQuery.isLoading}
                    value={
                      interventionScenarioRefs ??
                      userQuery?.data.interventionScenarioRefs
                    }
                    onChange={handleInterventionChange}
                  >
                    {scenarioListQuery.data?.map((scenario) => (
                      <Select.Option
                        key={scenario.id}
                        value={scenario.reference}
                      >
                        {scenario.label}
                      </Select.Option>
                    ))}
                  </Select>
                ) : (
                  <Skeleton.Input active className={classes.largeSelect} />
                )}
              </Form.Item>
            </Form>
          ) : null}
        </Flex>
      </Card>
      <UserDeletionModal
        isVisible={isModalVisible}
        onClose={toggleModal}
        onSubmit={deleteUserMutation.mutate}
      />
    </PageLayout>
  );
}
