import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { isMedia } from 'business/medias/services/folders';
import { MixedMedia } from 'business/medias/services/types';
import { Folder, Media } from 'business/type';
import GenericTable from 'ui/table';
import { Flex } from 'ui/flex';
import MediaBreadcrumb from './media-breadcrumb';
import MediaDeletionModal from './media-deletion';
import CreateFolderModal from './create-folder';
import { MediaReplaceModal } from './media-replace';
import { mediaTableColumns } from './media-table-columns';
import classes from './media-list.module.scss';

interface MediasListProps {
  isModal: boolean;
  parents: Folder[];
  urlFolderId: number;
  mediaQueryId: string | null;
  standard?: boolean;
  mixed: MixedMedia[];
  folderTree?: Folder[];
  mediasRessource?: Media[];
  selectionIsLoading?: boolean;
  listIsLoading?: boolean;
  disableFolderActions?: boolean;
  searchResult?: Media[];
  onSearch: (search: string) => void;
  onCopyMedia?: (media: Media, destinationFolderId: number) => void;
  onCopyFolder?: (folder: Folder, destinationFolderId: number) => void;
  onMoveMediaFolder?: (mediaId: number, newFolderId: number) => void;
  onMoveFolder?: (folderId: number, newFolderId: number) => void;
  onSelect?: (input: Media) => void;
  onUnselect?: (input: Media) => void;
  onDeleteMedia?: (media: Media) => void;
  onDeleteFolder?: (folder: Folder) => void;
  onCreateFolder?: (label: string) => void;
}

export default function MediasList({
  isModal,
  parents,
  urlFolderId,
  mediaQueryId,
  standard = false,
  mixed,
  folderTree,
  mediasRessource,
  selectionIsLoading = false,
  listIsLoading = false,
  disableFolderActions = false,
  searchResult,
  onSearch,
  onCopyMedia,
  onCopyFolder,
  onMoveMediaFolder,
  onMoveFolder,
  onSelect,
  onUnselect,
  onDeleteMedia,
  onDeleteFolder,
  onCreateFolder,
}: MediasListProps) {
  const { t } = useTranslation('medias');
  const [createFolderModalIsVisible, setCreateFolderModalIsVisible] =
    useState(false);
  const [deletionMediaModalIsVisible, setDeletionMediaModalIsVisible] =
    useState(false);
  const [replaceModalIsVisible, setReplaceModalIsVisible] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState<Media>();
  const [selectedFolder, setSelectedFolder] = useState<Folder>();
  const [search, setSearch] = useState<string>();

  const location = useLocation();

  const toggleCreateFolderModal = () =>
    setCreateFolderModalIsVisible((current) => !current);

  const toggleMediaDeletionModal = () =>
    setDeletionMediaModalIsVisible((current) => !current);

  const folderSearch = mixed.filter((record) =>
    record.label.toLowerCase().includes(search?.toLowerCase() ?? ''),
  );
  const folders = folderSearch.filter((record) => !isMedia(record));
  const medias = folderSearch.filter((record) => isMedia(record));
  const displayableData = [...folders, ...medias];

  const columns: ColumnsType<MixedMedia> = mediaTableColumns(
    t,
    location,
    isModal,
    urlFolderId,
    mediaQueryId,
    toggleMediaDeletionModal,
    setSelectedMedia,
    setSelectedFolder,
    setReplaceModalIsVisible,
    setSearch,
    search,
    onSearch,
    folderTree,
    standard,
    selectionIsLoading,
    disableFolderActions,
    mediasRessource,
    onSelect,
    onUnselect,
    onCopyMedia,
    onCopyFolder,
    onMoveFolder,
    onMoveMediaFolder,
  );

  const mediaType = standard ? 'standard' : 'personal';
  let homeLink;
  if (isModal) {
    homeLink = `${location.pathname}?modal=add_media&media_type=${mediaType}`;
  } else {
    homeLink = `/medias/${mediaType}`;
  }

  return (
    <>
      <Flex justify="space-between">
        <Flex items="center" gap={16}>
          {standard ? (
            <Link to={homeLink}>
              <HomeOutlined />
            </Link>
          ) : (
            <MediaBreadcrumb
              isModal={isModal}
              parents={parents}
              mediaType={mediaType}
            />
          )}
        </Flex>
        <Flex.Child>
          {!standard ? (
            <Button onClick={toggleCreateFolderModal} type="primary">
              {t('list.new-folder')}
            </Button>
          ) : null}
        </Flex.Child>
      </Flex>
        <GenericTable
          className={classes.table}
          loading={listIsLoading}
          dataSource={displayableData}
          columns={columns}
          pageSize={20}
        />
      {onCreateFolder ? (
        <CreateFolderModal
          isVisible={createFolderModalIsVisible}
          onClose={toggleCreateFolderModal}
          onSubmit={(name) => {
            toggleCreateFolderModal();
            onCreateFolder(name);
          }}
        />
      ) : null}
      {onDeleteMedia && onDeleteFolder ? (
        <MediaDeletionModal
          isVisible={deletionMediaModalIsVisible}
          onClose={toggleMediaDeletionModal}
          onSubmit={() => {
            if (selectedMedia) onDeleteMedia(selectedMedia);
            if (selectedFolder) onDeleteFolder(selectedFolder);
            setSelectedMedia(undefined);
            setSelectedFolder(undefined);
            toggleMediaDeletionModal();
          }}
        />
      ) : null}
      <MediaReplaceModal
        mediaId={selectedMedia?.id}
        folderId={selectedMedia?.folderId}
        isVisible={replaceModalIsVisible}
        onClose={() => setReplaceModalIsVisible(false)}
        onSubmit={() => setReplaceModalIsVisible(false)}
      />
    </>
  );
}
