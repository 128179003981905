import React, { useState } from "react";
import { ColumnsType } from "antd/lib/table";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ScenarioStatistics } from "business/type";
import GenericTable from "ui/table";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";

interface MonitoringScenariosProps {
  scenarios?: ScenarioStatistics[];
  loading: boolean;
}

export default function MonitoringScenario({
  scenarios,
  loading,
}: MonitoringScenariosProps) {
  const { t } = useTranslation("monitoring");
  const [dataSource, setDataSource] = useState<ScenarioStatistics[]>();

  const columns: ColumnsType<ScenarioStatistics> = [
    {
      title: t("scenario-list.scenarioName"),
      dataIndex: "label",
      render: (text: string, record: ScenarioStatistics) => (
        <Link to={`/scenarios/${record.reference}`}>{text}</Link>
      ),
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: t("scenario-list.previousScenario"),
      dataIndex: "hasPreviousScenario",
      render: (record: boolean) =>
        record ? (
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        ) : (
          <CloseCircleTwoTone twoToneColor="red" />
        ),
      align: "center",
    },
    {
      title: t("scenario-list.mandatorySteps"),
      dataIndex: "hasMandatorySteps",
      render: (record: boolean) =>
        record ? (
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        ) : (
          <CloseCircleTwoTone twoToneColor="red" />
        ),
      align: "center",
    },
  ];

  return (
    <>
      <GenericTable
        loading={loading}
        dataSource={dataSource ?? scenarios}
        columns={columns}
        pageSize={20}
      />
    </>
  );
}
