import { notification } from 'antd';
import { useMutation, useQuery } from 'react-query';
import { useUserSession } from 'technical/hooks/use-user-session';
import { queryClient } from 'technical/react-query';
import { removeZoneFromStep, updateStep } from '../services/scenario-detail';
import { getStepInformations } from '../services/step-informations';
import { importSteps } from '../services/task-step-import';
import { StepInputUpdate } from '../services/type';

export function useHandleSteps({
  scenarioReference,
  stepId,
  zone,
}: {
  scenarioReference: number;
  stepId: number;
  zone: number;
}) {
  const { tenant } = useUserSession();
  const stepInformationsKey = ['step', tenant, { stepId }];

  const stepQuery = useQuery(stepInformationsKey, () =>
    getStepInformations(tenant, stepId),
  );

  const handleOptimisticUpdate = async (stepInput: StepInputUpdate) => {
    const step = queryClient.getQueryData(stepInformationsKey);
    if (!step) {
      return;
    }

    queryClient.setQueryData(stepInformationsKey, {
      ...step,
      ...stepInput,
    });
  };

  const updateStepMutation = useMutation(
    async (stepInput: StepInputUpdate) => updateStep(tenant, stepInput),
    {
      // Optimistic update
      onMutate: (stepInput: StepInputUpdate) => {
        handleOptimisticUpdate(stepInput);
        const taskAndStep: any[] | undefined = queryClient.getQueryData([
          'scenarioTasks',
          tenant,
          { reference: scenarioReference },
        ]);

        const tmpData = taskAndStep?.map((task) => {
          task.steps = task.steps.map((step: { reference: number }) => {
            if (step.reference === stepInput.stepRef)
              return {
                ...step,
                ...stepInput,
              };
            return step;
          });
          return task;
        });

        queryClient.setQueryData(
          ['scenarioTasks', tenant, { reference: scenarioReference }],
          tmpData,
        );
      },
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(stepInformationsKey);
        if (variables.mediasId || variables.mediasOrder) {
          queryClient.invalidateQueries(['medias', tenant, { step: stepId }]);
        }

        if (variables.answersRef) {
          queryClient.invalidateQueries(['answers', tenant, stepId]);
        }

        if (variables.taskRef || variables.rank) {
          queryClient.invalidateQueries([
            'scenarioTasks',
            tenant,
            { reference: scenarioReference },
          ]);
          queryClient.invalidateQueries(['scenarioTasks', tenant]);
          queryClient.invalidateQueries([
            'stepTaskTree',
            tenant,
            { zoneId: zone },
          ]);
        }

        if (variables.gearsId || variables.zoneId) {
          queryClient.invalidateQueries([
            'scenarios',
            tenant,
            { zoneId: zone },
          ]);
        }
      },
      onError: (err: Error) => notification.error({ message: err.message }),
    },
  );

  const updateStepMutationWithoutInvalidation = useMutation(
    async (stepInput: StepInputUpdate) => updateStep(tenant, stepInput),
    {
      // Optimistic update
      onMutate: (stepInput: StepInputUpdate) =>
        handleOptimisticUpdate(stepInput),
      onSuccess: () => {
        queryClient.invalidateQueries(['scenarios', tenant, { zoneId: zone }]);
        queryClient.invalidateQueries(stepInformationsKey);
      },

      onError: (err: Error) => notification.error({ message: err.message }),
    },
  );

  const importStepsMutation = useMutation(
    async (input: { refsList: number[]; taskRef: number }) =>
      importSteps(tenant, input.refsList, input.taskRef),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([
          'scenarioTasks',
          tenant,
          { reference: scenarioReference },
        ]);
        queryClient.invalidateQueries([
          'stepTaskTree',
          tenant,
          { zoneId: zone },
        ]);
      },
      onError: (err: Error) => notification.error({ message: err.message }),
    },
  );

  const removeZoneMutation = useMutation(
    async () => removeZoneFromStep(tenant, stepId),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(stepInformationsKey);
      },
    },
  );

  return {
    data: stepQuery.data,
    loading: {
      global:
        stepQuery.isLoading ||
        updateStepMutation.isLoading ||
        importStepsMutation.isLoading,
      step: stepQuery.isLoading,
      updateStep: updateStepMutation.isLoading,
      importSteps: importStepsMutation.isLoading,
    },
    updateStep: updateStepMutation.mutate,
    updateStepAsync: updateStepMutation.mutateAsync,
    updateStepWithoutInvalidation:
      updateStepMutationWithoutInvalidation.mutateAsync,
    importSteps: importStepsMutation.mutate,
    removeZone: removeZoneMutation.mutate,
  };
}
