import { CommentInputUpdate, Intervention } from "business/type";
import i18next from "i18next";
import { api } from "technical/api";

export default async function getInterventionDetail(
  tenant: string,
  interventionId: number
) {
  return api(tenant)
    .get<Intervention>(`/v1/interventions/${interventionId}`, {
      params: { full: true },
    })
    .then((response) => response.data);
}

export async function exportIntervention(
  tenant: string,
  interventionId: number
) {
  const lang = i18next.language;
  const file = await api(tenant).get(
    `/v1/interventions/exports/${interventionId}?lang=${lang}`,
    { responseType: "blob" }
  );
  return file.data;
}

export async function updateComment(
  tenant: string,
  commentInput: CommentInputUpdate
) {
  await api(tenant).patch(`/v1/comments/${commentInput.id}`, {
    text: commentInput.text,
  });
}
