/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Background,
  ReactFlow,
  ReactFlowProvider,
  type ReactFlowProps,
} from 'reactflow';

import ViewControls from './controls/view-controls';

import 'reactflow/dist/style.css';

type GraphViewProps<T, U> = {
  viewProps: ReactFlowProps;
  rendererProps: T;
  renderer: (arg: T) => U;
};

export function GraphView<T, U>({
  viewProps,
  rendererProps,
  renderer,
}: GraphViewProps<T, U>) {
  const { ...dragProps } = renderer(rendererProps);

  return (
    <ReactFlow {...viewProps} {...dragProps}>
      <ViewControls />
      <Background />
    </ReactFlow>
  );
}

export default function GraphViewWithProvider<T, U>(
  props: GraphViewProps<T, U>,
) {
  return (
    <ReactFlowProvider>
      <GraphView {...props} />
    </ReactFlowProvider>
  );
}
