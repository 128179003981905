import React from 'react';
import { Layout as AntLayout, Divider } from 'antd';
import { Flex } from 'ui/flex';
import SpectralLogo from 'ui/images/spectral-logo.png';
import SpectralThumbnail from 'ui/images/logo-thumbnail.png';
import classes from './global-layout.module.scss';

interface GlobalLayoutProps {
  children: React.ReactNode;
  menu: React.ReactNode;
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
}

export function GlobalLayout({
  children,
  menu,
  collapsed,
  setCollapsed,
}: GlobalLayoutProps) {
  return (
    <AntLayout className={classes.layout}>
      <AntLayout.Sider
        width={260}
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <Flex
          direction="column"
          justify="space-between"
          className={classes.sidebar}
        >
          <Flex.Child shrink={0} className={classes.top}>
            {collapsed ? (
              <img
                src={SpectralThumbnail}
                alt="spectral thumbnail"
                height="37px"
                className={classes.logoCollapsed}
              />
            ) : (
              <img
                src={SpectralLogo}
                alt="spectral logo"
                width="100%"
                className={classes.logo}
              />
            )}
            <Divider className={classes.divider} />
          </Flex.Child>
          <Flex.Child grow={1} shrink={1} basis={0} className={classes.middle}>
            {menu}
          </Flex.Child>
          {/* <Flex.Child shrink={0} className={classes.bottom}>
            <Divider className={classes.divider} />
            <Copyright />
          </Flex.Child> */}
        </Flex>
      </AntLayout.Sider>
      <AntLayout.Content className={classes.content}>
        {children}
      </AntLayout.Content>
    </AntLayout>
  );
}
