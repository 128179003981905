import React, { useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { Zone } from 'business/type';
import { useUserSession } from 'technical/hooks/use-user-session';
import GenericTable from 'ui/table';
import { hasEditingRights } from 'technical/auth/authorization';
import { deleteZone } from 'business/zones/services/zone';
import ZoneDeletionModal from '../zone/zone-deletion';

interface TopZoneListProps {
  topZones?: Zone[];
  loading: boolean;
}

export default function TopZoneList({ topZones, loading }: TopZoneListProps) {
  const { t } = useTranslation('zones');
  const { tenant, zone, role } = useUserSession();
  const [isModalVisible, setModalIsVisible] = useState(false);
  const [zoneId, setZoneId] = useState<number>();
  const [zoneLabel, setZoneLabel] = useState<string>();

  const toggleModal = () => setModalIsVisible((current) => !current);

  const onConfirm = (record: Zone) => {
    setZoneId(record.id);
    setZoneLabel(record.label);
    toggleModal();
  };

  const queryClient = useQueryClient();
  const deleteZoneMutation = useMutation(
    async () => (zoneId ? deleteZone(tenant, zoneId) : undefined),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['top-level-zones']);
        queryClient.invalidateQueries([
          'stepTaskTree',
          tenant,
          { zoneId: zone },
        ]);
        toggleModal();
      },
    },
  );

  const columns: ColumnsType<Zone> = [
    {
      title: t('list.name'),
      dataIndex: 'label',
      key: 'label',
    },
    /*  {
      title: t("list.zone"),
      dataIndex: ["zone", "label"],
      key: "zone",
    }, */
  ];

  // feel free to change this, this is a usage example
  if (hasEditingRights(role)) {
    columns.push({
      title: t('list.action'),
      key: 'delete',
      render: (record: Zone) => (
        <Button
          type="primary"
          onClick={() => onConfirm(record)}
          icon={<DeleteOutlined />}
          danger
        />
      ),
    });
  }

  return (
    <>
      <GenericTable
        loading={loading}
        dataSource={topZones}
        columns={columns}
        pageSize={20}
      />
      <ZoneDeletionModal
        isVisible={isModalVisible}
        onClose={toggleModal}
        onSubmit={deleteZoneMutation.mutate}
        label={zoneLabel!}
      />
    </>
  );
}
