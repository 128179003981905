import { Alert, Zone } from 'business/type';
import i18next from 'i18next';
import { api } from 'technical/api';

export default async function getAlertListFromZone(
  tenant: string,
  id: number,
  startDate?: string,
  endDate?: string,
) {
  const alertList = await api(tenant)
    .get<Alert[]>(`/v1/alerts/zones/roots/${id}`, {
      params: {
        fromDate: startDate,
        toDate: endDate,
      },
    })
    .then((response) => response.data);

  return alertList;
}

export async function exportAlerts(
  tenant: string,
  startDate: string,
  endDate?: string,
) {
  const lang = i18next.language;
  const file = await api(tenant).get(`/v1/alerts/exports?lang=${lang}`, {
    params: {
      fromDate: startDate,
      toDate: endDate,
    },
    responseType: 'blob',
  });
  return file.data;
}
