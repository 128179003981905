import { ScenarioStatistics, Statistics } from 'business/type';
import i18next from 'i18next';
import { api } from 'technical/api';

export async function getStatistics(tenant: string) {
  return api(tenant)
    .get<Statistics>(`/v1/statistics`)
    .then((response) => response.data);
}

export async function getScenarioStatistics(tenant: string) {
  return api(tenant)
    .get<ScenarioStatistics[]>(`/v1/scenarios/monitoring`)
    .then((response) => response.data);
}

export async function exportMonitoring(
  tenant: string,
  fromDate: string | undefined,
  toDate: string | undefined,
) {
  const lang = i18next.language;
  const file = await api(tenant).get(`v1/exports/monitoring?`, {
    params: {
      fromDate,
      toDate,
      lang,
    },
    responseType: 'blob',
  });
  return file.data;
}
