import { createContext, useContext } from "react";
import { Role } from "technical/auth/roles";

export interface UserSession {
  name: string;
  role: Role;
  tenant: string;
  authId: string;
  availableTenants: Array<string>;
  switchTenant: (tenant: string) => void;
  zone: number;
  availableZones: Array<{ id: number; label: string }>;
  changeZone: (zone: number) => void;
  logout: () => void;
}

export const UserSessionContext = createContext<UserSession | null>(null);

export function useUserSession(): UserSession {
  const context = useContext(UserSessionContext);
  if (context === null) {
    throw new Error("useUserSession must be used within a UserSessionProvider");
  }
  return context;
}
