import React, { useEffect, useState } from 'react';
import { Upload, UploadProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Flex } from 'ui/flex';
import { InboxOutlined } from '@ant-design/icons';
import { hasEditingRights } from 'technical/auth/authorization';
import { useUserSession } from 'technical/hooks/use-user-session';
import { useUrlQueryParams } from 'technical/hooks/use-url-query-params';
import { getParentFolders } from 'technical/utils/tree';
import { useHandleMedias } from 'business/scenarios/hooks/medias';
import { Folder, Media } from 'business/type';
import MediasList from './media-list';

interface PersonalMediasProps {
  isModal: boolean;
  mediasRessource?: Media[];
  selectionIsLoading?: boolean;
  disableFolderActions?: boolean;
  onSelect?: (input: Media) => void;
  onUnselect?: (input: Media) => void;
  onUpload: UploadProps['customRequest'];
  onDeleteMedia?: (media: Media, currentFolderId?: number) => void;
}

type MediaParams = {
  mediaFolderId: string;
};

export default function PersonalMedias({
  isModal,
  mediasRessource,
  selectionIsLoading,
  disableFolderActions = false,
  onSelect,
  onUnselect,
  onUpload,
  onDeleteMedia,
}: PersonalMediasProps) {
  const [parents, setParents] = useState<Folder[]>([]);
  const { t } = useTranslation('medias');
  const { role, zone } = useUserSession();
  const query = useUrlQueryParams();
  const { mediaFolderId } = useParams<MediaParams>() as MediaParams;

  let urlFolderId = 0;
  if (isModal) {
    const paramFolderId = query.get('folder_id');
    urlFolderId = parseInt(paramFolderId || '0', 10);
  } else {
    urlFolderId = parseInt(mediaFolderId, 10);
  }

  const {
    queries,
    loading,
    updatePersonalMedia,
    copyPersonalMedia,
    createPersonalFolder,
    updatePersonalFolder,
    deletePersonalFolder,
    copyPersonalFolder,
  } = useHandleMedias({ personalFolderId: urlFolderId });

  useEffect(() => {
    if (urlFolderId) {
      const parentsFolder = queries.foldersTree.data
        ?.map((folder) => {
          const parentsTab: Folder[] = [];
          getParentFolders(folder, urlFolderId, parentsTab);
          return parentsTab;
        })
        .filter((array) => array.length > 0)
        .flat()
        .reverse();

      if (parentsFolder) {
        setParents(parentsFolder);
      }
    } else {
      setParents([]);
    }
  }, [queries.foldersTree.data, urlFolderId]);

  const handleDeleteUpload = (input: any) => {
    if (input.response && onDeleteMedia) {
      onDeleteMedia(
        {
          id: input.response.data.id,
          label: input.name,
          filename: input.name,
          url: input.response.data.url,
          replicasId: [],
          originId: input.response.data.originId,
          deleted: false,
        },
        urlFolderId,
      );
    }
  };

  const getPersonalMixed = () => {
    const medias = queries.mediasTopZone.data || [];
    const folders = queries.foldersTopZone.data || [];

    let personalMixedData;
    if (queries.foldersChilds.isSuccess && queries.foldersChilds.data) {
      personalMixedData = [
        ...(queries.foldersChilds.data.medias || []),
        ...(queries.foldersChilds.data.childs || []),
      ];
      return personalMixedData.filter((m) => !m.deleted);
    }

    personalMixedData = [...folders, ...medias];
    return personalMixedData.filter((m) => !m.deleted);
  };

  return (
    <Flex direction="column" gap={16}>
      {hasEditingRights(role) ? (
        <Upload.Dragger
          multiple
          accept=".pdf,.mp4,.avi,.stl,.obj,.fbx,.wav,.png,.jpeg,.jpg"
          onRemove={(input) => handleDeleteUpload(input)}
          customRequest={(options) =>
            onUpload
              ? // @ts-ignore
                onUpload({ ...options, folderId: urlFolderId })
              : undefined
          }
        >
          <InboxOutlined />
          <p>{t('list.upload')}</p>
        </Upload.Dragger>
      ) : null}
      <div>
        <MediasList
          isModal={isModal}
          parents={parents}
          urlFolderId={urlFolderId}
          mediaQueryId={query.get('media_id')}
          standard={false}
          listIsLoading={loading.personalMedias}
          selectionIsLoading={selectionIsLoading}
          disableFolderActions={disableFolderActions}
          folderTree={queries.foldersTree.data}
          mixed={getPersonalMixed()}
          mediasRessource={mediasRessource}
          onSearch={(value) => {}}
          onCopyMedia={(media, destination) =>
            copyPersonalMedia({
              mediaId: media.id,
              destinationFolderId: destination === -1 ? undefined : destination,
            })
          }
          onCopyFolder={(folder, destination) =>
            copyPersonalFolder({
              folderId: folder.id,
              destinationFolderId: destination,
            })
          }
          onSelect={onSelect}
          onUnselect={onUnselect}
          onDeleteMedia={
            onDeleteMedia
              ? (item) => onDeleteMedia(item, urlFolderId)
              : undefined
          }
          onDeleteFolder={(item) => deletePersonalFolder(item.id)}
          onMoveFolder={(folderId, destinationFolderId) =>
            updatePersonalFolder({
              id: folderId,
              parentId: destinationFolderId === -1 ? null : destinationFolderId,
              ...(destinationFolderId === -1 && { zoneId: zone }),
            })
          }
          onMoveMediaFolder={(mediaId, folderId) =>
            updatePersonalMedia({
              id: mediaId,
              folderId: folderId === -1 ? null : folderId,
            })
          }
          onCreateFolder={(label) =>
            createPersonalFolder({
              label,
              zoneId: zone,
              parentId: urlFolderId,
            })
          }
        />
      </div>
    </Flex>
  );
}
