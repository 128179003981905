import React, { useState } from 'react';
import { CommentInputUpdate, InterventionStep } from 'business/type';
import { notification } from 'antd';
import { MediasGalery } from 'ui/media';
import { useUserSession } from 'technical/hooks/use-user-session';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getMediaFromComment } from 'business/medias/services/medias';
import { updateComment } from 'business/interventions/services/intervention-detail';
import { EditOutlined } from '@ant-design/icons';

interface CommentProps {
  interventionStep: InterventionStep;
}

export function CommentText({ interventionStep }: CommentProps) {
  const { tenant } = useUserSession();

  const queryClient = useQueryClient();

  const updateCommentMutation = useMutation(
    async (data: { comment: CommentInputUpdate }) =>
      updateComment(tenant, data.comment),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([
          'intervention',
          tenant,
          { interventionId: interventionStep.interventionId },
        ]);
      },
      onError: (err: Error) => notification.error({ message: err.message }),
    },
  );

  if (!interventionStep.comment) {
    return null;
  }

  return <span>{interventionStep.comment}</span>;
}

export function CommentMedia({ interventionStep }: CommentProps) {
  const { tenant } = useUserSession();

  const mediaQuery = useQuery(
    ['medias', tenant, { commentId: interventionStep.commentId }],
    () => getMediaFromComment(tenant, interventionStep.commentId!),
    { enabled: !!interventionStep.commentId },
  );

  return mediaQuery.isSuccess && mediaQuery.data ? (
    <MediasGalery medias={mediaQuery.data} type="thumbnail" />
  ) : null;
}
