/* eslint-disable no-nested-ternary */
import React, { Key, useEffect, useState } from 'react';
import { useHandleZones } from 'business/digital-twin/hooks/zones';
import { useHandleGears } from 'business/digital-twin/hooks/gears';
import { useTranslation } from 'react-i18next';
import { Gear, Zone } from 'business/type';
import { Space, Spin, Tree, Input, notification } from 'antd';
import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import { DataNode } from 'antd/lib/tree';
import { ZoneBlock } from './zone-block';
import { GearBlock } from './gear-block';

interface DigitalTreeProps {
  checkable?: boolean;
  selectable?: boolean;
  alreadyChecked?: Gear[];
  zoneId?: number;
  onUpdateArray?: (array: number[]) => void;
  onUpdateSelected?: (key: number) => void;
}

const sortByRank = (a: Zone | Gear, b: Zone | Gear) => {
  if (typeof a.rank !== 'number' && typeof b.rank !== 'number') {
    return 0;
  }
  if (typeof a.rank !== 'number') {
    return 1; // `a` comes after `b` if `a.rank` is undefined
  }
  if (typeof b.rank !== 'number') {
    return -1; // `a` comes before `b` if `b.rank` is undefined
  }
  return a.rank - b.rank; // Compare the ranks as numbers
};

export default function DigitalTree({
  checkable = false,
  selectable = false,
  alreadyChecked,
  zoneId,
  onUpdateArray,
  onUpdateSelected,
}: DigitalTreeProps) {
  const { queries, updateZone } = useHandleZones();
  const { updateGear } = useHandleGears();
  const { t } = useTranslation('digitaltwin');
  const [expandedKeys, setExpandedKeys] = useState<Key[]>();
  const [checkedKeys, setCheckedKeys] = useState<Key[]>();
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);
  const [treeData, setTreeData] = useState<any>([]);
  const [searchValue, setSearchValue] = useState('');
  const [dragNode, setDragNode] = useState<any>();

  useEffect(() => {
    if (queries.tree.isSuccess) {
      setTreeData([buildZoneTree(queries.tree.data, 0)]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedKeys, searchValue, queries.tree.data]);

  useEffect(() => {
    if (zoneId) {
      setExpandedKeys([`zone-${zoneId}`]);
      setAutoExpandParent(true);
    }
    if (checkable && alreadyChecked) {
      if (alreadyChecked.length > 0) {
        setExpandedKeys([...alreadyChecked.map((gear) => `gear-${gear.id}`)]);
        setAutoExpandParent(true);
        setCheckedKeys(alreadyChecked.map((gear) => `gear-${gear.id}`));
      } else {
        setCheckedKeys([]);
      }
    } else setExpandedKeys([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkable, alreadyChecked]);

  const { Search } = Input;

  function buildZoneTree(zone: Zone, depth: number): any {
    let children: { children: any[] } = { children: [] };
    let maxDepth = 0;

    if (zone.childs.length > 0) {
      children = {
        children: zone.childs
          .sort(sortByRank)
          .map((child) => buildZoneTree(child, depth + 1)),
      };
      maxDepth = children.children.reduce((acc, child) => {
        if (child.maxDepth > acc) return child.maxDepth;
        return acc;
      }, 0);
    } else if (zone.gears) {
      children = {
        children: buildGearTree(zone.gears),
      };
    }

    return {
      title: (
        <ZoneBlock
          zone={zone}
          searchValue={searchValue}
          addExpand={onAddExpand}
          depth={depth}
        />
      ),
      subtitle: zone.label.toLowerCase(),
      key: `zone-${zone.id}`,
      checkable: false,
      selectable:
        selectable &&
        (zone.useQRCode ||
          !zone.childs ||
          (!!zone.gears && zone.gears.length > 0)),
      ...children,
      depth,
      maxDepth: maxDepth + 1,
    };
  }

  function buildGearTree(gears: Gear[]): any {
    const parentGears = gears
      .filter((gear) => !gear.parentId && !gear.deleted)
      .sort(sortByRank);

    return parentGears.map((gear) => ({
      title: (
        <GearBlock
          gear={gear}
          searchValue={searchValue}
          addExpand={onAddExpand}
        />
      ),
      subtitle: gear.label.toLowerCase(),
      key: `gear-${gear.id}`,
      children: subGearTree(gears, gear.id),
      zoneId: gear.zoneId,
      stepsLinked: gear.steps && gear.steps.length > 0,
      selectable: false,
      disableCheckbox:
        (zoneId && gear.zoneId !== zoneId) ||
        (checkedKeys &&
          checkedKeys.length > 0 &&
          gears.find(
            (treeGear) =>
              treeGear.id ===
              parseInt((checkedKeys[0] as string).split('-')[1], 10),
          )?.zoneId !== gear.zoneId),
    }));
  }

  function subGearTree(gears: Gear[], parentId: number): any {
    return gears.sort(sortByRank).reduce((acc, gear) => {
      if (gear.parentId === parentId && !gear.deleted) {
        acc.push({
          title: (
            <GearBlock
              gear={gear}
              searchValue={searchValue}
              addExpand={onAddExpand}
            />
          ),
          subtitle: gear.label.toLowerCase(),
          key: `gear-${gear.id}`,
          children: subGearTree(gears, gear.id),
          zoneId: gear.zoneId,
          stepsLinked: gear.steps && gear.steps.length > 0,
          selectable: false,
          disableCheckbox:
            (zoneId && gear.zoneId !== zoneId) ||
            (checkedKeys &&
              checkedKeys.length > 0 &&
              gears.find(
                (treeGear) =>
                  treeGear.id ===
                  parseInt((checkedKeys[0] as string).split('-')[1], 10),
              )?.zoneId !== gear.zoneId),
        });
      }
      return acc;
    }, [] as any[]);
  }

  const onAddExpand = (key: React.Key) => {
    if (expandedKeys) {
      if (!expandedKeys.includes(key)) setExpandedKeys([...expandedKeys, key]);
    } else setExpandedKeys([key]);
    setAutoExpandParent(true);
  };

  const onExpand = (expandedKeysValue: React.Key[]) => {
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const onCheck = (checkedKeysValue: any) => {
    // eslint-disable-next-line no-param-reassign
    const gearIds = checkedKeysValue.checked.map((key: string) =>
      parseInt(key.split('-')[1], 10),
    );
    if (onUpdateArray) onUpdateArray(gearIds);

    setCheckedKeys(checkedKeysValue.checked);
  };

  const onSelect = (selectedKeyValue: any) => {
    const selectedKey = parseInt(selectedKeyValue[0].split('-')[1], 10);
    if (onUpdateSelected) onUpdateSelected(selectedKey);
  };

  const flattenTree = (tree: any[]): any[] =>
    tree
      .map((node) => {
        if (node.children) {
          return [node, ...flattenTree(node.children)];
        }
        return [node];
      })
      .flat();

  const defaultData = queries.tree.isSuccess
    ? flattenTree([buildZoneTree(queries.tree.data, 0)])
    : [];

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value.length > 0) {
      const newExpandedKeys = defaultData
        .map((item: { subtitle: string | string[]; key: React.Key }) => {
          if (item.subtitle.indexOf(value.toLowerCase()) > -1) {
            return getParentKey(item.key, defaultData);
          }
          return null;
        })
        .filter(
          (item: any, i: any, self: string | any[]) =>
            item && self.indexOf(item) === i,
        );
      setExpandedKeys(newExpandedKeys as React.Key[]);
      setSearchValue(value.toLowerCase());
      setAutoExpandParent(true);
    } else {
      setSearchValue('');
      setExpandedKeys([]);
      setAutoExpandParent(false);
    }
  };

  const getParentKey = (key: React.Key, tree: DataNode[]): React.Key => {
    let parentKey: React.Key;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node.children) {
        if (node.children.some((item) => item.key === key)) {
          parentKey = node.key;
        } else if (getParentKey(key, node.children)) {
          parentKey = getParentKey(key, node.children);
        }
      }
    }
    return parentKey!;
  };

  const onDragStart = (info: any) => {
    const { node } = info;

    setDragNode(node);
  };

  function compareTreePos(
    str1: string,
    str2: string,
    dropToGap: boolean,
  ): boolean {
    // Split the strings into arrays using the '-' delimiter
    const parts1 = str1.split('-');
    const parts2 = str2.split('-');

    // Check if the arrays have the same length
    if (parts1.length - parts2.length > 0) {
      return false; // They have different formats
    }

    if (parts1.length === parts2.length && !dropToGap) {
      return false;
    }

    // Compare each part of the arrays as numbers
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < parts2.length - 1; i++) {
      const num1 = parseFloat(parts1[i]);
      const num2 = parseFloat(parts2[i]);

      if (isNaN(num1) || isNaN(num2) || num1 !== num2) {
        return false; // Parts at index i are not equal as numbers
      }
    }

    return true; // All parts are the same as numbers
  }

  const onDrop = (info: any) => {
    const { node } = info;

    if (!compareTreePos(node.pos, dragNode.pos, info.dropToGap)) {
      if (node.key.split('-')[0] === 'gear') {
        if (dragNode.key.split('-')[0] === 'gear') {
          if (dragNode.stepsLinked && dragNode.zoneId !== node.zoneId) {
            notification.error({
              message: t('notifications.not-droppable-title'),
              description: t('notifications.gear-placed'),
            });
          } else {
            updateGear(
              {
                id: parseInt(dragNode.key.split('-')[1], 10),
                rank: info.dropPosition,
                parentId: parseInt(node.key.split('-')[1], 10),
              },
              {
                onSuccess: async () => {
                  notification.success({
                    message: t('notifications.move', {
                      move: dragNode.subtitle,
                      target: node.subtitle,
                    }),
                  });
                },
              },
            );
          }
        } else {
          notification.error({
            message: t('notifications.not-droppable-title'),
            description: t('notifications.zone-to-gear'),
          });
        }
      } else if (node.key.split('-')[0] === 'zone') {
        if (dragNode.key.split('-')[0] === 'zone') {
          if (
            node.children.length === 0 ||
            (node.children.length > 0 &&
              node.children[0].key.split('-')[0] === 'zone')
          ) {
            if (node.depth + dragNode.maxDepth < 10) {
              updateZone(
                {
                  id: parseInt(dragNode.key.split('-')[1], 10),
                  rank: info.dropPosition,
                  parentId: parseInt(node.key.split('-')[1], 10),
                },
                {
                  onSuccess: async () => {
                    notification.success({
                      message: t('notifications.move', {
                        move: dragNode.subtitle,
                        target: node.subtitle,
                      }),
                    });
                  },
                },
              );
            } else {
              notification.error({
                message: t('notifications.not-droppable-title'),
                description: t('notifications.zone-to-many-depth'),
              });
            }
          } else {
            notification.error({
              message: t('notifications.not-droppable-title'),
              description: t('notifications.zone-to-zone'),
            });
          }
        }
        if (dragNode.key.split('-')[0] === 'gear') {
          if (
            dragNode.stepsLinked &&
            parseInt(node.key.split('-')[1], 10) !== dragNode.zoneId
          ) {
            notification.error({
              message: t('notifications.not-droppable-title'),
              description: t('notifications.gear-placed'),
            });
          } else if (
            node.children.length === 0 ||
            (node.children.length > 0 &&
              node.children[0].key.split('-')[0] === 'gear')
          ) {
            updateGear(
              {
                id: parseInt(dragNode.key.split('-')[1], 10),
                parentId: null,
                rank: info.dropToGap ? info.dropPosition : 0,
                zoneId: parseInt(node.key.split('-')[1], 10),
              },
              {
                onSuccess: async () => {
                  notification.success({
                    message: t('notifications.move', {
                      move: dragNode.subtitle,
                      target: node.subtitle,
                    }),
                  });
                },
              },
            );
          } else {
            notification.error({
              message: t('notifications.not-droppable-title'),
              description: t('notifications.gear-to-folder'),
            });
          }
        }
      }
    } else {
      // change rank, update rank with info.dropPosition
      if (dragNode.key.split('-')[0] === 'gear') {
        updateGear({
          id: parseInt(dragNode.key.split('-')[1], 10),
          rank: info.dropToGap ? info.dropPosition : 0,
        });
      }

      if (dragNode.key.split('-')[0] === 'zone') {
        updateZone({
          id: parseInt(dragNode.key.split('-')[1], 10),
          rank: info.dropToGap ? info.dropPosition : 0,
        });
      }
    }
  };

  return (
    <Space direction="vertical" style={{ display: 'flex' }}>
      <Search placeholder="input search text" allowClear onChange={onChange} />
      {queries.tree.isSuccess && treeData.length > 0 ? (
        <Tree
          showLine
          blockNode
          draggable
          selectable
          autoExpandParent={autoExpandParent}
          checkedKeys={checkedKeys}
          expandedKeys={expandedKeys}
          onExpand={onExpand}
          switcherIcon={<DownOutlined />}
          checkable={checkable}
          onCheck={onCheck}
          onSelect={onSelect}
          checkStrictly
          treeData={treeData}
          /* onDragEnter={onDragEnter} */
          onDragStart={onDragStart}
          onDrop={onDrop}
        />
      ) : (
        <Spin
          size="large"
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        />
      )}
    </Space>
  );
}
