import React, { useCallback } from 'react';
import { useReactFlow, Node } from 'reactflow';
import { CustomStepData } from '../utils/factory/custom-step';
import { CustomTaskData } from '../utils/factory/custom-task';
import useTasksAndStepsLifeCycle from '../../hooks/use-tasks-and-steps-life-cycle';

export default function useNodeDrag({
  tenant,
  zone,
  reference,
}: {
  tenant: string;
  zone: number;
  reference: number;
}) {
  const { getIntersectingNodes, setNodes, getNode } = useReactFlow();
  const { updateStepMutation, updateTaskMutation, deleteTaskMutation } =
    useTasksAndStepsLifeCycle(tenant, Number(zone), Number(reference));

  const onNodeDragStart = useCallback(
    (
      _: React.MouseEvent,
      node: Node<CustomStepData | CustomTaskData>,
      __: Node[],
    ) => {
      setNodes((nds) =>
        nds.map((n) => {
          if (n.id === node.id) {
            n.draggable = true;
            n.parentNode = undefined;
          }
          return n;
        }),
      );
    },
    [setNodes],
  );

  const onNodeDrag = useCallback(
    (
      _: React.MouseEvent,
      node: Node<CustomStepData | CustomTaskData>,
      __: Node[],
    ) => {
      const intersections = getIntersectingNodes(node)
        .map((n: Node<CustomStepData | CustomTaskData>) =>
          n.data.type === node.data.type ? n.id : undefined,
        )
        .filter((n) => typeof n !== 'undefined');

      setNodes((nds) =>
        nds.map((n: Node<CustomStepData | CustomTaskData>) => ({
          ...n,
          className: intersections.includes(n.id) ? 'isIntersecting' : '',
        })),
      );
    },
    [getIntersectingNodes, setNodes],
  );

  const onNodeDragStop = useCallback(
    (
      _: React.MouseEvent,
      node: Node<CustomStepData | CustomTaskData>,
      __: Node[],
    ) => {
      const draggedNode = node;

      if (!draggedNode) return;

      const intersections = getIntersectingNodes(node)
        .map((n) => (n.data.type === node.data.type ? n.id : undefined))
        .filter((n) => n !== undefined);

      if (
        intersections.length > 0 &&
        typeof getNode(intersections?.[0] || '') !== 'undefined'
      ) {
        const intersectedNode = getNode(intersections?.[0] || '');

        if (!intersectedNode) return;

        if (draggedNode.data.type === 'Step') {
          const attachedTask = getNode(draggedNode.data.parentNode || '');
          const destinationTask = getNode(intersectedNode?.parentNode || '');

          updateStepMutation.mutate(
            {
              taskRef: destinationTask?.data.reference,
              stepRef: draggedNode.data.reference,
              rank: intersectedNode.data.rank + 1,
              startIndex: draggedNode.data.rank - 1,
              startTaskIndex: attachedTask?.data.rank - 1,
              endIndex: intersectedNode.data.rank,
              endTaskIndex: destinationTask?.data.rank - 1,
            },
            {
              onSettled: () => {
                if (attachedTask?.data.steps.length === 1) {
                  deleteTaskMutation.mutate(attachedTask.data.reference);
                }
              },
            },
          );
        }

        if (draggedNode.data.type === 'Task') {
          updateTaskMutation.mutate({
            reference: draggedNode.data.reference,
            rank: intersectedNode.data.rank + 1,
            startIndex: draggedNode.data.rank - 1,
            endIndex: intersectedNode.data.rank - 1,
          });
        }

        setNodes((nds) => [
          ...nds.map((n) => {
            if (intersections.includes(n.id)) {
              return {
                ...n,
                className: n.className?.replace('isIntersecting', ''),
              };
            }

            return n;
          }),
        ]);
      } else {
        setNodes((nds) =>
          nds.map((n) => {
            if (intersections.includes(n.id)) {
              return {
                ...n,
                className: n.className?.replace('isIntersecting', ''),
              };
            }

            if (n.id === draggedNode.id) {
              return {
                ...n,
                position: n.data.startPosition,
                draggable: false,
                parentNode: n.data.parentNode,
                data: {
                  ...n.data,
                  parentNode: '',
                },
              };
            }

            return n;
          }),
        );
      }
    },
    [
      getIntersectingNodes,
      getNode,
      setNodes,
      updateStepMutation,
      updateTaskMutation,
    ],
  );

  return { onNodeDragStart, onNodeDrag, onNodeDragStop };
}
