import { apiMediaUpload } from 'technical/api';
import { Gear, Media, Scenario, Step, Zone } from 'business/type';

export async function uploadMedia({
  tenant,
  file,
  label,
  ressource,
  onProgress,
  isStep = false,
  folderId,
  stepsRef,
  scenariosRef,
}: {
  tenant: string;
  file: any;
  label: string;
  ressource?: Scenario | Step | Gear | Zone;
  onProgress: any;
  isStep?: boolean;
  folderId?: number;
  stepsRef?: number[];
  scenariosRef?: number[];
}) {
  if (
    ![
      'pdf',
      'mp4',
      'avi',
      'stl',
      'obj',
      'fbx',
      'wav',
      'png',
      'jpeg',
      'jpg',
    ].includes(label.toLowerCase().split('.').pop()!)
  ) {
    throw new Error('Format not accepted');
  }
  const fmData = new FormData();
  fmData.append('media', file);
  fmData.append('label', label);

  if (stepsRef && stepsRef.length > 0) {
    fmData.append('stepsRef', `[${stepsRef.toString()}]`);
  } else if (scenariosRef && scenariosRef.length > 0) {
    fmData.append('scenariosRef', `[${scenariosRef.toString()}]`);
  } else if (ressource) {
    if (isStep) {
      fmData.append('stepsId', `[${ressource.id}]`);
    } else if ('reference' in ressource) {
      fmData.append('scenariosRef', `[${ressource.reference}]`);
    } else if ('zoneId' in ressource) {
      fmData.append('gearsId', `[${ressource.id}]`);
    } else {
      fmData.append('zonesId', `[${ressource.id}]`);
    }
  }

  // Folder
  if (folderId) {
    fmData.append('folderId', folderId.toString());
  }

  // Create Configuration
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: (event: {
      loaded: number | undefined;
      total?: number;
    }) => {
      if (event.loaded && event.total)
        onProgress({ percent: (event.loaded / event.total) * 100 });
    },
  };

  const media = await apiMediaUpload(tenant).post<
    Pick<Media, 'id' | 'url' | 'originId'>
  >('/v1/medias', fmData, config);

  return media;
}
