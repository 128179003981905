/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Droppable, Draggable } from '@hello-pangea/dnd';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Step as StepType } from 'business/type';
import { deleteStep } from 'business/scenarios/services/step-edition';
import { useUserSession } from 'technical/hooks/use-user-session';
import { queryClient } from 'technical/react-query';
import { Flex } from 'ui/flex';
import { Step } from './step';
import classes from './steps-list.module.scss';
import { AddElement } from '../utils/add-element';

export function StepsList({
  isLoading,
  taskIndex,
  steps,
  activeStep,
  scenarioRef,
  disableCreation,
  canUpdate,
  onCreateStep,
  onImportStep,
  onSelectStep,
}: {
  isLoading: boolean;
  steps: StepType[];
  taskIndex: number;
  scenarioRef: number;
  activeStep?: number;
  disableCreation: boolean;
  canUpdate: boolean;
  onCreateStep: () => void;
  onImportStep: (Refs: number[]) => void;
  onSelectStep: (ref: number | undefined, id: number | undefined) => void;
}) {
  const { t } = useTranslation('scenarios');
  const { tenant, zone } = useUserSession();

  const deleteStepMutation = useMutation(
    async (data: { reference: number; index: number }) =>
      deleteStep(tenant, data.reference),
    {
      onSuccess: (res, data) => {
        if (data.reference === activeStep) {
          if (steps.length > 1) {
            onSelectStep(
              data.index === 0
                ? steps[1].reference
                : steps[data.index - 1].reference,
              data.index === 0 ? steps[1].id : steps[data.index - 1].id,
            );
          } else {
            onSelectStep(undefined, undefined);
          }
        }
        queryClient.invalidateQueries([
          'stepTaskTree',
          tenant,
          { zoneId: zone },
        ]);
        queryClient.invalidateQueries([
          'scenarioTasks',
          tenant,
          { reference: scenarioRef },
        ]);
      },
    },
  );

  return (
    <>
      <Droppable droppableId={`${taskIndex}-steps`} type="step">
        {(provided) => (
          <>
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={classes.stepDroppable}
            >
              {steps.map((step, stepIndex) => (
                <Draggable
                  key={step.reference}
                  draggableId={`step-${step.reference}`}
                  isDragDisabled={isLoading}
                  index={stepIndex}
                >
                  {(info) => (
                    <div
                      ref={info.innerRef}
                      {...info.draggableProps}
                      {...info.dragHandleProps}
                    >
                      <Step
                        reference={step.reference}
                        canUpdate={canUpdate}
                        step={step}
                        active={activeStep === step.reference}
                        position={`#${taskIndex + 1}.${stepIndex + 1}`}
                        onClick={() => onSelectStep(step.reference, step.id)}
                        onDelete={
                          canUpdate
                            ? () =>
                                deleteStepMutation.mutate({
                                  reference: step.reference,
                                  index: stepIndex,
                                })
                            : undefined
                        }
                        isCondition={!!step.answers && step.answers.length > 1}
                        isDeletionLoading={
                          deleteStepMutation.variables?.reference ===
                            step.reference && deleteStepMutation.isLoading
                        }
                      >
                        {step.label}
                      </Step>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
            {canUpdate ? (
              <Flex gap={16} direction="column">
                <AddElement onClick={onCreateStep} disabled={disableCreation}>
                  {t('detail.add-step')}
                </AddElement>
              </Flex>
            ) : null}
          </>
        )}
      </Droppable>
    </>
  );
}
