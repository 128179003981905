/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import {
  Select,
  Button,
  Space,
  Layout,
  Dropdown,
  MenuProps,
  Switch,
  Tooltip,
  Typography,
} from 'antd';
import { useQuery } from 'react-query';
import {
  BarsOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  MenuOutlined,
  PartitionOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import config from 'config';
import { IPublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { ReactFlowProps } from 'reactflow';
import { Step } from 'business/type';
import { useHandleScenario } from 'business/scenarios/hooks/scenario';
import { useUserSession } from 'technical/hooks/use-user-session';
import { useHandleTasks } from 'business/scenarios/hooks/tasks';
import {
  hasApproveRights,
  hasEditingRights,
  hasReviewRights,
} from 'technical/auth/authorization';
import {
  exportScenarioCSV,
  exportScenarioPDF,
  getDraftSubscription,
  getMediaFromScenario,
  getScenarioVersions,
} from 'business/scenarios/services/scenario-detail';
import { useHandleAnswers } from 'business/scenarios/hooks/answers';
import { getCurrentAccount } from 'technical/auth/azure-active-directory';
import { downloadCsv, downloadFile, downloadZip } from 'technical/utils/download';
import { Flex } from 'ui/flex';
import ScenariosDuplicationModal from './duplication/scenario-duplication';

import { StepEdition } from './steps/step-edition';
import GraphViewWithProvider from './graph';
import ScenarioDeletionModal from '../scenarios/scenario-deletion';
import useScenarioView, {
  type ScenarioViewProps,
} from './graph/hooks/use-scenario-view';
import TasksList from './tasks/tasks-list';
import { fitViewOptions, nodeTypes, proOptions } from './graph/utils/config';
import { ScenarioSider } from './header/scenario-sider';

type ScenarioParams = {
  reference: string;
};

type ViewType = 'graph' | 'list';

export default function ScenarioWithGraph() {
  const { reference } = useParams<ScenarioParams>();
  const { zone, tenant, role } = useUserSession();
  const { t } = useTranslation('scenarios');
  const [, setCurrentTaskIndex] = useState<number>();
  const [isModalVisible, setModalIsVisible] = useState(false);
  const [isModalDeletionVisible, setModalDeletionIsVisible] = useState(false);
  const [currentStepRef, setCurrentStepRef] = useState<number | undefined>(
    undefined,
  );
  const [currentStepId, setCurrentStepId] = useState<number | undefined>(
    undefined,
  );
  const [, setLoadingExport] = useState(false);
  const [activeTab, setActiveTab] = useState<string>('infosTab');
  const [versionId, setVersionId] = useState<number>();
  const [viewType, setViewType] = useState<ViewType>('graph');

  const { Text } = Typography;

  const navigate = useNavigate();

  const {
    data: scenarioData,
    loading: scenarioLoading,
    queries,
    updateScenario,
    updateScenarioAsync,
    validateScenario,
    declineScenario,
    deleteScenario,
  } = useHandleScenario(reference ? +reference : 0, versionId!, zone, () =>
    setVersionId(scenarioQuery.data?.id),
  );

  const { scenarioQuery } = queries;

  const scenarioMediasQuery = useQuery(
    ['medias', tenant, { scenarioId: versionId ?? scenarioQuery.data?.id }],
    () => getMediaFromScenario(tenant, versionId ?? scenarioQuery.data?.id!),
    { enabled: scenarioQuery.isSuccess },
  );

  const {
    data: tasksAndStep,
    isLoading: tasksAndStepIsLoading,
    isSuccess: tasksAndStepIsSuccess,
  } = useHandleTasks(reference ? +reference : 0, versionId);

  const draftSubscriptionQuery = useQuery(['draftSubscription', tenant], () =>
    getDraftSubscription(tenant),
  );

  const versionsQuery = useQuery(
    ['versions', tenant, { reference: reference ? +reference : 0 }],
    () => getScenarioVersions(tenant, reference ? +reference : 0),
  );

  useEffect(() => {
    setCurrentStepRef(undefined);
    setCurrentStepId(undefined);
    setVersionId(undefined);
  }, [reference]);

  useEffect(() => {
    const currentStep = tasksAndStep
      ?.flatMap((task) => task.steps)
      .find((step) => step?.reference === currentStepRef);
    setCurrentStepId(currentStep?.id);
    setCurrentStepRef(currentStep?.reference);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasksAndStep]);

  useEffect(() => {
    if (hasCondition) {
      setViewType('graph');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasksAndStep]);

  const selectedStepRef = currentStepRef ?? undefined;

  const selectedStepId = currentStepId ?? undefined;

  const handleChangeStepReference = (stepReference: number) => {
    const currentStep = tasksAndStep
      ?.flatMap((task) => task.steps)
      .find((step) => step?.reference === stepReference);
    setCurrentStepId(currentStep?.id);
    setCurrentStepRef(currentStep?.reference);
  };

  const availableSteps =
    tasksAndStep && tasksAndStep.length > 0
      ? tasksAndStep
          ?.flatMap(
            (task) =>
              task.steps?.map(
                (step) => ({ ...step, taskRank: task.rank }) as Step,
              ),
          )
          .filter((step): step is Step => !!step)
      : undefined;

  async function handleExportPDFScenario() {
    setLoadingExport(true);
    const scenarioName = scenarioData!.label
      ? `${scenarioData!.label}_export`
      : 'export';
    const blob = await exportScenarioPDF(tenant, scenarioData!.id);
    downloadFile(blob, scenarioName);
    setLoadingExport(false);
  }

  async function handleExportCSVScenario() {
    setLoadingExport(true);
    const scenarioName = scenarioData!.label
      ? `${scenarioData!.label}_export`
      : 'export';
    const blob = await exportScenarioCSV(tenant, scenarioData!.id);
    downloadZip(blob, scenarioName);
    setLoadingExport(false);
  }

  async function handleValidateScenario() {
    validateScenario();
  }

  async function handleDeclineScenario() {
    declineScenario();
  }

  async function handleSignature(instance: IPublicClientApplication) {
    const account = getCurrentAccount(instance);
    const claims = account.idTokenClaims;

    // user informations
    const user = {
      name: claims.name,
      authId: claims.sub,
    };

    instance
      .loginPopup({
        scopes: [config.environmentVariables.azureActiveDirectory.login.scopes],
        prompt: 'login',
      })
      .then((response) => {
        if (response.uniqueId === user.authId) handleValidateScenario();
      });
  }

  const toggleModal = () => setModalIsVisible((current) => !current);
  const toggleDeletionModal = () =>
    setModalDeletionIsVisible((current) => !current);

  const handleDeletion = () => {
    deleteScenario();
    navigate('/scenarios');
  };

  const maxVersion = scenarioQuery.isSuccess ? scenarioQuery.data!.version : 0;

  const canUpdate = scenarioData
    ? hasEditingRights(role) &&
      scenarioData.isDraft &&
      !(scenarioData.pendingApprove || scenarioData.pendingReview) &&
      !scenarioData.deleted
    : hasEditingRights(role);

  const hasCondition = tasksAndStep.find(
    (task) =>
      task.steps?.find(
        (step) =>
          (step.answers && step.answers.length > 1) ||
          (step.linkedCases && step.linkedCases.length > 1),
      ),
  );

  const onSelectStep = (
    taskIndex: number,
    stepRef: number | undefined,
    stepId: number | undefined,
  ) => {
    if (document.activeElement) {
      (document.activeElement as HTMLElement).blur();
    }
    setCurrentTaskIndex(taskIndex);
    setCurrentStepRef(stepRef);
    setCurrentStepId(stepId);
  };

  const { createFullAnswer } = useHandleAnswers(
    selectedStepRef!,
    selectedStepId!,
    ['scenarioTasks', tenant, { reference: reference ? +reference : 0 }],
  );

  function ReviewButton() {
    const { instance } = useMsal();

    return (
      <Button
        type="primary"
        icon={<CheckCircleOutlined />}
        onClick={() => handleSignature(instance)}
        disabled={scenarioLoading.validateScenario || !hasReviewRights(role)}
      >
        {t('detail.buttons.review')}
      </Button>
    );
  }

  function ApproveButton() {
    const { instance } = useMsal();

    return (
      <Button
        type="primary"
        icon={<CheckCircleOutlined />}
        onClick={() => handleSignature(instance)}
        disabled={scenarioLoading.validateScenario || !hasApproveRights(role)}
      >
        {t('detail.buttons.approve')}
      </Button>
    );
  }

  function validationButton() {
    if (scenarioData!.pendingReview)
      return (
        <>
          <ReviewButton />
          <Button
            type="default"
            danger
            icon={<CloseCircleOutlined />}
            onClick={() => handleDeclineScenario()}
            disabled={
              scenarioLoading.validateScenario || !hasReviewRights(role)
            }
          >
            {t('detail.buttons.declined')}
          </Button>
        </>
      );
    if (scenarioData!.pendingApprove)
      return (
        <>
          <ApproveButton />
          <Button
            type="default"
            danger
            icon={<CloseCircleOutlined />}
            onClick={() => handleDeclineScenario()}
            disabled={
              scenarioLoading.validateScenario || !hasApproveRights(role)
            }
          >
            {t('detail.buttons.declined')}
          </Button>
        </>
      );
    return (
      <Button
        type="primary"
        icon={<CheckCircleOutlined />}
        onClick={() => handleValidateScenario()}
        disabled={scenarioLoading.validateScenario || !hasEditingRights(role)}
      >
        {t('detail.buttons.validate')}
      </Button>
    );
  }

  const bouttonVisible =
    draftSubscriptionQuery.isSuccess &&
    draftSubscriptionQuery.data?.subscribed ? (
      <Space>
        {t('detail.versions.version')} :
        <Select
          data-testid="scenario-version-select"
          value={versionId ?? scenarioQuery.data?.id}
          popupMatchSelectWidth={false}
          onSelect={(value: number) => {
            setVersionId(value);
          }}
        >
          {versionsQuery.isSuccess
            ? versionsQuery.data
                ?.sort((a, b) => b.version - a.version)
                .map((scenario) => (
                  <Select.Option value={scenario.id} key={scenario.id}>
                    {scenario.version === maxVersion
                      ? `${scenario.version} (${t('detail.versions.draft')})`
                      : scenario.version === maxVersion - 1
                        ? `${scenario.version} (${t('detail.versions.active')})`
                        : scenario.version}
                  </Select.Option>
                ))
            : null}
        </Select>
      </Space>
    ) : null;

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    switch (e.key) {
      case 'duplicate':
        toggleModal();
        break;
      case 'csv':
        handleExportCSVScenario();
        break;
      case 'pdf':
        handleExportPDFScenario();
        break;
      case 'delete':
        toggleDeletionModal();
        break;
      default:
    }
  };

  const items: MenuProps['items'] = [
    {
      label: t('detail.buttons.duplicate'),
      key: 'duplicate',
      disabled: !hasEditingRights(role),
    },
    {
      label: t('detail.buttons.csv'),
      key: 'csv',
    },
    {
      label: t('detail.buttons.pdf'),
      key: 'pdf',
    },
    {
      type: 'divider',
    },
    {
      label: t('detail.buttons.delete'),
      key: 'delete',
      danger: true,
      disabled: !hasEditingRights(role) || !scenarioData?.isDraft,
    },
  ];

  function onConnect(source: string | null, target: string | null) {
    if (source && target) {
      let sourceRef;
      let targetRef;
      for (const task of tasksAndStep) {
        const tmpSource = task.steps?.find(
          (step) => step.id === parseInt(source, 10),
        );
        const tmpTarget = task.steps?.find(
          (step) => step.id === parseInt(target, 10),
        );
        if (tmpSource) {
          sourceRef = tmpSource.reference;
        }
        if (tmpTarget) {
          targetRef = tmpTarget.reference;
        }
      }
      tasksAndStep.find(
        (task) => task.steps?.find((step) => step.id === parseInt(source, 10)),
      );
      if (targetRef && sourceRef)
        createFullAnswer({
          nextStepRef: targetRef,
          stepRef: sourceRef,
          stepId: parseInt(source, 10),
        });
    }
  }

  return (
    <Layout>
      <Layout.Header
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: 'calc(100% + 32px)',
          backgroundColor: 'white',
          marginLeft: '-16px',
        }}
        onClick={() => {
          setCurrentStepId(undefined);
          setCurrentStepRef(undefined);
        }}
      >
        {scenarioQuery.isSuccess &&
        draftSubscriptionQuery.isSuccess &&
        scenarioMediasQuery.isSuccess &&
        scenarioMediasQuery.data ? (
          <Flex justify="space-between">
            <Text
              style={{ width: '60vw', lineHeight: 'inherit' }}
              ellipsis={{ tooltip: scenarioData!.label }}
            >
              {scenarioData!.label}
            </Text>

            <Space>
              <Space>
                {hasCondition ? (
                  <Tooltip
                    placement="bottom"
                    title={t('detail.buttons.switch.disabled')}
                  >
                    <Switch
                      unCheckedChildren={<PartitionOutlined />}
                      checkedChildren={<BarsOutlined />}
                      checked={viewType === 'list'}
                      onChange={() => {
                        if (viewType === 'graph') setViewType('list');
                        else {
                          setViewType('graph');
                        }
                      }}
                      disabled={!!hasCondition}
                    />
                  </Tooltip>
                ) : (
                  <Switch
                    unCheckedChildren={<PartitionOutlined />}
                    checkedChildren={<BarsOutlined />}
                    checked={viewType === 'list'}
                    onChange={() => {
                      if (viewType === 'graph') setViewType('list');
                      else {
                        setViewType('graph');
                      }
                    }}
                    disabled={!!hasCondition}
                  />
                )}
              </Space>
              {bouttonVisible}
              {(hasEditingRights(role) ||
                hasReviewRights(role) ||
                hasApproveRights(role)) &&
              draftSubscriptionQuery.isSuccess &&
              draftSubscriptionQuery.data?.subscribed &&
              scenarioData &&
              scenarioData?.isDraft
                ? validationButton()
                : null}
              <Dropdown
                menu={{ items, onClick: handleMenuClick }}
                placement="bottom"
                arrow
              >
                <Button icon={<MenuOutlined />} />
              </Dropdown>
            </Space>
          </Flex>
        ) : null}
      </Layout.Header>
      <Layout>
        <Layout.Content>
          {scenarioQuery.isSuccess && tasksAndStepIsSuccess ? (
            viewType === 'graph' ? (
              <GraphViewWithProvider<
                ScenarioViewProps,
                Pick<
                  ReactFlowProps,
                  'onNodeDrag' | 'onNodeDragStart' | 'onNodeDragStop'
                >
              >
                viewProps={{
                  nodeTypes,
                  fitViewOptions,
                  proOptions,
                  defaultNodes: [],
                  defaultEdges: [],
                  minZoom: 0.1,
                  defaultViewport: { x: -10, y: 10, zoom: 0.7 },
                  nodesDraggable: false,
                  deleteKeyCode: null,
                  panOnScroll: true,
                  onConnect: ({ source, target }) => onConnect(source, target),
                  onPaneClick: () => {
                    setCurrentStepId(undefined);
                    setCurrentStepRef(undefined);
                  },
                }}
                renderer={useScenarioView}
                rendererProps={{
                  zone,
                  tenant,
                  tasksAndStep,
                  availableSteps,
                  canUpdate,
                  onSelectStep,
                  setActiveTab,
                  selectedStepId,
                  selectedStep: selectedStepRef,
                  scenarioReference: scenarioData!?.reference,
                  loading: tasksAndStepIsLoading,
                  onSelectStepRef: handleChangeStepReference,
                  scenarioZone: scenarioQuery?.data?.zoneId || 0,
                }}
              />
            ) : (
              <TasksList
                scenario={scenarioData!}
                tasksAndStep={tasksAndStep}
                onSelectStep={onSelectStep}
                selectedStep={selectedStepRef}
                loading={tasksAndStepIsLoading}
                canUpdate={canUpdate}
              />
            )
          ) : null}
        </Layout.Content>
        <Layout.Sider
          width={viewType === 'graph' ? '40%' : '70%'}
          style={{ marginRight: '-16px', backgroundColor: 'white' }}
        >
          {scenarioQuery.isSuccess &&
          tasksAndStepIsSuccess &&
          selectedStepRef &&
          selectedStepId ? (
            <div>
              <StepEdition
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                scenarioReference={scenarioQuery.data.reference}
                scenarioZoneId={scenarioQuery.data.zoneId}
                selectedStep={selectedStepRef}
                selectedStepId={selectedStepId}
                availableSteps={availableSteps}
                setSelectedStep={(stepReference) => {
                  handleChangeStepReference(stepReference);
                }}
                canUpdate={
                  hasEditingRights(role) &&
                  scenarioData!.isDraft &&
                  !(scenarioData!.pendingReview || scenarioData!.pendingApprove)
                }
                viewType={viewType}
              />
            </div>
          ) : draftSubscriptionQuery.data && scenarioData ? (
            <ScenarioSider
              scenario={scenarioData!}
              onModify={updateScenario}
              onModifyAsync={updateScenarioAsync}
              isSaving={scenarioLoading.updateScenario}
              canUpdate={canUpdate}
              isSubscribedDraft={draftSubscriptionQuery.data.subscribed}
            />
          ) : null}
        </Layout.Sider>
      </Layout>
      {isModalVisible && scenarioQuery.data && (
        <ScenariosDuplicationModal
          onClose={toggleModal}
          originId={scenarioData!.id}
          originLabel={scenarioData!.label}
        />
      )}

      <ScenarioDeletionModal
        isVisible={isModalDeletionVisible}
        onClose={toggleDeletionModal}
        onSubmit={handleDeletion}
      />
    </Layout>
  );
}
