import React, { useEffect, useState } from 'react';
import { Flex } from 'ui/flex';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { Gear } from 'business/type';
import DigitalTree from 'business/digital-twin/routes/digital-tree/digital-tree';

interface GearAddModalProps {
  open: boolean;
  list: Gear[];
  stepZoneId?: number;
  onClose: () => void;
  onChange: ({
    added,
    removed,
  }: {
    added: number[];
    removed: number[];
  }) => void;
}

export function GearAddModal({
  open,
  list,
  stepZoneId,
  onClose,
  onChange,
}: GearAddModalProps) {
  const { t } = useTranslation('gears');
  const [gearsId, setGearsId] = useState<number[]>([]);

  useEffect(() => {
    if (open) {
      setGearsId(list.map((gear) => gear.id));
    } else {
      setGearsId([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClose = () => {
    const added = gearsId;
    const removed = list.reduce((acc, gear) => {
      if (!gearsId.find((id) => id === gear.id)) {
        acc.push(gear.id);
      }
      return acc;
    }, [] as number[]);

    onChange({ added, removed });
    onClose();
  };

  const handleUpdate = (array: number[]) => {
    setGearsId(array);
  };

  return (
    <Modal
      open={open}
      centered
      // 70% arbitrary value to have the modal not taking all the screen but still be wide enough
      width="70%"
      title={t('modal.add.title')}
      onOk={handleClose}
      okText={t('modal.add.submit')}
      onCancel={handleClose}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <Flex direction="column" gap={32}>
        <DigitalTree
          checkable
          zoneId={stepZoneId}
          alreadyChecked={list}
          onUpdateArray={handleUpdate}
        />
      </Flex>
    </Modal>
  );
}
