import React from 'react';
import { Media } from 'business/type';
import { Document, Page, pdfjs } from 'react-pdf';
import {
  Mp4Logo,
  PdfLogo,
  ThreeDimensionLogo,
  WavLogo,
  FolderLogo,
} from 'ui/images';
import { StandardPreview } from 'ui/images/standard-thumbnails';
import classes from './index.module.scss';

interface MediaThumbnailPreviewProps {
  media?: Media;
  onClick?: () => void;
  type: 'thumbnail' | 'preview' | 'detail' | 'folder';
}

export function MediaThumbnailPreview({
  media,
  onClick,
  type,
}: MediaThumbnailPreviewProps) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

  if (type === 'folder') {
    return (
      <img
        alt=""
        className={classes[type]}
        src={FolderLogo}
        onClick={onClick}
        aria-hidden="true"
      />
    );
  }

  if (!media) {
    return null;
  }

  const format = media.filename.split('.').pop();
  switch (format) {
    case 'mp4':
    case 'avi': {
      if (type === 'detail') {
        return (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <video controls className={classes[type]} onClick={onClick}>
            <source src={media.url} type="video/mp4" />
          </video>
        );
      }
      return (
        <img
          alt={media.label}
          className={classes[type]}
          src={Mp4Logo}
          onClick={onClick}
          aria-hidden="true"
        />
      );
    }
    case 'wav': {
      if (type === 'detail') {
        return (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <audio
            controls
            src={media.url}
            className={classes[type]}
            onClick={onClick}
          />
        );
      }
      return (
        <img
          alt={media.label}
          className={classes[type]}
          src={WavLogo}
          onClick={onClick}
          aria-hidden="true"
        />
      );
    }
    case 'pdf': {
      if (type === 'detail') {
        return (
          <div>
            <Document
              file={{
                url: media.url,
              }}
            >
              <Page pageNumber={1} />
            </Document>
          </div>
        );
      }
      return (
        <img
          alt={media.label}
          className={classes[type]}
          src={PdfLogo}
          /* src={media.url} */
          onClick={onClick}
          aria-hidden="true"
        />
      );
    }
    case 'fbx':
    case 'stl':
    case 'obj': {
      if (media.isStandard) {
        return (
          <img
            className={classes[type]}
            src={StandardPreview[media.label]}
            alt={media.label}
            onClick={onClick}
            aria-hidden="true"
          />
        );
      }
      return (
        <img
          className={classes[type]}
          src={ThreeDimensionLogo}
          alt={media.label}
          onClick={onClick}
          aria-hidden="true"
        />
      );
    }
    default: {
      return (
        <img
          className={classes[type]}
          src={media.url}
          alt={media.label}
          onClick={onClick}
          aria-hidden="true"
        />
      );
    }
  }
}
