import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHandleMedias } from 'business/scenarios/hooks/medias';
import { Input } from 'antd';
import { MixedMedia } from 'business/medias/services/types';
import { isMedia } from 'business/medias/services/folders';
import classes from './media-label-input.module.scss';

interface MediaLabelInputProps {
  ressource: MixedMedia;
}

export default function MediaLabelInput({ ressource }: MediaLabelInputProps) {
  const { t } = useTranslation('medias');
  const { updatePersonalFolder, updatePersonalMedia } = useHandleMedias({});

  function rename(label: string) {
    if (isMedia(ressource))
      updatePersonalMedia({
        id: ressource.id,
        folderId: ressource.folderId!,
        label,
      });
    else {
      updatePersonalFolder({
        id: ressource.id,
        parentId: ressource.parentId!,
        label,
      });
    }
  }

  return !ressource.isStandard ? (
    <Input
      className={classes.customLabelInput}
      defaultValue={ressource.label}
      onPressEnter={(e) => rename(e.currentTarget.value)}
      onBlur={(e) => rename(e.currentTarget.value)}
    />
  ) : (
    ressource.label
  );
}
