import React, { useCallback, useEffect, useState } from 'react';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react';
import { useQuery, useQueryClient, UseQueryResult } from 'react-query';
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate } from 'react-router';
import { ReactQueryDevtools } from 'react-query/devtools';
import useEventListener from '@use-it/event-listener';
import { InteractionStatus } from '@azure/msal-browser';
import {
  Account,
  getCurrentAccount,
  renewSession,
} from 'technical/auth/azure-active-directory';
import { Button } from 'antd';
import { Flex } from 'ui/flex';
import { GlobalLayout } from 'ui/layout';
import { NavigationMenu, UserMenu } from 'ui/menus';
import { Role, validateRole } from 'technical/auth/roles';
import { UserSessionContext } from 'technical/hooks/use-user-session';
import { useLocalStorage } from 'technical/hooks/use-local-storage';
import { SpectralLoader } from 'ui/loader/spectral-loader';
import { getTopLevelZones } from './zones/services/top-level-zones';
import classes from './bootstrap.module.scss';
import { getTenants } from './users/services/tenant';
import { getUser } from './users/services/user-detail';

interface BootstrapProps {
  children: React.ReactNode;
  fallback: React.ReactNode;
}
export function Bootstrap({ children, fallback }: BootstrapProps) {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <>
      <UnauthenticatedTemplate>{fallback}</UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <UserSessionProvider>
          <GlobalLayout
            menu={
              <Flex direction="column" justify="space-between" height="100%">
                <NavigationMenu />
                <UserMenu collapsed={collapsed} />
              </Flex>
            }
            collapsed={collapsed}
            setCollapsed={(value) => setCollapsed(value)}
          >
            {children}
          </GlobalLayout>
        </UserSessionProvider>
      </AuthenticatedTemplate>
      {/* react query devtools are not included in production bundle */}
      <ReactQueryDevtools position="bottom-right" />
    </>
  );
}

interface UserSessionProviderProps {
  children: React.ReactNode;
}
function UserSessionProvider({ children }: UserSessionProviderProps) {
  const { instance, inProgress } = useMsal();
  const [getToken, setGetToken] = useState(false);

  const [firstFocus, setFirstFocus] = useState(false);
  const navigate = useNavigate();

  // session refresh on focus
  useEventListener('focus', async () => {
    if (inProgress === InteractionStatus.None && firstFocus) {
      await renewSession(instance);
      setGetToken(true);
    }
    setFirstFocus(true);
  });

  useEventListener('load', async () => {
    if (inProgress === InteractionStatus.None) {
      await renewSession(instance);
      setGetToken(true);
    }
  });

  useEffect(() => {
    if (inProgress === InteractionStatus.None) setGetToken(true);
  }, [inProgress]);

  let account: Account | undefined;
  try {
    account = getCurrentAccount(instance);
  } catch {
    instance.loginRedirect();
  }
  const claims = account!.idTokenClaims;

  // user informations
  const user = {
    name: claims.name,
    authId: claims.sub,
  };

  const [tenants, setTenants] = useState<string[]>();

  const tenantsQuery = useQuery(
    ['tenants', user.authId],
    () => getTenants(user.authId),
    {
      onSuccess: (data) => {
        setTenants(data);
        if (!currentTenant || currentTenant === '') {
          setCurrentTenant(data[0]);
        }
      },
    },
  );

  // const tenants = claims.extension_tenant.split(",");

  const defaultTenant = tenants ? tenants[0] : '';

  const [currentTenant, setCurrentTenant] = useLocalStorage<string>(
    'current-tenant',
    defaultTenant,
    (value) => {
      if (tenants && !tenants.includes(value as string))
        throw new Error(`tenant ${value} is not valid`);
    },
  );

  const [role, setRole] = useState<Role>(Role.Operator);

  const userTenantQuery = useQuery(
    ['user', currentTenant, user.authId],
    () => getUser(currentTenant, user.authId),
    {
      enabled: getToken,
      onSuccess: (data) => {
        setRole(validateRole(data.roles));
      },
    },
  );

  const queryClient = useQueryClient();
  function switchTenant(newTenant: string) {
    if (tenants && tenants.includes(newTenant)) {
      setCurrentTenant(newTenant);
      // redirect to root page
      navigate('/scenarios');
      queryClient.removeQueries();
    }
  }

  const [zonesByTenant, setZonesByTenant] = useLocalStorage<
    Record<
      string,
      { selected: number; all: Array<{ id: number; label: string }> }
    >
  >('zone-informations', {}, (value) => {
    for (const [key, zones] of Object.entries(
      value as Record<
        string,
        { selected: number; all: Array<{ id: number; label: string }> }
      >,
    )) {
      if (tenants && !tenants.includes(key))
        throw new Error(`tenant ${key} is not valid`);
      if (!zones.all.find((z) => z.id === zones.selected))
        throw new Error(`zone with id ${zones.selected} is not valid`);
    }
  });

  function changeZone(newZoneId: number) {
    const zones = zonesByTenant[currentTenant];
    if (zones.all.find((zone) => zone.id === newZoneId)) {
      setZonesByTenant({
        ...zonesByTenant,
        [currentTenant]: { ...zones, selected: newZoneId },
      });
      // redirect to root page
      navigate('/scenarios');
    }
  }

  const query = useQuery(
    ['top-level-zones', currentTenant],
    async () => {
      if (currentTenant) return getTopLevelZones(currentTenant);
      return null;
    },
    {
      enabled: !!currentTenant && getToken,
      placeholderData: zonesByTenant[currentTenant]?.all,
      onSuccess: (data) => {
        if (data) {
          setZonesByTenant((zones) => {
            const currentZones = zones[currentTenant];
            return {
              ...zones,
              [currentTenant]: {
                selected: currentZones?.all.find(
                  (zone) => zone.id === currentZones.selected,
                )
                  ? currentZones.selected
                  : data[0].id,
                all: data,
              },
            };
          });
        }
      },
    },
  );

  const { status, error } = deriveBootstrapStatus(
    inProgress,
    query,
    tenantsQuery,
    userTenantQuery,
  );

  const logout = useCallback(() => {
    instance.logout();
  }, [instance]);

  const { t } = useTranslation('common');

  return (
    <UserSessionContext.Provider
      value={{
        ...user,
        role,
        tenant: currentTenant,
        availableTenants: tenants ?? [],
        switchTenant,
        zone: zonesByTenant[currentTenant]?.selected,
        availableZones: zonesByTenant[currentTenant]?.all,
        changeZone,
        logout,
      }}
    >
      <SpectralLoader status={status}>
        <Trans
          t={t}
          i18nKey="global-loader.welcoming-message"
          values={{ name: user.name }}
          components={{
            container: <div />,
            highlight: <span className={classes.highlight} />,
          }}
        />
        {status === 'error' ? (
          <>
            <span className={classes.error}>
              {t(`global-loader.errors.${error}`)}
            </span>
            <Button size="large" onClick={logout}>
              {t('user.logout')}
            </Button>
          </>
        ) : (
          t('global-loader.loading')
        )}
      </SpectralLoader>
      {status === 'ready' ? children : null}
    </UserSessionContext.Provider>
  );
}

type Await<T> = T extends PromiseLike<infer R> ? R : T;

function deriveBootstrapStatus(
  authStatus: InteractionStatus,
  query: UseQueryResult<Await<ReturnType<typeof getTopLevelZones>> | null>,
  tenantQuery: UseQueryResult<Await<ReturnType<typeof getTenants>> | null>,
  userTenantQuery: UseQueryResult<Await<ReturnType<typeof getUser>> | null>,
): {
  status: 'ready' | 'error' | 'loading';
  error?: 'generic' | 'no-site' | 'no-tenant';
} {
  if (tenantQuery.status === 'success' && tenantQuery.data?.length === 0) {
    return { status: 'error', error: 'no-tenant' };
  }
  if (tenantQuery.status === 'error') {
    return { status: 'error', error: 'generic' };
  }
  if (query.status === 'success' && query.data?.length === 0) {
    return { status: 'error', error: 'no-site' };
  }
  if (query.status === 'error') {
    return { status: 'error', error: 'generic' };
  }
  if (
    authStatus === InteractionStatus.None &&
    query.status === 'success' &&
    !query.isPlaceholderData
  ) {
    return { status: 'ready' };
  }
  return { status: 'loading' };
}
