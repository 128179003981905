import { Gear } from 'business/type';
import { api } from 'technical/api';
import { GearInputCreate, GearInputDuplicate, GearInputUpdate } from './type';

export async function getGearsFromZone(tenant: string, zone: number) {
  return api(tenant)
    .get<Gear[]>(`/v1/gears/zones/${zone}`)
    .then((response) => response.data);
}

export async function getGearsFromScenario(
  tenant: string,
  scenarioRef: number,
) {
  return api(tenant)
    .get<Gear[]>(`/v1/gears/scenarios/${scenarioRef}`)
    .then((response) => response.data);
}

export async function getGear(tenant: string, gear: number) {
  return api(tenant)
    .get<Gear>(`/v1/gears/${gear}`)
    .then((response) => response.data);
}

export async function getChildsGear(tenant: string, gear: number) {
  return api(tenant)
    .get<Gear[]>(`/v1/gears/childs/${gear}`)
    .then((response) => response.data);
}

export async function deleteGear(tenant: string, gear: number) {
  return api(tenant).delete<void>(`/v1/gears/${gear}`);
}

export async function deleteGearFromStep(
  tenant: string,
  gear: number,
  stepId: number,
) {
  return api(tenant).delete<void>(`/v1/gears/steps/${gear}/${stepId}`);
}

export async function createGear(tenant: string, input: GearInputCreate) {
  const { data: zone } = await api(tenant).post<Pick<Gear, 'id'>>(
    '/v1/gears',
    input,
  );
  return zone;
}

export async function duplicateGear(tenant: string, input: GearInputDuplicate) {
  const { zoneId, childs, tags, medias } = input;
  const { data: zone } = await api(tenant).post<Pick<Gear, 'id'>>(
    `/v1/gears/${input.id}`,
    { zoneId, childs, tags, medias },
  );
  return zone;
}

export async function updateGear(tenant: string, input: GearInputUpdate) {
  await api(tenant).patch<void>(`/v1/gears/${input.id}`, {
    ...input,
  });
}

export async function getScenarioGear(tenant: string, scenarioRef: number) {
  const gears = await api(tenant).get<Gear[]>(
    `/v1/gears/scenarios/${scenarioRef}`,
  );
  return gears.data;
}
