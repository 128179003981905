import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import 'translations';
import {
  azureAuthClient,
  AzureAuthProvider,
} from 'technical/auth/azure-active-directory';
import { queryClient } from 'technical/react-query';
import { AuthenticatedRoutes, UnauthenticatedRoutes } from './routes';
import { Bootstrap } from './bootstrap';

const router = createBrowserRouter([{ path: '*', Component: Root }]);

function Root() {
  return (
    <AzureAuthProvider client={azureAuthClient}>
      <QueryClientProvider client={queryClient}>
        <Bootstrap fallback={<UnauthenticatedRoutes />}>
          <AuthenticatedRoutes />
        </Bootstrap>
      </QueryClientProvider>
    </AzureAuthProvider>
  );
}

export default function App() {
  return <RouterProvider router={router} />;
}
