import React from "react";
import { Popover } from "antd";
import { useTranslation } from "react-i18next";
import classes from "./index.module.scss";

export function JumpStepHelperTooltip({
  children,
}: {
  children: React.ReactNode;
}) {
  const { t } = useTranslation("scenarios");
  return (
    <Popover
      placement="left"
      trigger="hover"
      content={
        <dl className={classes.HelperTooltip}>
          <div>{t(`detail.step-edition.step-help.jump`)}</div>
        </dl>
      }
    >
      {children}
    </Popover>
  );
}

export function EndStepHelperTooltip({
  children,
}: {
  children: React.ReactNode;
}) {
  const { t } = useTranslation("scenarios");
  return (
    <Popover
      placement="left"
      trigger="hover"
      content={
        <dl className={classes.HelperTooltip}>
          <div>{t(`detail.step-edition.step-help.end`)}</div>
        </dl>
      }
    >
      {children}
    </Popover>
  );
}

export function MandatoryGearHelperTooltip({
  children,
}: {
  children: React.ReactNode;
}) {
  const { t } = useTranslation("scenarios");
  return (
    <Popover
      placement="left"
      trigger="hover"
      content={
        <dl className={classes.HelperTooltip}>
          <div>{t(`detail.step-edition.step-help.mandatorygear`)}</div>
        </dl>
      }
    >
      {children}
    </Popover>
  );
}
