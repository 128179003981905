import { Step } from 'business/type';
import { notification } from 'antd';
import {
  createGear,
  deleteGearFromStep,
  getGearsFromScenario,
  getGearsFromZone,
} from 'business/gears/services/gear';
import { useMutation, useQuery } from 'react-query';
import { useUserSession } from 'technical/hooks/use-user-session';
import { queryClient } from 'technical/react-query';
import { getZoneGears } from '../services/step-actions';

export function useHandleGears(
  scenarioZoneId: number,
  stepId: number,
  scenarioRef: number,
) {
  const { tenant, zone } = useUserSession();
  const gearsFromZoneRootKey = ['gears', tenant, { zoneId: scenarioZoneId }];
  const allGearsRootKey = ['all gears', tenant, { zoneId: scenarioZoneId }];
  const stepInformationsKey = ['step', tenant, { stepId }];
  const gearsFromScenarioRootKey = ['gears', tenant, { scenarioRef }];

  const gearsFromZoneQuery = useQuery(gearsFromZoneRootKey, () =>
    getGearsFromZone(tenant, scenarioZoneId),
  );

  const gearsFromScenarioQuery = useQuery(gearsFromScenarioRootKey, () =>
    getGearsFromScenario(tenant, scenarioRef),
  );

  const allGearsQuery = useQuery(allGearsRootKey, () =>
    getZoneGears(tenant, scenarioZoneId),
  );

  const handleDeletionOptimisticUpdate = (gearId: number) => {
    const step = queryClient.getQueryData<Step>(stepInformationsKey);
    if (!step) {
      return;
    }

    queryClient.setQueryData(stepInformationsKey, {
      ...step,
      gearsId: step.gearsId ? step.gearsId.filter((id) => id !== gearId) : [],
    });
  };

  const deleteGearFromStepMutation = useMutation(
    async (gearId: number) => deleteGearFromStep(tenant, gearId, stepId),
    {
      // Optimistic update
      onMutate: (gearId) => handleDeletionOptimisticUpdate(gearId),
      onSuccess: () => {
        queryClient.invalidateQueries(['step', tenant, { stepId }]);
        queryClient.invalidateQueries(['scenarios', tenant, { zoneId: zone }]);
        queryClient.invalidateQueries(gearsFromZoneRootKey);
        queryClient.invalidateQueries(allGearsRootKey);
      },
      onError: (err: Error) => notification.error({ message: err.message }),
    },
  );

  const deleteGearFromStepMutationWithoutInvalidation = useMutation(
    async (gearId: number) => deleteGearFromStep(tenant, gearId, stepId),
    {
      // Optimistic update
      onMutate: (gearId) => handleDeletionOptimisticUpdate(gearId),
      onError: (err: Error) => notification.error({ message: err.message }),
    },
  );

  const createGearMutation = useMutation(
    async (input: { label: string; parentId?: number }) =>
      createGear(tenant, {
        label: input.label,
        zoneId: scenarioZoneId,
        parentId: input.parentId,
      }),
    {
      onSuccess: () => queryClient.invalidateQueries(gearsFromZoneRootKey),
      onError: (err: Error) => notification.error({ message: err.message }),
    },
  );

  return {
    queries: {
      gearsFromZone: gearsFromZoneQuery,
      allGears: allGearsQuery,
      gearsFromScenario: gearsFromScenarioQuery,
    },
    keys: {
      gearsFromZone: gearsFromZoneRootKey,
      allGears: allGearsRootKey,
      gearsFromScenario: gearsFromScenarioRootKey,
    },
    isLoading:
      gearsFromZoneQuery.isLoading ||
      allGearsQuery.isLoading ||
      deleteGearFromStepMutation.isLoading ||
      gearsFromScenarioQuery.isLoading,
    createGear: createGearMutation.mutate,
    createGearAsync: createGearMutation.mutateAsync,
    deleteGearFromStep: deleteGearFromStepMutation.mutate,
    deleteGearFromStepWithoutInvalidation:
      deleteGearFromStepMutationWithoutInvalidation.mutate,
  };
}
