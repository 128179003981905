/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Card, Tabs, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { Media, Step } from 'business/type';
import { StepGearElement } from 'business/scenarios/services/type';
import { useHandleAnswers } from 'business/scenarios/hooks/answers';
import { useHandleMedias } from 'business/scenarios/hooks/medias';
import { useHandleSteps } from 'business/scenarios/hooks/step';
import { useUserSession } from 'technical/hooks/use-user-session';
import { queryClient } from 'technical/react-query';
import { itemsReordering } from 'technical/utils/object';
import { Field } from 'ui/forms';
import { Flex } from 'ui/flex';
import { MediasAddModal } from 'ui/modals';
import StepEditionInfos from './step-infos/step-edition-infos';
import StepEditionInputRequest from './step-input-request/step-edition-input-request';
import StepEditionLogic from './step-logic/step-edition-logic';
import { useHandleInputRequest } from '../../../hooks/input-requests';

type ViewType = 'graph' | 'list';

interface StepEditionProps {
  scenarioReference: number;
  scenarioZoneId: number;
  selectedStep: number;
  selectedStepId: number;
  setSelectedStep: (reference: number) => void;
  setActiveTab: (tab: string) => void;
  availableSteps?: Step[];
  canUpdate: boolean;
  viewType: ViewType;
  activeTab: string;
}

export function StepEdition({
  scenarioReference,
  scenarioZoneId,
  selectedStep,
  selectedStepId,
  setSelectedStep,
  availableSteps,
  canUpdate,
  viewType,
  setActiveTab,
  activeTab = 'infosTab',
}: StepEditionProps) {
  const { t } = useTranslation(['scenarios', 'common']);
  const location = useLocation();
  const navigate = useNavigate();
  const { tenant } = useUserSession();
  const [isModalVisible, setModalIsVisible] = useState(false);
  const [isMediaLoading, setIsMediaLoading] = useState(false);

  const invalidationKey = [
    'scenarioTasks',
    tenant,
    { reference: scenarioReference },
  ];

  // Steps management
  const {
    data: stepData,
    updateStep,
    updateStepAsync,
  } = useHandleSteps({
    scenarioReference,
    stepId: selectedStepId,
    zone: scenarioZoneId,
  });

  // Medias management
  const { queries, uploadStepMedia, deleteStepMedia, deleteStepMediaAsync } =
    useHandleMedias({
      selectedStepId,
    });

  // Answers management
  const { data: answerList } = useHandleAnswers(
    selectedStep,
    selectedStepId,
    invalidationKey,
  );

  // Input requests management
  const {
    data: inputRequestList,
    createInputRequest,
    updateInputRequest,
    deleteInputRequest,
  } = useHandleInputRequest(selectedStep, selectedStepId, invalidationKey);

  const newMediasOrder = itemsReordering(
    queries.stepMedias.data,
    stepData?.mediasOrder,
  );

  useEffect(() => {
    if (stepData && stepData?.gearsOrder?.length === 0) {
      stepData.gearsOrder = stepData?.gearsId;
    }
  }, [stepData]);

  const form = useFormik({
    initialValues: {
      name: stepData?.label,
      description: stepData?.description,
      gearlist:
        stepData?.gearsOrder?.map<StepGearElement>((gearId) => ({
          value: gearId,
        })) ?? [],
      answers: answerList,
      inputRequests: inputRequestList,
      isMandatory: stepData?.mandatoryValidation,
      medias: newMediasOrder ?? [],    },
    enableReinitialize: true,
    onSubmit: () => {},
  });
  const gearsOrder = form.values.gearlist.map((gear) => gear.value);
  const mediasOrder = form.values.medias.map((media) => media.id);

  const handleClose = () => {
    setModalIsVisible(false);
    queryClient.invalidateQueries(['medias', tenant, { step: selectedStepId }]);
    navigate(location.pathname);
  };

  const handleSelectStepMedia = async (media: Media) => {
    setIsMediaLoading(true);
    await updateStepAsync({
      stepRef: selectedStep,
      mediasId: [media.id],
      gearsOrder,
      mediasOrder,
    });
    setIsMediaLoading(false);
  };

  const handleUnselectStepMedia = async (media: Media) => {
    const mediasWithSameName = form.values.medias.filter(
      (scenarioMedia) => scenarioMedia.filename === media.filename,
    );

    if (mediasWithSameName.length > 0) {
      setIsMediaLoading(true);
      await deleteStepMediaAsync(mediasWithSameName[0]);
      setIsMediaLoading(false);
    }
  };

  return (
    <Card bordered style={{ height: '90vh', overflow: 'auto' }}>
      <form id="step-edition-form" onSubmit={form.handleSubmit}>
        <Flex direction="column" gap={12}>
          <Tabs
            activeKey={activeTab}
            defaultActiveKey="infosTab"
            onTabClick={(tabKey) => setActiveTab(tabKey)}
          >
            <Tabs.TabPane
              tab={t('detail.step-edition.tabs.infos')}
              key="infosTab"
            >
              <StepEditionInfos
                canUpdate={canUpdate}
                form={form}
                scenarioReference={scenarioReference}
                scenarioZoneId={scenarioZoneId}
                selectedStepId={selectedStepId}
                selectedStep={selectedStep}
                isMediaLoading={isMediaLoading}
                setModalIsVisible={setModalIsVisible}
              />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={t('detail.step-edition.tabs.input-request')}
              key="inputTab"
            >
              <Field
                name="inputRequests"
                label={t('detail.step-edition.fields.input-requests')}
              >
                <StepEditionInputRequest
                  value={form.values.inputRequests}
                  selectedStep={selectedStep}
                  canUpdate={canUpdate}
                  onChange={(value) =>
                    form.setFieldValue('inputRequests', value)
                  }
                  onCreate={createInputRequest}
                  onUpdate={updateInputRequest}
                  onDelete={deleteInputRequest}
                />
              </Field>
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                viewType === 'list' ? (
                  <Tooltip
                    placement="top"
                    title={t('detail.step-edition.tabs.disabled')}
                  >
                    {t('detail.step-edition.tabs.logic')}
                  </Tooltip>
                ) : (
                  t('detail.step-edition.tabs.logic')
                )
              }
              key="logicTab"
              disabled={viewType === 'list'}
            >
              <StepEditionLogic
                canUpdate={canUpdate}
                form={form}
                scenarioReference={scenarioReference}
                scenarioZoneId={scenarioZoneId}
                selectedStepId={selectedStepId}
                selectedStep={selectedStep}
                availableSteps={availableSteps}
                setSelectedStep={setSelectedStep}
              />
            </Tabs.TabPane>
          </Tabs>
        </Flex>
      </form>
      {isModalVisible && (
        <MediasAddModal
          mediasRessource={form.values.medias}
          selectionIsLoading={isMediaLoading}
          onClose={handleClose}
          onUpload={uploadStepMedia}
          onDeleteMedia={deleteStepMedia}
          onSelect={(media) => handleSelectStepMedia(media)}
          onUnselect={(media) => handleUnselectStepMedia(media)}
        />
      )}
    </Card>
  );
}
