import React from 'react';
import { Intervention } from 'business/type';
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { useUserSession } from 'technical/hooks/use-user-session';
import {
  ClockCircleOutlined,
  ReadOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useQuery } from 'react-query';
import { getUserList } from 'business/users/services/users-list';

interface InterventionsHeaderProps {
  intervention?: Intervention;
}

export default function InterventionHeader({
  intervention,
}: InterventionsHeaderProps) {
  const { t } = useTranslation('interventions');
  const { tenant } = useUserSession();

  const userListQuery = useQuery(['users', tenant], () => getUserList(tenant));

  const interventionUser = userListQuery.data?.find(
    (user) => user.id === intervention?.session.userId,
  );

  return (
    <Card title={intervention?.scenario.label}>
      <ClockCircleOutlined /> {t('detail.create.when')}{' '}
      {new Date(intervention?.startDate ?? '').toLocaleString()}{' '}
      {intervention?.duration
        ? t('detail.create.long') +
          new Date(intervention.duration).toISOString().slice(11, -5)
        : t('detail.create.unfinished')}
      <p>
        <UserOutlined /> {t('detail.create.who')} {interventionUser?.firstname}{' '}
        {interventionUser?.surname}
      </p>
      <ReadOutlined /> {t('detail.create.version')}{' '}
      {intervention?.scenario.version}
    </Card>
  );
}
