import * as fr from './fr';
import * as es from './es';
import * as en from './en';
import * as it from './it';
import * as cn from './cn';
import * as pl from './pl';
import * as th from './th';
import * as hu from './hu';
import * as nl from './nl';

export const resources = { fr, en, es, it, cn, pl, th, hu, nl } as const;
