import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from 'ui/layout';
import { useHandleMedias } from 'business/scenarios/hooks/medias';
import Medias from './medias';

export default function MediasPage() {
  const { t } = useTranslation('medias');
  const { uploadPersonalMedia, deletePersonalMedia } = useHandleMedias({});

  return (
    <PageLayout title={t('list.title')}>
      <Medias
        isModal={false}
        onUpload={uploadPersonalMedia}
        onDeleteMedia={(media, folderId) =>
          deletePersonalMedia({ mediaId: media.id, folderId })
        }
      />
    </PageLayout>
  );
}
