import { Folder, Media } from 'business/type';
import { api } from 'technical/api';
import {
  CreateFolderInput,
  UpdateFolderInput,
  UpdateMediaInput,
} from './types';

export async function getMedias(tenant: string) {
  const medias = api(tenant)
    .get<Media[]>(`/v1/medias/origins`)
    .then((response) => response.data);

  return medias;
}

export async function getMediasTopZone(
  tenant: string,
  zoneId: number,
  search?: string,
) {
  const medias = api(tenant)
    .get<Media[]>(
      `/v1/medias/origins/zones/${zoneId}${search ? `?search=${search}` : ''}`,
    )
    .then((response) => response.data);

  return medias;
}

export async function getFoldersStandard(tenant: string) {
  const folders = api(tenant)
    .get<Folder[]>('/v1/folders/standard')
    .then((response) => response.data);

  return folders;
}

export async function deleteMedia(tenant: string, id: number) {
  const media = api(tenant).delete(`/v1/medias/${id}`);

  return media;
}

export async function getMediaFromGear(tenant: string, gearId: number) {
  const medias = api(tenant)
    .get<Media[]>(`/v1/medias/gears/${gearId}`)
    .then((response) => response.data);

  return medias;
}

export async function getMediaFromComment(tenant: string, commentId: number) {
  const medias = api(tenant)
    .get<Media[]>(`/v1/medias/comments/${commentId}`)
    .then((response) => response.data);

  return medias;
}

export async function getMediasChild(tenant: string, mediaId: number) {
  const medias = api(tenant)
    .get<Media[]>(`/v1/medias/origins/${mediaId}`)
    .then((response) => response.data);

  return medias;
}

export async function getMediaFromZone(tenant: string, zoneId: number) {
  const medias = api(tenant)
    .get<Media[]>(`/v1/medias/zones/${zoneId}`)
    .then((response) => response.data);

  return medias;
}

export async function getMediaFromAlert(tenant: string, alertId: number) {
  const medias = api(tenant)
    .get<Media[]>(`/v1/medias/alerts/${alertId}`)
    .then((response) => response.data);

  return medias;
}

export async function updateMedia(tenant: string, data: UpdateMediaInput) {
  const { id, ...rest } = data;
  const media = api(tenant)
    .patch<Media>(`/v1/medias/${id}`, rest)
    .then((response) => response.data);

  return media;
}

export async function copyMedia(
  tenant: string,
  mediaId: number,
  folderId?: number,
) {
  const media = api(tenant)
    .post<Media>(`/v1/medias/${mediaId}`, folderId ? { folderId } : undefined)
    .then((response) => response.data);

  return media;
}

export async function getFoldersTree(zoneId: number, tenant: string) {
  const foldersTree = api(tenant)
    .get<Folder[]>(`/v1/folders/trees/${zoneId}`)
    .then((response) => response.data);

  return foldersTree;
}

export async function getFoldersTopZone(tenant: string, zoneId: number) {
  const folders = api(tenant)
    .get<Folder[]>(`/v1/folders/zones/${zoneId}`)
    .then((response) => response.data);

  return folders;
}

export async function getFolderContent(tenant: string, folderId: number) {
  const childs = api(tenant)
    .get<Folder>(`/v1/folders/${folderId}`)
    .then((response) => response.data);

  return childs;
}

export async function createFolder(
  tenant: string,
  createFolderInput: CreateFolderInput,
) {
  const { zoneId, ...rest } = createFolderInput;
  const folder = api(tenant)
    .post<Folder>(
      '/v1/folders',
      createFolderInput.parentId ? rest : createFolderInput,
    )
    .then((response) => response.data);

  return folder;
}

export async function updateFolder(tenant: string, data: UpdateFolderInput) {
  const { id, ...rest } = data;
  const folder = api(tenant)
    .patch<Folder>(`/v1/folders/${id}`, rest)
    .then((response) => response.data);

  return folder;
}

export async function deleteFolder(tenant: string, id: number) {
  const media = api(tenant).delete(`/v1/folders/${id}`);
  return media;
}

export async function copyFolder(
  tenant: string,
  folderId: number,
  parentId?: number,
) {
  const media = api(tenant)
    .post<Folder>(
      `/v1/folders/${folderId}`,
      parentId && parentId !== -1 ? { parentId } : undefined,
    )
    .then((response) => response.data);

  return media;
}
