import { InboxOutlined, LoadingOutlined } from '@ant-design/icons';
import { Modal, Checkbox, Upload } from 'antd';
import { useHandleMedias } from 'business/scenarios/hooks/medias';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './media-replace.module.scss';

interface MediaReplaceModalProps {
  mediaId?: number;
  folderId?: number;
  isVisible: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

type Value = {
  id: number; // Media id
  ref: number; // Scenario or step ref
  label: string; // Scenario or step label
};

export function MediaReplaceModal({
  mediaId,
  folderId,
  isVisible,
  onSubmit,
  onClose,
}: MediaReplaceModalProps) {
  const { t } = useTranslation('medias');
  const { queries, uploadMediaAsync, deleteMediaAsync } = useHandleMedias({
    selectedMediaId: mediaId,
    personalFolderId: folderId,
  });
  const [step, setStep] = useState<'selection' | 'upload'>('selection');
  const [stepsRef, setStepsRef] = useState<number[]>([]);
  const [scenariosRef, setScenariosRef] = useState<number[]>([]);
  const [deletionMediaIds, setDeletionMediaIds] = useState<number[]>([]);

  const originMedias = queries.mediasChild?.data || [];
  const values = originMedias.reduce(
    (acc: { step: Value[]; scenario: Value[] }, media) => {
      const { id, stepLabel, stepRef, scenarioLabel, scenarioRef } = media;
      if (stepLabel && stepRef && scenarioLabel && scenarioRef) {
        return {
          ...acc,
          step: [...acc.step, { id, ref: stepRef, label: stepLabel }],
          scenario: [
            ...acc.scenario,
            { id, ref: scenarioRef, label: scenarioLabel },
          ],
        };
      }

      if (stepLabel && stepRef) {
        return {
          ...acc,
          step: [...acc.step, { id, ref: stepRef, label: stepLabel }],
        };
      }

      if (scenarioLabel && scenarioRef) {
        return {
          ...acc,
          scenario: [
            ...acc.scenario,
            { id, ref: scenarioRef, label: scenarioLabel },
          ],
        };
      }

      return acc;
    },
    { step: [], scenario: [] },
  );

  const handleClose = () => {
    onClose();
    setStepsRef([]);
    setScenariosRef([]);
    setStep('selection');
  };

  const handleCheck = (
    type: 'step' | 'scenario',
    typeRef: number,
    id: number,
  ) => {
    if (type === 'step') {
      const isAlreadyChecked = stepsRef.includes(typeRef);
      if (isAlreadyChecked) {
        setStepsRef(stepsRef.filter((stepRef) => stepRef !== typeRef));
        setDeletionMediaIds(deletionMediaIds.filter((i) => i !== id));
        return;
      }
      setStepsRef([...stepsRef, typeRef]);
      setDeletionMediaIds([...deletionMediaIds, id]);
      return;
    }

    const isAlreadyChecked = scenariosRef.includes(typeRef);
    if (isAlreadyChecked) {
      setScenariosRef(
        scenariosRef.filter((scenarioRef) => scenarioRef !== typeRef),
      );
      setDeletionMediaIds(deletionMediaIds.filter((i) => i !== id));
      return;
    }
    setScenariosRef([...scenariosRef, typeRef]);
    setDeletionMediaIds([...deletionMediaIds, id]);
  };

  const handleUpload = async (options: any) => {
    await uploadMediaAsync({ ...options, stepsRef, scenariosRef, folderId });
    await handleDelete();
    onSubmit();
  };

  const handleDelete = async () => {
    await Promise.allSettled(
      deletionMediaIds.map((id) => deleteMediaAsync(id)),
    );
  };

  if (!mediaId) {
    return null;
  }

  const isDisabled = stepsRef.length <= 0 && scenariosRef.length <= 0;
  return (
    <Modal
      title={t('replacement.modal.title')}
      cancelText={t('replacement.modal.cancel')}
      okButtonProps={{
        disabled: isDisabled,
        style: { display: step === 'upload' ? 'none' : 'initial' },
      }}
      okText={
        step === 'selection'
          ? t('replacement.modal.next')
          : t('replacement.modal.submit')
      }
      open={isVisible}
      okType="primary"
      onOk={() => (step === 'selection' ? setStep('upload') : onSubmit())}
      onCancel={handleClose}
    >
      {queries.mediasChild.isLoading ? (
        <LoadingOutlined className={classes.loader} />
      ) : null}
      {step === 'selection' && !queries.mediasChild.isLoading ? (
        <>
          <p>{t('replacement.modal.message')}</p>
          {values.step.length > 0 ? (
            <>
              <p className={classes.title}>{t('replacement.modal.steps')}</p>
              {values.step.map(({ id, ref, label }) => (
                <div key={ref} className={classes.checkbox}>
                  <Checkbox
                    checked={stepsRef.includes(ref)}
                    onChange={() => handleCheck('step', ref, id)}
                  >
                    {label}
                  </Checkbox>
                </div>
              ))}
            </>
          ) : null}
          {values.scenario.length > 0 ? (
            <>
              <p className={clsx(classes.title, classes.space)}>
                {t('replacement.modal.scenarios')}
              </p>
              {values.scenario.map(({ id, ref, label }) => (
                <div key={ref} className={classes.checkbox}>
                  <Checkbox
                    checked={scenariosRef.includes(ref)}
                    onChange={() => handleCheck('scenario', ref, id)}
                  >
                    {label}
                  </Checkbox>
                </div>
              ))}
            </>
          ) : null}
        </>
      ) : null}
      {step === 'upload' && !queries.mediasChild.isLoading ? (
        <>
          <Upload.Dragger
            multiple
            accept=".pdf,.mp4,.avi,.stl,.obj,.fbx,.wav,.png,.jpeg,.jpg"
            customRequest={(options) => handleUpload(options)}
          >
            <InboxOutlined />
            <p>{t('replacement.modal.upload')}</p>
          </Upload.Dragger>
        </>
      ) : null}
    </Modal>
  );
}
