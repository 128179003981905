import ELK, { ElkNode } from 'elkjs/lib/elk.bundled';
import { Node, Edge, Instance } from 'reactflow';

import {
  STEP_HEIGHT,
  STEP_WIDTH,
  TASK_MIN_HEIGHT,
  TASK_MIN_WIDTH,
} from './constants';

const elk = new ELK();

/**
 * We use Elksjs to safely create a graph layout,
 * this prevent us from having to write a layouting algorithm from scratch (and the downside of having to maintain more code)
 *
 * The layout is creaged in two steps:
 *  1- we pass our nodes & edges from reactflow to elkjs to create a hierarchy for elk algorithms, allowing the library to calculte the layout (nodes width, height, x & y positions, edges connections...)
 *  2- then we use the response from elkjs to reassign ou nodes and edges to reactflow with the new positionning and width/height attributes
 *
 * For more informations: https://www.eclipse.org/elk/reference/options.html
 */

const elkGenericOptions = {
  'elk.algorithm': 'mrtree',
  'elk.spacing.nodeNode': '100',
};

const elkTaskOptions = {
  'elk.algorithm': 'layered',
  'elk.direction': 'DOWN',
  'elk.spacing.edgeNode': '30',
  'elk.spacing.edgeEdge': '1',
  'eclipse.elk.edgeRouting': 'ORTHOGONAL',
  'elk.layered.spacing.nodeNodeBetweenLayers': '60',
  'elk.portConstraints': 'FREE',
};

function getLayoutedElements(
  nodes: Node[],
  edges: any,
  options = elkGenericOptions,
) {
  const graph = {
    id: 'root',
    layoutOptions: options,
    children: nodes
      .map((node) =>
        node.data.type === 'Task'
          ? {
              ...node,
              width: Number(node.style?.width) || TASK_MIN_WIDTH,
              height: Number(node.style?.height) || TASK_MIN_HEIGHT,
              /*               sourcePosition: 'bottom',
              targetPosition: 'top', */

              layoutOptions: {
                ...elkTaskOptions,
              },
              children: nodes
                .filter((nd) => nd.parentNode === node.id)
                .map((n) => ({
                  ...n,
                  sourcePosition: 'bottom',
                  targetPosition: 'top',
                  width: STEP_WIDTH,
                  height: STEP_HEIGHT,
                })),
            }
          : undefined,
      )
      .filter((e) => typeof e !== 'undefined'),
    edges,
  };

  return (
    elk
      .layout(graph as ElkNode)
      .then((layoutedGraph) => {
        const ndes: Node[] =
          layoutedGraph?.children?.reduce(
            (result: Node[], currentNode: any) => {
              result.push({
                id: currentNode.id,
                height: currentNode.height,
                width: currentNode.width,
                hidden: currentNode.hidden,
                type: currentNode.type,
                selected: currentNode.selected,
                position: {
                  x: currentNode?.x || currentNode.data.startPosition.x,
                  y: currentNode?.y || currentNode.data.startPosition.y,
                },
                style: {
                  height: currentNode.height + 25, // Ensure there is enough space at the bottom of the section node for the user to hover and click safely on the CTAs
                  width: currentNode.width,
                },
                data: {
                  ...currentNode.data,
                  startPosition: {
                    position: {
                      x: currentNode?.x || currentNode.data.startPosition.x,
                      y: currentNode?.y || currentNode.data.startPosition.y,
                    },
                  },
                },
              });

              if (currentNode?.children) {
                currentNode?.children?.forEach((child: any) =>
                  result.push({
                    id: child.id,
                    height: child.height,
                    width: child.width,
                    hidden: child.hidden,
                    parentNode: child.parentNode,
                    type: child.type,
                    selected: child.selected,
                    position: {
                      x: child?.x || child?.data.startPosition.x,
                      y: child?.y || child?.data.startPosition.y,
                    },
                    style: { height: child.height, width: child.width },
                    data: {
                      ...child.data,
                      next:
                        edges
                          .filter((ed: any) => ed.source === child.id)
                          ?.map((e: any) => e.target) || [],
                      startPosition: {
                        x: child?.x || child?.data.startPosition.x,
                        y: child?.y || child?.data.startPosition.y,
                      },
                    },
                  }),
                );
              }

              return result;
            },
            [],
          ) || [];

        return {
          nodes: ndes,
          edges: layoutedGraph.edges,
        };
      })
      // ? Log errors in a logger like sentry/datadog... ?
      // eslint-disable-next-line no-console
      .catch(console.error)
  );
}

type ElkLayoutProps = {
  nodes: Node[];
  edges: Edge[];
  direction: string;
  useInitialNodes: boolean;
  setNodes: Instance.SetNodes<any>;
  setEdges: Instance.SetEdges<any>;
};

export default function createElkLayout({
  nodes,
  edges,
  useInitialNodes = false,
  setNodes,
  setEdges,
}: ElkLayoutProps) {
  const layoutObj: { nds: Node[]; edgs: Edge[] } = { nds: [], edgs: [] };
  const opts = {
    useInitialNodes,
    ...elkGenericOptions,
  };
  const ns = nodes;
  const es = edges;

  getLayoutedElements(ns, es, opts).then((data) => {
    setNodes((data?.nodes as Node[]) || []);
    setEdges((data?.edges as unknown as Edge[]) || []);

    layoutObj.nds = data?.nodes || [];
    layoutObj.edgs = (data?.edges as unknown as Edge[]) || [];
  });
}
