import { InputRequestInputUpdate } from 'business/scenarios/services/type';
import { notification } from 'antd';
import {
  deleteInputRequest,
  getInputRequestsFromStep,
  updateInputRequest,
} from 'business/scenarios/services/scenario-detail';
import { createInputRequest } from 'business/scenarios/services/step-edition';
import { useQuery, useMutation, QueryKey } from 'react-query';
import { queryClient } from 'technical/react-query';
import { InputRequest } from 'business/type';
import { useUserSession } from 'technical/hooks/use-user-session';

export function useHandleInputRequest(
  selectedStep: number,
  selectedStepId: number,
  invalidationKey: QueryKey,
) {
  const { tenant } = useUserSession();
  const rootKey = ['inputRequests', tenant, selectedStepId];
  const inputRequestsQuery = useQuery(rootKey, () =>
    getInputRequestsFromStep(tenant, selectedStepId),
  );

  const invalidateQueries = () => {
    queryClient.invalidateQueries(rootKey);
    queryClient.invalidateQueries(invalidationKey);
  };

  const createInputRequestMutation = useMutation(
    async (label: string) => createInputRequest(tenant, label, selectedStep),
    {
      // Optimistic update
      onMutate: async (label: string) => {
        queryClient.setQueryData(rootKey, (current?: InputRequest[]) => [
          ...(current || []),
          {
            id: -1,
            reference: -1,
            label,
            stepRef: selectedStep,
            stepId: selectedStepId,
            deleted: false,
            isDraft: false,
            isLast: true,
          },
        ]);
      },
      onSuccess: async () => invalidateQueries(),
      onError: (err: Error) => notification.error({ message: err.message }),
    },
  );

  const updateInputRequestMutation = useMutation(
    async (inputRequest: InputRequestInputUpdate) =>
      updateInputRequest(tenant, inputRequest),
    {
      onSuccess: async () => invalidateQueries(),
      onError: (err: Error) => notification.error({ message: err.message }),
    },
  );

  const deleteInputRequestMutation = useMutation(
    async (inputRequestRef: number) =>
      deleteInputRequest(tenant, inputRequestRef),
    {
      // Optimistic update
      onMutate: async (inputRequestRef: number) => {
        const filtered = (inputRequestsQuery.data || []).filter(
          (inputRequest) => inputRequest.reference !== inputRequestRef,
        );
        queryClient.setQueryData(rootKey, () => filtered);
      },
      onSuccess: async () => invalidateQueries(),
      onError: (err: Error) => notification.error({ message: err.message }),
    },
  );

  return {
    data: (inputRequestsQuery.data || []).sort((a, b) => {
      if (a.id === -1) return 1;
      if (b.id === -1) return -1;
      return a.id - b.id;
    }),
    createInputRequest: createInputRequestMutation.mutate,
    updateInputRequest: updateInputRequestMutation.mutate,
    deleteInputRequest: deleteInputRequestMutation.mutate,
  };
}
