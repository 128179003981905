import { Media } from 'business/type';

export const itemsReordering = (
  items: Media[] | undefined,
  idsOrder: number[] | undefined,
) => {
  if (!items) return [];

  if (!idsOrder || items.length !== idsOrder.length) {
    return items;
  }

  const newItemsReordering: Media[] = [];
  for (const id of idsOrder) {
    const item = items.filter((element) => element.id === id)[0];
    if (item) {
      newItemsReordering.push(item);
    }
  }

  return newItemsReordering;
};
