import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Spin } from 'antd';
import { Gear, Media } from 'business/type';
import { Flex } from 'ui/flex';
import { useUserSession } from 'technical/hooks/use-user-session';
import { useQuery } from 'react-query';
import { getMediaFromGear } from 'business/medias/services/medias';
import { MediasGalery } from 'ui/media';
import { hasEditingRights } from 'technical/auth/authorization';
import { PlusOutlined } from '@ant-design/icons';
import { MediasAddModal } from 'ui/modals';
import { useHandleMedias } from 'business/scenarios/hooks/medias';
import { useHandleGears } from 'business/digital-twin/hooks/gears';

import classes from './modals.module.scss';

interface GearMediaModalProps {
  gear: Gear;
  isModalOpen: boolean;
  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export function GearMediaModal({
  gear,
  isModalOpen,
  onCancel,
}: GearMediaModalProps) {
  const { tenant, role } = useUserSession();
  const { t } = useTranslation('medias');
  const [mediaAddModalVisible, setMediaAddModalVisible] = useState(false);
  const [isMediaLoading, setIsMediaLoading] = useState(false);

  const mediasQuery = useQuery(['medias', tenant, { gearId: gear.id }], () =>
    getMediaFromGear(tenant, gear.id),
  );

  const { uploadGearMedia, deleteGearMedia, deleteGearMediaAsync } =
    useHandleMedias({
      gearId: gear.id,
    });

  const { updateGearAsync } = useHandleGears();

  const handleSelectGearMedia = async (media: Media) => {
    setIsMediaLoading(true);
    await updateGearAsync({
      id: gear.id,
      mediasId: [media.id],
    });
    setIsMediaLoading(false);
  };

  const handleUnselectGearMedia = async (media: Media) => {
    const mediasWithSameName = mediasQuery.data?.filter(
      (scenarioMedia) => scenarioMedia.filename === media.filename,
    );

    if (mediasWithSameName && mediasWithSameName.length > 0) {
      setIsMediaLoading(true);
      await deleteGearMediaAsync(mediasWithSameName[0]);
      setIsMediaLoading(false);
    }
  };

  return (
    <Modal
      centered
      // 50% arbitrary value to have the modal not taking all the screen but still be wide enough
      width="50%"
      title={t('modal.detail.title')}
      open={isModalOpen}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          {t('modal.detail.cancel')}
        </Button>,
      ]}
    >
      <div className={classes.modalMediaContent}>
        <Flex gap={64} direction="column">
          <Flex.Child grow={1} shrink={0}>
            {mediasQuery.isSuccess ? (
              <MediasGalery medias={mediasQuery.data} />
            ) : (
              <Spin size="large" />
            )}
          </Flex.Child>
          <Flex.Child shrink={1}>
            {hasEditingRights(role) ? (
              <Button onClick={() => setMediaAddModalVisible(true)} type="link">
                <PlusOutlined />
                {t('action.add-media')}
              </Button>
            ) : null}
          </Flex.Child>
        </Flex>
      </div>
      {mediaAddModalVisible && mediasQuery.isSuccess ? (
        <MediasAddModal
          mediasRessource={mediasQuery.data}
          selectionIsLoading={isMediaLoading}
          onClose={() => setMediaAddModalVisible(false)}
          onUpload={uploadGearMedia}
          onDeleteMedia={deleteGearMedia}
          onSelect={(media) => handleSelectGearMedia(media)}
          onUnselect={(media) => handleUnselectGearMedia(media)}
        />
      ) : null}
    </Modal>
  );
}
