import React, { useState, memo } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Handle, Position, NodeProps, useReactFlow } from 'reactflow';
import {
  EllipsisOutlined,
  PlusOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Dropdown,
  Input,
  MenuProps,
  Modal,
  Row,
  Tooltip,
} from 'antd';

import { Flex } from 'ui/flex';
import { Role } from 'technical/auth/roles';
import {
  AuthorizedAccessOnly,
  hasEditingRights,
} from 'technical/auth/authorization';
import { GraphModals, Step } from 'business/type';
import { useUserSession } from 'technical/hooks/use-user-session';

import useNodeClick from '../hooks/use-node-click';
import useTasksAndStepsLifeCycle from '../../hooks/use-tasks-and-steps-life-cycle';

import classes from './node.module.scss';
import { CustomTaskData } from '../utils/factory/custom-task';
import { ImportTasksModal } from '../../tasks/import-tasks-modal';

function TaskNode({
  id,
  data,
  targetPosition = Position.Top,
  sourcePosition = Position.Bottom,
  ...nodeProps
}: NodeProps<CustomTaskData>) {
  const { t } = useTranslation('scenarios');
  const { setDraggable } = useNodeClick(id);
  const { fitView } = useReactFlow();
  const { role } = useUserSession();

  const [showBtn, setShowBtn] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<GraphModals>('idle');

  const hideMenuAndButtons = () => {
    setShowBtn(false);
    setShowMenu(false);
  };

  const onHoverHandler = () => {
    setShowBtn(!showBtn);
    if (showBtn === false && showMenu) setShowMenu(false);
  };

  const onShowMenuHandler = () => {
    setShowMenu(showBtn && !showMenu);
  };

  const {
    createTaskMutation,
    createStepMutation,
    deleteTaskMutation,
    importTasksMutation,
    updateTaskMutation,
    loading: isLoading,
  } = useTasksAndStepsLifeCycle(
    data.tenant,
    Number(data.zone),
    Number(data.scenarioReference),
    data.onSelectStep,
  );

  const handleCreateTask = () => {
    hideMenuAndButtons();
    createTaskMutation
      .mutateAsync({
        label: t('creation.modal.new-task.label'),
        scenarioRef: Number(data.scenarioReference),
        rank: Number(data.rank) + 1,
      })
      .then((datas) =>
        createStepMutation.mutate(
          {
            label: t('creation.modal.new-step.label'),
            rank: 1,
            taskRef: Number(datas.reference),
            newCondition: false,
          },
          {
            onSettled: (d) => {
              data?.onSelectStep(
                Number(data.rank),
                Number(d?.reference),
                Number(d?.id),
              );

              fitView({
                nodes: [{ id: d?.id?.toString() || id }],
                duration: 250,
              });
            },
          },
        ),
      );
  };

  const handleDeleteTask = () => {
    if (
      data?.steps?.find((step: Step) => step.reference === data.selectedStep)
    ) {
      data.onSelectStep(data.reference, undefined, undefined);
    }
    hideMenuAndButtons();
    deleteTaskMutation.mutate(data.reference);
  };

  const handleImportTask = (
    refsList: number[],
    keepGears: boolean,
    keepMedias: boolean,
  ) => {
    hideMenuAndButtons();
    importTasksMutation.mutate({
      refsList,
      scenarioRef: Number(data.scenarioReference),
      keepGears,
      keepMedias,
    });
  };

  const handleUpdateTask = (
    e:
      | React.KeyboardEvent<HTMLInputElement>
      | React.FocusEvent<HTMLInputElement, Element>,
  ) => {
    const { reference, rank } = data;
    hideMenuAndButtons();
    updateTaskMutation.mutate({
      reference,
      rank,
      label: e.currentTarget.value,
    });
  };

  const stepsId = data.steps.map((step: Step) => step.id);
  const taskExit = data.steps.flatMap(
    (step: Step) =>
      step.answers?.filter((answer) => !stepsId.includes(answer.nextStepId)),
  );
  const taskEntry = data.steps.flatMap(
    (step: Step) =>
      step.linkedCases?.filter((answer) => !stepsId.includes(answer.stepId)),
  );

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    switch (e.key) {
      case 'duplicate':
        setShowModal('import');
        hideMenuAndButtons();
        break;
      case 'move':
        setDraggable(true);
        hideMenuAndButtons();
        break;
      case 'delete':
        setShowModal('delete');
        break;
      default:
    }
  };

  const items: MenuProps['items'] = [
    {
      label: t('detail.import-task'),
      key: 'duplicate',
      disabled: isLoading || !data.canUpdate,
    },
    /* {
      label: t('detail.move-task'),
      key: 'move',
      disabled: isLoading || !data.canUpdate,
    }, */
    {
      type: 'divider',
    },
    {
      label: t('detail.delete-task.title'),
      key: 'delete',
      danger: true,
      disabled: taskExit.length > 1 || isLoading || !data.canUpdate,
    },
  ];

  const hasWarnings = data.rank !== 1 && taskEntry.length === 0;

  return (
    <>
      <Handle
        type="target"
        position={targetPosition}
        className={classes.customNodeHandler}
        style={{ visibility: 'hidden' }}
      />
      <div
        data-testid={`task-node-wrapper-${id}`}
        className={classes.customTaskWrapper}
        onMouseEnter={onHoverHandler}
        onMouseLeave={() => setShowBtn(false)}
      >
        <Card
          data-testid={`task-node-${id}`}
          className={[
            classes.customTask,
            hasWarnings && classes.hasWarning,
          ].join(' ')}
        >
          <Row
            className={[
              classes.customStateIconsGroup,
              classes.customStateIconsGroupTop,
            ].join(' ')}
          >
            {hasWarnings ? (
              <WarningOutlined className={classes.customStateIcon} />
            ) : null}
          </Row>
          <Tooltip title={data.label} color="#9747ff">
            <div className={classes.customTaskLabel}>
              {data?.tag.replace('.0', '.')}
              <Input
                data-testid="task-input-label"
                defaultValue={data?.label}
                disabled={!data.canUpdate || isLoading}
                onPressEnter={handleUpdateTask}
                onBlur={handleUpdateTask}
              />
            </div>
          </Tooltip>
        </Card>
        <Flex
          className={`${classes.customCtaGroup} ${
            showBtn ? classes.isVisible : ''
          }`}
          testid="task-node-cta"
        >
          <Dropdown
            menu={{ items, onClick: handleMenuClick }}
            disabled={showMenu && showBtn && !hasEditingRights(role)}
          >
            <Button
              data-testid="task-menu-cta"
              type="default"
              shape="circle"
              icon={
                <EllipsisOutlined className={classes.customCtaButtonIcon} />
              }
              size="small"
              className={[
                classes.customCtaButton,
                classes['customCtaButton--task'],
              ].join(' ')}
              onClick={onShowMenuHandler}
              style={{ marginRight: '14px' }}
            />
          </Dropdown>
          <Button
            data-testid="task-menu-add-cta"
            type="default"
            title={t('detail.add-task')}
            shape="circle"
            icon={<PlusOutlined className={classes.customCtaButtonIcon} />}
            size="small"
            disabled={isLoading || !data.canUpdate}
            onClick={handleCreateTask}
            className={[
              classes.customCtaButton,
              classes['customCtaButton--task'],
            ].join(' ')}
          />
        </Flex>
      </div>
      <Handle
        type="source"
        position={sourcePosition}
        className={classes.customNodeHandler}
        style={{ visibility: 'hidden' }}
      />
      {showModal === 'import'
        ? createPortal(
            <ImportTasksModal
              onClose={() => setShowModal('idle')}
              onImport={(taskRef, keepGears, keepMedias) =>
                handleImportTask(taskRef, keepGears, keepMedias)
              }
              scenarioRef={data.scenarioReference}
            />,
            document.body,
          )
        : null}
      {showModal === 'delete'
        ? createPortal(
            <AuthorizedAccessOnly
              authorized={[Role.Admin, Role.SuperAdmin, Role.Author]}
            >
              <Modal
                open={showModal === 'delete'}
                title={t('detail.delete-task.title')}
                okText={t('detail.delete-task.submit')}
                okType="danger"
                onOk={() => {
                  handleDeleteTask();
                  setShowModal('idle');
                }}
                onCancel={() => setShowModal('idle')}
                cancelText={t('detail.delete-task.cancel')}
              >
                <p>{t('detail.delete-task.message')}</p>
              </Modal>
            </AuthorizedAccessOnly>,
            document.body,
          )
        : null}
    </>
  );
}

export default memo(TaskNode);
