import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'antd';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { REQUESTS } from 'technical/auth/azure-active-directory';
import { SpectralLogo } from 'ui/images';
import MicrosoftIcon from 'ui/icons/microsoft-icon';
import classes from './index.module.scss';

// function useLogin(strategy: "popup" | "redirect") {
//   const { instance, accounts, inProgress } = useMsal();

//   async function login(account: AccountInfo) {
//     if (inProgress === InteractionStatus.None) {
//       switch (strategy) {
//         case "popup":
//           return instance.loginPopup(REQUESTS.login);

//         case "redirect":
//           return instance.loginRedirect(REQUESTS.login);
//       }
//     }
//     return null;
//   }

//   return { accounts, login };
// }

export default function LoginPage() {
  const { instance, accounts, inProgress } = useMsal();
  const { t } = useTranslation('login');

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        <div className={classes.imageBlock}>
          <img src={SpectralLogo} alt="Spectral" width="100%" />
        </div>
        <button
          type="button"
          disabled={inProgress === 'login' || accounts.length > 0}
          onClick={async () => {
            if (inProgress === InteractionStatus.None) {
              await instance
                .loginRedirect(REQUESTS.login)
                .then((response) => console.log('logged', response))
                .catch((error) => console.log(error));
            }
          }}
        >
          <MicrosoftIcon />
          {t('form.submit')}
        </button>
      </Card>
    </div>
  );
}
