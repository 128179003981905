import React from "react";
import classes from "./index.module.scss";

interface FieldProps {
  name: string;
  error?: string;
  legend?: string;
  label?: React.ReactNode;
  children: React.ReactNode;
}
export function Field({ name, label, error, legend, children }: FieldProps) {
  return (
    <label htmlFor={name} className={classes.group}>
      <span className={classes.label}>{label}</span>
      <div>{children}</div>
      {legend ? <span className={classes.legend}>{legend}</span> : null}
      {error ? <span className={classes.error}>{error}</span> : null}
    </label>
  );
}
