import React from 'react';
import { TagOutlined } from '@ant-design/icons';
import { Select, Tag } from 'antd';
import { useUserSession } from 'technical/hooks/use-user-session';
import { useTranslation } from 'react-i18next';
import { useHandleTags } from 'business/tags/hooks/tags';
import classes from './scenario-tags.module.scss';

interface ScenarioTagsProps {
  scenarioId: number;
  canUpdate: boolean;
}

export function ScenarioTags({ scenarioId, canUpdate }: ScenarioTagsProps) {
  const { tenant } = useUserSession();
  const { t } = useTranslation('scenarios');

  const {
    data: tags,
    isLoading,
    createTag,
    updateTag,
    deleteTag,
  } = useHandleTags({
    tenant,
    queryKey: ['tags', tenant, 'scenario'],
    type: 'scenario',
    typeId: scenarioId,
  });

  const handleSelect = (tagLabel: string) => {
    const selectedTag = tags.find((tag) => tag.label === tagLabel);
    if (selectedTag) {
      const scenarioIds = [
        ...selectedTag.scenarios.map((scenario) => scenario.id),
        scenarioId,
      ];
      updateTag({ id: selectedTag.id, type: 'scenario', typeIds: scenarioIds });
      return;
    }
    createTag({ label: tagLabel, type: 'scenario', typeId: scenarioId });
  };

  const handleUnselect = (tagLabel: string) => {
    const selectedTagId = tags.find((tag) => tag.label === tagLabel)?.id;
    if (selectedTagId) {
      deleteTag({ tagId: selectedTagId });
    }
  };

  const scenarioTags = tags
    .filter((tag) =>
      tag.scenarios.some((scenario) => scenario.id === scenarioId),
    )
    .map((tag) => tag.label);

  return (
    <div className={classes.tagSelector}>
      <label htmlFor="references">{t('detail.header.tags')}</label>
      <Select
        mode="tags"
        id="references"
        maxTagCount="responsive"
        style={{ width: '100%' }}
        value={scenarioTags}
        placeholder={t('detail.header.tags')}
        loading={isLoading}
        onSelect={handleSelect}
        onDeselect={handleUnselect}
        disabled={!canUpdate}
      >
        {tags.map((tag) => (
          <Select.Option key={tag.id} value={tag.label}>
            <Tag icon={<TagOutlined />} color="cyan">
              {tag.label}
            </Tag>
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}
