import React, { memo, useState } from 'react';
import { createPortal } from 'react-dom';
import { Handle, Position, NodeProps, useReactFlow, Node } from 'reactflow';
import Paragraph from 'antd/lib/typography/Paragraph';
import {
  EllipsisOutlined,
  PictureOutlined,
  PlusOutlined,
  SafetyCertificateOutlined,
  SettingOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Button, Card, Dropdown, MenuProps, Modal, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import { Answer } from 'business/scenarios/services/type';
import { useHandleInputRequest } from 'business/scenarios/hooks/input-requests';
import { Flex } from 'ui/flex';
import { Role } from 'technical/auth/roles';
import { GraphModals, Step } from 'business/type';
import { useHandleSteps } from 'business/scenarios/hooks/step';
import { useUserSession } from 'technical/hooks/use-user-session';
/* import { useHandleAnswers } from 'business/scenarios/hooks/answers'; */
import {
  AuthorizedAccessOnly,
  hasEditingRights,
} from 'technical/auth/authorization';
import useNodeClick from '../hooks/use-node-click';
import useTasksAndStepsLifeCycle from '../../hooks/use-tasks-and-steps-life-cycle';
import { CustomStepData } from '../utils/factory/custom-step';
import { CustomTaskData } from '../utils/factory/custom-task';

import classes from './node.module.scss';
import { MAX_CONDITION } from '../utils/constants';

function StepNode({
  id,
  data,
  targetPosition = Position.Top,
  sourcePosition = Position.Bottom,
}: NodeProps<CustomStepData>) {
  const { t } = useTranslation('scenarios');
  const { getNode, fitView } = useReactFlow();
  const { role } = useUserSession();
  const [showBtn, setShowBtn] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<GraphModals>('idle');

  const { setDraggable } = useNodeClick(id);

  const parentTask: Node<CustomTaskData> | undefined = getNode(
    data.linkedTask.toString(),
  );

  const { data: stepQueryData /* updateStep */ } = useHandleSteps({
    scenarioReference: parentTask?.data.scenarioReference,
    stepId: Number(id),
    zone: parentTask?.data?.scenarioZone,
  });

  const invalidationKey = [
    'scenarioTasks',
    parentTask?.data.tenant,
    { reference: parentTask?.data.scenarioReference },
  ];

  const { data: inputRequestList } = useHandleInputRequest(
    data.reference,
    Number(id),
    invalidationKey,
  );

  const {
    createStepMutation,
    importStepsMutation,
    deleteStepMutation,
    deleteTaskMutation,
    updateStepMutation,
    loading: isLoading,
  } = useTasksAndStepsLifeCycle(
    parentTask?.data.tenant,
    parentTask?.data.zone,
    Number(parentTask?.data.scenarioReference),
    (stepRef, stepId) =>
      data.onSelectStep(parentTask?.data.rank - 1 || 0, stepRef, stepId),
  );

  const stepsIds: number[] =
    data?.availableSteps?.map((step: Step) => step.id) || [];

  const checkRefExist = () =>
    data?.answers?.filter(
      (answer: Answer) => !stepsIds.includes(answer?.nextStepId as number),
    ).length > 0 || false;

  const hasWarnings =
    !data.label ||
    (data.answers &&
      data.answers?.find((answer: Answer) => !answer.nextStepId)) ||
    checkRefExist();

  const onHoverHandler = () => {
    setShowBtn(!showBtn);
    if (showBtn === false && showMenu) setShowMenu(false);
  };

  const onShowMenuHandler = () => {
    setShowMenu(showBtn && !showMenu);
  };

  const hasConditions = data?.answers?.length > 0;

  const handleCreateStep = () => {
    if (!parentTask) return;

    createStepMutation.mutate(
      {
        label: t('creation.modal.new-step.label'),
        rank:
          data.next.length > 0
            ? Number(data.rank) + 1
            : parentTask.data?.steps.length + 1,
        taskRef: Number(parentTask.data?.reference),
        fromId: Number(id),
        newCondition: false,
      },
      {
        onSettled: (d) => {
          data?.onSelectStep(
            Number(parentTask?.data.reference),
            Number(d?.reference),
            Number(d?.id),
          );
        },
      },
    );
  };

  const handleDuplicateStep = () => {
    importStepsMutation.mutate(
      {
        refsList: [data.reference],
        taskRef: parentTask?.data.reference,
      },
      {
        onSuccess: (ddata) => {
          setTimeout(() => {
            const [nextPossibleId, nextPossibleRef] = [
              Math.max(
                ...data.availableSteps.map((step: { id: number }) => step.id),
              ),
              Math.max(
                ...data.availableSteps.map(
                  (step: { reference: number }) => step.reference,
                ),
              ),
            ];

            if (data?.onSelectStep) {
              data?.onSelectStep(
                parentTask?.data.reference,
                nextPossibleRef + 1,
                nextPossibleId + 1,
              );
            }
            fitView({
              nodes: [{ id: (nextPossibleId + 1).toString() }],
              duration: 250,
            });

            updateStepMutation.mutate({
              stepRef: nextPossibleRef + 1,
              rank: data.rank + 1,
              startIndex: data.rank - 1,
              endIndex: data.rank,
              startTaskIndex: parentTask?.data.rank - 1,
              endTaskIndex: parentTask?.data.rank - 1,
            });
          }, 1200);
        },
      },
    );
  };

  const handleAddCondition = () => {
    if (!parentTask) return;

    createStepMutation.mutate(
      {
        label: t('creation.modal.new-step.label'),
        rank:
          data.next.length > 0
            ? Number(data.rank) + 1
            : parentTask.data?.steps.length + 1,
        taskRef: Number(parentTask.data?.reference),
        fromId: Number(id),
        newCondition: true,
      },
      {
        onSettled: (d) => {
          data?.onSelectStep(
            Number(parentTask?.data.reference),
            Number(d?.reference),
            Number(d?.id),
          );
        },
      },
    );
  };

  const handleDeleteStep = () => {
    if (!data.canUpdate) return;

    deleteStepMutation.mutateAsync(
      {
        reference: data.reference,
        index: Number(data.rank) - 1,
        activeStep: data.activeStep,
        steps: parentTask?.data.steps,
      },
      {
        onSettled: () => {
          if (parentTask?.data.steps.length === 1) {
            deleteTaskMutation.mutate(parentTask.data.reference);
          }
        },
      },
    );
  };

  const setStepAsSelected = () => {
    if (data?.onSelectStep) {
      data?.onSelectStep(
        parentTask?.data.reference,
        data.reference,
        Number(id),
      );
    }
  };

  const handleCardClick = () => {
    if (data?.onSelectStep) {
      setStepAsSelected();
      data?.setActiveTab('infosTab');
    }
  };

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    switch (e.key) {
      case 'duplicate':
        handleDuplicateStep();
        break;
      case 'add':
        handleAddCondition();
        break;
      case 'move':
        setDraggable(true);
        setShowMenu(false);
        break;
      case 'delete':
        setShowModal('delete');
        break;
      default:
    }
  };

  const items: MenuProps['items'] = [
    {
      label: t('detail.step-edition.duplicate-step'),
      key: 'duplicate',
      disabled: !data.canUpdate || isLoading,
    },
    {
      label: t('detail.step-edition.step-type-condition.add'),
      key: 'add',
      disabled:
        (hasConditions && data?.answers.length === MAX_CONDITION) || isLoading,
    },
    /*  {
      label: t('detail.move-step'),
      key: 'move',
      disabled: hasConditions || isLoading,
    }, */
    {
      type: 'divider',
    },
    {
      label: t('detail.delete-step.title'),
      key: 'delete',
      danger: true,
      disabled:
        (deleteStepMutation.variables?.reference === data.reference &&
          deleteStepMutation.isLoading) ||
        data?.answers?.length > 1 ||
        isLoading,
    },
  ];

  return (
    <>
      {/* <Handle
        type="target"
        position={Position.Left}
        className={classes.customHandle}
      />
      <Handle
        type="target"
        position={Position.Right}
        className={classes.customHandle}
      /> */}
      <div
        data-testid={`step-node-wrapper-${id}`}
        onMouseEnter={onHoverHandler}
        onMouseLeave={() => setShowBtn(false)}
      >
        <Card
          data-testid={`step-node-${id}`}
          onClick={handleCardClick}
          className={[
            classes.customStep,
            hasWarnings && classes.hasWarning,
          ].join(' ')}
        >
          <Handle
            type="target"
            position={targetPosition}
            className={classes.customHandle}
          />
          <Handle
            type="source"
            position={sourcePosition}
            className={classes.customHandle}
            isConnectableStart={
              stepQueryData?.answers && stepQueryData?.answers.length < 6
            }
          />
          <Row
            className={[
              classes.customStateIconsGroup,
              classes.customStateIconsGroupTop,
            ].join(' ')}
          >
            {hasWarnings ? (
              <WarningOutlined className={classes.customStateIcon} />
            ) : null}
          </Row>
          <Paragraph
            style={{ fontSize: 18 }}
            ellipsis
            data-testid="step-node-label"
          >
            {data?.tag.replace(/^0+/, '')} {data?.label}
          </Paragraph>
          <Row
            className={[
              classes.customStateIconsGroup,
              classes.customStateIconsGroupBottom,
            ].join(' ')}
          >
            {stepQueryData?.mediasId && stepQueryData?.mediasId.length > 0 ? (
              <PictureOutlined className={classes.customStateIcon} />
            ) : null}
            {stepQueryData?.gearsOrder &&
            stepQueryData?.gearsOrder.length > 0 ? (
              <SettingOutlined className={classes.customStateIcon} />
            ) : null}
            {inputRequestList.length > 0 ? (
              <SafetyCertificateOutlined className={classes.customStateIcon} />
            ) : null}
          </Row>
        </Card>
        <Flex
          className={`${classes.customCtaGroup} ${
            showBtn ? classes.isVisible : ''
          }`}
          test-id="step-node-cta"
        >
          <Dropdown
            menu={{ items, onClick: handleMenuClick }}
            disabled={showMenu && showBtn && !hasEditingRights(role)}
          >
            <Button
              data-testid="step-menu-cta"
              type="default"
              shape="circle"
              icon={
                <EllipsisOutlined className={classes.customCtaButtonIcon} />
              }
              size="small"
              className={classes.customCtaButton}
              onClick={onShowMenuHandler}
              style={{ marginRight: '14px' }}
              disabled={!data.canUpdate}
            />
          </Dropdown>
          <Button
            data-testid="step-menu-add-cta"
            title={t('detail.add-step')}
            disabled={!data.canUpdate || data.loading || isLoading}
            type="default"
            shape="circle"
            icon={<PlusOutlined className={classes.customCtaButtonIcon} />}
            size="small"
            onClick={handleCreateStep}
            className={classes.customCtaButton}
          />
        </Flex>
      </div>
      {showModal === 'delete'
        ? createPortal(
            <AuthorizedAccessOnly
              authorized={[Role.Admin, Role.Author, Role.SuperAdmin]}
              fallback={null}
            >
              <Modal
                open={showModal === 'delete'}
                title={t('detail.delete-step.title')}
                okText={t('detail.delete-step.submit')}
                okType="danger"
                onOk={() => {
                  handleDeleteStep();
                  setShowModal('idle');
                }}
                onCancel={() => setShowModal('idle')}
                cancelText={t('detail.delete-step.cancel')}
              >
                <p>{t('detail.delete-step.message')}</p>
              </Modal>
            </AuthorizedAccessOnly>,
            document.body,
          )
        : null}
    </>
  );
}

export default memo(StepNode);
