import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu as AntMenu, MenuProps } from 'antd';
import {
  ReadOutlined,
  PictureOutlined,
  AreaChartOutlined,
  SettingOutlined,
  UsergroupAddOutlined,
  GlobalOutlined,
  AlertOutlined,
  CarryOutOutlined,
  HomeOutlined,
  RadarChartOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Flex } from 'ui/flex';
import { useUserSession } from 'technical/hooks/use-user-session';
import {
  hasEditingRights,
  hasSuperAdminRights,
} from 'technical/auth/authorization';
import classes from './menus.module.scss';

export function NavigationMenu() {
  const { t } = useTranslation('common');
  const { role } = useUserSession();

  const usePathname = () => {
    const location = useLocation();
    return location.pathname.split('/')[1];
  };

  const menuItems: MenuProps['items'] = [
    {
      key: 'scenarios',
      label: <Link to="/scenarios">{t('navigation.scenarios')}</Link>,
      icon: <ReadOutlined />,
    },
    {
      key: 'medias',
      label: <Link to="/medias">{t('navigation.medias')}</Link>,
      icon: <PictureOutlined />,
    },
    hasEditingRights(role)
      ? {
          key: 'reportrs',
          label: t('navigation.reports'),
          icon: <AreaChartOutlined />,
          children: [
            {
              key: 'alerts',
              label: <Link to="/alerts">{t('navigation.alerts')}</Link>,
              icon: <AlertOutlined />,
            },
            {
              key: 'interventions',
              label: (
                <Link to="/interventions">{t('navigation.interventions')}</Link>
              ),
              icon: <CarryOutOutlined />,
            },
          ],
        }
      : null,
    {
      key: 'zones',
      label: <Link to={`/zones/`}>{t('navigation.zone')}</Link>,
      icon: <HomeOutlined />,
    },
    {
      key: 'advanced',
      label: t('navigation.advanced'),
      icon: <SettingOutlined />,
      children: [
        {
          key: 'users',
          label: <Link to="/users">{t('navigation.users')}</Link>,
          icon: <UsergroupAddOutlined />,
        },
        hasSuperAdminRights(role)
          ? {
              key: 'zones-top',
              label: (
                <Link to="/zones-top">{t('navigation.top-level-zones')}</Link>
              ),
              icon: <GlobalOutlined />,
            }
          : null,
        hasSuperAdminRights(role)
          ? {
              key: 'monitoring',
              label: <Link to="/monitoring">{t('navigation.monitoring')}</Link>,
              icon: <RadarChartOutlined />,
            }
          : null,
      ],
    },
  ];

  return (
    <Flex direction="column" gap={2} className={classes.navigationMenu}>
      <AntMenu
        mode="inline"
        theme="dark"
        selectedKeys={[usePathname()]}
        items={menuItems}
      />
    </Flex>
  );
}
