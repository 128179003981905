import React from 'react';
import {
  DeleteOutlined,
  MinusOutlined,
  PlusOutlined,
  CopyOutlined,
  RetweetOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Button, Popover, TreeSelect } from 'antd';
import { formatTreeData, isMedia } from 'business/medias/services/folders';
import { MixedMedia } from 'business/medias/services/types';
import { Folder, Media } from 'business/type';
import { hasEditingRights } from 'technical/auth/authorization';
import { useUserSession } from 'technical/hooks/use-user-session';
import { Flex } from 'ui/flex';
import { ReactComponent as MoveSvg } from 'ui/icons/move.svg';

interface MediaActionsProps {
  record: MixedMedia;
  folderTree?: Folder[];
  mediasRessource?: Media[];
  standard: boolean;
  selectionIsLoading: boolean;
  disableFolderActions: boolean;
  onConfirmDeletion: (record: MixedMedia) => void;
  onSelect?: (input: Media) => void;
  onUnselect?: (input: Media) => void;
  onCopyMedia?: (media: Media, destinationFolderId: number) => void;
  onMoveMediaFolder?: (mediaId: number, newFolderId: number) => void;
  onMoveFolder?: (folderId: number, newFolderId: number) => void;
  onCopyFolder?: (folder: Folder, destinationFolderId: number) => void;
  onDisplayReplaceModal: () => void;
}

export default function MediaActions({
  record,
  folderTree,
  mediasRessource,
  standard,
  selectionIsLoading,
  disableFolderActions,
  onConfirmDeletion,
  onSelect,
  onUnselect,
  onCopyFolder,
  onMoveMediaFolder,
  onMoveFolder,
  onCopyMedia,
  onDisplayReplaceModal,
}: MediaActionsProps) {
  const { t } = useTranslation('medias');
  const { role } = useUserSession();

  const treeValues = formatTreeData(folderTree || [], t('list.home'));
  const isSelection = !!onSelect && !!onUnselect && !!mediasRessource;
  if (!isMedia(record)) {
    if (!hasEditingRights(role) || standard || disableFolderActions) {
      return null;
    }

    return (
      <Flex gap={16} items="center">
        <Popover
          title={t('list.copy')}
          content={
            <TreeSelect
              style={{ width: '100%' }}
              placeholder={t('list.format.folder')}
              treeData={treeValues}
              onSelect={
                onCopyFolder
                  ? (destination) => onCopyFolder(record, destination)
                  : undefined
              }
            />
          }
        >
          <CopyOutlined />
        </Popover>
        <Popover
          title={t('list.move')}
          content={
            <TreeSelect
              style={{ width: '100%' }}
              placeholder={t('list.format.folder')}
              treeData={treeValues}
              onSelect={(folderId) =>
                onMoveFolder ? onMoveFolder(record.id, folderId) : undefined
              }
            />
          }
        >
          <MoveSvg style={{ cursor: 'pointer', width: 20 }} />
        </Popover>
        <Button
          type="primary"
          onClick={() => onConfirmDeletion(record)}
          icon={<DeleteOutlined />}
          danger
        />
      </Flex>
    );
  }

  if (isSelection) {
    const addedMedias = mediasRessource.filter(
      (m) => m.filename === record.filename,
    );

    return (
      <Flex gap={16} items="center">
        <Button
          block
          icon={<MinusOutlined />}
          disabled={addedMedias.length <= 0}
          loading={selectionIsLoading}
          onClick={() => onUnselect(record)}
        />
        <span>{addedMedias.length}</span>
        <Button
          block
          icon={<PlusOutlined />}
          loading={selectionIsLoading}
          onClick={() => onSelect(record)}
        />
      </Flex>
    );
  }

  if (hasEditingRights(role) && !standard) {
    return (
      <Flex gap={16} items="center">
        <Popover
          title={t('list.copy')}
          content={
            <TreeSelect
              style={{ width: '100%' }}
              placeholder={t('list.format.folder')}
              treeData={treeValues}
              onSelect={
                onCopyMedia
                  ? (destination) => onCopyMedia(record, destination)
                  : undefined
              }
            />
          }
        >
          <CopyOutlined />
        </Popover>
        <Popover
          title={t('list.move')}
          content={
            <TreeSelect
              style={{ width: '100%' }}
              placeholder={t('list.format.folder')}
              treeData={treeValues}
              onSelect={(folderId) =>
                onMoveMediaFolder
                  ? onMoveMediaFolder(record.id, folderId)
                  : undefined
              }
            />
          }
        >
          <MoveSvg style={{ cursor: 'pointer', width: 20 }} />
        </Popover>
        <Button
          onClick={() => onDisplayReplaceModal()}
          icon={<RetweetOutlined />}
        />
        <Button
          type="primary"
          onClick={() => onConfirmDeletion(record)}
          icon={<DeleteOutlined />}
          danger
        />
      </Flex>
    );
  }

  return null;
}
