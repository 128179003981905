import React, { useEffect, useState } from 'react';
import {
  AnswerInputUpdate,
  StepAnswer,
} from 'business/scenarios/services/type';
import { Input, Select, Button, Alert, Tag } from 'antd';
import { Flex } from 'ui/flex';
import { Step } from 'business/type';
import { useTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  PlusOutlined,
  RightCircleOutlined,
} from '@ant-design/icons';
import classes from './index.module.scss';

interface StepConditionsProps {
  value: StepAnswer[];
  onChange: (input: StepAnswer[]) => void;
  availableSteps: Step[];
  selectedStep: number;
  canUpdate: boolean;
  setSelectedStep: (stepReference: number) => void;
  onUpdate: (answerInput: AnswerInputUpdate) => void;
  onDelete: (answerRef: number) => void;
  onCreate: () => void;
}

export default function StepConditions({
  value,
  onChange,
  availableSteps,
  selectedStep,
  canUpdate,
  setSelectedStep,
  onUpdate,
  onDelete,
  onCreate,
}: StepConditionsProps) {
  const { t } = useTranslation('scenarios');
  const [isValueError, setIsValueError] = useState(false);

  useEffect(() => {
    setIsValueError(value.filter((answer) => !answer.nextStepRef).length > 0);
  }, [value]);

  useEffect(() => {
    function verifyStepExist() {
      const stepsRefs: number[] = availableSteps.map((step) => step.reference);
      return (
        value.filter((val) => !stepsRefs.includes(val?.nextStepRef as number))
          .length > 0
      );
    }

    setIsValueError(verifyStepExist());
  }, [availableSteps, value]);

  function checkRefExist(ref: number) {
    const stepsRefs: number[] = availableSteps.map((step) => step.reference);
    return !stepsRefs.includes(ref);
  }

  async function handleAddCondition() {
    onCreate();
  }

  function handleRemoveCondition(conditionId: number) {
    const filteredConditions = value.filter(
      (condition) => condition.reference !== conditionId,
    );

    onChange(filteredConditions);
    onDelete(conditionId);

    if (
      filteredConditions.filter((element) => !element.nextStepRef).length === 0
    ) {
      setIsValueError(false);
    }
  }

  function handleChangeValue(id: number, stepRef: number) {
    const filteredAnswers = value.filter((element) => element.reference !== id);
    const current = value.find((element) => element.reference === id);
    onUpdate({
      reference: id,
      stepRef: selectedStep,
      nextStepRef: stepRef,
    });
    if (current) {
      const data = [...filteredAnswers, { ...current, nextStepRef: stepRef }];

      if (data.filter((element) => !element.nextStepRef).length === 0) {
        setIsValueError(false);
        onChange(data);
        return;
      }

      onChange(data);
      setIsValueError(true);
    }
  }

  function handleChangeLabel(id: number, label: string) {
    const updatedAnswers = value.map((element) => {
      if (element.reference === id) {
        return { ...element, label };
      }
      return element;
    });

    const inputsWithEmptyLabel = updatedAnswers.filter(
      (input) => input.label === '',
    );
    if (inputsWithEmptyLabel.length === 0) {
      onChange(updatedAnswers);
      return;
    }

    onChange(updatedAnswers);
  }

  return (
    <>
      {isValueError ? (
        <Alert
          type="warning"
          message={t('detail.step-edition.fields.condition-value-warning')}
          className={classes.warning}
        />
      ) : null}
      {value.map((condition, index) => (
        <Flex
          direction="column"
          gap="12px"
          key={condition.reference}
          className={classes.condition}
        >
          <Flex.Child className={classes.conditionElement}>
            <span>{index + 1}.</span>
            <Input
              id="label"
              name="label"
              defaultValue={condition.label ?? undefined}
              disabled={!canUpdate || condition.reference === -1}
              onChange={(e) =>
                handleChangeLabel(condition.reference, e.target.value)
              }
              onPressEnter={(e) => {
                onUpdate({
                  reference: condition.reference,
                  stepRef: selectedStep,
                  label: e.currentTarget.value,
                });
              }}
              onBlur={(e) => {
                onUpdate({
                  reference: condition.reference,
                  stepRef: selectedStep,
                  label: e.currentTarget.value,
                });
              }}
              placeholder={t(
                'detail.step-edition.step-type-condition.information',
              )}
            />
          </Flex.Child>
          <Flex.Child className={classes.conditionElement}>
            <Select
              className={classes.select}
              optionFilterProp="label"
              value={condition.nextStepRef}
              status={
                checkRefExist(condition?.nextStepRef ?? 0) ? 'warning' : ''
              }
              disabled={!canUpdate || condition.reference === -1}
              onSelect={(stepReference: number) =>
                handleChangeValue(condition.reference, stepReference)
              }
              placeholder={t(
                'detail.step-edition.step-type-condition.associed-step',
              )}
            >
              {availableSteps.map((step) => (
                <Select.Option
                  key={step.reference}
                  value={step.reference}
                  label={step.label}
                >
                  <Tag color="blue">{`#${step.taskRank}.${step.rank}`}</Tag>
                  {step.label}
                </Select.Option>
              ))}
            </Select>
            {condition.nextStepRef ? (
              <RightCircleOutlined
                className={classes.navigation}
                // use ! because i'm sure it exist with ternary before, but typescript doesn't understand it
                onClick={() => setSelectedStep(condition.nextStepRef!)}
              />
            ) : null}
            {canUpdate ? (
              <DeleteOutlined
                className={classes.delete}
                onClick={() => handleRemoveCondition(condition.reference)}
              />
            ) : null}
          </Flex.Child>
        </Flex>
      ))}
      {value.length < 6 && canUpdate ? (
        <Button onClick={() => handleAddCondition()} type="link">
          <PlusOutlined />
          {t('detail.step-edition.step-type-condition.add')}
        </Button>
      ) : null}
    </>
  );
}
