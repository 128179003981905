import type {
  AxiosInstance,
  AxiosRequestHeaders,
  InternalAxiosRequestConfig,
} from 'axios';
import axios from 'axios';
import config from 'config';
import {
  AuthClient,
  azureAuthClient,
  getAccessToken,
} from 'technical/auth/azure-active-directory';

function createAzureAuthInterceptor(authClient: AuthClient) {
  return async function setAuthorizationHeader(
    axiosConfig: InternalAxiosRequestConfig,
  ): Promise<InternalAxiosRequestConfig> {
    const accessToken = await getAccessToken(authClient);
    if (!accessToken) {
      return axiosConfig;
    }
    return {
      ...axiosConfig,
      headers: {
        ...axiosConfig.headers,
        Authorization: `${accessToken.type} ${accessToken.token}`,
      } as AxiosRequestHeaders,
    };
  };
}

const clients: Record<string, AxiosInstance> = {};
const clientsMedias: Record<string, AxiosInstance> = {};

function createTenantApi(
  authClient: AuthClient,
  tenant: string,
): AxiosInstance {
  const client = axios.create({
    baseURL: `${config.environmentVariables.apiURL}/${tenant}`,
  });
  client.interceptors.request.use(createAzureAuthInterceptor(authClient));
  return client;
}

function createApiClient(authClient: AuthClient) {
  return (tenant: string) => {
    if (!(tenant in clients)) {
      clients[tenant] = createTenantApi(authClient, tenant);
    }
    return clients[tenant];
  };
}

function createTenantApiMedia(
  authClient: AuthClient,
  tenant: string,
): AxiosInstance {
  const clientMedias = axios.create({
    baseURL: `${config.environmentVariables.apiURLMedia}/${tenant}`,
  });
  clientMedias.interceptors.request.use(createAzureAuthInterceptor(authClient));
  return clientMedias;
}

function createApiMediaClient(authClient: AuthClient) {
  return (tenant: string) => {
    if (!(tenant in clientsMedias)) {
      clientsMedias[tenant] = createTenantApiMedia(authClient, tenant);
    }
    return clientsMedias[tenant];
  };
}

export const apiMediaUpload = createApiMediaClient(azureAuthClient);

export const api = createApiClient(azureAuthClient);
