import { Folder } from 'business/type';

export const getParentFolders = (
  folder: Folder,
  idFolder: number,
  parentsFolder: Folder[],
) => {
  if (idFolder === folder.id) {
    parentsFolder.push(folder);
    return;
  }

  if (!folder.childs) {
    return;
  }

  for (const subFolder of folder.childs) {
    const tmpLength = parentsFolder.length;
    getParentFolders(subFolder, idFolder, parentsFolder);
    if (parentsFolder.length > tmpLength) {
      parentsFolder.push(folder);
    }
  }
};
