import { NodeTypes } from 'reactflow';
import StepNode from '../nodes/step-node';
import TaskNode from '../nodes/task-node';

export const fitViewOptions = {
  padding: 1,
  minZoom: 0.1,
  maxZoom: 2,
};

export const proOptions = {
  hideAttribution: true,
};

export const nodeTypes: NodeTypes = {
  step: StepNode,
  task: TaskNode,
};
