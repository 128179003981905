import { api } from 'technical/api';
import { User, Zone } from 'business/type';
import { UserInputCreate } from './type';

export async function createUser(tenant: string, input: UserInputCreate) {
  const { data: user } = await api(tenant).post<Pick<User, 'id'>>(
    '/v1/users',
    input,
  );
  return user;
}

export async function getTopZone(tenant: string) {
  const topZones = api(tenant)
    .get<Zone[]>('/v1/zones/roots')
    .then((response) => response.data);

  const zones = await Promise.resolve(topZones);
  return zones;
}
