import React, { useState } from 'react';
import {
  Alert,
  Button,
  Checkbox,
  Modal,
  notification,
  Space,
  Upload,
} from 'antd';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useUserSession } from 'technical/hooks/use-user-session';
import getScenarioListFromZone from 'business/scenarios/services/scenario-list';
import { PageLayout } from 'ui/layout';
import { hasEditingRights } from 'technical/auth/authorization';
import {
  DownloadOutlined,
  ImportOutlined,
  InboxOutlined,
} from '@ant-design/icons';
import { queryClient } from 'technical/react-query';
import { uploadCsv } from 'business/scenarios/services/scenario-creation';
import ScenarioList from './scenario-list';
import ScenariosCreationModal from './scenario-creation';
import { downloadZip } from 'technical/utils/download';
import { exportScenariosCSVByRefs } from 'business/scenarios/services/scenario-detail';

export default function ScenariosPage() {
  const { t } = useTranslation('scenarios');
  const { zone, tenant, role } = useUserSession();
  const [isModalCreationVisible, setModalCreationIsVisible] = useState(false);
  const [isModalImportVisible, setModalImportIsVisible] = useState(false);
  const [isModalExportVisible, setModalExportIsVisible] = useState(false);
  const [isSameZoneChecked, setSameZoneChecked] = useState(false);
  const [isExportMediasChecked, setExportMediasChecked] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const toggleModalCreation = () =>
    setModalCreationIsVisible((current) => !current);
  const toggleModalImport = () =>
    setModalImportIsVisible((current) => !current);
  const toggleModalExport = () =>
    setModalExportIsVisible((current) => !current);

  const checkedSameZone = () => setSameZoneChecked((current) => !current);
  const checkedExportMedias = () =>
    setExportMediasChecked((current) => !current);

  const onSelect = (keys: React.Key[]) => {
    setSelectedRowKeys(keys);
  };

  const scenarioListQuery = useQuery(
    ['scenarios', tenant, { zoneId: zone }],
    () => getScenarioListFromZone(tenant, zone),
  );

  const uploadScenariosMutation = useMutation(
    async (options: any) => {
      await uploadCsv(tenant, zone, isSameZoneChecked, options.file);
      toggleModalImport();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('zones');
        queryClient.invalidateQueries('gears');
        queryClient.invalidateQueries('tree');
        queryClient.invalidateQueries(['scenarios', tenant, { zoneId: zone }]);
        queryClient.invalidateQueries('zone');
      },
      onError: (err: Error) => {
        notification.error({
          message: err.message,
          description: 'File malformed, please check your file',
        });
      },
    },
  );

  const exportScenario = async () => {
    const blob = await exportScenariosCSVByRefs(
      tenant,
      selectedRowKeys as string[],
      isExportMediasChecked,
    );
    downloadZip(blob, 'export_scenarios');
    toggleModalExport();
  };

  return (
    <PageLayout
      title={t('list.title')}
      leftButton={
        hasEditingRights(role) ? (
          <Button
            type="primary"
            data-testid="new-scenario"
            onClick={toggleModalCreation}
          >
            {t('list.creation')}
          </Button>
        ) : null
      }
      rightButton={
        hasEditingRights(role) ? (
          <Space>
            <Button onClick={toggleModalImport} icon={<ImportOutlined />}>
              {t('list.import')}
            </Button>
            <Button
              onClick={toggleModalExport}
              icon={<DownloadOutlined />}
              disabled={selectedRowKeys.length === 0}
            >
              {t('list.export')}
            </Button>
          </Space>
        ) : null
      }
    >
      {scenarioListQuery.status === 'error' ? (
        <Alert type="error" message={t('list.error')} />
      ) : null}
      <ScenarioList
        loading={scenarioListQuery.status === 'loading'}
        scenarios={scenarioListQuery.data}
        onSelect={onSelect}
      />
      {isModalCreationVisible && (
        <ScenariosCreationModal onClose={toggleModalCreation} />
      )}

      {isModalImportVisible && (
        <Modal
          open
          title={t('import.modal.title')}
          onCancel={toggleModalImport}
          footer={[
            <Button key="cancel" onClick={toggleModalImport}>
              {t('import.modal.cancel')}
            </Button>,
          ]}
        >
          <Space direction="vertical" style={{ width: '100%' }}>
            <Upload.Dragger
              accept=".csv,.zip"
              customRequest={uploadScenariosMutation.mutate}
            >
              <InboxOutlined />
              <p>{t('import.modal.upload')}</p>
            </Upload.Dragger>
            {/*  <Checkbox onChange={checkedSameZone}>
              {t('import.modal.zone')}
            </Checkbox> */}
          </Space>
        </Modal>
      )}

      {isModalExportVisible && (
        <Modal
          open
          title={t('export.modal.title')}
          onCancel={toggleModalExport}
          footer={[
            <Button type="primary" key="submit" onClick={exportScenario}>
              {t('export.modal.submit')}
            </Button>,
            <Button key="cancel" onClick={toggleModalExport}>
              {t('export.modal.cancel')}
            </Button>,
          ]}
        >
          <Space direction="vertical" style={{ width: '100%' }}>
            <Checkbox onChange={checkedExportMedias}>
              {t('export.modal.medias')}
            </Checkbox>
          </Space>
        </Modal>
      )}
    </PageLayout>
  );
}
