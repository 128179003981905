import React, { useState } from 'react';
import { Alert, Button, DatePicker } from 'antd';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useUserSession } from 'technical/hooks/use-user-session';
import getAlertListFromZone, {
  exportAlerts,
} from 'business/alerts/services/alert-list';
import { PageLayout } from 'ui/layout';
import dayjs from 'dayjs';
import { DownloadOutlined } from '@ant-design/icons';
import { downloadCsv } from 'technical/utils/download';
import AlertList from './alert-list';

export default function AlertsPage() {
  const { t } = useTranslation('alerts');
  const { zone, tenant } = useUserSession();
  const [startDate, setStartDate] = useState(
    dayjs().subtract(7, 'days').toISOString().slice(0, 10),
  );
  const [endDate, setEndDate] = useState(
    dayjs().toISOString().slice(0, 10),
  );
  const [loadingExport, setLoadingExport] = useState(false);

  const alertListQuery = useQuery(
    ['alerts', tenant, { zoneId: zone, startDate, endDate }],
    () => getAlertListFromZone(tenant, zone, startDate, endDate),
  );

  const handleChange = (dates: any, dateStrings: [string, string]) => {
    setStartDate(dateStrings[0]);
    setEndDate(dateStrings[1]);
  };

  async function handleExportAlerts() {
    setLoadingExport(true);
    let alertName;
    if (startDate === '' && endDate === '') {
      alertName = 'alerts_export';
    } else if (endDate === undefined) {
      alertName = `alerts_from_${new Date(
        startDate,
      ).toLocaleDateString()}_to_${new Date().toLocaleDateString()}_export`;
    } else {
      alertName = `alerts_from_${new Date(
        startDate,
      ).toLocaleDateString()}_to_${new Date(
        endDate,
      ).toLocaleDateString()}_export`;
    }

    const blob = await exportAlerts(tenant, startDate, endDate);
    downloadCsv(blob, alertName);
    setLoadingExport(false);
  }

  const { RangePicker } = DatePicker;

  return (
    <PageLayout
      title={t('list.title')}
      leftButton={
        <RangePicker
          onChange={handleChange}
          defaultValue={[dayjs(startDate), dayjs(endDate)]}
        />
      }
      rightButton={
        <Button
          icon={<DownloadOutlined />}
          onClick={() => handleExportAlerts()}
          loading={loadingExport}
        >
          {t('list.button')}
        </Button>
      }
    >
      {alertListQuery.status === 'error' ? (
        <Alert type="error" message={t('list.error')} />
      ) : null}
      <AlertList
        loading={alertListQuery.status === 'loading'}
        alerts={alertListQuery.data}
      />
    </PageLayout>
  );
}
