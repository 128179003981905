import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
// import resourcesToBackend from "i18next-resources-to-backend";
import LanguageDetector from 'i18next-browser-languagedetector';
import { Languages } from './types';
import { resources } from './locales';

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: Languages.fr,
    defaultNS: 'common',
    ns: [
      'common',
      'scenarios',
      'users',
      'login',
      'medias',
      'zones',
      'gears',
      'alerts',
      'interventions',
      'monitoring',
      'digitaltwin',
    ],
    debug: process.env.NODE_ENV === 'development',
    /* other options */

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18next;
