import React, { useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Space, Button, Row, Col, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { useUserSession } from 'technical/hooks/use-user-session';
import { downloadCsv } from 'technical/utils/download';
import { PageLayout } from 'ui/layout';
import getInterventionDetail, {
  exportIntervention,
} from 'business/interventions/services/intervention-detail';
import { getScenarioGear } from 'business/gears/services/gear';
import InterventionHeader from './intervention-header';
import InterventionDetailTable from './intervention-detail-table';
import InterventionTimeline from './intervention-timeline';

type InterventionParams = {
  interventionId: string;
};

export default function InterventionDetailPage() {
  const { interventionId } = useParams<
    keyof InterventionParams
  >() as InterventionParams;
  const { tenant } = useUserSession();
  const { t } = useTranslation('interventions');
  const [loadingExport, setLoadingExport] = useState(false);

  const interventionQuery = useQuery(
    ['intervention', tenant, { interventionId: +interventionId }],
    () => getInterventionDetail(tenant, +interventionId),
  );

  const gearQuery = useQuery(
    [
      'gears',
      tenant,
      { scenarioRef: interventionQuery.data?.scenario.reference },
    ],
    // bang because we know it exist because it's enabled only when data is fetch
    () => getScenarioGear(tenant, interventionQuery.data!.scenario.reference),
    { enabled: interventionQuery.isFetched },
  );

  async function handleExportIntervention() {
    setLoadingExport(true);
    const interventionName = interventionQuery.data?.scenario.label
      ? `${interventionQuery.data.scenario.label}_export`
      : 'export';
    const blob = await exportIntervention(tenant, +interventionId);
    downloadCsv(blob, interventionName);
    setLoadingExport(false);
  }

  return (
    <PageLayout
      title={t('detail.title')}
      rightButton={
        <Space size="small">
          <Button
            icon={<DownloadOutlined />}
            onClick={() => handleExportIntervention()}
            loading={loadingExport}
          >
            {t('detail.buttons.export')}
          </Button>
        </Space>
      }
    >
      <Row>
        <Col span={24}>
          <Skeleton active loading={interventionQuery.isLoading}>
            {interventionQuery.isSuccess ? (
              <InterventionHeader intervention={interventionQuery.data} />
            ) : null}
          </Skeleton>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          {interventionQuery.isSuccess ? (
            <InterventionTimeline intervention={interventionQuery.data} />
          ) : null}
        </Col>
        <Col span={18}>
          {interventionQuery.isSuccess ? (
            <InterventionDetailTable
              intervention={interventionQuery.data}
              gears={gearQuery.data}
            />
          ) : null}
        </Col>
      </Row>
    </PageLayout>
  );
}
