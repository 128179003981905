import React from 'react';
import { useTranslation } from 'react-i18next';
import { useEditor, EditorContent, Editor } from '@tiptap/react';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import TextStyle from '@tiptap/extension-text-style';
import { Color } from '@tiptap/extension-color';
import Bold from '@tiptap/extension-bold';
import OrderedList from '@tiptap/extension-ordered-list';
import Italic from '@tiptap/extension-italic';
import History from '@tiptap/extension-history';
import Underline from '@tiptap/extension-underline';
import Heading from '@tiptap/extension-heading';
import BulletList from '@tiptap/extension-bullet-list';
import ListItem from '@tiptap/extension-list-item';
import clsx from 'clsx';
import { Button, Dropdown, Space, Divider, MenuProps } from 'antd';
import Icon, {
  BoldOutlined,
  EditFilled,
  EditOutlined,
  ItalicOutlined,
  LoadingOutlined,
  OrderedListOutlined,
  UnderlineOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { ReactComponent as H1Svg } from 'ui/icons/type-h1.svg';
import { ReactComponent as H2Svg } from 'ui/icons/type-h2.svg';
import classes from './index.module.scss';

interface RichTextEditorProps {
  defaultValue?: string;
  editable?: boolean;
  loading?: boolean;
  onChange: (value: any) => void;
  customSave: (value: any) => void;
}
export function RichTextEditor({
  defaultValue,
  editable = false,
  loading = false,
  onChange,
  customSave,
}: RichTextEditorProps) {
  const editor = useEditor(
    {
      extensions: [
        Bold,
        BulletList,
        Color,
        Document,
        Heading,
        History,
        Italic,
        ListItem,
        OrderedList,
        Paragraph,
        Text,
        TextStyle,
        Underline,
      ],
      content: defaultValue,
      onUpdate: (params) => onChange(params.editor.getHTML()),
      onBlur: (params) => customSave(params.editor.getHTML()),
      editable: editable && !loading,
      editorProps: {
        attributes: {
          class: classes.richTextEditor,
        },
      },
    },
    [editable, loading, defaultValue],
  );

  return (
    <div className={classes.container}>
      {loading ? (
        <div className={classes.loader}>
          <LoadingOutlined />
        </div>
      ) : null}
      {editable ? (
        <div className={classes.menu}>
          <RichTextEditorMenu disabled={loading} editor={editor} />
        </div>
      ) : null}
      <EditorContent
        editor={editor}
        className={clsx(
          classes.editor,
          editable && classes.editable,
          loading && classes.loading,
        )}
      />
    </div>
  );
}

function RichTextEditorMenu({
  disabled = false,
  editor,
}: {
  disabled: boolean;
  editor: Editor | null;
}) {
  const { t } = useTranslation(['scenarios', 'common']);
  if (!editor) {
    return null;
  }

  const allowedColors: MenuProps['items'] = [
    {
      key: 'black',
      label: t('detail.step-edition.step-type-infos.colours.black'),
      icon: <EditFilled style={{ color: 'black' }} />,
    },
    {
      key: 'purple',
      label: t('detail.step-edition.step-type-infos.colours.purple'),
      icon: <EditFilled style={{ color: 'purple' }} />,
    },
    {
      key: 'grey',
      label: t('detail.step-edition.step-type-infos.colours.grey'),
      icon: <EditFilled style={{ color: 'grey' }} />,
    },
    {
      key: 'silver',
      label: t('detail.step-edition.step-type-infos.colours.silver'),
      icon: <EditFilled style={{ color: 'silver' }} />,
    },
    {
      key: 'green',
      label: t('detail.step-edition.step-type-infos.colours.green'),
      icon: <EditFilled style={{ color: 'green' }} />,
    },
    {
      key: 'red',
      label: t('detail.step-edition.step-type-infos.colours.red'),
      icon: <EditFilled style={{ color: 'red' }} />,
    },
    {
      key: 'orange',
      label: t('detail.step-edition.step-type-infos.colours.orange'),
      icon: <EditFilled style={{ color: 'orange' }} />,
    },
    {
      key: 'yellow',
      label: t('detail.step-edition.step-type-infos.colours.yellow'),
      icon: <EditFilled style={{ color: 'yellow' }} />,
    },
  ];

  const handleColorClick: MenuProps['onClick'] = (event) => {
    editor.commands.setColor(event.key);
  };

  return (
    <Space
      align="center"
      split={<Divider type="vertical" plain className={classes.divider} />}
    >
      <div>
        <Button
          type="text"
          disabled={disabled}
          icon={<Icon component={H1Svg} />}
          onMouseDown={(event) => event.preventDefault()}
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 1 }).run()
          }
          className={clsx({
            [classes.activeButton]: editor.isActive('heading', { level: 1 }),
          })}
        />
        <Button
          type="text"
          disabled={disabled}
          icon={<Icon component={H2Svg} />}
          onMouseDown={(event) => event.preventDefault()}
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }
          className={clsx({
            [classes.activeButton]: editor.isActive('heading', { level: 2 }),
          })}
        />
      </div>
      <div>
        <Button
          type="text"
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={clsx({ [classes.activeButton]: editor.isActive('bold') })}
          disabled={disabled}
          icon={<BoldOutlined />}
          onMouseDown={(event) => event.preventDefault()}
        />
        <Button
          type="text"
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={disabled}
          icon={<ItalicOutlined />}
          onMouseDown={(event) => event.preventDefault()}
          className={clsx({
            [classes.activeButton]: editor.isActive('italic'),
          })}
        />
        <Button
          type="text"
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          disabled={disabled}
          icon={<UnderlineOutlined />}
          onMouseDown={(event) => event.preventDefault()}
          className={clsx({
            [classes.activeButton]: editor.isActive('underline'),
          })}
        />
      </div>
      <div>
        <Button
          type="text"
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          onMouseDown={(event) => event.preventDefault()}
          disabled={disabled}
          icon={<OrderedListOutlined />}
          className={clsx({
            [classes.activeButton]: editor.isActive('orderedList'),
          })}
        />
        <Button
          type="text"
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          disabled={disabled}
          icon={<UnorderedListOutlined />}
          onMouseDown={(event) => event.preventDefault()}
          className={clsx({
            [classes.activeButton]: editor.isActive('bulletList'),
          })}
        />
      </div>
      <div>
        <Dropdown
          menu={{
            items: allowedColors,
            defaultSelectedKeys: ['1'],
            onClick: handleColorClick,
            onMouseDown: (event) => event.preventDefault(),
          }}
        >
          <Button onMouseDown={(event) => event.preventDefault()}>
            {t('detail.step-edition.step-type-infos.colors')}
            <EditOutlined />
          </Button>
        </Dropdown>
      </div>
    </Space>
  );
}
