import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useHandleSteps } from 'business/scenarios/hooks/step';
import { useHandleGears } from 'business/scenarios/hooks/gears';
import { Gear } from 'business/type';
import { queryClient } from 'technical/react-query';
import { useUserSession } from 'technical/hooks/use-user-session';
import { GearAddModal } from './gear-add-modal';

interface StepGearSelectorProps {
  scenarioReference: number;
  stepReference: number;
  scenarioZoneId: number;
  stepZoneId?: number;
  selectedStepId: number;
  list: Gear[];
  canUpdate: boolean;
}

export function StepGearSelector({
  scenarioReference,
  stepReference,
  scenarioZoneId,
  stepZoneId,
  selectedStepId,
  list,
  canUpdate,
}: StepGearSelectorProps) {
  const { tenant } = useUserSession();
  const { t } = useTranslation('scenarios');
  const { deleteGearFromStepWithoutInvalidation, keys: gearKeys } =
    useHandleGears(scenarioZoneId, selectedStepId, scenarioReference);
  const { updateStepWithoutInvalidation } = useHandleSteps({
    scenarioReference,
    stepId: selectedStepId,
    zone: scenarioZoneId,
  });
  const [gearAddModalIsVisible, setGearAddModalIsVisible] = useState(false);

  const handleList = async ({
    added,
    removed,
  }: {
    added: number[];
    removed: number[];
  }) => {
    if (added.length > 0) {
      await updateStepWithoutInvalidation({
        gearsId: added,
        gearsOrder: added,
        stepRef: stepReference,
      });

      await Promise.allSettled(
        removed.map((id) => deleteGearFromStepWithoutInvalidation(id)),
      );
    }

    // Invalidation of update
    queryClient.invalidateQueries(['medias', tenant, { step: selectedStepId }]);
    queryClient.invalidateQueries(['scenarioTasks', tenant]);
    queryClient.invalidateQueries(['answers', tenant, selectedStepId]);
    queryClient.invalidateQueries([
      'scenarioTasks',
      tenant,
      { reference: scenarioReference },
    ]);
    queryClient.invalidateQueries([
      'stepTaskTree',
      tenant,
      { zoneId: scenarioZoneId },
    ]);
    queryClient.invalidateQueries(gearKeys.gearsFromScenario);

    // Invalidation of delete
    queryClient.invalidateQueries(gearKeys.gearsFromZone);
    queryClient.invalidateQueries(gearKeys.allGears);
  };

  return (
    <div>
      {!canUpdate ? null : (
        <Button
          onClick={() => setGearAddModalIsVisible(true)}
          type="link"
          style={{ paddingLeft: '2px', paddingTop: '10px' }}
        >
          <PlusOutlined />
          {t('detail.step-edition.step-type-action.add-gear')}
        </Button>
      )}
      <GearAddModal
        open={gearAddModalIsVisible}
        list={list}
        stepZoneId={stepZoneId}
        onClose={() => setGearAddModalIsVisible(false)}
        onChange={(gearsIds) => handleList(gearsIds)}
      />
    </div>
  );
}
