import { api } from 'technical/api';
import { StepAnswer } from './type';

export async function deleteStep(tenant: string, reference: number) {
  await api(tenant).delete(`/v1/steps/${reference}`);
  return null;
}

export async function createAnswer(
  tenant: string,
  stepRef: number,
  label?: string,
  nextStepRef?: number,
) {
  const { data: answer } = await api(tenant).post<StepAnswer>(`/v1/answers`, {
    stepRef,
    nextStepRef,
    label,
  });

  answer.label = '';

  return answer;
}

export async function createInputRequest(
  tenant: string,
  label: string,
  stepRef: number,
) {
  const { data: inputRequest } = await api(tenant).post(`/v1/inputRequests`, {
    label,
    stepRef,
  });
  return inputRequest;
}

export async function deleteAnswer(tenant: string, answerRef: number) {
  await api(tenant).delete<void>(`/v1/answers/${answerRef}`);
}
