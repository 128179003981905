import { notification } from 'antd';
import {
  createTag,
  getTags,
  deleteTagFromGear,
  deleteTagFromScenario,
  updateTag,
} from 'business/tags/services/tags';
import { QueryKey, useQuery, useMutation } from 'react-query';
import { queryClient } from 'technical/react-query';
import { TagInputCreate, TagInputUpdate } from '../services/type';

export function useHandleTags({
  tenant,
  queryKey,
  type,
  typeId,
}: {
  tenant: string;
  queryKey: QueryKey;
  type: 'gear' | 'scenario';
  typeId?: number; // gearId or scenarioId
}) {
  const invalidateQueries = () => queryClient.invalidateQueries(queryKey);

  const tagQuery = useQuery(queryKey, () => getTags(tenant, type));

  const createTagMutation = useMutation(
    async (input: TagInputCreate) => createTag(tenant, input),
    {
      onSuccess: () => invalidateQueries(),
      onError: (err: Error) => notification.error({ message: err.message }),
    },
  );

  const updateTagMutation = useMutation(
    async (input: TagInputUpdate) => updateTag(tenant, input),
    {
      onSuccess: () => invalidateQueries(),
      onError: (err: Error) => notification.error({ message: err.message }),
    },
  );

  const deleteTagMutation = useMutation(
    async ({ tagId }: { tagId: number }) => {
      if (typeId && type === 'gear') {
        deleteTagFromGear(tenant, typeId, tagId);
      }
      if (typeId && type === 'scenario') {
        deleteTagFromScenario(tenant, typeId, tagId);
      }
    },
    {
      onSuccess: () => invalidateQueries(),
      onError: (err: Error) => notification.error({ message: err.message }),
    },
  );

  return {
    data: tagQuery.data ?? [],
    isLoading:
      tagQuery.isLoading ||
      createTagMutation.isLoading ||
      updateTagMutation.isLoading ||
      deleteTagMutation.isLoading,
    createTag: createTagMutation.mutate,
    updateTag: updateTagMutation.mutate,
    deleteTag: deleteTagMutation.mutate,
  };
}
