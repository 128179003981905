import React from 'react';
import { useTranslation } from 'react-i18next';
import Switch from 'antd/lib/switch';
import { QuestionCircleOutlined, QrcodeOutlined } from '@ant-design/icons';
import { useHandleGears } from 'business/scenarios/hooks/gears';
import { StepGearElement } from 'business/scenarios/services/type';
import { Flex } from 'ui/flex';
import { MandatoryGearHelperTooltip } from '../../helper';
import { StepGears } from './step-gears';
import { StepGearSelector } from './step-gear-selector';
import classes from './step-gears.module.scss';

interface StepGearListProps {
  scenarioReference: number;
  stepReference: number;
  scenarioZoneId: number;
  stepZoneId?: number
  selectedStepId: number;
  list: StepGearElement[];
  isMandatory: boolean;
  canUpdate: boolean;
  onChecked: (checked: boolean) => void;
}

export default function StepGearList({
  scenarioReference,
  stepReference,
  scenarioZoneId,
  stepZoneId,
  selectedStepId,
  list,
  isMandatory,
  canUpdate,
  onChecked,
}: StepGearListProps) {
  const { t } = useTranslation('scenarios');
  const { queries } = useHandleGears(
    scenarioZoneId,
    selectedStepId,
    scenarioReference,
  );

  const gearList = queries.gearsFromScenario.isSuccess
    ? queries.gearsFromScenario.data.filter((gear) =>
        list.find((elem) => elem.value === gear.id),
      )
    : [];

  const sortOrder = list.map((stepGear) => stepGear.value);

  return (
    <Flex direction="column" gap={16}>
      {gearList.length > 0 && gearList[0].zone ? (
        <div>
          <QrcodeOutlined style={{ fontSize: '18px', marginRight: '16px' }} />
          <span style={{ fontSize: '16px' }}>{gearList[0].zone.label}</span>
        </div>
      ) : null}
      <Flex.Child className={classes.gearsPanel}>
        <StepGears
          scenarioReference={scenarioReference}
          stepReference={stepReference}
          scenarioZoneId={scenarioZoneId}
          selectedStepId={selectedStepId}
          list={gearList.sort((a, b) => {
            const indexA = sortOrder.indexOf(a.id);
            const indexB = sortOrder.indexOf(b.id);
            return indexA - indexB;
          })}
          canUpdate={canUpdate}
        />
        <StepGearSelector
          scenarioReference={scenarioReference}
          stepReference={stepReference}
          scenarioZoneId={scenarioZoneId}
          stepZoneId={stepZoneId}
          selectedStepId={selectedStepId}
          list={gearList}
          canUpdate={canUpdate}
        />
      </Flex.Child>
      {list.length > 0 ? (
        <Flex.Child>
          <Flex gap={8}>
            <Flex.Child>
              <MandatoryGearHelperTooltip>
                <QuestionCircleOutlined />
              </MandatoryGearHelperTooltip>
            </Flex.Child>
            {t('detail.step-edition.step-type-checklist.mandatory')}
            <Switch
              checked={isMandatory}
              onChange={(checked) => onChecked(checked)}
              disabled={!canUpdate}
            />
          </Flex>
        </Flex.Child>
      ) : null}
    </Flex>
  );
}
